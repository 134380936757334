// MaintDialog

import React, { useState } from "react"
import { useDispatch } from "react-redux"

import { makeStyles } from '@mui/styles';
import { PageHeading } from "../styles/ftfStyles"
import { Button, Dialog, Divider, Grid, Slide, Typography } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel'
import Language from '@mui/icons-material/Language'

import { SmallButton } from "../styles/ftfStyles"

import { FormattedMessage } from 'react-intl'

import { cleanAllEvents } from '../actions/maint.actions'

// import { addAppLocaleData } from "./localeData";
// addAppLocaleData();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})


const useStyles = makeStyles(theme => ({
    buttonCancel: {
        display: 'flex',
        fontWeight: '400',
        padding: '6px 12px',
        justifySelf: 'center',
        margin: 'auto',
    },

    buttonWide: {
        fontWeight: '400',
        padding: '6px 12px',
        margin: 2,
        maxWidth: 275,
        minWidth: 275,
        justifySelf: 'center',
    },

    dialogPaper: {
        margin: 8,
        padding: 8,
        overflow: 'hidden',

        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        border: '2px solid',
    },

    icon: {
        color: theme.palette.primary.dark,
        flexshrink: 0,
        marginRight: 8,
        marginTop: 3,
    },
}))



export default function MaintDialog(props) {
    const classes = useStyles(props)
    const dispatch = useDispatch()

    const [isClearSystemEventsDisabled, setIsClearSystemEventsDisabled] = useState(false)


    //-------------------------------------------
    // 
    //-------------------------------------------
    // useEffect(() => {
    //     console.log('Maint useEffect called..  ')
    //     setIsClearSystemEventsDisabled(isClearSystemEventsDisabled)
    // }, [isClearSystemEventsDisabled])


    //-------------------------------------------
    // Clear All System Events
    //-------------------------------------------
    function handleClearSystemEvents(e) {
        e.stopPropagation()
        dispatch(cleanAllEvents())
        setIsClearSystemEventsDisabled(true)
    }


    //-------------------------------------------
    // Clear All System Events
    //-------------------------------------------
    function handleDialogClose(e) {
        props.onClose()
        setIsClearSystemEventsDisabled(false)
    }

    return (
        <Dialog classes={{ paper: classes.dialogPaper }}
            fullScreen={props.fullscreen}
            open={props.show}
            onClose={props.onClose}
            TransitionComponent={Transition}
        >
            <Grid container style={{ display: 'flex', justifyContent: "center" }}>
                <Typography className={classes.icon} variant="h5">
                    <Language />
                </Typography>

                <Typography style={PageHeading} variant="h6">
                    System Maintenance
                </Typography>
            </Grid>


            <Grid container style={{ display: 'flex', justifyContent: "center" }}>

                <Button disabled={isClearSystemEventsDisabled} variant="contained" color="primary" size="large" className={classes.buttonWide} onClick={(e) => handleClearSystemEvents(e)}>
                    Clear all system events
                </Button>

            </Grid>

            <Divider style={{ margin: 8 }} />

            <Grid container style={{ display: 'flex', justifyContent: "center" }}>
                <Button variant="contained" color="primary"
                    style={SmallButton} onClick={handleDialogClose}
                    startIcon={<CancelIcon aria-label='Close' />}
                >
                    <FormattedMessage id="system.close" defaultMessage="Close" />
                </Button>
            </Grid>
        </Dialog>
    )
}

