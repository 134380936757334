// search.Reducer.js
import moment from 'moment';
import { LOGOUT } from '../actions/auth.actions'
import configureSocket from '../_components/socket';
import { joinRoom, leaveRoom, rejectMeeting } from '../_components/socket';
import isEmpty from '../validation/is-empty'

import { ONLINE_COUNT, SOCKET_CONNECT } from '../actions/socket.actions';
import { STOP_DEVICES, DEVICES } from '../actions/socket.actions';
import { MEETING_MSG, MEETING_CREATE, MEETING_CREATED, MEETING_JOIN, MEETING_LEAVE, MEETING_REQUEST, MEETING_REJECTED } from '../actions/socket.actions';

import { UPLOAD_LIST, UPLOAD_START, UPLOAD_FINISHED, UPLOAD_PROGRESS } from '../actions/socket.actions';


const initialState = {
    mySocket: {},
    usersOnline: 0,

    // VideoChat
    showVideoChat: false,
    user: null,

    isRoomCreator: 'false',

    guestId: null,      // <------------<<<<<  app.user.guestId
    accessId: '',

    // From a request, don't overwrite 'accessId' until accepted
    accessIdNew: '',
    userNew: null,

    videoChatMsgName: null,
    videoChatMsgId: null,

    localStream: null,
    remoteStream: null,

    // Debugging
    audioStopDevices: '',
    audioInputSelect: '',
    audioOutputSelect: '',
    videoSelect: '',

    // videoChats: [],  // Future: List of chats 

    uploading: false,
    uploadList: {},
    uploadProgress: 0,

    errors: {},

    receivedAt: moment().format('lll SSS'),
}

export default function (state = initialState, action) {

    switch (action.type) {
        case LOGOUT:
            if (!isEmpty(state.mySocket) && !isEmpty(state.guestId) && state.accessId != '' )
                leaveRoom(state.mySocket, state.accessId, action.userId, state.guestId);

            return {
                initialState
            }

        case ONLINE_COUNT:
            return {
                ...state,
                usersOnline: action.msg,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case SOCKET_CONNECT: {
            let s = configureSocket(state.mySocket, action.id)
            state = { ...state, mySocket: s }
            break;
        }

        //---------------------------------------
        // Video Chat
        //---------------------------------------
        case STOP_DEVICES: // ...from navigator.mediaDevices.enumerateDevices()
            state = {
                ...state,
                audioStopDevices: action.audioStopDevices,
                receivedAt: moment().format('lll SSS'),
            }

            return state

        case DEVICES: // ...from navigator.mediaDevices.enumerateDevices()
            state = {
                ...state,
                audioStopDevices: [],
                audioInputSelect: action.audioInputSelect,
                audioOutputSelect: action.audioOutputSelect,
                videoSelect: action.videoSelect,
                receivedAt: moment().format('lll SSS'),
            }

            return state

        case MEETING_MSG: // ...from Server
            state = {
                ...state,
                videoChatMsgName: action.guestName,
                videoChatMsgId: action.msgId,
                receivedAt: moment().format('lll SSS'),
            }

            return state

        case MEETING_REQUEST: // ...from Member
            state = {
                ...state,
                videoChatMsgId: 'videoChat.request',
                accessIdNew: action.accessId,
                userNew: action.user,
                guestId: action.user.id,
                receivedAt: moment().format('lll SSS'),
            }

            return state

        case MEETING_CREATE:   // ROOM = ACCESS ID
            state = {
                ...state,
                videoChatMsgName: null,
                videoChatMsgId: null,
                localStream: null,
                remoteStream: null,

                guestId: action.guestId,
                accessId: action.accessId,
                isRoomCreator: action.isRoomCreator,
                receivedAt: moment().format('lll SSS'),
            }

            if (state.mySocket) {
                // state.mySocket.emit('join', action.userId, action.guestId, action.accessId)
                joinRoom(state.mySocket, action.userId, action.guestId, action.accessId)
            }

            return state

        case MEETING_CREATED:
            return {
                ...state,
                videoChatMsgName: null,
                videoChatMsgId: null,
                localStream: action.localStream,
                remoteStream: action.remoteStream,
                receivedAt: moment().format('lll SSS'),
            }

        // state = { ...state, videoChats: state.videoChats.splice(1, 1, action.localStream) };
        // break;

        case MEETING_JOIN:
            state = {
                ...state,
                // Make the new accesId the current accessId
                accessId: state.accessIdNew,
                user: state.userNew,
            }

            joinRoom(state.mySocket, action.userId, action.guestId, state.accessIdNew);

            break;

        case MEETING_REJECTED:
            rejectMeeting(state.mySocket, action.userId, state.guestId, state.accessIdNew);

            state = {
                ...state,
                accessIdNew: null,
                userNew: null,
                guestId: null,
            }

            break;

        case MEETING_LEAVE:
            leaveRoom(state.mySocket, state.accessId, action.userId, state.guestId);

            state = {
                ...state,
                accessId: null,
                videoChatMsgName: null,
                videoChatMsgId: 'videoChat.closing',
                localStream: null,
                remoteStream: null,
                isRoomCreator: false,
                guestId: null,

                // Future - Multiple chats 
                // videoChats: state.videoChats.filter(chat => chat.id !== action.localStream.id) 
                receivedAt: moment().format('lll SSS'),
            }

            return state


        //---------------------------------------
        // Upload
        //---------------------------------------
        case UPLOAD_LIST:
            // Pseudo code: 
            //  o Get image upload list
            //  o Uploading
            //  o Uploading Finished
            // 
            // emit event to the web socket so that the server is updated
            return {
                ...state,
                uploadList: action.uploadList,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case UPLOAD_START:
            return {
                ...state,
                uploading: true,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case UPLOAD_FINISHED:
            return {
                ...state,
                uploading: false,
                uploadProgress: 0,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case UPLOAD_PROGRESS:
            return {
                ...state,
                uploadProgress: action.uploadProgress,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        default:
            break;
    }

    return state;
}

