// https://www.twilio.com/blog/2017/10/implement-chat-javascript-nodejs-react-apps.html
// https://blogs.msdn.microsoft.com/cdndevs/2014/09/19/a-chatroom-for-all-part-3-building-a-backend-with-node-mongo-and-socket-io/


import React from "react"

import { useSelector } from "react-redux"
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles'
import { ImageList, ImageListItem } from "@mui/material"

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh

import ChatPageMsg from './ChatsPageMsg'

// https://github.com/topics/spinners
import BeatLoader from 'react-spinners/BeatLoader'
import { css } from 'styled-components' 

const cssBeatLoader = css`
    display: block
    margin: 0 auto
    border-color: red
`

const useStyles = makeStyles(theme => ({
    content: {
        flexGrow: 1,
        width: '100%',
        overflow: 'hidden',
    },

    imageList: {
        overflow: 'hidden',
        margin: 0,
    },
}))


export default function ChatsPageMsgs(props) {
    const classes = useStyles(props)
    const theme = useTheme()

    const messages = useSelector(state => state.chat.messages)
    const isLoading = useSelector(state => state.chat.isLoading)

    return (
        <div>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div className={classes.content}>
                    {messages && <ImageList className={classes.imageList} cols={1}>
                        {messages.map((msg, i) => {
                            return (
                                <ImageListItem key={i} style={{ height: 'auto' }}>
                                    <ChatPageMsg msg={msg} />
                                </ImageListItem>
                            )
                        })}
                    </ImageList>}
                </div>
            </div>

            <div id='spinner' style={{ boxSizing: "content-box", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <BeatLoader css={cssBeatLoader} sizeUnit={"px"} size={32} color={theme.palette.primary.dark} loading={isLoading} />
            </div>
        </div>
    )
}

