// https://til.neilmagee.com/post/material-ui-theming-and-style-overrides-pt3/

import { createTheme, lighten } from '@mui/material/styles';  // responsiveFontSizes

import { lightBlue, purple, pink, grey } from '@mui/material/colors';

//-----------------------------------------------
// Localization
//-----------------------------------------------
// https://medium.com/ableneo/internationalize-react-apps-done-right-using-react-intl-library-82978dbe175e
// https://stackoverflow.com/questions/35776663/react-intl-multi-language-app-changing-languages-and-translations-storage
// https://www.smashingmagazine.com/2017/01/internationalizing-react-apps/


// const breakpoints = createBreakpoints({})

// Theme Customization
// https://material-ui.com/guides/typescript/#customization-of-theme

const ThemeApp = createTheme({
    adHeight: {
        lg: '90px',
        md: '90px',
        sm: '50px',
        xs: '100px',
    },

    toolbarHeight: '44px',

    headerHeight: '34px',  // Max height of font: 1rem + 1px
    profileHeaderHeight: '35px',

    memberProfileHeaderHeight: '76px',
    // memberProfileHeaderHeight: '95px',

    smallTabHeight: '31px',
    mediumTabHeight: '31px',
    largeTabHeight: '31px',

    drawerWidth: 153,

    // Adjust Ad Height
    // -----------------------------------------------------------
    // value         |0px     600px    960px    1280px   1920px
    // key           |xs      sm       md       lg       xl
    // screen width  |--------|--------|--------|--------|-------->
    // range         |   xs   |   sm   |   md   |   lg   |   xl

    // *Note: Doesn't work!! - DSW
    // adHeight: '200px',

    // // Phone Landscape
    // [breakpoints.down('sm')]: {
    //     adHeight: '50px',
    // },

    // // // Phone Portrait
    // [breakpoints.down('xs')]: {
    //     adHeight: '100px',
    // },

    palette: {
        primary: {
            light: lightBlue[100],
            main: lightBlue[800],
            dark: lightBlue[900],
            contrastText: '#fff',
        },

        secondary: {
            light: purple['A200'],
            main: purple[600],
            dark: purple[900],
            contrastText: '#fff',
        },

        // FTF Background is info.main and info.dark
        info: {
            light: grey[50],
            main: grey[200],
            dark: grey[400],
            contrastText: '#000',
        },

        error: {
            light: pink[100],
            main: pink[600],
            dark: grey[800],
            contrastText: '#fff',
        },

        warning: {
            light: pink[100],
            main: pink[600],
            dark: grey[800],
            contrastText: '#fff',
        },

        // success: 
    },

    components: {
        //---------------------------------------
        // MuiBadge
        //---------------------------------------
        MuiBadge: {
            styleOverrides: {
                root: {
                    verticalAlign: 'baseline',
                },
            }
        },

        //---------------------------------------
        // Button
        //---------------------------------------
        MuiButton: {
            styleOverrides: {
                root: {
                    // background: 'linear-gradient(90deg, #FE6B8B 30%, #FFFFFF 90%)',
                    fontWeight: '400',
                    lineHeight: 1.0,

                    minHeight: '20px',
                    minWidth: '40px',

                    height: 'auto',
                    width: 'auto',
                    padding: '4px 10px',
                    textTransform: 'none',
                },

                text: {
                    padding: '4px 6px',
                    fontWeight: '400',
                    lineHeight: 1.0,
                },
            }
        },

        //---------------------------------------
        // MuiButtonBase
        //---------------------------------------
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    focusRipple: true,

                    // '.MuiTouchRipple-child': {
                    //     color: pink[800],
                    //     // animation: `250ms ease-out`
                    // }, 

                    '& .Mui-expanded': {
                        minHeight: 32,
                        maxheight: 32,
                        height: 32,
                    },

                    '& MuiAccordionSummary-root.Mui-expanded': {
                        minHeight: 32,
                        maxheight: 32,
                        height: 32,
                    },
                },

                focusVisible: {
                },

                //---------------------------------------
                // TouchRipple
                //--------------------------------------- 
                // TouchRippleProps: {
                //     ripple: {
                //         color: "#fff",
                //         backgroundColor: green[600],
                //     }
                // },
            }
        },

        MuiIconButton: {
            styleOverrides: {
                root: {
                    padding: '6px',
                },
            }
        },

        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: '6px',
                },
            }
        },

        //---------------------------------------
        // Cards
        //---------------------------------------

        // MuiCard: {
        //     root: {
        //         padding: '4px'
        //     }
        // },

        // MuiCardContent: {
        //     root: {
        //         padding: '0',
        //         // backgroundColor: "#b4d455",
        //     },
        // },

        //---------------------------------------
        // Dialog
        //---------------------------------------
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    padding: '8px 12px',
                },
            }
        },

        //---------------------------------------
        // MuiExpansionPanel
        MuiExpansionPanel: {
            styleOverrides: {
                root: {
                    boxShadow: 'none',
                },
            }
        },

        //--------------------------------------
        // MuiAccordionSummary-root-457.MuiAccordionSummary-expanded
        //--------------------------------------
        // MuiAccordion: {
        //     styleOverrides: {
        //         root: {
        //             minHeight: 32,
        //             height: 32,
        //         },

        //         expanded: {
        //             minHeight: 32,
        //             height: 32,
        //             // padding: '0 12px 0 8px',
        //         },
        //     }
        // },

        //--------------------------------------
        // MuiAccordionSummary-root-457.MuiAccordionSummary-expanded
        //--------------------------------------
        MuiAccordionSummary: {
            styleOverrides: {
                root: {
                    // Needs to be Transparent
                    // backgroundColor: grey[100],

                    // borderBottom: '2px solid #e8e8e8',
                    // borderTopLeftRadius: 10,
                    // borderTopRightRadius: 10,
                    // boxShadow: 'none',

                    // '& .Mui-expanded': {
                    //     minHeight: 32,
                    //     maxheight: 32,
                    //     height: 32,
                    //     alignItems: 'center',
                    // },

                    // minHeight: 32,
                    // maxheight: 32,
                    // height: 32,
                    // padding: '0 12px 0 8px',

                    // cursor: 'pointer',

                    '&:hover': {
                        backgroundColor: lighten(lightBlue[100], 0.75),
                    },

                    '&:active': {
                        backgroundColor: lighten(lightBlue[100], 0.50),
                    },

                    // '&$expanded': {
                    //     // minHeight: 32,
                    //     // height: 32,
                    //     // 
                    //     // backgroundColor: lighten(lightBlue[100], 0.50),
                    // },
                },
            }
        },

        // MuiExpansionPanelActions: {
        //     styleOverrides: {
        //         root: {
        //             padding: '8px',
        //         },
        //     }
        // },
        
        MuiList: {
            styleOverrides: {
                padding: {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
            }
        },

        MuiListItem: {
            styleOverrides: {
                root: {
                    padding: '0px 4px',
                    // color: 'purple',
                    
                    '&.Mui-selected': {
                        backgroundColor: lightBlue[50],
                    },
                    
                    '.MuiTouchRipple-child': {
                        color: lightBlue[800],
                        // animation: `250ms ease-out`
                    }, 
                },
            }
        },

        MuiListItemText: {
            styleOverrides: {
                root: {
                    padding: '0px 4px',
                    // marginBottom: 2,
                    // marginTop: 2,

                    '&.Mui-selected': {
                        backgroundColor: 'green',
                    },
                },
            }
        },



        MuiMenuItem: {
            styleOverrides: {
                root: {
                    padding: '4px 6px',
                    // marginBottom: 2,
                    // marginTop: 2,
                },

                primary: {
                    color: lightBlue[800],
                    // fontSize: '0.9em',
                },
            }
        },

        //---------------------------------------
        // MuiSelect
        //---------------------------------------
        MuiSelect: {
            styleOverrides: {
                // see https://github.com/mui-org/material-ui/issues/9826
                select: {
                    paddingLeft: 4,

                    // "&:focus": {
                    //     background: "#3A3660"
                    // },
                    // color:'white'
                },
            }
        },

        MuiStepper: {
            styleOverrides: {
                root: {
                    padding: '3px 0',
                },
            }
        },

        // MuiStep: {
        //     horizontal: {
        //         paddingRight: 4,
        //     },
        // },

        MuiStepLabel: {
            styleOverrides: {
                iconContainer: {
                    paddingRight: 6,
                },
            }
        },


        //---------------------------------------
        // MuiTabs
        //---------------------------------------  
        MuiTabs: {
            styleOverrides: {
                root: {
                    color: 'white',   // Tab text color
                    minHeight: 25, // smallTabHeight,
                }
            }
        },

        //---------------------------------------
        // MuiTab
        //---------------------------------------  
        MuiTab: {
            styleOverrides: {
                root: {
                    color: 'white',
                    backgroundColor: lightBlue[800],

                    // borderBottom: '3px solid #0277bd',
                    borderBottom: '3px solid #fff',
                    borderRight: '2px solid #fff',
                    borderLeft: '2px solid #fff',

                    borderTopLeftRadius: 10,
                    borderTopRightRadius: 10,


                    lineHeight: 1.5,
                    opacity: 1,
                    minHeight: 25, // smallTabHeight,
                    minWidth: 'unset',
                    padding: 2,

                    // fontSize: '1rem',

                    // [breakpoints.up('md')]: {
                    //     fontSize: '1rem',
                    // },

                    '&:hover': {
                        color: 'white',
                        backgroundColor: lightBlue[500],
                    },

                    '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: lightBlue[600],
                    },
                },
            }
        },

        //---------------------------------------
        // Tables
        //---------------------------------------

        MuiTableHead: {
            root: {
                backgroundColor: lightBlue[600],
            },
        },

        MuiTableRow: {
            root: {
                borderBottom: '1px solid #e8e8e8',

                '&$hover:hover': {
                    backgroundColor: lighten(purple[100], 0.85),
                },

                '&$selected': {
                    fontWeight: "bold",
                    backgroundColor: lighten(lightBlue[100], 0.85),
                }
            },
        },

        MuiTableCell: {
            root: {
                borderBottom: '1px solid #e8e8e8',
                padding: '2px',
                "&:last-child": {
                    paddingRight: 4
                }
            },

            head: {
                color: '#f0f',
                fontSize: '2.0vh',
                textAlign: 'start',
                maxWidth: '300px',
            },

            body: {
                padding: 2,
                color: lightBlue[600],
            },
        },

        //---------------------------------------
        // ToolTip
        //---------------------------------------
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: ".75rem",
                    color: "#fff",
                    backgroundColor: lightBlue[600]
                },

                arrow: {
                    color: lightBlue[600],
                },
            }
        },





        //---------------------------------------
        // MuiInputBase
        //---------------------------------------
        MuiInputBase: {
            styleOverrides: {
                root: {
                    '& .MuiInputBase-input': {
                        padding: 2,  // narrowing
                    }
                },
            },
        },


        //---------------------------------------
        // MuiOutlinedInput
        //---------------------------------------
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    '&$focused $notchedOutline': {
                        borderColor: 'lightblue',
                        borderWidth: 1.5,
                    },
                    padding: "6px 4px",
                },

                input: {
                    padding: "10px 8px",
                },

                multiline: {
                    padding: "6px 6px",
                },
            },
        },
    },
});

export default ThemeApp;