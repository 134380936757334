
import React, { useEffect, useState } from "react"
// import { makeStyles } from '@mui/material/styles'
// import { useTheme } from '@mui/material/styles';

import isEmpty from '../validation/is-empty'

import FTF_Images from './FTF_Images'
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone'
import LED_GreenFlash from '../images/LED_GreenFlash.png'

import styled, { css } from 'styled-components'   // keyframes

const animatedCss = css`
    opacity: 1;
    transform: translateY(0);
`

const StyledFirstname = styled.div`
    text-align: center;
    font-size: .6rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-height: ${props => (props.small ? '48px' : (props.big ? '128px' : '64px'))};
    max-width: ${props => (props.small ? '48px' : (props.big ? '128px' : '64px'))};
`

// background-color: ${props => (props.borderColor ? props.borderColor : props.borderColor)};
// borderRadius: '5px 5px 5px 20px','#FFF'
// margin: ${props => (props.noMargin ? 0 : '0 0 4px 0')};  // box-shadow needs a margin

const StyledAvatar = styled.div`
    background-color: #fff;
    border: 2px solid ${props => (props.borderColor ? props.borderColor : '#FFF')};
    box-shadow: 0 4px 8px -5px rgba(0, 0, 0, 1);
    border-radius: 20%;

    height: ${props => (props.small ? '48px' : (props.big ? '128px' : '64px'))};
    width: ${props => (props.small ? '48px' : (props.big ? '128px' : '64px'))};
    min-height: ${props => (props.small ? '48px' : (props.big ? '128px' : '64px'))};
    min-width: ${props => (props.small ? '48px' : (props.big ? '128px' : '64px'))};
    
    opacity: 0;
    display: block;
    margin: auto;
    margin-bottom: 4px;
    position: relative;

    transform: translateY(50px);
    transition: ${props => (props.noAnimation ? 0 : '250ms')} all ease-in-out;

    ${props => props.animated && animatedCss}
`

const StyledImage = styled.img`
    src: ${props => props.src}

    height: ${props => (props.small ? '45px' : (props.big ? '128px' : '60px'))};
    width: ${props => (props.small ? '45px' : (props.big ? '128px' : '60px'))};
    min-height: ${props => (props.small ? '45px' : (props.big ? '128px' : '60px'))};
    min-width: ${props => (props.small ? '45px' : (props.big ? '128px' : '60px'))};

    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    border-radius: 20%;
`

// const useStyles = makeStyles(theme => ({
//     actions: {
//         display: 'flex',
//     },

//     fab: {
//         position: 'absolute',
//         bottom: theme.spacing(1),
//         right: theme.spacing(4),
//     },

//     imageOnline: {
//         width: 10,
//         height: 10,
//         position: 'absolute',
//         top: 4,
//         left: 4,
//     },

// }))


// https://levelup.gitconnected.com/building-a-reusable-component-system-with-react-js-and-styled-components-4e9f1018a31c?gi=d9a0a6b14e11
// https://gist.github.com/gisderdube
export default function Avatar(props) {
    // const classes = useStyles(props)
    // const theme = useTheme()

    const { borderColor, big, small, delay = 0, alt, gender, isAdmin, isOnline, firstname, noAnimation, noMargin } = props

    const [isAnimationOver, setIsAnimationOver] = useState(false)

    useEffect(() => {
        var timerId = null

        if (isAnimationOver) {
            return
        }
        else {
            timerId = setTimeout(() => {
                setIsAnimationOver(true)
            }, noAnimation ? 0 : props.delay)
        }

        return () => clearTimeout(timerId)
    }, [isAnimationOver, noAnimation, props.delay])

    var src = props.avatarUrl

    // Generic Gender Images
    if (isEmpty(src)) {
        switch (parseInt(gender)) {
            case 2:     // Woman interested in men
                src = FTF_Images.genderF  
                break
            case 4:     // Man interested in women
                src = FTF_Images.genderM
                break
            case 8:     // Woman interested in women
                src = FTF_Images.genderFF
                break
            case 16:    // Man interested in men
                src = FTF_Images.genderMM
                break
            default:
                break
        }
    }

    return (
        <div>
            <StyledAvatar
                animated={isAnimationOver}
                borderColor={borderColor}
                big={big}
                delay={delay}
                noAnimation={noAnimation}
                noMargin={noMargin}
                small={small}
                {...props}
            >
                <StyledImage aria-label={alt} src={src} big={big} small={small} />

                {isOnline > 0 && <img alt='Online' src={LED_GreenFlash} style={{ opacity: { isOnline }, position: 'absolute', top: 0, right: 0, width: '12px', height: '12px' }} />}
            </StyledAvatar>

            {firstname && <StyledFirstname>
                {isAdmin > 0 && <SecurityTwoToneIcon color="primary" style={{ marginBottom: -2, width: '12px', height: '12px' }} />} {firstname}
            </StyledFirstname>}
        </div>
    )
}

