import 'leaflet/dist/leaflet.css'
import MapNew from './MapNew'

import React, { useEffect, useState, Fragment } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles';

// import { useDebounce } from 'use-debounce'

import isEmpty from '../validation/is-empty'

// import { MyTabbedLayout } from "../styles/ftfStyles"

import UploadImage from "./UploadImage"
import { DialogHeading, GeneralText } from "../styles/ftfStyles"
import { GroupType } from '../constants/ftfDefinitions'

import { Box, Button, Dialog, DialogActions, Divider, FormControlLabel, Grid, MenuItem, Select, Switch, Slide, TextField, Typography } from '@mui/material'

import Stepper from '@mui/material/Stepper'
import Step from '@mui/material/Step'
import StepLabel from '@mui/material/StepLabel'

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'
import CancelIcon from '@mui/icons-material/Cancel'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import Group from '@mui/icons-material/Group'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import { FormattedMessage, useIntl } from 'react-intl'

// import { groupChangesUpdate } from '../actions/group.actions'
import { groupCreate } from '../actions/group.actions'
import styled from 'styled-components'   // keyframes


const StyledPhoto = styled.div`
    width: auto;
    height: auto;
        
    &:hover {
        box-shadow: 5px 5px 15px 0px rgba(0, 153, 255, 0.3);
    }

    box-shadow: 5px 5px 15px 0px rgba(0, 0, 0, 0.3);  
    
    transition: box-shadow 0.5s;
    will-change: transform;

    display: block;
`


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        margin: 8,
        height: 620,
        width: 400,
        overflow: 'hidden',

        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        border: '2px solid',
    },

    groupTypeSelect: {
        margin: 0,
        minWidth: 250,
        width: '100%',
    },

    headerRegion: {
        overflow: 'hidden',
        marginLeft: 8,
    },

    icon: {
        color: theme.palette.primary.dark,
        margin: '4px 6px 0 0',
    },

    button: {
        marginRight: theme.spacing(1),
    },

    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}))





// GroupCreate
export default function GroupCreate(props) {
    const classes = useStyles(props)
    const intl = useIntl()

    // const errors = useSelector(state => state.profile.errors)
    // const group = useSelector(state => state.group.group)
    const profile = useSelector(state => state.profile.profile)

    const dispatch = useDispatch()


    // Steps
    const [activeStep, setActiveStep] = React.useState(0)
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps()

    const [groupNameError, setGroupNameError] = useState(null)
    const [descriptionError, setDescriptionError] = useState(null)
    // const [imageError, setImageError] = useState(null)

    // Name and Description
    const [groupName, setGroupName] = useState('')
    // const [debouncedGroupName] = useDebounce(groupName, 1500)
    const [description, setDescription] = useState('')
    // const [debouncedDescription] = useDebounce(description, 1500)

    const [groupType, setGroupType] = useState(4)  // Personal

    const [groupVis, setGroupVis] = useState('')
    const [groupVisState, setGroupVisState] = useState('')
    const [isVisible, setIsVisible] = useState(false)

    const [isLocal, setIsLocal] = useState(false)

    // Image
    const [previewHeight, setPreviewHeight] = useState(400)
    const [previewWidth, setPreviewWidth] = useState(400 * .66)
    const [imageSrc, setImageSrc] = useState(null)
    const [showUploadImage, setShowUploadImage] = useState(false)
    // const [imageType, setImageType] = useState(null)
    // const [imageDesc, setImageDesc] = useState("")

    // Location/IsLocal  
    const [location, setLocation] = useState({
        lng: profile.location ? profile.location.coordinates[0] : -88.08038399999998,
        lat: profile.location ? profile.location.coordinates[1] : 42.1707776,
    })


    // Create - Step Labels
    function getSteps() {
        return [
            intl.formatMessage({ id: 'group.create.step1', defaultMessage: 'Details' }),
            intl.formatMessage({ id: 'group.create.step2', defaultMessage: 'Photo' }),
            intl.formatMessage({ id: 'group.create.step3', defaultMessage: 'Location' }),
        ]
    }


    //-------------------------------------------
    // Indicators
    //-------------------------------------------
    useEffect(() => {
        // Visibility
        setGroupVis(intl.formatMessage({ id: 'group.yourGroupIs', defaultMessage: 'Your group is' }))

        setGroupVisState(intl.formatMessage({ id: 'system.visible', defaultMessage: 'Visible' }))
        if (!isVisible)
            setGroupVisState(intl.formatMessage({ id: 'system.hidden', defaultMessage: 'Hidden' }))

    }, [intl, groupVis, groupVisState, isVisible])


    // Was Step skipped?
    const isStepSkipped = React.useCallback(
        (step) => {
            return skipped.has(step)
        },
        [skipped]
    )


    // Step was skipped...
    const addSkippedStep = React.useCallback(
        (step) => {
            if (isStepSkipped(step))
                return

            setSkipped((prevSkipped) => {
                const newSkipped = new Set(prevSkipped.values());
                newSkipped.add(step)
                return newSkipped
            })
        },
        [isStepSkipped]
    )


    // Remove from skipped
    const removeSkippedStep = React.useCallback(
        (step) => {
            let newSkipped = skipped;
            if (isStepSkipped(step)) {
                newSkipped = new Set(skipped.values());
                newSkipped.delete(step);
                setSkipped(newSkipped);
            }
        },
        [isStepSkipped, skipped]
    )


    // 
    const validateStep = React.useCallback(
        () => {
            setGroupNameError(null)
            setDescriptionError(null)
            // setImageError(null)

            // Validate each step...
            switch (activeStep) {
                case 0: // Name and Desc
                    if (isEmpty(groupName)) {
                        addSkippedStep(activeStep)
                        setGroupNameError(intl.formatMessage({ id: 'system.required', defaultMessage: 'system.required' }))
                        return
                    }

                    if (groupName.length < 6) {
                        addSkippedStep(activeStep)
                        setGroupNameError(intl.formatMessage({ id: 'group.nameShort', defaultMessage: 'group.nameShort' }))
                        return
                    }

                    if (isEmpty(description)) {
                        addSkippedStep(activeStep)
                        setDescriptionError(intl.formatMessage({ id: 'system.required', defaultMessage: 'system.required' }))
                        return
                    }

                    if (description.length < 15) {
                        addSkippedStep(activeStep)
                        setDescriptionError(intl.formatMessage({ id: 'system.descShort', defaultMessage: 'system.descShort' }))
                        return
                    }

                    removeSkippedStep(activeStep)
                    break

                case 1: // Photo
                    if (isEmpty(imageSrc)) {
                        addSkippedStep(activeStep)
                        // setImageError(intl.formatMessage({ id: 'system.required', defaultMessage: 'system.required' }))
                        return
                    }

                    removeSkippedStep(activeStep)
                    break

                case 2: // Location
                    break

                default:
                    break
            }
        },
        [intl, activeStep, description, groupName, imageSrc, addSkippedStep, removeSkippedStep]
    )


    // useEffect(() => {
    //     // Local or Global Switch
    //     setLocalGlobal(intl.formatMessage({ id: 'group.local', defaultMessage: 'Local Group' }))
    //     if (!isLocal)
    //         setLocalGlobal(intl.formatMessage({ id: 'group.global', defaultMessage: 'Global Group' }))

    // }, [intl, isLocal])



    // Next
    const handleNext = () => {
        validateStep(activeStep)
        setActiveStep((prevActiveStep) => (prevActiveStep < steps.length - 1) ? prevActiveStep + 1 : prevActiveStep)
    }

    // Back
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }

    // Reset
    // const handleReset = () => {
    //     setActiveStep(0)
    // }


    //-------------------------------------------
    // Group Create
    //-------------------------------------------
    function onGroupCreate() {
        // Any more validation???

        var group = {
            groupName: groupName,
            description: description,

            groupType: groupType,
            isPrivate: isVisible,
            isLocal: isLocal,
            url: imageSrc,

            location: location,
        }

        dispatch(groupCreate(group))

        props.onClose()
    }

    //-------------------------------------------
    // Upload Image Dialog
    //-------------------------------------------
    const showUploadImageDialog = () => {
        setShowUploadImage(true)
    }

    const onUploadImageClose = (e, reason) => {
        if (reason === 'backdropClick')
            return

        setShowUploadImage(false)
    }


    //-------------------------------------------
    // Group Name
    //-------------------------------------------
    function handleGroupNameChange(v) {
        setGroupName(s => v)
        validateStep()
    }

    //-------------------------------------------
    // Group Description
    //-------------------------------------------
    function handleDescriptionChange(v) {
        setDescription(s => v)
        validateStep()
    }

    //-------------------------------------------
    // onGroupTypeChanged
    //-------------------------------------------
    // const onGroupTypeChanged = useCallback((e) => {
    //     if (!isLoading && e.target.value !== group.groupType) {
    //         dispatch(groupSettings(group._id, { groupType: e.target.value }))
    //     }

    // }, [dispatch, isLoading, group])

    //-------------------------------------------
    // Details
    //-------------------------------------------
    function onStep0() {
        return (
            <Fragment>
                {/* Group Name */}
                < Grid container direction="row" >
                    <Typography style={GeneralText} variant="subtitle2">
                        <FormattedMessage id="group.name" defaultMessage="Group Name" />
                    </Typography>
                </Grid >

                <Grid item xs sm md>
                    <TextField fullWidth id="GroupName"
                        // label=<FormattedMessage id="group.name" defaultMessage="Group Name"/> 
                        name="groupName"
                        value={groupName}
                        rows="2"
                        variant="outlined"
                        onChange={(e) => handleGroupNameChange(e.target.value.trimStart())}

                        error={!isEmpty(groupNameError)}
                        helperText={groupNameError}

                        InputLabelProps={{ shrink: true }}
                        inputProps={
                            { maxLength: 75, minLength: 6 }
                        }
                    />
                </Grid>


                {/* Group Description */}
                <Grid container direction="row" style={{ marginTop: 8 }}>
                    <Typography style={GeneralText} variant="subtitle2">
                        <FormattedMessage id="group.about" defaultMessage="What we're about" />   <FormattedMessage id="system.searchable" defaultMessage="(searchable)" />
                    </Typography>
                </Grid>

                <Grid item xs sm md>
                    <TextField fullWidth multiline id="description"
                        // label=<FormattedMessage id="profile.desc.desc" defaultMessage="Describe yourself and your interests (searchable)" />
                        name="description"
                        value={description}
                        rows={!isEmpty(groupNameError) || !isEmpty(descriptionError) ? 12 : 13}
                        variant="outlined"
                        onChange={(e) => handleDescriptionChange(e.target.value.trimStart())}

                        error={!isEmpty(descriptionError)}
                        helperText={descriptionError}

                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            style: { lineHeight: 1.25 },
                        }}
                        inputProps={
                            { maxLength: 750, minLength: 25 }
                        }
                    />
                </Grid>


                <Grid container direction='row' style={{ alignItems: 'baseline', marginTop: 8 }}>
                    <Typography color="primary" style={GeneralText} variant="subtitle2">
                        <FormattedMessage id="group.type" defaultMessage="Group Type" />
                    </Typography>

                    <Select
                        className={classes.groupTypeSelect}
                        name="groupType"
                        value={groupType}
                        onChange={(e) => setGroupType(e.target.value)}
                    >
                        {/* <MenuItem key={0} value={0}>
                                    {intl.formatMessage({ id: 'system.any', defaultMessage: "Any" })}
                                </MenuItem> */}

                        {GroupType.map(option => (
                            <MenuItem key={option.value} value={option.value}>
                                {intl.formatMessage({ id: option.id, defaultMessage: option.label })}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>


                <Grid container style={{ marginTop: 8 }}>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isVisible}
                                color="primary"
                                name='Private'
                                onChange={e => setIsVisible(e.target.checked)}
                            />
                        }

                        label={groupVis + " " + groupVisState}

                    // label={<FormattedMessage id="group.visible" defaultMessage="Private" />}
                    />
                </Grid>

            </Fragment>
        )
    }


    //-------------------------------------------
    // Group Image
    //-------------------------------------------
    function handleImageChange(photo) {
        if (photo) {
            setPreviewWidth(photo.imageWidth)
            setPreviewHeight(photo.imageHeight)
            setImageSrc(photo.image)
        }
    }

    function onStep1() {
        return (
            <div style={{ height: '100%', display: 'flex', flexDirection: 'column', flexWrap: 'wrap', justifyContent: 'center', alignItems: 'center' }}>
                <StyledPhoto>
                    <Box height={previewHeight} width={previewWidth} style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>
                        <img alt='' style={{ maxWidth: '100%' }} src={imageSrc} />
                    </Box>
                </StyledPhoto>

                {/* TODO: Need to Debounce */}
                <Button
                    style={{ margin: 12, position: 'absolute', bottom: 50 }}
                    autoFocus
                    variant="contained"
                    color="primary"
                    onClick={showUploadImageDialog}
                    className={classes.button}
                    startIcon={<AddAPhotoIcon />}
                >
                    <FormattedMessage id="group.photo" defaultMessage="Group Photo" />
                </Button>

                {showUploadImage &&
                    <UploadImage
                        icon={<Group />}
                        fullscreen={false}
                        show={showUploadImage}

                        autoClose
                        id={null}
                        idType='local'
                        title={<FormattedMessage id="group.photo" defaultMessage="Group Photo" />}
                        showComment={false}
                        imageRequired={false}
                        onImageChange={handleImageChange}
                        onClose={onUploadImageClose}

                        variant='GroupPhoto'
                    />}
            </div>
        )
    }



    //-------------------------------------------
    // Location
    //-------------------------------------------
    function onStep2() {
        var circleRadius = 10000

        //    if (!isEmpty(this.props.profile.location)) {
        //         markerPosition = {
        //             lat: this.props.profile.location.coordinates[1],
        //             lng: this.props.profile.location.coordinates[0]
        //         }

        //         circleRadius = this.props.profile.targetRadius
        //     }

        function handleGeocoding(isBusy) {
            // Stubbed out
        }


        function handleLngLatChange(newPosition, city, state, country, zipcode) {
            // [ lng, lat ]

            // Testing...
            // const markerPosition = {
            //     lng: profile.location ? profile.location.coordinates[0] : -88.08038399999998,
            //     lat: profile.location ? profile.location.coordinates[1] : 42.1707776,
            // }

            let coordinates = { lng: newPosition.lng, lat: newPosition.lat }

            setLocation(coordinates)

            // console.log("handleLngLatChange: " + newPosition.lng + ", " + newPosition.lat)

            // dispatch(profileChangesUpdate({ location: coordinates }))

            // // city
            // dispatch(profileChangesUpdate({ city: city }))

            // // state
            // dispatch(profileChangesUpdate({ state: state }))

            // // country - *Note: Country is a "two char code"
            // dispatch(profileChangesUpdate({ country: country }))

            // // zipcode 
            // dispatch(profileChangesUpdate({ zipcode: zipcode }))
        }

        return (
            <Fragment>
                {/* <FormattedMessage id="group.global" defaultMessage="All groups are global by default" /> */}

                {/* LocalGlobal */}
                < Grid container direction="row" >
                    <FormControlLabel
                        control={
                            <Switch
                                checked={isLocal}
                                name='LocalGlobal'
                                onChange={e => setIsLocal(e.target.checked)}
                                color="primary"
                            />
                        }

                        label={<FormattedMessage id="group.makeLocalGroup" defaultMessage="Make this a local Group" />}
                    />
                </Grid >

                {/* Map (if Local) */}
                {isLocal && <Grid container style={{ height: 400, marginTop: 8 }}>
                    <MapNew id='map' isEditable
                        handleLngLatChange={(latlng, city, state, country, zipcode) => handleLngLatChange(latlng, city, state, country, zipcode)}
                        handleGeocoding={(isBusy) => handleGeocoding(isBusy)}

                        isCenteringMap
                        // {this.state.isCenteringMap}
                        markerPosition={location}
                        circleRadius={circleRadius}
                        zoom={9}
                    />
                </Grid >}
            </Fragment>
        )
    }


    return (
        <Dialog classes={{ paper: classes.dialogPaper }}

            fullScreen={props.fullscreen}
            open={props.show}
            onClose={props.onClose}
            TransitionComponent={Transition}
        >
            {/* Create a Group */}
            <Grid container direction="row" alignItems="flex-start" className={classes.headerRegion}>
                <Group small="true" className={classes.icon} />
                <Typography variant="h6" style={DialogHeading}><FormattedMessage id="group.create" defaultMessage="New Group" /></Typography>
            </Grid>

            <Stepper activeStep={activeStep} style={{ margin: '0 4px' }}>
                {steps.map((label, index) => {
                    const stepProps = {}
                    const labelProps = {}

                    // if (isStepOptional(index)) {
                    // labelProps.optional = <Typography variant="caption">The Group Name is too short</Typography>
                    // }

                    if (isStepSkipped(index)) {
                        stepProps.completed = false
                        labelProps.error = true
                        // labelProps.optional = <Typography variant="caption">The Group Name is too short</Typography>
                    }

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>

            <Divider />

            <div style={{ flex: '1 1 auto', padding: '4px 12px', overflowY: 'auto' }}>
                {/* Details */}
                {activeStep === 0 && onStep0()}

                {/* Add an Image */}
                {activeStep === 1 && onStep1()}

                {/* Set Location */}
                {activeStep === 2 && onStep2()}
            </div>

            <Divider />

            <DialogActions>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    startIcon={<NavigateBeforeIcon />}
                >
                    <FormattedMessage id="system.back" defaultMessage="Back" />
                </Button>

                {/* {isStepOptional(activeStep) && (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSkip}
                        className={classes.button}
                    >
                        Skip
                    </Button>
                )} */}

                {activeStep < steps.length - 1 &&
                    <Button
                        autoFocus
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        endIcon={<NavigateNextIcon />}
                    >
                        <FormattedMessage id="system.next" defaultMessage="Next" />
                    </Button>}

                {activeStep === steps.length - 1 &&
                    <Button
                        disabled={skipped.size > 0}  // No skips!!
                        variant="contained"
                        color="primary"
                        onClick={event => {
                            event.preventDefault()
                            onGroupCreate()
                        }}
                        startIcon={<CloudUploadIcon />}
                    >
                        <FormattedMessage id="system.create" defaultMessage="Create" />
                    </Button>}

                <Button
                    variant="contained"
                    color="primary"
                    onClick={props.onClose}
                    startIcon={<CancelIcon />}
                >
                    <FormattedMessage id="system.cancel" defaultMessage="Cancel" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}


