// profile.Reducer.js
import moment from 'moment';

// import { CONNECTION_ERROR } from '../actions/error.actions';
import { LOGOUT } from '../actions/auth.actions'

import { PROFILE_CLEAR, PROFILE_CLEAR_ERROR, PROFILE_CHANGES_BUFFER, PROFILE_CHANGES_CLEAR, PROFILE_CHANGES_ERROR } from '../actions/profile.actions';
import { PROFILE_GET_REQUEST, PROFILE_GET_SUCCESS, PROFILE_GET_FAILED, PROFILE_SAVE_REQUEST, PROFILE_SAVE_SUCCESS, PROFILE_SAVE_FAILED } from '../actions/profile.actions';
import { PROFILE_CANCEL_MEMBERSHIP_REQUEST, PROFILE_CANCEL_MEMBERSHIP_FAILED } from '../actions/profile.actions';
import { PROFILE_GETBLOCKED_REQUEST, PROFILE_GETBLOCKED_SUCCESS, PROFILE_GETBLOCKED_FAILED } from '../actions/profile.actions';
import { VALIDATE_NEW_EMAILVID_REQUEST, VALIDATE_NEW_EMAILVID_SUCCESS, VALIDATE_NEW_EMAILVID_FAILED } from '../actions/profile.actions';


const initialState = {
    isSaving: false,
    isPristine: true,
    isValidating: false,

    profile: {},
    profileChanges: {},
    blockedMembers: [],

    errors: {},
    receivedAt: moment().format('lll SSS'),
}

export default function (state = initialState, action) {

    switch (action.type) {
        case LOGOUT:
            return { ...initialState }

        case PROFILE_CHANGES_BUFFER:
            // White Box Testing
            // console.log('profile.Reducer PROFILE_CHANGES_BUFFER: ', { ...state.profileChanges, ...action.profileChanges })

            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                // Keep loaded profile in sync with changes for comparision flip-flops
                profile: { ...state.profile, ...action.profileChanges },
                profileChanges: { ...state.profileChanges, ...action.profileChanges },
                isPristine: false,
            }

        case PROFILE_CHANGES_CLEAR:
            return {
                ...state,
                profileChanges: {},
                errors: {},
                isPristine: true,
            }

        case PROFILE_CHANGES_ERROR:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                errors: { ...state.errors, ...action.error },
                isPristine: true,  // Can't save
            }

        case PROFILE_CLEAR_ERROR:  
            delete state.errors[action.propName]

            return {
                ...state,
                errors: { ...state.errors },
                receivedAt: moment().format('lll SSS'),
            }


        case PROFILE_CLEAR:     // Wipes profile...
            return { ...initialState }

        // Get Profile

        case PROFILE_GET_REQUEST:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isLoading: true,
                isSaving: false,
                isPristine: true,
                errors: {},
            }

        case PROFILE_GET_SUCCESS:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isLoading: false,
                isSaving: false,
                isPristine: true,

                profile: action.profile,
                profileChanges: {},
                errors: {},
            }

        case PROFILE_GET_FAILED:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isLoading: false,
                isSaving: false,
                isPristine: true,
                errors: action.errors,
            }

        // Save Profile

        case PROFILE_SAVE_REQUEST:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isSaving: true,
                isPristine: false,
                errors: {},
            }

        case PROFILE_SAVE_SUCCESS:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isSaving: false,
                isPristine: true,

                profile: action.profile,
                profileChanges: {},
                errors: {},
            }

        case PROFILE_SAVE_FAILED:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isSaving: false,
                isPristine: false,
                errors: action.errors,
            }


        case PROFILE_GETBLOCKED_REQUEST:
            return {
                ...state,
                error: {},
                // blockedMembers: [],
                isLoading: true,
                receivedAt: moment().format('lll SSS'),
            };

        case PROFILE_GETBLOCKED_SUCCESS:
            return {
                ...state,
                error: {},
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
                blockedMembers: action.blockedMembers,
            };

        case PROFILE_GETBLOCKED_FAILED:
            return {
                ...state,
                error: action.errors,
                blockedMembers: [],
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
            };


        case VALIDATE_NEW_EMAILVID_REQUEST:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isValidating: true,
                // isPristine: false,                
                errors: {},
            };

        case VALIDATE_NEW_EMAILVID_SUCCESS:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isValidating: false,
                // isPristine: true,
                profile: action.profile, 
                profileChanges: {},
                errors: {},
            };

        case VALIDATE_NEW_EMAILVID_FAILED:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isValidating: false,
                // isPristine: false,
                errors: action.errors,
            };


        // Cancel Membership

        case PROFILE_CANCEL_MEMBERSHIP_REQUEST:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isSaving: true,
                profileChanges: {},
                errors: {},
            }

        // case PROFILE_CANCEL_MEMBERSHIP_SUCCESS:
        // Do nothing...

        case PROFILE_CANCEL_MEMBERSHIP_FAILED:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isSaving: false,
                errors: action.errors,
            }

        default:
            return state;
    }
}