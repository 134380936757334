// ChatCard

// ImageList
// https://codesandbox.io/s/5yjpp339w4
// https://codesandbox.io/s/rzmz5jnom

// Animation
// https://medium.com/capriza-engineering/implementing-material-design-animation-on-cards-list-using-react-js-86994b6a4112
// https://medium.com/the-react-native-log/tips-for-react-native-images-or-saying-goodbye-to-trial-and-error-b2baaf0a1a4d


import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import { useTheme, lighten } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import isEmpty from '../validation/is-empty';

import { JellyAnimation } from '../helpers/utilities'

import { Button, Dialog, Grid, Card, CardActionArea, CardContent, IconButton, Slide, Tooltip, Typography, Zoom } from '@mui/material';

import { DialogHeadingCentered, GeneralText, SmallButton } from "../styles/ftfStyles"

import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
// import Block from '@mui/icons-material/Block'
import CancelIcon from '@mui/icons-material/Cancel'
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone'
import FavoriteBorderTwoToneIcon from '@mui/icons-material/FavoriteBorderTwoTone'
import DeleteForever from '@mui/icons-material/DeleteForever'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import VoiceChatTwoToneIcon from '@mui/icons-material/VoiceChatTwoTone'

// import { useSpring, animated } from 'react-spring'
import Avatar from './Avatar'
import { FormattedMessage, FormattedDate, FormattedTime } from 'react-intl'

import { showVideoChatDialog } from '../actions/app.actions'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        margin: 8,
        padding: 8,
        overflow: 'hidden',

        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        border: '2px solid',
    },

    actionButton: {
        padding: '2px 5px',
    },

    margin: {
        margin: 4,
        marginRight: 16,
    },

    textCancel: {
        alignSelf: 'center',
        color: theme.palette.primary.dark,
        lineHeight: 1.25,
        textAlign: 'left',
    },

    member: {
        display: 'flex',
    },

    timestamp: {
        color: 'Gray',
        display: 'flex',
        alignSelf: 'flex-end',
        fontSize: '.6rem'
    },
}))


export default function ChatCard(props) {
    const classes = useStyles(props)
    const theme = useTheme()
    const dispatch = useDispatch()

    // isSelected
    const currentChatId = useSelector(state => state.chat.currentChatId)
    const isProfileVisible = useSelector(state => state.profile.profile.isVisible)

    const isSelected = (currentChatId === props.chat.chatId)

    const [showDeleteApproval, setShowDeleteApproval] = useState(false)

    //-------------------------------------------
    // Open VideoChat with friend
    //-------------------------------------------
    function handleVideoChatClick(e, user) {
        e.stopPropagation()
        dispatch(showVideoChatDialog(true, true, user, null))
    }


    //-------------------------------------------
    // getBackgroundColor
    //-------------------------------------------
    function getBackgroundColor() {
        return isSelected ? lighten(theme.palette.primary.light, 0.50) : "white"
    }

    //-------------------------------------------
    // handleDeleteChat
    //-------------------------------------------
    function handleDeleteChatClick() {
        setShowDeleteApproval(true)
    }

    //-------------------------------------------
    // handleDeleteApproved
    //-------------------------------------------
    function handleDeleteApproved() {
        if (!isEmpty(props.chat)) {
            setShowDeleteApproval(false)
            props.handleDeleteChatClick(props.chat)
        }
    }

    //-------------------------------------------
    // handleDeleteCancelled
    //-------------------------------------------
    function handleDeleteCancelled() {
        setShowDeleteApproval(false)
    }

    //-------------------------------------------
    // onVerifyDeleteDialog (Yes/No)  
    //-------------------------------------------
    function onVerifyDeleteDialog() {
        return (
            <Dialog classes={{ paper: classes.dialogPaper }}
                style={{ zIndex: 8100 }}
                open={showDeleteApproval}
                TransitionComponent={Transition}
                onClose={handleDeleteCancelled}
                keepMounted
            >
                <Typography style={DialogHeadingCentered} variant="h6">
                    {<FormattedMessage id="system.ays" defaultMessage="Are you sure?" />}
                </Typography>

                <Grid container direction="row" style={{ justifyContent: "flex-start" }}>
                    <Button variant="contained" color="primary" size="large"
                        style={SmallButton} onClick={handleDeleteApproved}
                        startIcon={<DeleteForever aria-label='Yes' />}
                    >
                        <FormattedMessage id="system.yes" defaultMessage="Yes" />
                    </Button>

                    <Button autoFocus variant="contained" color="primary" size="large"
                        style={SmallButton} onClick={handleDeleteCancelled}
                        startIcon={<CancelIcon aria-label='Cancel' />}
                    >
                        <FormattedMessage id="system.cancel" defaultMessage="Cancel" />
                    </Button>
                </Grid>
            </Dialog>
        )
    }


    // const spr = useSpring({ to: { opacity: 1 }, from: { opacity: 0 }, delay: 1000 })
    return (
        <Card style={{ background: getBackgroundColor(), margin: 1, borderRadius: 7 }}>
            <CardActionArea>
                <CardContent style={{ padding: 2 }} onClick={e => props.handleGotoMsgsClick(props.chat)}>
                    <div className={classes.member}>
                        <Avatar noMargin small isOnline={props.chat.isOnline} gender={props.chat.gender} avatarUrl={props.chat.avatarUrl} delay={250} alt={props.chat.firstname}>...</Avatar>

                        <div style={{ marginLeft: 6 }}>
                            <Typography variant="subtitle2" style={GeneralText}>
                                {props.chat.firstname}
                            </Typography>

                            <Typography gutterBottom className={classes.timestamp}>
                                <FormattedDate value={props.chat.msgDate} year="2-digit" month="short" day="2-digit" /> <br />
                                <FormattedTime value={props.chat.msgDate} />
                            </Typography>
                        </div>
                    </div>
                </CardContent>
            </CardActionArea>

            {isSelected &&
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    {/* <animated.div className="opacity" style={spr}> */}
                    {/* {!props.chat.isSupport &&
                        <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="tooltip.block" defaultMessage="Block Member" />}>
                            < IconButton className={classes.actionButton} onClick={e => props.handleBlockMemberClick(e, props.chat)} aria-label="Block" color="primary">
                                <Block fontSize="small" />
                            </IconButton>
                        </Tooltip>} */}

                    <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="tooltip.deleteConv" defaultMessage="Delete Conversation" />}>
                        <JellyAnimation>
                            <IconButton
                                className={classes.actionButton}
                                onClick={e => handleDeleteChatClick(e)}
                                aria-label="Delete"
                                color="primary"
                                size="large"
                            >
                                <DeleteForeverTwoToneIcon fontSize="small" />
                            </IconButton>
                        </JellyAnimation>
                    </Tooltip>

                    {!props.chat.isSupport && <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="tooltip.videoChat" defaultMessage="Video Chat" />}>
                        <span>
                            <JellyAnimation>
                                <IconButton
                                    // || member['isOnline'] === 0 || member['allowVideoChat'] === false}
                                    disabled={!isProfileVisible || !props.chat.notify.allowVideoChat}

                                    className={classes.actionButton}
                                    onClick={(e) => handleVideoChatClick(e, props.chat)}
                                    aria-label="Video Chat"
                                    color="primary"
                                    size="large"
                                >
                                    <VoiceChatTwoToneIcon fontSize="small" />
                                </IconButton>
                            </JellyAnimation>
                        </span>
                    </Tooltip>}

                    {!props.chat.isSupport && <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="tooltip.profile" defaultMessage="Profile" />}>
                        <span>
                            <JellyAnimation>
                                <IconButton
                                    disabled={isEmpty(props.chat.memberId)}  // Member is gone... TODO: Needs testing and cleanChats?
                                    className={classes.actionButton}
                                    onClick={e => props.handleGotoProfileClick(e, props.chat)}
                                    aria-label="Profile"
                                    color="primary"
                                    size="large"
                                >
                                    <AccountCircleTwoToneIcon fontSize="small" />
                                </IconButton>
                            </JellyAnimation>
                        </span>
                    </Tooltip>}

                    <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="tooltip.addremove" defaultMessage="Add or Remove Friend" />}>
                        <span>
                            <JellyAnimation>
                                <IconButton
                                    disabled={isEmpty(props.chat.memberId)}  // Member is gone...
                                    className={classes.actionButton}
                                    onClick={e => props.handleAddFriendClick(e, props.chat)}
                                    aria-label="Add to Friends"
                                    color="primary"
                                    size="large"
                                >
                                    {!props.chat.favorite && <FavoriteBorderTwoToneIcon fontSize="small" />}
                                    {props.chat.favorite && <FavoriteTwoToneIcon fontSize="small" color="secondary" />}
                                </IconButton>
                            </JellyAnimation>
                        </span>
                    </Tooltip>

                    {/* </animated.div> */}
                </div>}

            {isSelected && onVerifyDeleteDialog()}
        </Card>
    );
}
