import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import ReactResizeDetector from 'react-resize-detector'

import { Typography } from "@mui/material"
import { makeStyles } from '@mui/styles'

import FTF_Images from './FTF_Images'

import Gallery from "../helpers/Gallery";
// import lightBlue from '@mui/material/colors/pink'

import isEmpty from '../validation/is-empty'

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

import { MemberProfileTabbedLayout } from "../styles/ftfStyles"
import ViewLikes from "./ViewLikes"

import { FormattedMessage } from 'react-intl'  // , FormattedHTMLMessage

import { getPhotoLikes } from '../actions/image.actions'


const useStyles = makeStyles(theme => ({
    imageList: {
        overflow: 'hidden',
        margin: 0,
    },

    noRows: {
        color: theme.palette.primary.dark,
        textAlign: "left",
        marginLeft: 8,
    },
}))


export default function MemberProfileWall(props) {
    const classes = useStyles(props)

    const member = useSelector(state => state.member.member)
    const isLoading = useSelector(state => state.member.isLoading)

    // Only after useSelector(s) for now... - DSW
    const dispatch = useDispatch()

    const [imageWidth, setImageWidth] = useState(0)
    const [imageHeight, setImageHeight] = useState(0)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [viewLikes, setShowLikes] = useState(false)

    function onResize(width = 500, height = 250) {
        setImageWidth(width)
        setImageHeight(height)
    }

    //-------------------------------------------
    // Show Likes
    //-------------------------------------------
    const onShowLikes = (e, photoId) => {
        dispatch(getPhotoLikes('member', member._id, photoId))
        setAnchorEl(e.currentTarget)
        setShowLikes(true)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const masonryBreakpoints = {
        default: 6,
        1100: 5,
        999: 4,
        800: 3,
        700: 2,
        500: 2,
    }


    return (
        <Div100vh>
            <MemberProfileTabbedLayout>
                {isEmpty(member.widgets) && !isLoading && 
                    <div>
                        <Typography className={classes.noRows}>
                            <FormattedMessage id="system.photos.none" defaultMessage="No photos..." />
                        </Typography>

                        <img src={FTF_Images.NoPhotos} style={{ maxWidth: '50%', display: 'block', marginLeft: 'auto', marginRight: 'auto', opacity: 0.20 }} />
                    </div>
                }

                <ReactResizeDetector handleHeight handleWidth onResize={onResize} />

                {member.widgets &&
                    <Gallery
                        showUploadImage={null}
                        showHeader={false}
                        masonryBreakpoints={masonryBreakpoints}
                        allowChanges={false}

                        fullscreen={false}
                        height={imageHeight}
                        width={imageWidth}

                        idType="member"
                        id={member._id}

                        viewLikes={onShowLikes}

                        images={member.widgets.map(({ headline, _id, likes, url }) => ({
                            headline,

                            widgetId: _id,

                            likes,
                            src: url,
                            original: url,
                        }))}

                    />}

                {viewLikes && anchorEl && <ViewLikes anchorEl={anchorEl} handleClose={handleClose} />}
            </MemberProfileTabbedLayout>
        </Div100vh>
    )
}

