import React from "react"

import { useSelector } from "react-redux"
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles'

import { FormattedTime } from 'react-intl'

import { Typography } from '@mui/material'

import { GeneralText } from "../styles/ftfStyles"

import Avatar from './Avatar'
import styled from 'styled-components'

const sizes = {
    mobileS: '320px',
    mobileM: '375px',
    mobileL: '425px',
    tablet: '768px',
    laptop: '1024px',
    laptopL: '1440px',
    desktop: '2560px',
};

export const devices = {
    mobileS: `(min-width: ${sizes.mobileS})`,
    mobileM: `(min-width: ${sizes.mobileM})`,
    mobileL: `(min-width: ${sizes.mobileL})`,
    tablet: `(min-width: ${sizes.tablet})`,
    laptop: `(min-width: ${sizes.laptop})`,
    laptopL: `(min-width: ${sizes.laptopL})`,
    desktop: `(min-width: ${sizes.desktop})`,
};

const StyledMsg = styled.div`
    color: ${props => props.color};
    margin: 4px 0;
    padding: 0 4px;
    line-height: 1.2;
    word-break: break-word;
    white-space: normal;

    @media ${devices.mobileM} {
        font-size: .75rem;
    }

    @media ${devices.tablet} {
        font-size: .875rem;
    }

    @media ${devices.desktop} {
        font-size: .875rem;
    }
`

// box-shadow: 2px 2px 2px -1px rgba(0,0,0,.25);
// margin: 4px;
// border: 1px solid #ccc;
// background-color: ${props => props.background};   
// border-radius: 16px;

const useStyles = makeStyles(theme => ({
    member: {
        display: 'flex',
        minHeight: 50,
    },

    timestamp: {
        color: 'Gray',
        // margin: '0 8px',
        display: 'flex',
        alignSelf: 'flex-end',
        fontSize: '.6rem'
    },
}))


export default function ChatsPageMsg(props) {
    const classes = useStyles(props)
    const theme = useTheme()

    const ownerId = useSelector(state => state.profile.profile._id)

    //-------------------------------------------
    // getMsgAlignment
    //-------------------------------------------
    function getMsgAlignment(msgOwnerId) {
        // if (msgOwnerId === ownerId)
        //     return 'flex-end'

        return 'flex-start'
    }


    //-------------------------------------------
    // getTextColor
    //-------------------------------------------
    function getTextColor(msgOwnerId) {
        return (msgOwnerId === ownerId) ? theme.palette.info.contrastText : "black"
    }


    //-------------------------------------------
    // getBackgroundColor
    //-------------------------------------------
    function getBackgroundColor(msgOwnerId) {
        return (msgOwnerId === ownerId) ? "white" : lighten(theme.palette.primary.light, 0.50)
    }





    return (
        <div style={{ flexDirection: 'column', justifyContent: getMsgAlignment(props.msg.ownerId) }}>

            <div className={classes.member}>
                <Avatar style={{ marginBottom: -8 }} noMargin small gender={props.msg.gender} avatarUrl={props.msg.avatarUrl} delay={250} alt={props.msg.firstname}>...</Avatar>

                <div style={{ marginLeft: 6 }}>
                    <Typography variant="subtitle2" style={GeneralText}>
                        {props.msg.firstname}
                    </Typography>

                    <Typography className={classes.timestamp}>
                        <FormattedTime value={new Date(props.msg.createdOn)} year="numeric" month="short" day="numeric" hour='numeric' minute='numeric' />
                    </Typography>

                    {/* <Typography gutterBottom className={classes.timestamp}>
                        <FormattedDate value={props.chat.msgDate} year="2-digit" month="short" day="2-digit" /> <br />
                        <FormattedTime value={props.chat.msgDate} />
                    </Typography> */}
                </div>
            </div>



            {/* <div style={{ display: 'flex', justifyContent: getMsgAlignment(props.msg.ownerId) }}>
                <Avatar style={{ marginBottom: -8 }} noMargin small gender={props.msg.gender} avatarUrl={props.msg.avatarUrl} delay={250} alt={props.msg.firstname}>...</Avatar> */}

            {/* <Typography variant="caption" style={GeneralText}>
                    {props.msg.firstname}
                </Typography> */}
            {/* 
                <Typography className={classes.timestamp}>
                    <FormattedTime value={new Date(props.msg.createdOn)} year="numeric" month="short" day="numeric" hour='numeric' minute='numeric' />
                </Typography>
            </div> */}

            <div style={{ display: 'flex', justifyContent: getMsgAlignment(props.msg.ownerId) }}>
                <StyledMsg
                    justifyContent={getMsgAlignment(props.msg.ownerId)}
                    background={getBackgroundColor(props.msg.ownerId)}
                    color={getTextColor(props.msg.ownerId)}
                    {...props}
                >
                    <div dangerouslySetInnerHTML={{ __html: props.msg.msg }} />
                </StyledMsg>
            </div>
        </div>
    )
}

