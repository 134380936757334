// socket.actions.js

import moment from 'moment'
// import axios from 'axios';
// import { logoutUser } from './auth.actions';
// import { CONNECTION_ERROR } from './error.actions';

export const SOCKET_CONNECT = 'SOCKET_CONNECT'
export const ONLINE_COUNT = 'ONLINE_COUNT'

export const DEVICES = 'DEVICES'
export const STOP_DEVICES = 'STOP_DEVICES'
export const MEETING_CREATE = 'MEETING_CREATE'
export const MEETING_CREATED = 'MEETING_CREATED'
export const MEETING_JOIN = 'MEETING_JOIN'
export const MEETING_LEAVE = 'MEETING_LEAVE'
export const MEETING_MSG = 'MEETING_MSG'
export const MEETING_REJECTED = 'MEETING_REJECTED'
export const MEETING_REQUEST = 'MEETING_REQUEST'

export const UPLOAD_LIST = 'UPLOAD_LIST'
export const UPLOAD_START = 'UPLOAD_START'
export const UPLOAD_FINISHED = 'UPLOAD_FINISHED'
export const UPLOAD_PROGRESS = 'UPLOAD_PROGRESS'

export const NOTIFICATION = 'NOTIFICATION'
export const UPLOAD_IMAGES = 'UPLOAD_IMAGES'


//-----------------------------------------------
// Entry point for showVideoChatDialog...
//-----------------------------------------------
// export function showVideoChatDialog(showVideoChat, isOwner, chat) {
//     return { type: MEETING_xxxxT, showVideoChat, isOwner, chat }    
//     // return { type: SHOW_VIDEO_CHAT, showVideoChat, isOwner, chat }
// }


//-----------------------------------------------
// SOCKET_CONNECT
//-----------------------------------------------
export const socketConnect = (id) => dispatch => {
    dispatch({ type: SOCKET_CONNECT, id: id })
}


//-----------------------------------------------
// ONLINE_COUNT
//-----------------------------------------------
export const onlineCount = (count) => dispatch => {
    dispatch({ type: ONLINE_COUNT, count: count })
}


//-----------------------------------------------
// Meeting Request
//-----------------------------------------------
export const socketMeetingRequest = (user, accessId ) => dispatch => {
    dispatch({ type: MEETING_REQUEST, accessId: accessId, user: user })
}


//-----------------------------------------------
// Meeting Message
//-----------------------------------------------
export const socketMeetingMsg = (msgId, guestName) => dispatch => {
    dispatch({ type: MEETING_MSG, msgId, guestName })
}


//-----------------------------------------------
// Meeting Create
//-----------------------------------------------
export const socketCreateMeeting = (userId, guestId, isRoomCreator) => dispatch => {
    const randomName = Math.random().toString(36).substring(2, 6) + moment().valueOf().toString(36)
    dispatch({ type: MEETING_CREATE, userId: userId, guestId: guestId, accessId: randomName, isRoomCreator: isRoomCreator })
}


//-----------------------------------------------
// Meeting Created
//-----------------------------------------------
export const socketMeetingCreated = (localStream, remoteStream) => dispatch => {
    dispatch({ type: MEETING_CREATED, localStream: localStream, remoteStream: remoteStream })
}


//-----------------------------------------------
// Meeting Join
//-----------------------------------------------
export const socketMeetingJoin = (accessId, userId) => dispatch => {
    dispatch({ type: MEETING_JOIN, accessId: accessId, userId: userId })
}


//-----------------------------------------------
// Meeting Leave
//-----------------------------------------------
export const socketMeetingLeave = (userId) => dispatch => {
    
    dispatch({ type: MEETING_LEAVE,  userId: userId })
}


//-----------------------------------------------
// Meeting Rejected
//-----------------------------------------------
export const socketMeetingRejected = (userId, accessId) => dispatch => {
    dispatch({ type: MEETING_REJECTED,  userId: userId, accessId: accessId })
}


//-----------------------------------------------
// Meeting STOP_DEVICES
//-----------------------------------------------
export const socketStopDevices = (audioStopDevices) => dispatch => {
    dispatch({ type: STOP_DEVICES, audioStopDevices })
}

//-----------------------------------------------
// Meeting DEVICES
//-----------------------------------------------
export const socketDevices = (audioInputSelect, audioOutputSelect, videoSelect) => dispatch => {
    dispatch({ type: DEVICES, audioInputSelect, audioOutputSelect, videoSelect })
}
