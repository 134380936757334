// App.js
// https://stackoverflow.com/questions/49051975/material-ui-drawer-wont-move-under-appbar
// https://stackoverflow.com/questions/37120713/material-ui-drawer-with-appbar-not-working-with-component-syntax

// Routing
// https://reacttraining.com/react-router/web/api/BrowserRouter
// https://itnext.io/basics-of-react-router-v4-336d274fd9e0
// https://reacttraining.com/react-router/web/example/auth-workflow

// Ads
// https://support.google.com/adsense/answer/6002621?hl=en

// Google Analytics
// https://medium.com/geekculture/how-to-use-google-analytics-on-reactjs-in-5-minutes-7f6b43017ba9

// Using socket.io in React-Redux app to handle real-time data
// https://medium.com/@gethylgeorge/using-socket-io-in-react-redux-app-to-handle-real-time-data-c0e734297795

// Integrating Redux and Socket.io
// https://artsy.github.io/blog/2018/03/15/integrating-redux-and-socketio/

// Good! => https://medium.com/@siddharthac6/json-web-token-jwt-the-right-way-of-implementing-with-node-js-65b8915d550e
// jwtToken

import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles'

import { HotKeys } from "react-hotkeys";
import MaintDialog from './_components/MaintDialog'

// Mobile "real height": https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

// import AdContainer from './_components/AdContainer'
import FTF_Images from './_components/FTF_Images'

import { useSpring, animated } from "react-spring"

// https://www.npmjs.com/package/react-use-gesture
// import { useGesture } from "react-use-gesture";

// , useLocation
import { Route, Routes, useLocation, useNavigate } from "react-router-dom"

import classNames from 'classnames'

import store from './store'

import jwt_decode from 'jwt-decode'
import { setAuthToken } from './setAuthToken'

import { logoutUser } from './actions/auth.actions'
import { socketConnect } from './actions/socket.actions'


// node-notifier - Support Functionality
// https://github.com/mikaelbr/node-notifier

// https://github.com/fkhadra/react-toastify#usage-with-redux
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import "./styles/toastify.css"

import { AppBar, Button, IconButton, Toolbar, Typography } from '@mui/material'

import ForwardIcon from '@mui/icons-material/Forward'
import MenuIcon from '@mui/icons-material/Menu'

// import ErrorBoundary from './_components/ErrorBoundary'
// import { alertActions } from './actions/alert.actions'

import Navbar from './_components/Navbar'

import ChatsPage from './_components/ChatsPage'
import FriendsPage from './_components/FriendsPage'
import FrontPage from './_components/FrontPage'
import GroupsPage from './_components/GroupsPage'
import DashboardPage from './_components/DashboardPage'

const GroupPage = React.lazy(() => import("./_components/GroupPage"))
const SearchPage = React.lazy(() => import("./_components/SearchPage"))
const HelpPage = React.lazy(() => import("./_components/HelpPage"))

import Login from './_components/Login'

import MemberProfile from './_components/MemberProfile'
import Profile from './_components/Profile'
import Register from './_components/Register'

import Gdpr from './_components/Gdpr'
import ValidateEmail from './_components/ValidateEmail'
import VideoChat from './_components/VideoChat'
// import VideoChatsPage from './_components/VideoChatsPage'

import ViewsPage from './_components/ViewsPage'

import WallPage from './_components/WallPage'
import WhoisOnlinePage from './_components/WhoisOnlinePage'

import { getProfile } from './actions/profile.actions'
import { setAppWidth, setIsDrawerOpen, showVideoChatDialog } from './actions/app.actions'

import { FormattedMessage } from 'react-intl'  // , FormattedHTMLMessage

import styled from 'styled-components'


const AnimatedArrow = styled.svg`
  width: 25px;
  height: 25px;
  color: white;
  animation: scale 2.5s linear infinite;

  &:hover {
    animation: scale 0s linear;
  }

  @keyframes scale {
    100% {
        transform: scale(.7);
    }
  }
`

// https://github.com/greena13/react-hotkeys#full-reference
// https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key/Key_Values#modifier_keys
const keyMap = {
    MAINT: "ctrl Home down up",
};




if (localStorage.jwtToken) {
    setAuthToken(localStorage.jwtToken)
    const decoded = jwt_decode(localStorage.jwtToken)
    const currentTime = Date.now() / 1000

    // Auto logout...
    if (decoded.exp < currentTime) {
        store.dispatch(logoutUser())
        window.location.href = '/login'
    }
    else {
        store.dispatch(socketConnect(decoded.id))
    }
}


const useStyles = makeStyles(theme => ({

    drawer: {
        fontWeight: 600,
        fontSize: '8em',
        willChange: 'opacity',
    },

    myApp: {
        // https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
        // https://stackoverflow.com/questions/37112218/css3-100vh-not-constant-in-mobile-browser/55003985#55003985
        // http://css-tricks.com/the-lengths-of-css/
        // height: `calc(100vh - calc(100vh - 100%))`,
        // id: 'ftfContent',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden',
    },

    appBar: {
        // flex: 'none',
        // position: 'unset',
        zIndex: theme.zIndex.drawer + 1,
    },

    // Drawer - Old Way
    // drawerOpenShift: {
    //     marginLeft: theme.drawerWidth,
    //     width: `calc(100 - ${theme.drawerWidth})`,

    //     transition: theme.transitions.create(['width', 'margin'], {
    //         easing: theme.transitions.easing.easeOut,
    //         duration: theme.transitions.duration.short,
    //         // duration: theme.transitions.duration.enteringScreen,
    //     }),
    // },

    logo: {
        maxHeight: window.innerWidth <= 500 ? 20 : 27,
        marginRight: 4,
        marginBottom: 3,
        alignSelf: 'center',
    },

    ftf: {
        fontSize: window.innerWidth <= 500 ? '1.1rem' : 'auto',
    },

    //------------------------------------------------------------
    // Adjust Ad Height
    // -----------------------------------------------------------
    // value         |0px     600px    960px    1280px   1920px
    // key           |xs      sm       md       lg       xl
    // screen width  |--------|--------|--------|--------|-------->
    // range         |   xs   |   sm   |   md   |   lg   |   xl
    //------------------------------------------------------------
    footerAd: {
        minHeight: theme.adHeight.md,
        maxHeight: theme.adHeight.md,

        // Phone Landscape
        [theme.breakpoints.down('md')]: {
            minHeight: theme.adHeight.sm,
            maxHeight: theme.adHeight.sm,
        },

        // Phone Portrait
        [theme.breakpoints.down('sm')]: {
            minHeight: theme.adHeight.xs,
            maxHeight: theme.adHeight.xs,
        },

        zIndex: 0,
        bottom: 0,
        left: 0,
        right: 0,
        position: 'fixed',

        flex: 'none',

        textAlign: 'center',
        justifyContent: 'center',

        color: theme.palette.info.contrastText,
        background: theme.palette.info.dark,

        // position: 'relative',
        // alignContent: 'top',
        display: 'flex',
        // height: 84,
        // margin: 2,
        // padding: 2,
    },

    // Styles for the content area. It fills the available space
    // in the flex container to the right (or left) of the drawer.
    mainContent: {
        top: theme.toolbarHeight,
        left: 0,
        position: 'fixed',

        justifyContent: 'center',  // horz
        margin: 0,
        padding: 0,
        overflow: 'hidden',

        flexGrow: 1,

        // height: '100%',  
        // height: '-moz-available',          /* WebKit-based browsers will ignore this. */
        // height: '-webkit-fill-available',  /* Mozilla-based browsers will ignore this. */
        // height: 'fill-available',

        width: '100%',
        // eslint-disable-next-line no-dupe-keys
        width: '-moz-available',          /* WebKit-based browsers will ignore this. */
        // eslint-disable-next-line no-dupe-keys
        width: '-webkit-fill-available',  /* Mozilla-based browsers will ignore this. */
        // eslint-disable-next-line no-dupe-keys
        width: 'fill-available',


        // height: `calc(100vh - calc(100vh - 100%)) - 150px`,

        // maxHeight: `calc(calc(100vh - calc(100vh - 100%)) - ${theme.adHeight.md})`,

        // // Phone Landscape
        // [theme.breakpoints.down('sm')]: {
        //     maxHeight: `calc(calc(100vh - calc(100vh - 100%)) - ${theme.adHeight.sm})`,
        // },

        // // Phone Portrait
        // [theme.breakpoints.down('xs')]: {
        //     maxHeight: `calc(calc(100vh - calc(100vh - 100%)) - ${theme.adHeight.xs})`,
        // },

        // flex: '1 1 100%',  // https://github.com/philipwalton/flexbugs#flexbug-17
        // minWidth: '100%',  // https://github.com/philipwalton/flexbugs#flexbug-17

        // Set the max content width for each breakpoint
        // Content will be centered in the space to the right/left of drawer
        // [theme.breakpoints.up('lg')]: {
        //     maxWidth: theme.breakpoints.values.lg,
        // },
    },


    toast: {
        bottom: theme.adHeight.md,

        // Phone Landscape
        [theme.breakpoints.down('md')]: {
            bottom: theme.adHeight.sm,
        },

        // Phone Portrait
        [theme.breakpoints.down('sm')]: {
            bottom: theme.adHeight.xs,
        },
    },

    toolbar: {
        maxHeight: theme.toolbarHeight,
        minHeight: theme.toolbarHeight,
        alignItems: 'center',
        padding: '0 2px',
    },

    toolbarButtonStyle: {
        fontSize: window.innerWidth <= 500 ? '.8rem' : 'auto',
        margin: '0 6px 0 0',
        padding: '1px, 1px',
        alignItems: 'center',
        color: '#ffffff',
    },
}))


// function getViewports() {

//     return (
//         {
//             viewport: {
//                 width: Math.max(
//                     document.documentElement.clientWidth || 0,
//                     window.innerWidth || 0
//                 ),
//                 height: Math.max(
//                     document.documentElement.clientHeight || 0,
//                     window.innerHeight || 0
//                 )
//             },
//             visualViewport: {
//                 width: window.visualViewport.width,
//                 height: window.visualViewport.height
//             },
//         }
//     )
// }


// //-------------------------------------------
// // useIsDrawerOpen Hook
// //-------------------------------------------
// function useIsDrawerOpen(isOpen) {
//     const [isDrawerOpen, setIsDrawerOpen] = useState(false);

//     useEffect(() => {
//         setIsDrawerOpen(isOpen);

//         // ChatAPI.subscribeToFriendStatus(isOpen, handleStatusChange);
//         // return () => {
//         //     ChatAPI.unsubscribeFromFriendStatus(isOpen, handleStatusChange);
//         // };

//     }, [isOpen]);

//     return isDrawerOpen;
// }




// https://reacttraining.com/react-router/web/api/BrowserRouter
export default function App(props) {
    const classes = useStyles(props)
    const dispatch = useDispatch()
    const theme = useTheme()
    let navigate = useNavigate()
    let location = useLocation()

    // Future: Add location specific images
    // const location = useLocation()

    // const refFooter = useRef(null)

    const width = useSelector(state => state.app.width)
    const isDrawerOpen = useSelector(state => state.app.isDrawerOpen)

    const emailRequested = useSelector(state => state.profile.profile.emailRequested)
    const gdprAccepted = useSelector(state => state.profile.profile.gdprAccepted)
    const isAdmin = useSelector(state => state.profile.profile.ftfAdmin)

    const isStealthMode = useSelector(state => state.auth.isStealthMode)
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    const [mounted, setIsMounted] = useState(false)

    const showVideoChat = useSelector(state => state.app.showVideoChat)

    // const isDrawerLockedOpen = useSelector(state => state.app.isDrawerLockedOpen)
    // const [showVideoChat, setShowVideoChat] = useState(false)

    const [showMaintDialog, setShowMaintDialog] = useState(false)
    const [isDrawerLocked, setIsDrawerLocked] = useState(false)

    // useSpring
    const fadeinAnimation = useSpring({
        to: { opacity: 1 }, from: { opacity: 0 }, delay: 500,
    })


    const animationMainContent = useSpring({
        config: { duration: 200 },
        to: {
            marginLeft: isDrawerOpen ? theme.drawerWidth : 0,

            // If "vertical scrolling" is possible...
            // marginLeft: !isDrawerOpen ? 0 : (window.innerHeight > 685 ? theme.drawerWidth : theme.drawerWidth + 15) 
        }
    })


    //-------------------------------------------
    // Testing: Video Chat
    //-------------------------------------------
    // Action to reducer (Testing button in Toolbar)
    // function handleVideoChat(e) {
    //     dispatch(showVideoChatDialog(true))
    // }

    //-------------------------------------------
    // Testing: GDPR
    //-------------------------------------------
    // Action to reducer
    // function handleCloseGDPR(e) {
    //     dispatch(showGdprDialog(false))
    // }

    // Action to reducer
    function handleCloseVideoChat(e) {
        dispatch(showVideoChatDialog(false))
    }


    // // Action to call reducer
    // function handleVideoChat(e) {
    //     dispatch(showVideoChat(true))
    // }

    // // Action to call reducer
    // function handleCloseVideoChat(e) {
    //     dispatch(showVideoChat(false))
    // }

    // function gotoDefaultRoute() {
    //     if (isAuthenticated) {
    //         navigate('/dashboard')
    //     }
    //     else {
    //         navigate('/')
    //     }
    // }

    //-------------------------------------------
    // handleDrawerToggle
    // Handle closing the drawer from the drawer component itself
    // 
    // Hamburger Menu Animation Examples
    // https://www.sliderrevolution.com/resources/css-animation-examples/
    //-------------------------------------------
    function handleDrawerToggle() {
        if (isDrawerLocked)
            return

        dispatch(setIsDrawerOpen(!isDrawerOpen))
        // console.log("App.handleDrawerToggle(): " + isDrawerOpen)
    }


    // Size app for drawer
    useEffect(() => {
        if (!isDrawerOpen)
            dispatch(setAppWidth(window.innerWidth - theme.drawerWidth))
        else
            dispatch(setAppWidth(window.innerWidth))
    }, [dispatch, isDrawerOpen, theme.drawerWidth])


    //-------------------------------------------
    // Handle closing the drawer from the drawer component 
    //-------------------------------------------
    function closeNavBar() {
        console.log(">>>---> App.closeNavBar()")

        if (isDrawerLocked)
            return

        dispatch(setAppWidth(window.innerWidth))
        dispatch(setIsDrawerOpen(false))

        // setIsDrawerOpen(true)
    }


    //-------------------------------------------
    // isNavBarLocked - Handle closing the drawer from the drawer component 
    //-------------------------------------------
    function isNavBarLocked(isLocked) {
        setIsDrawerLocked(isLocked)
    }

    //-------------------------------------------
    // onLogin
    //-------------------------------------------
    function onLogin() {
        setIsDrawerLocked(false)
        dispatch(setIsDrawerOpen(false))
        navigate('/login', { replace: true })
    }


    //-------------------------------------------
    // onLogoutUser
    //-------------------------------------------
    function onLogoutUser() {
        try {
            // Close VideoChat
            handleCloseVideoChat()
            setIsDrawerLocked(false)
            // closeNavBar()

            store.dispatch(logoutUser(navigate))
        } catch (err) {
            console.log('FindTrueFriends Logout error: ', err)
        }
    }


    //-------------------------------------------
    // onRegister
    //-------------------------------------------
    function onRegister() {
        setIsDrawerLocked(false)
        closeNavBar()
        navigate('/register', { replace: true })
    }


    //-------------------------------------------
    // Mount the APP
    // Can't use navigate because router_dom nav changes will force updates
    //-------------------------------------------
    useEffect(() => {
        // Refresh the token on a restart
        if (localStorage.jwtToken) {
            let decoded = jwt_decode(localStorage.jwtToken)
            dispatch(getProfile(decoded.id))

            // https://stackoverflow.com/questions/45978230/get-version-number-from-package-json-in-react-redux-create-react-app
            // https://medium.com/how-to-react/using-env-file-in-react-js-b2714235e77e

            const locale = localStorage.locale ||
                (navigator.languages && navigator.languages[0]) || navigator.language
                || navigator.userLanguage
                || 'en-US'

            console.log(`${process.env.REACT_APP_DESCRIPTION}: ${process.env.REACT_APP_VERSION} - ${navigator.language} detected`)
            console.log(`${process.env.REACT_APP_DESCRIPTION}: ${process.env.REACT_APP_VERSION} - ${locale} selected`)
        }
        else if (localStorage.locale) {
            return  // locale was already set (below)...
        }
        else {
            // If locale IS NOT set...
            // Default the locale based on "navigator.language"
            if (navigator.language.includes('en')) {
                console.log(`Loading English`)
                localStorage.setItem('locale', 'en-US')
            }
            else if (navigator.language.includes('es')) {
                console.log(`Loading Español`)
                localStorage.setItem('locale', 'es-ES')
            }
            else if (navigator.language.includes('fr')) {
                console.log(`Loading Français`)
                localStorage.setItem('locale', 'fr-FR')
            }

            window.location.reload()
        }

    }, [dispatch])


    //-------------------------------------------
    // Future: Unsplash Ad Placeholder
    //-------------------------------------------
    // useEffect(() => {
    //     if (refFooter && refFooter.current)
    //         setAdImage("https://source.unsplash.com/" + refFooter.current.clientWidth + "x" + refFooter.current.clientHeight + "/?local,business/sig=" + Math.random() + "/SameSite=None")
    // }, [width])

    // https://github.com/mvasin/react-div-100vh/issues/30
    // Give this a shot. Seems to be working for me.

    //-------------------------------------------
    // Start and end with Drawer open on wide windows
    // Not on narrow devices
    //-------------------------------------------
    useEffect(() => {
        if (window.innerWidth > 500)
            dispatch(setIsDrawerOpen(true))
    }, [dispatch, isAuthenticated])


    //-------------------------------------------
    // App Width
    //-------------------------------------------
    useEffect(() => {
        // On mount...
        if (!mounted) {
            dispatch(setAppWidth(window.innerWidth))

            // Start with Drawer open on wide windows, not on narrow ones
            if (window.innerWidth > 500)
                dispatch(setIsDrawerOpen(true))

            setIsMounted(true)
        }

        const updateWidth = () => {
            dispatch(setAppWidth(window.innerWidth))
            // console.log("Window width: " + width + "/ height: " + height)
        }

        // const updateViewports = () => {
        //     // console.log("Window width: " + width + "/ height: " + height)
        //     setViewports(getViewports())
        // }

        window.addEventListener('resize', updateWidth)
        // window.visualViewport.addEventListener('resize', updateViewports)

        return () => {
            window.removeEventListener('resize', updateWidth)
            // window.visualViewport.removeEventListener('resize', updateViewports)
        }
    }, [dispatch, mounted, width])


    //-------------------------------------------
    // Maintenance Dialog
    //-------------------------------------------
    const handleMaintDialog = React.useCallback(() => {
        setShowMaintDialog(!showMaintDialog)
    }, [showMaintDialog])

    const handleMaintDialogClose = React.useCallback(() => {
        setShowMaintDialog(!showMaintDialog)
    }, [showMaintDialog])

    const handlers = {
        MAINT: handleMaintDialog
    };


    return (
        <Div100vh>
            <div id='ftfContent' className={classes.myApp}>

                <MaintDialog show={showMaintDialog} fullscreen={false} onClose={handleMaintDialogClose} />

                {/* style={{ height: appHeight }} */}

                <HotKeys keyMap={keyMap} handlers={handlers}>

                    <AppBar className={classNames(classes.appBar)}>
                        <Toolbar className={classes.toolbar} disableGutters >
                            <IconButton
                                color="inherit"
                                aria-label='drawer'
                                id='drawer'
                                disabled={(isStealthMode && !isAuthenticated)}
                                onClick={handleDrawerToggle}
                                size="large"
                            >
                                {/* className={classNames(classes.menuButton)} */}
                                <MenuIcon />
                            </IconButton>

                            <img alt='Logo' src={'https://www.findtruefriends.com/logo/ftf_logo_small.png'} className={classes.logo} />

                            <Typography variant="h5" color="inherit" noWrap className={classes.ftf}>
                                FindTrueFriends
                            </Typography>


                            {/* Testing: View App Height */}
                            {/* <Typography variant="subtitle2" color="inherit" noWrap>
                                    i: {window.innerHeight + "  "}
                                    100: {myHeight}
                                </Typography> */}
                            {/* <Button className={classes.toolbarButtonStyle} onClick={handleViewAppHeight}>
                                    <FormattedMessage id="test.Height" defaultMessage="Height" />
                                </Button> */}


                            <div style={{ float: 'right', marginLeft: 'auto', display: 'flex' }}>
                                {isAuthenticated && (
                                    <Button id='logout' className={classes.toolbarButtonStyle} onClick={onLogoutUser}>
                                        <FormattedMessage id="system.logout" defaultMessage="Logout" />
                                    </Button>
                                )}

                                {/* FrontPage only */}
                                {!isAuthenticated && location.pathname === '/' && (
                                    <AnimatedArrow>
                                        <ForwardIcon />
                                    </AnimatedArrow>
                                )}

                                {/* Normal usage */}
                                {!isAuthenticated && (
                                    <Button id='reg' className={classes.toolbarButtonStyle} disabled={isStealthMode && !isAdmin} onClick={onRegister}>
                                        <FormattedMessage id="system.signUp" defaultMessage="Sign Up" />
                                    </Button>
                                )}

                                {/* Testing Mode for Admins only */}
                                {/* {isAuthenticated && isAdmin && (
                                    <Button id='reg' className={classes.toolbarButtonStyle} onClick={onRegister}>
                                        <FormattedMessage id="system.signUp" defaultMessage="Sign Up" />
                                    </Button>
                                )} */}

                                {!isAuthenticated && (
                                    <Button id='login' className={classes.toolbarButtonStyle} onClick={onLogin}>
                                        <FormattedMessage id="system.login" defaultMessage="Login" />
                                    </Button>
                                )}

                            </div>

                        </Toolbar>
                    </AppBar>

                    {/* <ErrorBoundary> */}

                    <Navbar id='navBar' isDrawerOpen={isDrawerOpen} closeNavBar={closeNavBar} isNavBarLocked={isNavBarLocked} />

                    <animated.div id='mainContent' className={classes.mainContent} style={animationMainContent} >
                        <animated.div style={fadeinAnimation}>

                            {/* https://github.com/remix-run/react-router/blob/main/docs/upgrading/v5.md#advantages-of-route-element */}
                            <Routes>
                                <Route exact path="/" element={<FrontPage />} />
                                <Route exact path="/dashboard" element={<DashboardPage />} />
                                <Route exact path="/chats" element={<ChatsPage />} />
                                <Route exact path="/friends" element={<FriendsPage isDrawerOpen={isDrawerOpen} />} />

                                <Route exact path="/groups" element={<GroupsPage />} />
                                <Route exact path="/group"
                                    element={
                                        <React.Suspense fallback={<div />}>
                                            <GroupPage />
                                        </React.Suspense>
                                    }
                                />

                                <Route exact path="/search"
                                    element={
                                        <React.Suspense fallback={<div />}>
                                            <SearchPage />
                                        </React.Suspense>
                                    }
                                />

                                <Route exact path="/viewedme" element={<ViewsPage />} />
                                <Route exact path="/whoisonline" element={<WhoisOnlinePage />} />
                                <Route exact path="/wall" element={<WallPage />} />
                                <Route exact path="/memberprofile" element={<MemberProfile />} />
                                <Route exact path="/profile" element={<Profile />} />

                                <Route exact path="/help" element={
                                    <React.Suspense fallback={<div />}>
                                        <HelpPage />
                                    </React.Suspense>}
                                />

                                {/* App Bar Buttons */}
                                <Route exact path="/register" element={<Register />} />
                                <Route exact path="/login" element={<Login />} />

                                {/* Policies */}
                                {/* <Route exact path="/codeofconduct" render={() => <CodeOfConduct show={true} fullscreen={false} onClose={toggleCodeOfConduct}/>}/>
                                    <Route exact path="/cookiepolicy" render={() => <CookiePolicy show={true} fullscreen={false}/>}/>
                                    <Route exact path="/privacypolicy" render={() => <PrivacyPolicy show={true} fullscreen={false}/>}/>
                                    <Route exact path="/termsofuse" render={() => <TermsOfUseAgreement show={true} fullscreen={false}/>}/> */}

                                {/* FUTURE: groupevents */}
                                {/* <Route exact path="/events" element={<EventsPage />} /> */}
                                {/* <Route exact path="/groupevents" element={<GroupEventsPage />} /> */}

                                {/* FUTURE: news */}
                                {/* <Route exact path="/news" element={<NewsFeedPage />} /> */}

                                {/* FUTURE: videodates */}
                                {/* <Route exact path="/vdate" element={<VDatePage />} /> */}
                                {/* <Route exact path="/videoChats" element={<VideoChatsPage />} /> */}

                                {/* FrontPage - If page 'abcde' isn't found - Must be last */}
                                <Route exact path="/*" element={<FrontPage />} />
                            </Routes>

                            {/* Footer'Ads' */}
                            {/* {location.pathname !== "/" &&
                                <div id='AdContainer' ref={refFooter} className={classes.footerAd}>
                                    <AdContainer variant="footer" width='100%' />
                                </div>
                        } */}
                        </animated.div>
                    </animated.div>

                    <ToastContainer className={classes.toast} pauseOnFocusLoss={false} />

                    {isAuthenticated && !gdprAccepted && <Gdpr show />}

                    {isAuthenticated && emailRequested && <ValidateEmail show />}

                    <VideoChat show={showVideoChat} chat='dummy' onClose={handleCloseVideoChat} />

                    {/* </ErrorBoundary> */}
                </HotKeys>
            </div>
        </Div100vh>
    );
}

