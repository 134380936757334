/* eslint-disable no-console */

import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { lighten } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'
// import { useTheme } from '@mui/material/styles'

import { useNavigate } from 'react-router-dom'

import classNames from 'classnames'
import moment from 'moment'
import isEmpty from '../validation/is-empty'

// import Resize from 'react-resize-detector'
import { FormattedDate, FormattedTime, FormattedMessage, useIntl } from 'react-intl'

import Avatar from './Avatar'

import { Tooltip, Typography, Zoom } from '@mui/material'
import PhotoTwoToneIcon from '@mui/icons-material/PhotoTwoTone';
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone';

import 'react-virtualized/styles.css'
import './ReactVirtualizedTable.css'

import { StyledBadge } from "../styles/ftfStyles"

import { Column, SortIndicator } from 'react-virtualized'  // SortDirection
import AutoSizer from 'react-virtualized/dist/es/AutoSizer'
import Table from 'react-virtualized/dist/es/Table'

// import { Height } from '../constants/ftfDefinitions'

// Services  // Message
import { getMember } from '../actions/member.actions'
import { getGender } from '../helpers/utilities'


const useStyles = makeStyles(theme => ({
    // bullet: {
    //     color: theme.palette.primary.dark,
    //     fontSize: '.8rem',
    //     margin: '0 2px',
    // },

    buttonBar: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        position: 'absolute',
        height: 26,
        bottom: -5,
        marginLeft: -2,
    },

    cellCentered: {
        color: theme.palette.primary.dark,
        fontSize: '0.9rem',
        textAlign: 'center',
        // This needs to match the right margin on the Header to line up vertically
        marginRight: 10,
    },

    cellDate: {
        color: theme.palette.primary.dark,
        fontSize: '0.7rem',
        textAlign: 'center',
        // This needs to match the right margin on the Header to line up vertically
        marginRight: 10,
    },

    cellLeft: {
        color: theme.palette.primary.dark,
        textAlign: "left",
        alignSelf: 'top',
    },

    details: {
        display: 'flex',
        // paddingLeft: 6,
        // paddingTop: 0,
        flexDirection: 'column',
    },

    fab: {
        position: 'absolute',
        bottom: theme.spacing(4),
        right: theme.spacing(8),
    },

    flexContainer: {
        display: 'flex',
        alignItems: 'center',
    },

    headingCentered: {
        color: theme.palette.primary.dark,
        textAlign: 'center',
        alignSelf: 'center',
        justifyContent: 'center',
        // margin: '0 10px'
    },

    headingFirstname: {
        color: theme.palette.primary.dark,
        // textAlign: 'center',
        // alignSelf: 'center',
        // justifyContent: 'center',
        // margin: '0 10px'
    },

    icon: {
        marginRight: 8,
    },

    memberName: {
        color: theme.palette.primary.dark,
        // lineHeight: 1.2,
    },

    noClick: {
        // cursor: 'initial',
    },

    noRows: {
        color: theme.palette.primary.dark,
        textAlign: "left",
        marginLeft: 8,
    },

    rowOdd: {
        backgroundColor: lighten(theme.palette.primary.light, 0.5),
    },

    rowHeader: {
        backgroundColor: theme.palette.primary.light,
    },

    sortIndicator: {
        color: theme.palette.secondary.dark,
        fontSize: '2.8vh',
        paddingTop: 7,
    },

    table: {
        fontFamily: theme.typography.fontFamily,
    },

    tableRow: {
        cursor: 'pointer',
        height: 'auto',
        outline: 'none',
    },

    tableRowHover: {
        '&:hover': { backgroundColor: lighten(theme.palette.primary.light, 0.75), },
    },
}))


export default function ReactVirtualizedTable(props) {
    const classes = useStyles(props)
    const intl = useIntl()
    let navigate = useNavigate()
    // let theme = useTheme()

    const user = useSelector(state => state.profile.profile)
    // const isProfileVisible = useSelector(state => state.profile.profile.isVisible)
    const width = useSelector(state => state.app.width)
    const isMobile = (width <= 367)

    const dispatch = useDispatch()

    const [rows, setRows] = useState([])
    const [rowHeight, setRowHeight] = useState(75)
    // const [rowSelected, setRowSelected] = useState(null)

    const [showMoreColumns, setShowMoreColumns] = useState(true)
    const [showNarrowColumn, setShowNarrowColumn] = useState(true)

    // scrollToIndex: undefined,
    const [sortBy, setSortBy] = useState(props.defaultSortBy)
    const [sortDirection, setSortDirection] = useState(props.defaultSortDir)
    const [big, setBig] = useState(props.big)

    const { members, showViewDate, ...tableProps } = props

    // const columnVisibility = { xs: false, sm: true, md: true, lg: true, xl: true }
    // const showMoreColumns = xs[width]

    //-------------------------------------------
    // showColumns
    //-------------------------------------------
    useEffect(() => {
        // Narrow 
        if (width < 800)
            setShowMoreColumns(false)
        else
            setShowMoreColumns(true)

        // Extremely narrow with drawer open
        if (isMobile) {
            setBig(false)
            setShowNarrowColumn(showViewDate ? false : true)
        }
        else {
            if (props.big && !isMobile)
                setBig(true)

            setShowNarrowColumn(showViewDate ? false : true)
        }
    }, [props.big, isMobile, width, showViewDate])


    //-------------------------------------------
    // Load Friends (load rows)
    //-------------------------------------------
    useEffect(() => {
        // rowHeight is based on 'small', 'big', or neither (default)
        if (isMobile) {
            setRowHeight(75)
            setBig(false)
        }
        else if (big) {
            setRowHeight(150)
        }
        else if (props.small) {
            setRowHeight(60)
        }

        // if (members.length > 0) {
        setRows(members)
        // }
    }, [members, big, isMobile, props.small])


    //-------------------------------------------
    // 
    //-------------------------------------------
    // useEffect(() => {
    //     if (!isEmpty(rows)) {
    //         // Maintains the user's selected sort column and direction on a re-search
    //         if (!isEmpty(sortBy) && !isEmpty(sortDirection)) {
    //             let sortedRows = rows.sort(dynamicSort(sortBy, sortDirection))
    //             setRows(sortedRows)
    //         }
    //     }
    // }, [rows, sortBy, sortDirection])


    //---------------------------------------------
    // Add Friend
    //---------------------------------------------
    // function handleAddFriendClick(e, rowData) {
    //     e.stopPropagation()

    //     rowData.favorite = !rowData.favorite
    //     setRowSelected(rowData)
    //     // forceUpdate()

    //     dispatch(addFriend(rowData.id))  // Update DB
    // }

    //---------------------------------------------
    // Go to Profile
    //---------------------------------------------
    function onViewProfile(e, memberId) {
        e.stopPropagation()
        dispatch(getMember(memberId))
        navigate('/memberprofile')
    }

    //-------------------------------------------
    // Table Callbacks
    //-------------------------------------------
    function _rowClick(row) {

        if (row && row.index >= 0) {
            // setRowSelected(row.rowData)

            // Row click behavior
            onViewProfile(row.event, row.rowData.id)
        }
    }

    function _rowClassName({ index }) {
        return classNames(classes.tableRow, classes.flexContainer, {
            [classes.tableRowHover]: index !== -1, // && onRowClick != null,
            [classes.rowHeader]: index === -1,
            [classes.rowOdd]: index % 2 === 1,
        })
    }

    function _headerRenderer({ columnData, dataKey, disableSort, label, sortBy, sortDirection }) {
        return (
            <div component="div" className={classNames(classes.flexContainer, classes.headingCentered)}>
                <Typography variant="caption">
                    {label}
                </Typography>

                {sortBy === dataKey && (
                    <Typography className={classes.sortIndicator}>
                        <SortIndicator sortDirection={sortDirection} />
                    </Typography>
                )}
            </div>
        )
    }


    // https://github.com/bvaughn/react-virtualized/issues/723
    // https://github.com/twobin/react-lazyload
    // https://github.com/bvaughn/tweets/blob/37d0139736346db16b9681d5b859a4e127964518/src/components/TweetList.js#L126-L132
    function _cellRendererImage({ cellData, columnData, columnIndex, dataKey, isScrolling, rowData, parent, rowIndex }) {

        return (
            <div component="div" className={classNames(classes.noClick)} style={{ alignItems: 'start' }}>
                <Avatar small={props.small} big={big} isOnline={rowData['isOnline']} gender={rowData['gender']} avatarUrl={rowData[dataKey]} delay={250} alt={rowData['firstname']}>...</Avatar>
            </div >
        )
    }


    // https://github.com/bvaughn/react-virtualized/issues/723
    // https://github.com/twobin/react-lazyload
    // https://github.com/bvaughn/react-virtualized/blob/master/docs/Column.md
    // 
    function _cellRendererProfile({ cellData, columnData, columnIndex, dataKey, isScrolling, rowData, rowIndex }) {

        // Trim the headline based on width
        // var headline = ""
        // if (!isEmpty(rowData['headline'])) {
        //     if (width > 1280)       // lg
        //         headline = rowData['headline']    // lg, xl
        //     else if (width > 960)   // sm
        //         headline = rowData['headline'].slice(0, 125)
        //     else if (width > 600)   // md
        //         headline = rowData['headline'].slice(0, 70)
        //     else
        //         headline = rowData['headline'].slice(0, 50)
        // }

        return (
            <div id='Profile' className={classes.details} style={{ alignSelf: 'flex-start' }}>
                <Typography className={classes.memberName} variant="subtitle2">
                    {!isEmpty(rowData['firstname']) && rowData['firstname']}
                    {!isEmpty(rowData['groupName']) && rowData['groupName']}
                    {!isEmpty(rowData['eventName']) && rowData['eventName']}
                </Typography>

                {rowData['city'] && <Typography variant="caption" noWrap style={{ lineHeight: 1.25 }}>
                    {rowData['city']}, {rowData['state']}
                </Typography>}


                <Typography variant='caption' style={{ lineHeight: 1.25 }}>
                    {!isEmpty(rowData['gender']) && (rowData['gender'] > 0) && intl.formatMessage({ id: getGender(rowData['gender']), defaultMessage: 'Gender' })}
                </Typography>


                {/* ButtonBar */}
                <div className={classes.buttonBar}>
                    {rowData.favorite && <FavoriteTwoToneIcon fontSize="small" color="secondary" className={classes.icon} />}

                    {(rowData['widgetCount'] > 0) &&
                        <Tooltip enterDelay={750} TransitionComponent={Zoom} placement="top"
                            title={<FormattedMessage id="member.photo.count" defaultMessage="{count, plural, =0 {no Photos} one {# Photo} other {# Photos}}" values={{ count: rowData['widgetCount'] }}/>}
                        >
                            <StyledBadge color="secondary" className={classes.icon} offset={-3} badgeContent={rowData['widgetCount']} max={999} >
                                <PhotoTwoToneIcon color="primary" fontSize="small" />
                            </StyledBadge>
                        </Tooltip>
                    }

                </div>
            </div>
        )
    }


    // function _cellRendererHeight({ cellData, columnData, columnIndex, dataKey, isScrolling, rowData, parent, rowIndex }) {
    //     let h = Height.find(k => k.value === 0).label

    //     // Height may not have been answered
    //     if (dataKey in rowData)
    //         h = Height.find(k => k.value === rowData[dataKey]).label

    //     let p = h.indexOf("(")

    //     return (
    //         <div style={{ display: 'flex', flexDirection: 'column' }}>
    //             <div style={{ alignSelf: 'center', margin: 2 }}>{h.slice(0, p)}</div>
    //             <div style={{ alignSelf: 'center', margin: 2 }}>{h.slice(p)}</div>
    //         </div>
    //     )
    // }


    function _cellRendererDate({ cellData, columnData, columnIndex, dataKey, isScrolling, rowData, parent, rowIndex }) {
        let d = moment(rowData[dataKey])
        let t = moment(rowData[dataKey])

        // return (<div>{lo}</div>)

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div>
                    <FormattedDate value={d} year="2-digit" month="short" day="2-digit" />
                </div>

                <div>
                    <FormattedTime value={t} />
                </div>
            </div>
        )
    }


    // function _onRowCountChange(event) {
    //     const n = parseInt(event.target.value, 10) || 0
    //     // setRowCount(n)
    // }


    function _noRowsRenderer() {
        return (
            <Typography className={classes.noRows} />
        )
    }


    function _sort({ sortBy, sortDirection }) {
        let sortedRows = rows.sort(dynamicSort(sortBy, sortDirection))

        setRows(sortedRows)
        setSortBy(sortBy)
        setSortDirection(sortDirection)
    }


    function dynamicSort(sortBy, sortDirection) {
        var sortOrder = (sortDirection === "DESC") ? -1 : 1

        return function (a, b) {
            var result = 0

            if (typeof a[sortBy] === 'string')
                result = a[sortBy].toLowerCase().localeCompare(b[sortBy].toLowerCase())
            else
                result = (a[sortBy] < b[sortBy]) ? -1 : (a[sortBy] > b[sortBy]) ? 1 : 0

            return result * sortOrder
        }
    }


    return (
        <React.Fragment>
            {/* Old - <SendMessage isSupportMsg={false} chat={selectedChat} show={showSendMessage} fullscreen={false} onClose={handleCloseSendMessage} /> */}
            {/* {rowSelected && showSendMessage && <SendMessage2 isSupportMsg={false} chat={rowSelected} onClose={handleCloseSendMessage} />} */}

            <AutoSizer>
                {({ height, width }) => (

                    <Table className={classes.table}

                        headerHeight={30}
                        rowHeight={rowHeight}

                        height={height}
                        width={width}

                        disableHeader={false}
                        headerClassName={classes.headerColumn}

                        noRowsRenderer={_noRowsRenderer}
                        overscanRowCount={10}

                        rowGetter={({ index }) => rows[index]}

                        rowCount={isEmpty(rows) ? 0 : rows.length}
                        // rowCount={rowCount}

                        sort={_sort}
                        sortBy={sortBy}
                        sortDirection={sortDirection}

                        onRowClick={event => _rowClick(event)}

                        {...tableProps}

                        rowClassName={_rowClassName}
                    >

                        <Column
                            label=""
                            cellRenderer={_cellRendererImage}
                            dataKey="avatarUrl"
                            disableSort
                            headerRenderer={_headerRenderer}
                            minWidth={rowHeight}
                            width={rowHeight}
                            style={{ margin: "2px" }}
                        />

                        <Column
                            cellRenderer={_cellRendererProfile}
                            dataKey="firstname"
                            label={<FormattedMessage id="table.member" defaultMessage="Member" />}
                            flexGrow={1}
                            headerRenderer={_headerRenderer}
                            width={260}
                            style={{ alignSelf: "flex-start" }}
                        />

                        {showNarrowColumn && <Column
                            className={classes.cellCentered}
                            dataKey="age"
                            label={<FormattedMessage id="system.age" defaultMessage="Age" />}
                            headerRenderer={_headerRenderer}
                            width={90}
                            numeric="true"
                        />}

                        {showNarrowColumn && <Column
                            className={classes.cellCentered}
                            width={90}
                            label={(user.isMiles && <FormattedMessage id="profile.location.miles" defaultMessage="Miles" />) || (!user.isMiles && <FormattedMessage id="profile.location.kms" defaultMessage="kms" />)}
                            dataKey="distance"
                            headerRenderer={_headerRenderer}
                            numeric="true"
                        />}

                        {/* {showMoreColumns && <Column
                            cellRenderer={_cellRendererHeight}
                            className={classes.cellCentered}
                            dataKey="heightInches"
                            label={<FormattedMessage id="system.height" defaultMessage="Height" />}
                            headerRenderer={_headerRenderer}
                            width={120}
                            numeric="true"
                        />} */}

                        {showViewDate && <Column
                            cellRenderer={_cellRendererDate}
                            className={classes.cellDate}
                            dataKey="eventDate"
                            label={<FormattedMessage id="table.viewDate" defaultMessage="View Date" />}
                            headerRenderer={_headerRenderer}
                            width={150}
                            numeric="false"
                        />}

                        {showMoreColumns && !showViewDate && <Column
                            cellRenderer={_cellRendererDate}
                            className={classes.cellDate}
                            dataKey="onlineLast"
                            label={<FormattedMessage id="table.online" defaultMessage="Online" />}
                            headerRenderer={_headerRenderer}
                            width={150}
                            numeric="false"
                        />}
                    </Table>
                )}
            </AutoSizer>
        </React.Fragment>
    )
}

