import React, { useEffect } from "react"

import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles'

// Mobile "real height": https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'
import isEmpty from '../validation/is-empty'

import { Typography } from "@mui/material"

import { FormattedMessage } from 'react-intl';

import { LayoutWithoutHeader } from "../styles/ftfStyles"

import ReactVirtualizedTable from './ReactVirtualizedTable';
import { SortDirection } from 'react-virtualized';

import { whoViewedMe } from '../actions/whoviewedme.actions';


const useStyles = makeStyles(theme => ({
    noRows: {
        color: theme.palette.primary.dark,
        textAlign: "left",
        marginLeft: 8,
    },
}))

export default function ViewsPage(props) {
    const classes = useStyles(props)

    const members = useSelector(state => state.whoViewedMe.members)
    const isLoading = useSelector(state => state.whoViewedMe.isLoading)

    const dispatch = useDispatch()

    //-------------------------------------------
    // Reload
    //-------------------------------------------
    useEffect(() => {
        dispatch(whoViewedMe())
    }, [dispatch])

    return (
        <Div100vh>
            <LayoutWithoutHeader>
                {isEmpty(members) && !isLoading && 
                    <div>
                        <Typography className={classes.noRows}>
                            <FormattedMessage id="system.views.none" defaultMessage="No recent views..." />
                        </Typography>

                        {/* <img src={FTF_Images.NoViews} style={{ maxWidth: '50%', display: 'block', marginLeft: 'auto', marginRight: 'auto', opacity: 0.25 }} /> */}
                    </div>
                }

                {!isEmpty(members) &&
                    <ReactVirtualizedTable
                        title={<FormattedMessage id="navbar.views" defaultMessage="Views" />}
                        showViewDate
                        defaultSortBy="eventDate"
                        defaultSortDir={SortDirection.DESC}
                        members={members}
                    />}

            </LayoutWithoutHeader>
        </Div100vh>
    )
}

