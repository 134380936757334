import 'leaflet/dist/leaflet.css'
import MapNew from './MapNew'

import React from "react"
import { useSelector } from "react-redux"

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

import { MemberProfileTabbedLayout } from "../styles/ftfStyles"

export default function MemberProfileLocation(props) {
    // errors: state.profile.errors,
    const member = useSelector(state => state.member.member)

    const circleRadius = member.targetRadius
    const isCenteringMap = true
    const zoom = 9
    const markerPosition = {
        lat: member.location ? member.location.coordinates[1] : 42.1707776,
        lng: member.location ? member.location.coordinates[0] : -88.08038399999998,
    }
    

    function handleGeocoding(isBusy) {
        // Stubbed out
    }


    function handleLngLatChange(newPosition, city, state, country, zipcode) {
        // Stubbed out
    }

    return (
        <Div100vh>
            <MemberProfileTabbedLayout>

                {member.location &&
                    <MapNew id='map' isEditable={false}
                        handleLngLatChange={(latlng, city, state, country, zipcode) => handleLngLatChange(latlng, city, state, country, zipcode)}
                        handleGeocoding={(isBusy) => handleGeocoding(isBusy)}

                        isCenteringMap={isCenteringMap}
                        markerPosition={markerPosition}
                        circleRadius={circleRadius}
                        zoom={zoom}
                    />
                }

                {/* TODO: Future - Mobile Location - DSW */}
                {/* <FormControlLabel
                    control={
                        <Switch 
                            checked={this.props.profile.isVisibleMobileLocation || false}
                            name='isVisible'
                            onChange={this.props.handleChange()}
                            value="isVisible"
                            color="primary"
                        />
                    }
                    label={this.props.profile.isVisible ? "Your Location is Visible" : "Your Location is Hidden"}
                /> */}

            </MemberProfileTabbedLayout>
        </Div100vh>
    )
}


