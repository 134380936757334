
// chat.actions.js

// import { CONNECTION_ERROR } from '../actions/error.actions'
// import isEmpty from '../validation/is-empty'

import axios from 'axios'
import { getGroup } from '../actions/group.actions'
import { chatGetConvs } from '../actions/chat.actions'
import { logoutUser } from './auth.actions'
import { CONNECTION_ERROR } from './error.actions'
import { getProfile } from '../actions/profile.actions'
import { gaFireEvent } from '../helpers/utilities'
import { runGetPhotos } from '../actions/image.actions'

// Member
export const MEMBER_GET_REQUEST = 'MEMBER_GET_REQUEST'
export const MEMBER_GET_SUCCESS = 'MEMBER_GET_SUCCESS'
export const MEMBER_GET_FAILED = 'MEMBER_GET_FAILED'

// Friends
export const MEMBER_ADDFRIEND_REQUEST = 'MEMBER_ADDFRIEND_REQUEST'
export const MEMBER_ADDFRIEND_SUCCESS = 'MEMBER_ADDFRIEND_SUCCESS'
export const MEMBER_ADDFRIEND_FAILED = 'MEMBER_ADDFRIEND_FAILED'

// Friends
export const MEMBER_GETFRIENDS_REQUEST = 'MEMBER_GETFRIENDS_REQUEST'
export const MEMBER_GETFRIENDS_SUCCESS = 'MEMBER_GETFRIENDS_SUCCESS'
export const MEMBER_GETFRIENDS_FAILED = 'MEMBER_GETFRIENDS_FAILED'

// Likes - Member Photo
export const MEMBER_LIKEPHOTO_REQUEST = 'MEMBER_LIKEPHOTO_REQUEST'
export const MEMBER_LIKEPHOTO_SUCCESS = 'MEMBER_LIKEPHOTO_SUCCESS'
export const MEMBER_LIKEPHOTO_FAILED = 'MEMBER_LIKEPHOTO_FAILED'

// Block/Unblock
export const MEMBER_BLOCK_REQUEST = 'MEMBER_BLOCK_REQUEST'
export const MEMBER_BLOCK_SUCCESS = 'MEMBER_BLOCK_SUCCESS'
export const MEMBER_BLOCK_FAILED = 'MEMBER_BLOCK_FAILED'

// Support (Support person is a member)
export const SUPPORT_GET_REQUEST = 'SUPPORT_GET_REQUEST'
export const SUPPORT_GET_SUCCESS = 'SUPPORT_GET_SUCCESS'
export const SUPPORT_GET_FAILED = 'SUPPORT_GET_FAILED'

// Ban
export const BAN_REQUEST = 'BAN_REQUEST'
export const BAN_SUCCESS = 'BAN_SUCCESS'
export const BAN_FAILED = 'BAN_FAILED'

// Bump Members Reputation
export const BUMP_REQUEST = 'BUMP_REQUEST'
export const BUMP_SUCCESS = 'BUMP_SUCCESS'
export const BUMP_FAILED = 'BUMP_FAILED'

// Ding Members Reputation and File a Report
export const REPORT_REQUEST = 'REPORT_REQUEST'
export const REPORT_SUCCESS = 'REPORT_SUCCESS'
export const REPORT_FAILED = 'REPORT_FAILED'


//-----------------------------------------------
// bumpReputation "Bumps" Reputation +/- Points
//-----------------------------------------------
// memberId
// points
// comment
//-----------------------------------------------
export const bumpReputation = (memberId, points, comment, isAdmin = false) => dispatch => {

    gaFireEvent("reputation", {
        event_category: "reputation",
        bumped: points,
    })

    // console.log('getSupportMember:', this)    
    dispatch(start(memberId))

    axios.post('/api/users/bump', { params: { memberId: memberId, points: points, comment: comment, isAdmin: isAdmin } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(response.data.toString()))
        })
        .then(() => Promise.all([
            dispatch(getMember(memberId))
        ]))
        .catch(error => {
            let errMsg = ""
            if (error.response != null)
                errMsg = error.response.status.toString() + ": " + error.response.statusText.toString()
            else
                errMsg = error.message

            console.log(errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: "Cannot connect to the server" })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(memberId) { return { type: BUMP_REQUEST, memberId } }
    function success() { return { type: BUMP_SUCCESS } }
    function failed(errors) { return { type: BUMP_FAILED, errors } }
}

//-----------------------------------------------
// Ban Member
//-----------------------------------------------
// offenderId - Member
// comment
// group
// chat
// post
//-----------------------------------------------
export const banMember = (groupId, memberId) => dispatch => {

    gaFireEvent("member", {
        event_category: "banned",
        groupId: groupId,
    })

    // console.log('getSupportMember:', this)    
    dispatch(start(memberId))

    axios.post('/api/users/gb', { params: { groupId: groupId, memberId: memberId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(response.data.toString()))
        })
        // Causes refresh
        .then(() => Promise.all([
            dispatch(getGroup(groupId))
        ]))
        .catch(error => {
            let errMsg = ""
            if (error.response != null)
                errMsg = error.response.status.toString() + ": " + error.response.statusText.toString()
            else
                errMsg = error.message

            console.log(errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: "Cannot connect to the server" })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(memberId) { return { type: BAN_REQUEST, memberId } }
    function success() { return { type: BAN_SUCCESS } }
    function failed(errors) { return { type: BAN_FAILED, errors } }
}


//-----------------------------------------------
// Report inappropriate behavior...
//-----------------------------------------------
// operation  = 'add' or 'remove'
// groupId
// offenderId (memberId)
// widget - Can be null
// comment - can be null
// 
// 
// Example: "Remove", groupId, issue 
//-----------------------------------------------
export const reportMember = ( groupId, offenderId, widget, comment) => dispatch => {

    gaFireEvent("member", {
        event_category: "reported",
        groupId: groupId,
    })

    // console.log('getSupportMember:', this) 
    dispatch(start(offenderId))

    axios.post('/api/users/report', { params: { operation: 'add', groupId: groupId, offenderId: offenderId, widget: widget, comment: comment } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(response.data.toString()))
        })
        // Causes refresh
        .then(() => Promise.all([
            dispatch(getGroup(groupId))
        ]))
        .catch(error => {
            let errMsg = ""
            if (error.response != null)
                errMsg = error.response.status.toString() + ": " + error.response.statusText.toString()
            else
                errMsg = error.message

            console.log(errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: "Cannot connect to the server" })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(offenderId) { return { type: REPORT_REQUEST, offenderId } }
    function success() { return { type: REPORT_SUCCESS } }
    function failed(errors) { return { type: REPORT_FAILED, errors } }
}


//-----------------------------------------------
// Delete a Report
//-----------------------------------------------
// groupId
// issue object ( issueId, ding ) 
// Example: "Remove", groupId, issue 
//-----------------------------------------------
export const reportDelete = (groupId, issue) => dispatch => {

    gaFireEvent("member", {
        event_category: "report removed",
        groupId: groupId,
        issueId: issue,
    })

    // console.log('getSupportMember:', this)    
    dispatch(start(issue.issueId))

    axios.post('/api/users/report', { params: { operation: 'remove', groupId: groupId, issue: issue } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(response.data.toString()))
        })
        // Causes refresh
        .then(() => Promise.all([
            dispatch(getGroup(groupId))
        ]))
        .catch(error => {
            let errMsg = ""
            if (error.response != null)
                errMsg = error.response.status.toString() + ": " + error.response.statusText.toString()
            else
                errMsg = error.message

            console.log(errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: "Cannot connect to the server" })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(issueId) { return { type: REPORT_REQUEST, issueId } }
    function success() { return { type: REPORT_SUCCESS } }
    function failed(errors) { return { type: REPORT_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for get Support Member...
//-----------------------------------------------
// memberId - Requesting Member
//-----------------------------------------------
export const getSupportMember = () => dispatch => {
    // console.log('getSupportMember:', this)

    dispatch(start(''))

    axios.get('/api/users/support')
        .then(response => {
            if (response.status === 200)
                dispatch(success(response.data.member))
            else // 206 - Validation Error(s)
                dispatch(failed(response.data.toString()))
        })
        .catch(error => {
            let errMsg = ""
            if (error.response != null)
                errMsg = error.response.status.toString() + ": " + error.response.statusText.toString()
            else
                errMsg = error.message

            console.log(errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: "Cannot connect to the server" })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: SUPPORT_GET_REQUEST } }
    function success(member) { return { type: SUPPORT_GET_SUCCESS, member } }
    function failed(errors) { return { type: SUPPORT_GET_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for get Member...
//-----------------------------------------------
// memberId - Target Member
//-----------------------------------------------
export const getMember = (memberId) => dispatch => {

    gaFireEvent("member", {
        event_category: "viewed",
    })

    // console.log('getMember:', this)    
    dispatch(start(memberId))

    axios.get('/api/users/getMember', { params: { memberId: memberId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success(response.data.member))
            else // 206 - Blocked! or Hidden Profile!
                dispatch(failed(memberId, response.data.toString()))
        })
        .catch(error => {
            let errMsg = ""
            if (error.response != null)
                errMsg = error.response.status.toString() + ": " + error.response.statusText.toString()
            else
                errMsg = error.message

            console.log(errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: "Cannot connect to the server" })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(memberId) { return { type: MEMBER_GET_REQUEST, memberId } }
    function success(member) { return { type: MEMBER_GET_SUCCESS, member } }
    function failed(errors) { return { type: MEMBER_GET_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for addfriend...
//-----------------------------------------------
// memberId - Target Member
//-----------------------------------------------
export const addFriend = (memberId) => dispatch => {
    // console.log('addFriend:', this)    

    gaFireEvent("member", { event_category: "friend toggle" })

    dispatch(start())

    axios.post('/api/users/addfriend', { params: { memberId: memberId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(memberId, response.data.toString()))
        })
        .then(() => Promise.all([
            dispatch(chatGetConvs()),
            dispatch(getFriends()),
            dispatch(getProfile()),
            // dispatch(runSearch()), 
        ])
        )
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: MEMBER_ADDFRIEND_REQUEST } }
    function success() { return { type: MEMBER_ADDFRIEND_SUCCESS } }
    function failed(errors) { return { type: MEMBER_ADDFRIEND_FAILED, errors } }
}

//-----------------------------------------------
// Entry point for getFriends...
//-----------------------------------------------
export const getFriends = () => dispatch => {
    // console.log('getFriends:', this)   

    gaFireEvent("member", { event_category: "get friends" })

    dispatch(start())

    axios.get('/api/users/friends')
        .then(response => {
            if (response.status === 200)
                dispatch(success(response.data.members))
            else // 206 - Validation Error(s)
                dispatch(failed(response.data.toString()))
        })
        // .then(() => Promise.all([
        //     dispatch(chatGetConvs()) 
        //     ])
        // )
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: MEMBER_GETFRIENDS_REQUEST } }
    function success(members) { return { type: MEMBER_GETFRIENDS_SUCCESS, members } }
    function failed(errors) { return { type: MEMBER_GETFRIENDS_FAILED, errors } }
}

//-----------------------------------------------
// Entry point for likephoto...
//-----------------------------------------------
// idType - "user", "member", "group"
// id - MemberId who owns photo
// photoId - Photo Liked
//-----------------------------------------------
export const likePhoto = (idType, id, photoId) => dispatch => {
    gaFireEvent("member", { event_category: "like photo toggle" })

    dispatch(start())

    axios.post('/api/users/likephoto', { params: { idType: idType, memberId: id, photoId: photoId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(photoId, response.data.toString()))
        })
        // Causes HUGE refresh
        .then(() => Promise.all([
            dispatch((idType === 'user') ? runGetPhotos() : (idType === 'member') ? getMember(id) : getGroup(id)),
        ])
        )
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: MEMBER_LIKEPHOTO_REQUEST } }
    function success() { return { type: MEMBER_LIKEPHOTO_SUCCESS } }
    function failed(errors) { return { type: MEMBER_LIKEPHOTO_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for blockMember...
//-----------------------------------------------
// memberId - Target Member
//-----------------------------------------------
export const blockMember = (memberId) => dispatch => {
    // console.log('blockMember:', this)    

    gaFireEvent("blocked", {
        event_category: "blocked",
    })

    dispatch(start())

    axios.post('/api/users/block', { params: { memberId: memberId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(memberId, response.data.toString()))
        })
        .then(() => Promise.all([
            dispatch(chatGetConvs()),
            dispatch(getFriends()),
            dispatch(getProfile()),
        ])
        )
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: MEMBER_BLOCK_REQUEST } }
    function success() { return { type: MEMBER_BLOCK_SUCCESS } }
    function failed(errors) { return { type: MEMBER_BLOCK_FAILED, errors } }
}




