// app.actions.js  (...and videoChat for now)
import axios from 'axios'
// import { setAuthToken } from '../setAuthToken'
import { logoutUser } from './auth.actions'
import { CONNECTION_ERROR } from '../actions/error.actions'

import { gaFireEvent } from '../helpers/utilities'
// const moment = require('moment')

export const APP_HEIGHT = 'APP_HEIGHT'
export const APP_WIDTH = 'APP_WIDTH'
export const IS_DRAWER_LOCKED = 'IS_DRAWER_LOCKED'
export const IS_DRAWER_OPEN = 'IS_DRAWER_OPEN'

export const SHOW_GDPR = 'SHOW_GDPR'
export const SHOW_VIDEO_CHAT = 'SHOW_VIDEO_CHAT'

export const TOGGLE_DRAWER = 'TOGGLE_DRAWER'

export const VIDEOCHAT_START_REQUEST = 'VIDEOCHAT_START_REQUEST'
export const VIDEOCHAT_START_FAILED = 'VIDEOCHAT_START_FAILED'
export const VIDEOCHAT_START_SUCCESS = 'VIDEOCHAT_START_SUCCESS'

export const VIDEOCHAT_STOP_REQUEST = 'VIDEOCHAT_STOP_REQUEST'
export const VIDEOCHAT_STOP_FAILED = 'VIDEOCHAT_STOP_FAILED'
export const VIDEOCHAT_STOP_SUCCESS = 'VIDEOCHAT_STOP_SUCCESS'


export function setAppHeight(height) {
    return { type: APP_HEIGHT, height }
}

export function setAppWidth(width) {
    return { type: APP_WIDTH, width }
}

// export function  setHeightAndWidth(height, width) {
//     // return { type: HEIGHT, h }
//     return { type: WIDTH, width }
// }

// export const drawerActions = {
//     open,
//     closed,
// };

export function setIsDrawerOpen(isDrawerOpen) {
    return { type: TOGGLE_DRAWER, isDrawerOpen }
}

//-----------------------------------------------
// Entry point for showGDPRDialog...
//-----------------------------------------------
export function showGDPRDialog(show) {
    // If starting a new VC...
    return { type: SHOW_GDPR, show }
}


//-----------------------------------------------
// Entry point for showVideoChatDialog...
//-----------------------------------------------
export function showVideoChatDialog(showVideoChat, isRoomCreator, user, accessId) {
    // If starting a new VC...
    return { type: SHOW_VIDEO_CHAT, showVideoChat, isRoomCreator, user, accessId }
}


//-----------------------------------------------
// Entry point for videoChatStart...
//-----------------------------------------------
export const videoChat = (chatId) => dispatch => {
    gaFireEvent('share', {
        content_type: 'video',
        method: 'videochat request',
        item_id: chatId,
    })

    dispatch(start())

    axios.get('/api/videochat/start', { data: { chatId: chatId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })
    // .then(() => Promise.all([
    //     dispatch(chatGetConvs()) 
    //     ])
    // )

    function start() { return { type: VIDEOCHAT_START_REQUEST } }
    function failed(errors) { return { type: VIDEOCHAT_START_FAILED, errors } }
    function success() { return { type: VIDEOCHAT_START_SUCCESS } }
}



//-----------------------------------------------
// Entry point for videoChatStop...
//-----------------------------------------------
export const videoChatStop = (chatId) => dispatch => {

    gaFireEvent('share', {
        content_type: 'video',
        method: 'videochat stopped',
        item_id: chatId,
    })

    dispatch(start())

    axios.get('/api/videochat/stop', { data: { chatId: chatId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })
    // .then(() => Promise.all([
    //     dispatch(chatGetConvs()) 
    //     ])
    // )

    function start() { return { type: VIDEOCHAT_STOP_REQUEST } }
    function failed(errors) { return { type: VIDEOCHAT_STOP_FAILED, errors } }
    function success() { return { type: VIDEOCHAT_STOP_SUCCESS } }
}

