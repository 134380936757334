// Material UI & Virtualized
// https://github.com/mui-org/material-ui/issues/7450

// react-visualized
// 
// https://github.com/eh3rrera/react-virtualized-examples
// https://github.com/mavropalias/overclips/blob/master/src/components/Clips.js
// https://css-tricks.com/rendering-lists-using-react-virtualized/
// 
// https://stackoverflow.com/questions/41859045/react-virtualized-data-table-example
// https://github.com/bvaughn/react-virtualized/issues/873
// https://github.com/bvaughn/react-virtualized/blob/master/source/Table/Table.example.js
// https://blog.logrocket.com/rendering-large-lists-with-react-virtualized-82741907a6b3
// http://bvaughn.github.io/react-virtualized/#/components/Table
// https://blog.theodo.fr/2018/09/use-react-virtualized/
// https://css-tricks.com/rendering-lists-using-react-virtualized/


// https://github.com/adazzle/react-data-grid
// 
// react-table
// https://react-table.js.org/#/story/simple-table
// https://github.com/tannerlinsley/react-table
// https://codesandbox.io/s/m5lxzzpz69
// 
// *Search
// https://github.com/bvaughn/redux-search


import React, { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom'

import isEmpty from '../validation/is-empty'

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'
import { Button, Typography } from "@mui/material"
import { LayoutWithoutHeader } from "../styles/ftfStyles"

import SearchIcon from '@mui/icons-material/Search'

import { FormattedMessage } from 'react-intl'  // , FormattedHTMLMessage

// import { isDesktop, isMobile } from "react-device-detect"
// import { FormattedMessage } from 'react-intl'

import ReactVirtualizedTable from './ReactVirtualizedTable'
import { SortDirection } from 'react-virtualized'

import { getFriends } from '../actions/member.actions'

export default function FriendsPage(props) {
    let navigate = useNavigate()
    // const classes = useStyles(props)

    const friends = useSelector(state => state.member.friends)
    const isLoading = useSelector(state => state.member.isLoading)
    const dispatch = useDispatch()

    //-------------------------------------------
    // Reload
    //-------------------------------------------
    useEffect(() => {
        dispatch(getFriends())
    }, [dispatch]);


    //-------------------------------------------
    // onSearchForFriends
    //-------------------------------------------
    function onSearchForFriends() {
        navigate('/search?friends')  // Navigate to the "Search" page
    }


    return (
        <Div100vh>
            <LayoutWithoutHeader>
                {/* No Groups - Button to Search */}
                {isEmpty(friends) && !isLoading &&
                    <Button variant="contained" color="primary" style={{ margin: 6 }} onClick={onSearchForFriends}>
                        <SearchIcon /><Typography variant="subtitle2">{<FormattedMessage id="system.hint.friends" defaultMessage="Search for Friends..." />}</Typography>
                    </Button>
                }

                {!isEmpty(friends) &&
                    <ReactVirtualizedTable title='Friends' defaultSortBy="firstname" defaultSortDir={SortDirection.ASC} members={friends} />
                }
            </LayoutWithoutHeader>
        </Div100vh>
    )
}

