import React from "react"
import { useSelector } from "react-redux"
import { makeStyles } from '@mui/styles';

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

import { GeneralText, MemberProfileTabbedLayout } from "../styles/ftfStyles"

import { FormattedMessage, useIntl } from 'react-intl'

import { Chip, Grid, TextField, Typography } from '@mui/material'

import { BodyType, Children, Drinking, Education, Ethnicity, EyeColor, Exercise, Faith, HairColor, Height, MaritalStatus, Politics, Pets, Relationship, SecondLanguage, Smoking, Vehicles } from '../constants/ftfDefinitions';

import { getChipLabel, getSelection } from '../helpers/utilities'

const useStyles = makeStyles(theme => ({
    chip: {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        height: 25,
        margin: 2,
        size: "small",
    },

    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },

    description: {
        // color: theme.palette.primary.dark,
        lineHeight: 'unset',
        marginBottom: 24,
        whiteSpace: 'pre-wrap',
    },

    fieldShort: {
        marginBottom: 9,
        minWidth: 180,
        width: 'auto',
        display: 'flex',
    },
}))


export default function MemberProfileBasics(props) {
    const classes = useStyles(props)
    const intl = useIntl()

    const member = useSelector(state => state.member.member)
    // const isLoading = useSelector(state => state.member.isLoading)


    return (
        <Div100vh>
            <MemberProfileTabbedLayout>
                <Grid container direction="column" alignItems="flex-start" style={{ padding: 8 }}>
                    
                    <Typography variant='body1' className={classes.description}>{member.description}</Typography>

                    <Grid container spacing={1} direction="row" alignItems="flex-start">
                        <Grid item>
                            <TextField name='bodyID' label={<FormattedMessage id="profile.bodyType" defaultMessage="Body Type" />}
                                className={classes.fieldShort}

                                value={intl.formatMessage(getSelection(BodyType, member.bodyID))}

                                inputProps={{ readOnly: true }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField name='ethnicID' label={<FormattedMessage id="profile.ethnicity" defaultMessage="Ethnicity" />} // "Ethnicity"
                                className={classes.fieldShort}

                                value={intl.formatMessage(getSelection(Ethnicity, member.ethnicID))}

                                inputProps={{ readOnly: true }}
                            />
                        </Grid>


                        <Grid item>
                            <TextField name='educationID' label={<FormattedMessage id="profile.education" defaultMessage="Education" />} // "Education"
                                className={classes.fieldShort}

                                value={intl.formatMessage(getSelection(Education, member.educationID))}

                                inputProps={{ readOnly: true }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField name='eyeID' label={<FormattedMessage id="profile.eyeColor" defaultMessage="Eye color" />} // "Eye color"
                                className={classes.fieldShort}

                                value={intl.formatMessage(getSelection(EyeColor, member.eyeID))}

                                inputProps={{ readOnly: true }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField name='exerciseID' label={<FormattedMessage id="profile.exercise" defaultMessage="Exercise" />} // "Exercise"
                                className={classes.fieldShort}

                                value={intl.formatMessage(getSelection(Exercise, member.exerciseID))}

                                inputProps={{ readOnly: true }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField name='drinkID' label={<FormattedMessage id="profile.drinking" defaultMessage="How often do you drink?" />} // "How often do you drink?"
                                className={classes.fieldShort}

                                value={intl.formatMessage(getSelection(Drinking, member.drinkID))}

                                inputProps={{ readOnly: true }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField name='hairID' label={<FormattedMessage id="profile.hairColor" defaultMessage="Hair Color" />} // "Hair Color"
                                className={classes.fieldShort}

                                value={intl.formatMessage(getSelection(HairColor, member.hairID))}

                                inputProps={{ readOnly: true }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField name='heightInches' label={<FormattedMessage id="system.height" defaultMessage="Height" />} // "Height"
                                className={classes.fieldShort}

                                value={intl.formatMessage(getSelection(Height, member.heightInches))}

                                inputProps={{ readOnly: true }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField name='maritalID' label={<FormattedMessage id="profile.maritalStatus" defaultMessage="Marital Status" />} // "Marital Status"
                                className={classes.fieldShort}

                                value={intl.formatMessage(getSelection(MaritalStatus, member.maritalID))}

                                inputProps={{ readOnly: true }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField name='faithID' label={<FormattedMessage id="profile.faith" defaultMessage="Faith" />} // "Faith"
                                className={classes.fieldShort}

                                value={intl.formatMessage(getSelection(Faith, member.faithID))}

                                inputProps={{ readOnly: true }}
                            />
                        </Grid>


                        <Grid item>
                            <TextField name='politicsID' label={<FormattedMessage id="profile.politics" defaultMessage="Politics" />} // "Politics"
                                className={classes.fieldShort}

                                value={intl.formatMessage(getSelection(Politics, member.politicsID))}

                                inputProps={{ readOnly: true }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField name="profession" label={<FormattedMessage id="profile.profession" defaultMessage="Profession" />} // "Profession"
                                className={classes.fieldShort}

                                value={member.profession || ''}

                                InputLabelProps={{ shrink: true }}
                                inputProps={{ readOnly: true }}
                            />
                        </Grid>

                        <Grid item>
                            <TextField name='secondLangID' label={<FormattedMessage id="profile.secondLang" defaultMessage="Second Language" />} // "Second Language"
                                className={classes.fieldShort}

                                value={intl.formatMessage({
                                    id: SecondLanguage.find(({ value }) => value === member.secondLangID).id,
                                    defaultMessage: SecondLanguage.find(({ value }) => value === member.secondLangID).label
                                })}

                                inputProps={{ readOnly: true }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container direction='column' spacing={2} style={{ marginTop: 4 }}>
                        <Grid item>
                            <Typography style={GeneralText} variant="subtitle2">{<FormattedMessage id="profile.relationship" defaultMessage="Relationship" />}</Typography>

                            <div className={classes.chips}>
                                {member.relationship.map(item => (
                                    <Chip label="white" key={item} label={intl.formatMessage(getChipLabel(Relationship, item))} className={classes.chip} />
                                ))}
                            </div>
                        </Grid>

                        <Grid item>
                            <Typography style={GeneralText} variant="subtitle2">{<FormattedMessage id="profile.children" defaultMessage="Children" />}</Typography>

                            <div className={classes.chips}>
                                {member.children.map(item => (
                                    <Chip label="white" key={item} label={intl.formatMessage(getChipLabel(Children, item))} className={classes.chip} />
                                ))}
                            </div>
                        </Grid>


                        <Grid item>
                            <Typography style={GeneralText} variant="subtitle2">{<FormattedMessage id="profile.pets" defaultMessage="Pets" />}</Typography>

                            <div className={classes.chips}>
                                {member.pets.map(item => (
                                    <Chip label="white" key={item} label={intl.formatMessage(getChipLabel(Pets, item))} className={classes.chip} />
                                ))}
                            </div>
                        </Grid>


                        <Grid item>
                            <Typography style={GeneralText} variant="subtitle2">{<FormattedMessage id="profile.smoking" defaultMessage="Smoking" />}</Typography>

                            <div className={classes.chips}>
                                {member.smoking.map(item => (
                                    <Chip label="white" key={item} label={intl.formatMessage(getChipLabel(Smoking, item))} className={classes.chip} />
                                ))}
                            </div>
                        </Grid>

                        <Grid item>
                            <Typography style={GeneralText} variant="subtitle2">{<FormattedMessage id="profile.vehicles" defaultMessage="Vehicles" />}</Typography>

                            <div className={classes.chips}>
                                {member.vehicles.map(item => (
                                    <Chip label="white" key={item} label={intl.formatMessage(getChipLabel(Vehicles, item))} className={classes.chip} />
                                ))}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
            </MemberProfileTabbedLayout>
        </Div100vh>
    )

}

