// https://socket.io/docs/
// https://socket.io/docs/v3/client-installation/
// TODO: *Note: You may want to exclude debug from your browser bundle. With webpack, you can use webpack-remove-debug.

// Error: DOMException: Failed to execute 'addIceCandidate'
// https://stackoverflow.com/questions/58908081/webrtc-getting-failed-to-execute-addicecandidate-on-rtcpeerconnection-error-on

// https://acidtango.com/thelemoncrunch/how-to-implement-a-video-conference-with-webrtc-and-node/

import store from '../store'
import React from 'react'
import Avatar from './Avatar'

// import moment from 'moment'

import { toast } from "react-toastify"
import io from 'socket.io-client'
import isEmpty from '../validation/is-empty'

import { FormattedMessage } from 'react-intl'  // , FormattedHTMLMessage

import Message from '@mui/icons-material/Message'

import { getProfile } from '../actions/profile.actions'
import { chatGetConvs } from '../actions/chat.actions'
import { socketDevices, socketStopDevices, socketMeetingCreated, socketMeetingRequest, socketMeetingMsg } from '../actions/socket.actions'
import { showVideoChatDialog } from '../actions/app.actions'

// var SocketIOFileUpload = require('socketio-file-upload')

// Free public STUN servers provided by Google.
const iceServers = {
    iceServers: [
        { urls: 'stun:stun.l.google.com:19302' },
        { urls: 'stun:stun1.l.google.com:19302' },
        { urls: 'stun:stun2.l.google.com:19302' },
        { urls: 'stun:stun3.l.google.com:19302' },
        { urls: 'stun:stun4.l.google.com:19302' },
    ],
}

const socket = io()

// https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API/Constraints
const mediaConstraints = {
    'audio': true,
    'video': {
        facingMode: 'user',
        minAspectRatio: 1.333,
        aspectRatio: { ideal: 1.7777777778 },
        minFrameRate: 30,

        width: { min: 640, ideal: 1280, max: 1920 },
        height: { min: 400, ideal: 720, max: 1080 },

        // width: { min: 640, ideal: 1280, max: 1920 },
        // height: { min: 400, ideal: 720, max: 1080 }

        // 4k 
        // width: { ideal: 4096 },
        // height: { ideal: 2160 },

        // width: false ? 720 : 1280,
        // height: false ? 1280 : 720,
    },
}

// let errors = [] 
var localStream
var remoteStream
var isRoomCreator = false
var rtcPeerConnection // Connection between the local device and the remote peer.
var roomId // Saving a local copy of the roomId/accessId

// Devices
var audioStopDevices = ''
var audioInputSelect = ''
var audioOutputSelect = ''
var videoSelect = ''


// Video Chat ==================================================================

export function joinRoom(socket, userId, guestId, accessId) {
    roomId = accessId
    socket.emit('join', userId, guestId, accessId)
}

export function rejectMeeting(socket, userId, guestId, accessId) {
    roomId = accessId
    socket.emit('reject', userId, guestId, accessId)
}

export function muteMic(socket, userId, guestId, accessId) {

}

// TODO: Stopping the audio track on Saafri iOS is slow (15 seconds) - DSW

export async function leaveRoom(socket, accessId, userId, guestId) {

    // Testing
    // console.log(`${moment().format('LTSSS')} >>>---> socket.leaveRoom: ${accessId}`)
    // localStream.getTracks().forEach(function(track) { track.stop() })

    if (socket != null && localStream != null) {
        // try {
        //     localStream.removeAudioTrack()

        //     audioStopDevices = ''

        //     if (typeof localStream.getTracks === 'undefined') {
        //         // Support legacy browsers, like phantomJs we use to run tests.
        //         audioStopDevices = "track type: undefined" + '\r\n'
        //         await localStream.stop()
        //     } else {

        //         // Video Fast / Audio Slow on Safari 
        //         await localStream.getTracks().forEach(async function (track) {
        //             audioStopDevices = audioStopDevices + track.label + '\r\n'
        //             await track.stop()
        //         })
        //     }

        //     store.dispatch(socketStopDevices(audioStopDevices))
        // }
        // catch (ex) {
        //     console.log("socket.leaveRoom getTracks Exception: " + ex)
        // }

        socket.emit('leave', accessId)

        try {
            // Fast on Safari
            await localStream.getVideoTracks().forEach(function (track) {
                audioStopDevices = audioStopDevices + track.label + '\r\n'
                track.stop()
            })
        }
        catch (ex) {
            console.log("socket.leaveRoom getVideoTracks Exception: " + ex)
        }

        try {
            // https://webrtchacks.com/guide-to-safari-webrtc/
            // https://www.debugcn.com/en/article/74604143.html

            // await localStream.getAudioTracks().forEach(function (track) { track.stop() })
            var audioTrack = await localStream.getAudioTracks()[0]
            if (audioTrack) {
                audioStopDevices = audioStopDevices + audioTrack.label + '\r\n'
                await audioTrack.stop()
            }
        }
        catch (ex) {
            console.log("socket.leaveRoom getAudioTracks Exception: " + ex)
        }

        store.dispatch(socketStopDevices(audioStopDevices))
    }

    localStream = null
    remoteStream = null
    isRoomCreator = false
    rtcPeerConnection = null
    roomId = null
    remoteStream = null
}


// TODO: Future?
// async function enumerateSources() {
//     if (navigator && navigator.mediaDevices && typeof navigator.mediaDevices.enumerateDevices === 'function') {
//         try {
//             /* open a generic stream to get permission to see devices
//              * Mobile Safari insists */
//             const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true })
//             let devices = await navigator.mediaDevices.enumerateDevices()

//             const cameras = devices.filter(device => {
//                 return device.kind === 'videoinput'
//             })

//             if (cameras.length >= 1)
//                 console.log('cameras avail')

//             const mics = devices.filter(device => {
//                 return device.kind === 'audioinput'
//             })

//             if (mics.length >= 1)
//                 console.log('mics avail')

//             /* release stream */
//             const tracks = stream.getTracks()
//             if (tracks) {
//                 for (let t = 0; t < tracks.length; t++) tracks[t].stop()
//             }

//             return ({ cameras, mics })

//         } catch (error) {
//             /* user refused permission, or media busy, or some other problem */
//             console.error(error.name, error.message)
//             // return { cameras: [], mics: [] })
//         }
//     }
//     else throw ('media device stuff not available in this browser')
// }

async function gotDevices(deviceInfos) {
    audioStopDevices = ''
    audioInputSelect = ''
    audioOutputSelect = ''
    videoSelect = ''

    for (var i = 0; i !== deviceInfos.length; ++i) {
        var deviceInfo = deviceInfos[i]

        // var option = document.createElement('option')
        // option.value = deviceInfo.deviceId

        let option = ''

        if (deviceInfo.kind === 'audioinput') {
            option = deviceInfo.label || 'Microphone ' + (audioInputSelect.length + 1)
            audioInputSelect = audioInputSelect + option + '\r\n'
        } else if (deviceInfo.kind === 'audiooutput') {
            option = deviceInfo.label || 'Speaker ' + (audioOutputSelect.length + 1)
            audioOutputSelect = audioOutputSelect + option + '\r\n'
        } else if (deviceInfo.kind === 'videoinput') {
            option = deviceInfo.label || 'Camera ' + (videoSelect.length + 1)
            videoSelect = videoSelect + option + '\r\n'
        }
    }

    store.dispatch(socketDevices(audioInputSelect, audioOutputSelect, videoSelect))
}

async function setLocalStream(mediaConstraints) {
    // console.log("setLocalStream")

    if (localStream != null) {
        localStream.getTracks().forEach(function (track) { track.stop() })
    }

    localStream = null

    await navigator.mediaDevices.enumerateDevices().then(gotDevices)

    try {
        await navigator.mediaDevices.getUserMedia(mediaConstraints)
            .then((stream) => {
                console.log("setLocalStream: getUserMedia stream")
                localStream = stream
            })
            .catch((err) => {
                console.log("Video Camera: " + err)
                store.dispatch(socketMeetingMsg('videoChat.videoAcceesError'))
            })

    } catch (err) {
        console.error('Could not get camera: ' + err)
        store.dispatch(socketMeetingMsg('videoChat.videoAcceesError'))
    }
}

function addLocalTracks(rtcPeerConnection) {
    console.error('addLocalTracks - rtcPeerConnection.type: ' + rtcPeerConnection.type)

    if (localStream != null) {
        localStream.getTracks().forEach((track) => {
            rtcPeerConnection.addTrack(track, localStream)
        })
    }
}

async function createOffer(rtcPeerConnection) {
    console.error('createOffer - rtcPeerConnection.type: ' + rtcPeerConnection.type)

    let sessionDescription
    try {
        sessionDescription = await rtcPeerConnection.createOffer()
        rtcPeerConnection.setLocalDescription(sessionDescription)
    } catch (error) {
        console.error(error)
    }

    socket.emit('webrtc_offer', {
        type: 'webrtc_offer',
        sdp: sessionDescription,
        roomId,
    })
}

async function createAnswer(rtcPeerConnection) {
    console.error('createAnswer - rtcPeerConnection.type: ' + rtcPeerConnection.type)

    let sessionDescription
    try {
        sessionDescription = await rtcPeerConnection.createAnswer()
        rtcPeerConnection.setLocalDescription(sessionDescription)
    } catch (error) {
        console.error(error)
    }

    socket.emit('webrtc_answer', {
        type: 'webrtc_answer',
        sdp: sessionDescription,
        roomId,
    })
}


function setRemoteStream(event) {
    console.error('socket.setRemoteStream: ' + rtcPeerConnection.type)

    remoteStream = event.streams[0]
    // remoteStream = event.stream

    store.dispatch(socketMeetingCreated(localStream, remoteStream))
}


function sendIceCandidate(event) {
    if (event.candidate) {
        socket.emit('webrtc_ice_candidate', {
            roomId,
            label: event.candidate.sdpMLineIndex,
            candidate: event.candidate.candidate,
        })
    }
}




const TextFormat = (msgId) => (
    <div style={{ display: 'flex' }}>
        <Message style={{ alignSelf: 'center', margin: 4 }} />
        <div style={{ alignSelf: 'center', margin: 4 }}>
            {<FormattedMessage id={msgId.toString()} defaultMessage="" />}
        </div>
    </div>
)

const MemberFormat = (member, msgId) => (
    <div style={{ display: 'flex' }}>
        <Avatar small style={{ alignSelf: 'center', margin: 4 }} gender={member.gender} avatarUrl={member.avatarUrl} delay={250} alt={member.firstname}>...</Avatar>
        <div style={{ alignSelf: 'center', margin: 4 }}>
            {/* <FormattedMessage id="system.blockName" defaultMessage="Block {firstname}?" values={{ firstname: props.firstname }} /> */}

            <FormattedMessage id={msgId.toString()} defaultMessage="{firstname} sent you a message" values={{ firstname: member.firstname }} />
        </div>
        {/* <div style={{ alignSelf: 'center', margin: 4 }}>{member.city}, {member.state}</div> */}
    </div>
)


const configureSocket = (socket, memberId) => {
    // const joinRoom = (room) => {
    //     if (room === '') {
    //         alert('Please type a room ID')
    //     } else {
    //         roomId = room
    //         socket.emit('join', room)
    //         showVideoConference()
    //     }
    // }

    // No need to do this - DSW
    // if (!isEmpty(socket) && !isEmpty(id)) {  // Reconnnect
    //     socket.open()
    // }

    // https://www.appcoda.com/socket-io-chat-app/

    // Login  
    if (!isEmpty(memberId)) {
        socket = io({
            'forceNew': true,
            query: 'uid=' + memberId,
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 10000,
            reconnectionAttempts: 10000
        })

        // console.log(`>>>---> configureSocket socket`)

        // TODO: Note: For Development only! - localhost - DSW
        // socket = io('http://localhost:7600', )
    }
    else if (!isEmpty(socket)) // Logout
    {
        socket.close()
        socket = {}
    }


    //-------------------------------------------
    // Incoming
    //-------------------------------------------
    if (!isEmpty(socket)) {

        // var uploader = new SocketIOFileUpload(socket)
        // uploader.chunkSize = 1024 * 1000
        // uploader.listenOnInput(document.getElementById("file_input"))
        // uploader.listenOnDrop(document.getElementById("file_drop"))
        // uploader.listenOnSubmit(document.getElementById("my_button"), document.getElementById("file_input"))

        //---------------------------------------
        // Testing: make sure our socket is connected to the port
        // socket.on('connect', () => {
            // console.log(`>>>---> connect to socket.id: ${socket.id}`)
        // })

        socket.on('connect_timeout', (timeout) => {
            console.log('>>>---> Socket connect timeout')
        })

        socket.on('reconnect', (attemptNumber) => {
            console.log('>>>---> Socket reconnect: ' + attemptNumber)
        })

        socket.on('reconnect_failed', () => {
            console.log('>>>---> Socket reconnect failed, Logout')
        })

        //-----------------------------------------------------

        // FtfWelcome (works)
        // socket.on('FtfWelcome', msg => {
        //     toast.success(TextFormat("system.msg.welcome"), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
        // })

        // FtfBumpedReputation
        socket.on('FtfBumpedReputation', member => {
            toast.info(MemberFormat(member, "system.msg.bumped"), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
            store.dispatch(getProfile())
        })

        // FtfReportLogged
        socket.on('FtfReportLogged', () => {
            toast.info(TextFormat("system.msg.reportLogged"), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
        })


        // ftfOnlyOnceAnHour
        socket.on('ftfOnlyOnceAnHour', () => {
            toast.info(TextFormat("system.msg.onlyOnceAnHour"), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
        })


        // FtfPhotoLiked
        socket.on('FtfPhotoLiked', member => {
            toast.info(MemberFormat(member, "system.msg.likedPhoto"), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
            store.dispatch(getProfile())
        })


        // FtfPostLiked
        socket.on('FtfPostLiked', member => {
            toast.info(MemberFormat(member, "system.msg.likedPost"), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
            store.dispatch(getProfile())
        })


        // FtfChatMessage (works)
        socket.on('FtfChatMessage', member => {
            toast.info(MemberFormat(member, "system.msg.sentMessage"), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
            store.dispatch(chatGetConvs())  // Reload conversations
            // console.log('Socket - FtfChatMessage')
        })

        // FtfFavorite (works)
        socket.on('FtfFavorite', member => {
            toast.info(MemberFormat(member, "system.msg.favoredYou"), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
            store.dispatch(getProfile())
        })

        // FtfProfileViewed (works)
        socket.on('FtfProfileViewed', member => {
            toast.info(MemberFormat(member, "system.msg.viewedProfile"), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
            store.dispatch(getProfile())
        })

        // FtfNews (works)
        socket.on('FtfNews', msg => {
            toast.info(TextFormat(msg), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
        })

        // FtfWarning (works)
        socket.on('FtfWarning', msg => {
            toast.warn(TextFormat(msg), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
        })

        // Users Online (action/reducer) (works)
        socket.on('ONLINE_COUNT', msg => {
            store.dispatch({ type: 'ONLINE_COUNT', msg })
        })


        //=======================================
        // WebRTC
        //=======================================
        // WebRTC and Audio
        // https://www.html5rocks.com/en/tutorials/webrtc/basics/
        // https://webaudiodemos.appspot.com/
        // 
        // 
        // https://stackoverflow.com/questions/57256828/how-to-fix-invalidstateerror-cannot-add-ice-candidate-when-there-is-no-remote-s
        // It's like this: 
        //      1. getMedia -> emit enter room
        //      2. on enter room -> enable call button
        //      3. Click call button -> emit send offer
        //      4. on offer, enable answer button
        //      5. click button, emit answer
        //      6. on answer setRemoteDescription

        //=======================================
        // SOCKET EVENT CALLBACKS 
        //=======================================

        // room_created
        socket.on('room_created', async () => {
            // console.log('Socket event: room_created')
            // errorsconcat(" Socket event: room_created \n")

            await setLocalStream(mediaConstraints)

            isRoomCreator = true

            // Connect UI
            if (localStream)
                store.dispatch(socketMeetingCreated(localStream, null))
        })


        // Video Chat Request (Guest side)
        socket.on('FtfVDateRequest', (msgId, user, accessId) => {
            // toast.info(NewsFormat(msgId), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
            store.dispatch(showVideoChatDialog(true, false, user, accessId))
            store.dispatch(socketMeetingRequest(user, accessId))
        })

        // Video Chat Request Denied (for any reason)
        socket.on('FtfVDateDenied', (msgId, guestName) => {
            // toast.warn(NewsFormat(msg), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
            store.dispatch(socketMeetingMsg(msgId, guestName))
        })

        // Video Chat Request Denied (for any reason)
        socket.on('FtfVDateLeft', (msgId, guestName) => {
            // toast.warn(NewsFormat(msg), { position: toast.POSITION.BOTTOM_RIGHT, autoClose: 10000 })
            store.dispatch(socketMeetingMsg(msgId, guestName))
        })


        // room_joined (guest)
        socket.on('room_joined', async () => {
            // console.log('Socket event: room_joined')

            await setLocalStream(mediaConstraints)

            isRoomCreator = false

            // Connect UI
            if (localStream)
                store.dispatch(socketMeetingCreated(localStream, remoteStream))

            if (localStream !== null)
                socket.emit('start_call', roomId)
        })

        // full_room
        // socket.on('full_room', () => {
        //     // console.log('Socket event: full_room')
        //     alert('The room is full, please try another one')
        // })

        // room_left
        // socket.on('room_left', (arg) => {
        //     console.log(`Socket event: room_left: ${arg}`)
        //     // alert('Left the room')
        // })

        // start_call
        socket.on('start_call', async () => {
            // console.log('Socket event: start_call')
            // console.error('start_call - rtcPeerConnection.type: ' + rtcPeerConnection.type)

            if (isRoomCreator) {
                rtcPeerConnection = new RTCPeerConnection(iceServers)
                addLocalTracks(rtcPeerConnection)
                rtcPeerConnection.ontrack = setRemoteStream
                rtcPeerConnection.onicecandidate = sendIceCandidate
                await createOffer(rtcPeerConnection)
            }
        })

        // webrtc_offer
        // https://stackoverflow.com/questions/13396071/errors-when-ice-candidates-are-received-before-answer-is-sent
        socket.on('webrtc_offer', async (event) => {
            // console.log('Socket event: webrtc_offer')
            // console.error('webrtc_offer - rtcPeerConnection.type: ' + rtcPeerConnection.type)

            if (!isRoomCreator) {
                rtcPeerConnection = new RTCPeerConnection(iceServers)
                addLocalTracks(rtcPeerConnection)
                rtcPeerConnection.ontrack = setRemoteStream
                rtcPeerConnection.onicecandidate = sendIceCandidate
                rtcPeerConnection.setRemoteDescription(new RTCSessionDescription(event))
                await createAnswer(rtcPeerConnection)
            }
        })

        // webrtc_answer
        socket.on('webrtc_answer', (event) => {
            // console.log('Socket event: webrtc_answer')

            rtcPeerConnection.setRemoteDescription(new RTCSessionDescription(event))
        })

        // webrtc_ice_candidate
        socket.on('webrtc_ice_candidate', (event) => {
            // console.log('Socket event: webrtc_ice_candidate')

            // ICE candidate configuration.
            var candidate = new RTCIceCandidate({
                sdpMLineIndex: event.label,
                candidate: event.candidate,
            })

            // https://stackoverflow.com/questions/13396071/errors-when-ice-candidates-are-received-before-answer-is-sent
            // Set remoteDescription before you even have access to camera. 
            // This way the browser will handle ICE candidates and establish connection. Then when the video stream is available the browser (or your code - I don't remember now) will send new local description to the peer and the video will go through

            rtcPeerConnection.addIceCandidate(candidate)
        })

    }

    return socket
}


//-----------------------------------------------
// The following are functions are called by the client
// to emit actions to everyone connected to our web socket
//-----------------------------------------------
// export const getUsersOnline = () => socket.emit('GET_USERS_ONLINE')


export default configureSocket
