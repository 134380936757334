// https://nominatim.openstreetmap.org/search.php?q=44%2C+-88&polygon_geojson=1&viewbox=

// https://leafletjs.com/
// https://leafletjs.com/examples/quick-start/

// https://codesandbox.io/s/y0znnj58l1              // Component
// https://codesandbox.io/s/d9pkyg3mq
// https://codesandbox.io/s/pm8pro1olm?from-embed   // Component
// https://codesandbox.io/s/0p0xxyv8pw?from-embed   // Functional
// https://cherniavskii.com/using-leaflet-in-react-apps-with-react-hooks/
// https://medium.com/@brendan_ward/migrating-from-create-react-app-and-leaflet-to-gatsbyjs-and-mapbox-gl-98b49c2e75c4

// Ref
// https://www.azavea.com/blog/2016/12/05/getting-started-with-react-and-leaflet/



import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles';

import 'leaflet/dist/leaflet.css'
import MapNew from './MapNew'

import { FormattedMessage } from 'react-intl'

import { useDebounce } from 'use-debounce'

import isEmpty from '../validation/is-empty'

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'
import { MyTabbedLayout } from "../styles/ftfStyles"

import { Button, InputAdornment, TextField, Typography } from '@mui/material'

import { roundN } from '../helpers/utilities'
import { profileChangesUpdate } from '../actions/profile.actions'

// For Geocoding...
// import BeatLoader from 'react-spinners/BeatLoader'
// import { css } from 'styled-components'
// const cssBeatLoader = css`
//     display: block
//     margin: 0 auto
// `

const useStyles = makeStyles(theme => ({

    divPlugin: {
        id: 'myPlugin',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },

    locationRange: {
        background: 'white',

        display: 'flex',
        flexDirection: 'row',

        alignItems: 'baseline',
        justifyContent: 'center',

        padding: 4,
        marginBottom: 8,
        position: 'relative',

        zIndex: 0,
    },

    map: {
        flex: '1 0 auto',
    },

    range: {
        marginLeft: 12,
    },

    youAreLocated: {
        display: 'flex',
        flexDirection: 'row',

        alignItems: 'baseline',
        justifyContent: 'center',

        padding: 4,

        position: 'relative',
        zIndex: 1,
    },

    buttonUseLocation: {
        padding: 8,
        // position: 'absolute',
        // top: 16,
        // right: 8,

        marginRight: 24,

        position: 'relative',
    },
}))


export default function ProfileLocation(props) {
    const classes = useStyles(props)

    // const errors = useSelector(state => state.profile.errors)
    const profile = useSelector(state => state.profile.profile)

    // ...for White Box Testing
    // markerPosition: {
    //     lat: 42.1707776,
    //     lng: -88.08038399999998,
    // },


    // OLD - Doesn't happen anymore - DSW
    // TODO: Server Error 500 is making this blow!! - DSW
    // TODO: Auto logout is making this blow!! - DSW

    const [markerPosition, setMarkerPosition] = useState({
        lat: profile.location ? profile.location.coordinates[1] : 42.1707776,
        lng: profile.location ? profile.location.coordinates[0] : -88.08038399999998,
    })

    const [isCenteringOnLocation, setIsCenteringOnLocation] = useState(false)
    // const [isGeocoding, setIsGeocoding] = useState(false)
    const [mounted, setIsMounted] = useState(false)

    const [circleMeters, setCircleMeters] = useState(profile.targetRadius)

    const [radius, setRadius] = useState(0)
    const [debouncedRadius] = useDebounce(radius, 333)

    const zoom = 9

    const dispatch = useDispatch()


    //-------------------------------------------
    // Radius Debounced
    // Change the search radius
    //-------------------------------------------
    useEffect(() => {
        if (mounted) {
            if (debouncedRadius) {
                var meters = profile.isMiles ? radius / 0.00062137 : radius * 1000

                // Sanity checks...
                if (meters === profile.targetRadius)
                    return

                // Enforce a soft maximum
                if (radius > 12000) {
                    setRadius(12000)
                    return
                }


                // F's up the UI...
                // 
                // Enforce a soft minimum (10 miles)
                // if (profile.isMiles && radius < 10) {
                //     setRadius(10)
                //     return
                // }

                // Enforce a soft minimum (15 kms)
                // if (!profile.isMiles && radius < 15) {
                //     setRadius(15)
                //     return
                // }

                setCircleMeters(meters)
                dispatch(profileChangesUpdate({ targetRadius: meters }))  // Buffer profile changes...
            }
        }
    }, [dispatch, mounted, debouncedRadius, radius, profile.isMiles, profile.targetRadius])


    //-------------------------------------------
    // Setup Defaults
    // Sync search with profile...
    // radius
    //-------------------------------------------
    useEffect(() => {
        // Do only once, Need a profile
        if (mounted || isEmpty(profile))
            return

        // Marker
        setMarkerPosition({
            lat: profile.location.coordinates[1],
            lng: profile.location.coordinates[0]
        })

        // Radius defaults to users search radius,
        // but not less than 10 to 15 
        var standardDist = profile.isMiles ? roundN(profile.targetRadius * 0.00062137, 0) : roundN(profile.targetRadius * 0.001, 0)
        if (standardDist < 10)
            standardDist = profile.isMiles ? 10 : 15

        // Sync Debounced Values
        setRadius(standardDist)
        setIsMounted(true)
    }, [mounted, profile])


    //-------------------------------------------
    // 
    //-------------------------------------------
    // useEffect(() => {
    //     const handleResize = () => {
    //         if (refMap && refMap.current) {
    //             const { height, width } = refMap.current.getBoundingClientRect();
    //             if (height > 0)
    //                 setImageHeight(Math.round(height))

    //             // setImageWidth(Math.round(width))
    //             // refMap.current.style.height = 200
    //         }
    //     }

    //     // On mount...
    //     // if (refMap && refMap.current) 
    //     //     refMap.current.invalidateSize()

    //     window.addEventListener('resize', handleResize)

    //     return () => {
    //         window.removeEventListener('resize', handleResize)
    //     }
    // }, [refMap])


    //-------------------------------------------
    // Get current location
    //-------------------------------------------
    function onCenterOnLocation(e) {
        if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(position => {

                // console.log('handleCenterOnLocation: ', position.coords)

                setMarkerPosition({ lat: position.coords.latitude, lng: position.coords.longitude })
                setIsCenteringOnLocation(true)
            },
                error => {
                    switch (error.code) {
                        case 1:
                            console.log('getCurrentPosition: Permission Denied')
                            break
                        case 2:
                            console.log('getCurrentPosition: Position Unavailable')
                            break
                        case 3:
                            console.log('getCurrentPosition: Timeout')
                            break
                        default:
                            console.log('getCurrentPosition: ' + error.code)
                            break
                    }
                }
            )
        }
    }


    //-------------------------------------------
    // handleGeocoding
    // Spinner Control
    //-------------------------------------------
    // function handleGeocoding(isBusy) {
    //     setIsGeocoding(isBusy)
    // }


    //-------------------------------------------
    // Handle LatLng change from Map component
    // Update profile...
    //-------------------------------------------
    function handleLngLatChange(newMarkerPosition, city, state, country, zipcode) {
        // console.log(">>>---> ProfileLocation > handleLngLatChange: " + JSON.stringify(city))

        // Cascading updates issue involving "profile" & "profileChangesUpdate"
        if (city === profile.city && state === profile.state && country === profile.country)
            return
        
        // if (newMarkerPosition === markerPosition)
        //     return

        setMarkerPosition(newMarkerPosition)
        // setIsGeocoding(false)

        //=========================================
        // Buffer the changes
        //=========================================

        // location
        let coordinates = { coordinates: [newMarkerPosition.lng, newMarkerPosition.lat] }
        dispatch(profileChangesUpdate({ location: coordinates }))

        // city
        dispatch(profileChangesUpdate({ city: city }))

        // state
        dispatch(profileChangesUpdate({ state: state }))

        // country - *Note: Country is a "two char code"
        dispatch(profileChangesUpdate({ country: country }))

        // zipcode 
        dispatch(profileChangesUpdate({ zipcode: zipcode }))

        // console.log("handleLngLatChange: " + newPosition.lat + ", " + newPosition.lng)
    }


    // Label
    // var rangeLabel = intl.formatMessage( {id: 'profile.location.range', values: { scale: 'Jane Doe' }, defaultMessage: 'Range ({scale})'} )
    // rangeLabel += " (" + profile.isMiles ? intl.formatMessage({id: 'profile.location.miles', defaultMessage: 'Miles'}) : intl.formatMessage( {id: 'profile.location.kms', defaultMessage: 'kms'}) + ")"

    return (
        <Div100vh>
            <MyTabbedLayout>
                <div className={classes.divPlugin}>
                    <div className={classes.youAreLocated}>

                        {profile.city &&
                            < Typography variant='subtitle1' className={classes.headerDetails}>
                                <FormattedMessage id="system.youAreNear" defaultMessage="You are near:" />
                            </Typography>
                        }

                        {profile.city && <Typography variant="subtitle1" noWrap style={{ marginLeft: 8, lineHeight: 1.25 }}>
                            {profile.city},  {profile.state}, {profile.country}
                        </Typography>}

                    </div>

                    {/* Radius */}
                    <div className={classes.locationRange}>

                        {/* Use Location */}
                        <Button variant="contained" color="primary" className={classes.buttonUseLocation} onClick={e => onCenterOnLocation(e)}>
                            <FormattedMessage id="profile.location.useLocation" defaultMessage="Use Location" />
                        </Button>

                        <TextField id="range-radius" name='targetRadius' type="number"
                            sx={{ width: 122 }}
                            className={classes.range}
                            margin="dense"
                            required
                            // size="small"
                            // variant="outlined"
                            label={<FormattedMessage id="profile.location.range" defaultMessage="Range" />}

                            // value={(markerPosition && radius)}
                            value={radius}

                            onChange={e => setRadius(e.target.value)}

                            InputLabelProps={{
                                shrink: true,
                            }}

                            InputProps={{
                                inputProps: { min: profile.isMiles ? 10 : 15, max: 9999 },
                                endAdornment: <InputAdornment position="end">
                                    {profile.isMiles ?
                                        <FormattedMessage id='profile.location.miles' defaultMessage='Miles' /> :
                                        <FormattedMessage id='profile.location.kms' defaultMessage='kms' />}
                                </InputAdornment>,
                            }}
                        />
                    </div>

                    {profile.location &&
                    // style={{ height: 500 }}
                        <div id="mapLocation" className={classes.map} >

                            {/* scope.map.invalidateSize(); */}
                            {/* defined height */}
                            {/* https://leafletjs.com/examples/quick-start/ */}
                            {/* https://stackoverflow.com/questions/36362849/render-leaflet-map-after-flexbox-calculates-height */}
                            <MapNew id='map' isEditable
                                handleLngLatChange={(latlng, city, state, country, zipcode) => handleLngLatChange(latlng, city, state, country, zipcode)}

                                resetCenteringOnLocation={() => setIsCenteringOnLocation(false)}

                                isCenteringOnLocation={isCenteringOnLocation}
                                markerPosition={markerPosition}
                                circleRadius={circleMeters}
                                zoom={zoom}
                                // handleGeocoding={(isBusy) => handleGeocoding(isBusy)}
                            />
                        </div>
                    }

                    {/* TODO: Future - Mobile Location - DSW */}
                    {/* <FormControlLabel
                        control={
                            <Switch 
                                checked={profile.isVisibleMobileLocation || false}
                                name='isVisible'
                                onChange={handleChange()}
                                value="isVisible"
                                color="primary"
                            />
                        }
                        label={profile.isVisible ? "Your Location is Visible" : "Your Location is Hidden"}
                    /> */}
                </div>

                {/* <div id='spinner' style={{ boxSizing: "content-box", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <BeatLoader css={cssBeatLoader} sizeUnit={"px"} size={32} color={theme.palette.primary.dark} loading={isGeocoding} />
                </div> */}

            </MyTabbedLayout>
        </Div100vh >
    )
}




