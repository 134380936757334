
// ImageList
// https://codesandbox.io/s/5yjpp339w4
// https://codesandbox.io/s/rzmz5jnom

// Animation
// https://medium.com/capriza-engineering/implementing-material-design-animation-on-cards-list-using-react-js-86994b6a4112

// https://medium.com/the-react-native-log/tips-for-react-native-images-or-saying-goodbye-to-trial-and-error-b2baaf0a1a4d
// https://material.io/tools/icons/?style=baseline
// https://materialdesignicons.com/

import React, { useEffect, useState } from "react"
import { makeStyles } from '@mui/styles';
import { useDispatch } from "react-redux"

import isEmpty from '../validation/is-empty'
import { Accordion, AccordionDetails, AccordionSummary, Button, Dialog, Divider, FormControlLabel, Radio, RadioGroup, TextField, Typography } from '@mui/material'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import BlockIcon from '@mui/icons-material/Block'
import CancelIcon from '@mui/icons-material/Cancel'
// import FilterListIcon from '@mui/icons-material/FilterList'
import Slide from '@mui/material/Slide'

import { DialogHeading, SmallButton } from "../styles/ftfStyles"

import { FormattedMessage } from 'react-intl'

import { reportMember, blockMember } from '../actions/member.actions'


const useStyles = makeStyles(theme => ({
    dialogPaper: {
        margin: 8,
        padding: 8,
        overflow: 'hidden',

        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        border: '2px solid',
    },

    iconYellow: {
        color: 'yellow',
    },

    descText: {
        alignSelf: 'left',
        color: theme.palette.primary.dark,
        fontSize: '.75rem',
        // lineHeight: 1.25,
        textAlign: 'left',
    },
}))


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})


export default function BlockMember(props) {
    const classes = useStyles(props)
    const dispatch = useDispatch()

    const [reportType, setReportType] = useState(props.reportType)
    const [reportComment, setReportComment] = useState("")
    const [isExpanded, setIsExpanded] = useState(true)

    //-------------------------------------------
    // Resets Report Type on a Member Id Change...
    //-------------------------------------------
    useEffect(() => {
        setReportType(null)
        setReportComment("")
        setIsExpanded(false)
    }, [props.id])


    //===========================================
    // handleReportType
    //===========================================
    function handleReportType(rt) {
        setReportType(rt)
    }


    //===========================================
    // handleBlocking
    //===========================================
    const handleBlocking = async (e, mode) => {
        e.stopPropagation()

        if (mode === "BlockAndReport") {
            await dispatch(blockMember(props.id))
            await dispatch(reportMember(props.id, reportComment, props.objectId, props.objectType))
        }
        else {
            await dispatch(blockMember(props.id))
        }

        if (!isEmpty(props.handleBlockAndClose))
            props.handleBlockAndClose()
    }


    return (
        <Dialog classes={{ paper: classes.dialogPaper }}
            open={props.showWarning}
            TransitionComponent={Transition}
            onClose={props.handleClose}
        >
            <Typography style={DialogHeading} variant="h5">
                <FormattedMessage id="system.blockName" defaultMessage="Block {firstname}?" values={{ firstname: props.firstname }} />
            </Typography>

            {/* <Divider /> */}

            <Typography className={classes.descText}>
                <FormattedMessage id="system.blockText1" defaultMessage="• You will no not be Friends" />
                <br />
                <FormattedMessage id="system.blockText2" defaultMessage="• You will no not be able Chat" />
                <br />
                <FormattedMessage id="system.blockText3" defaultMessage="• You will not see each others Posts" />
                <br />
                <FormattedMessage id="system.blockText4" defaultMessage="• You will no not see each other in Searches" />
            </Typography>

            <div style={{ display: 'flex', justifyContent: "flex-start", margin: 2 }}>
                <Button variant="contained" color="primary" size="large"
                    style={SmallButton}
                    onClick={e => handleBlocking(e, null)} 
                    startIcon={<BlockIcon fontSize="small" className={classes.iconYellow}/>}
                >
                    <FormattedMessage id="tooltip.block" defaultMessage="Block" />
                </Button>
            </div>
            <br />

            <Divider />





            <Accordion
                expanded={isExpanded}
                elevation={0}
                style={{ margin: 0 }}
                onChange={e => setIsExpanded(i => !i)}
            >
                <AccordionSummary expandIcon={<ArrowDropDownIcon className={classes.icon} />}>
                    <Typography style={DialogHeading} variant="h5">
                        <FormattedMessage id="system.blockAndReportName" defaultMessage="Block & Report {firstname}?" values={{ firstname: props.firstname }} />
                    </Typography>
                </AccordionSummary>

                <AccordionDetails style={{ padding: '8px 0 0 0' }}>
                    <div style={{ flexWrap: "wrap", direction: "column" }} >
                        <Typography className={classes.descText} component={'span'} variant="caption">
                            {/* Member Comment */}
                            <TextField name="comment" multiline rows={2} variant="outlined" fullWidth
                                style={{ marginTop: 8 }}
                                label={<FormattedMessage id="system.comments" defaultMessage="Comments" />}
                                // helperText={<FormattedMessage id="system.textSearch.helperText" defaultMessage="Search: description, headline and profession" />}

                                // placeholder="comment"
                                // placeholder={<FormattedMessage id="system.textSearch.placeholder" defaultMessage="ie: ..." />}

                                InputLabelProps={{ shrink: true }}
                                value={reportComment || ''}

                                onChange={e => setReportComment(e.target.value)}
                            />

                            <RadioGroup aria-label="Report Behavior"
                                style={{ marginLeft: 2 }}
                                value={reportType}
                                onChange={e => handleReportType(e.target.value)}
                            >
                                {/* <FormControlLabel color="primary" value="profile" control={<Radio />} label={<FormattedMessage id="system.reportProfile" defaultMessage='No Report' />} /> */}

                                <FormControlLabel color="primary" value="profile" control={<Radio />}
                                    label={<Typography variant="subtitle2">
                                        <FormattedMessage id="system.reportProfile" defaultMessage='Inappropriate or Fake Profile' />
                                    </Typography>
                                    }
                                />
                                {<FormattedMessage id="system.reportProfileDesc" defaultMessage="Inappropriate or vulgar text, includes email or phone number, solicitations or urls to other sites, referencing illegal activities, pressured to move communication off the site, asked for financials, requested money, photos appear to be of someone else" />}

                                <FormControlLabel color="primary" value="photo" control={<Radio />}
                                    label={<Typography variant="subtitle2">
                                        <FormattedMessage id="system.reportPhoto" defaultMessage='Inappropriate Photo or Video' />
                                    </Typography>}
                                />
                                {<FormattedMessage id="system.reportPhotoDesc" defaultMessage="Obscene photo or video, photos or videos of illegal acts, photos or videos of minors alone" />}

                                <FormControlLabel color="primary" value="behavior" control={<Radio />}
                                    label={<Typography variant="subtitle2">
                                        <FormattedMessage id="system.reportBehavior" defaultMessage='Inappropriate Behavior' />
                                    </Typography>}
                                />
                                {<FormattedMessage id="system.reportBehaviorDesc" defaultMessage="Inappropriate or abusive behavior 'on' or 'off' the site" />}

                                <FormControlLabel color="primary" value="underage" control={<Radio />}
                                    label={<Typography variant="subtitle2">
                                        <FormattedMessage id="system.reportUnderage" defaultMessage='User under 18 years of age' />
                                    </Typography>}
                                />
                                <FormattedMessage id="system.reportUnderageDesc" defaultMessage="Member appears under 18 years of age" />

                                {/* <FormControlLabel color="primary" value="text" control={<Radio />} label="Sending a text" /> */}
                            </RadioGroup>
                        </Typography>

                        <br />

                        <div style={{ display: 'flex', justifyContent: "flex-start", margin: 2 }}>
                            <Button id="BlockAndReport" disabled={reportType === null} variant="contained" color="primary" size="large"
                                style={SmallButton}
                                onClick={e => handleBlocking(e, "BlockAndReport")} 
                                startIcon={<BlockIcon fontSize="small" className={classes.iconYellow}/>}
                            >
                                <FormattedMessage id="tooltip.blockAndReport" defaultMessage="Block & Report" />
                            </Button>
                        </div>

                        <Typography className={classes.descText} style={{ fontStyle: 'italic' }} component={'span'} variant="caption">
                            *Note: All reports are reviewed and researched (typically within 24 hours).
                            FindTrueFriends will not contact you unless we require additional details.
                        </Typography>
                    </div>
                </AccordionDetails>
            </Accordion>

            {/* Cancel */}
            <Divider />

            <div style={{ display: 'flex', justifyContent: "flex-end", margin: 2 }}>
                <Button variant="contained" color="primary" 
                    autoFocus
                    style={SmallButton}
                    onClick={props.handleClose} 
                    startIcon={<CancelIcon aria-label='Cancel'/>}
                >
                    <FormattedMessage id="system.cancel" defaultMessage="Cancel" />
                </Button>
            </div>
        </Dialog>
    )
}


