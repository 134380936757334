
//===============================================
// Redux DevTools
//===============================================
// https://github.com/reduxjs/redux-devtools/blob/master/docs/Walkthrough.md

import React from 'react';

// Exported from redux-devtools
import { createDevTools } from '@redux-devtools/core';

// Monitors are separate packages, and you can make a custom one
import { LogMonitor } from '@redux-devtools/log-monitor';
import { SliderMonitor } from '@redux-devtools/slider-monitor';
import { DockMonitor } from '@redux-devtools/dock-monitor';

// createDevTools takes a monitor and produces a DevTools component
const DevTools = createDevTools(
    <DockMonitor
        toggleVisibilityKey='ctrl-h'
        changeMonitorKey='ctrl-j'
        changePositionKey='ctrl-q'
        defaultPosition='bottom'
        defaultIsVisible={false}
    >
        <LogMonitor theme='tomorrow' />
    </DockMonitor>
);

export default DevTools;