// Masonry
// https://github.com/paulcollett/react-masonry-css

// https://codepen.io/asistapl/pen/yPGqWK
// https://codepen.io/LasseStilvang/pen/BrpqKx?editors=1100

// https://masonry.desandro.com/layout.html
// https://codepen.io/chriscoyier/pen/NeRNBO
// https://codepen.io/balazs_sziklai/pen/mOwoLg
// https://www.w3schools.com/howto/howto_js_lightbox.asp

// YouTube
// https://www.npmjs.com/package/react-lightbox-with-youtube
// https://www.npmjs.com/package/react-player

// *scaling-responsive-animations
// https://zachsaucier.com/
// https://css-tricks.com/scaling-responsive-animations/
// https://css-tricks.com/css-animation-tricks/
// https://css-tricks.com/controlling-css-animations-transitions-javascript/
// https://css-tricks.com/animate-blob-text-with-svg-text-clipping/


import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles';
// import clsx from 'clsx'
import isEmpty from '../validation/is-empty'
import Masonry from 'react-masonry-css'
import { StyledBadge } from "../styles/ftfStyles"

// import { useTheme } from '@mui/material/styles';

import Avatar from '../_components/Avatar'
import { SmallButton, GeneralText, FabButtonStyle, PageHeading, PageHeadingCentered } from "../styles/ftfStyles"

import debounce from 'lodash.debounce'

// import { PageHeading } from "../styles/ftfStyles"
// import { JellyAnimation } from '../helpers/utilities'

import { Box, Button, Dialog, DialogActions, Fab, Grid, IconButton, TextField, Tooltip, Typography, Zoom } from '@mui/material'

import CancelIcon from '@mui/icons-material/Cancel'
import CloseIcon from '@mui/icons-material/Close';

import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone'
import ThumbUpTwoToneIcon from '@mui/icons-material/ThumbUpTwoTone'

import ListIcon from '@mui/icons-material/List';

import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone'

import { FormattedMessage, useIntl, FormattedDate, FormattedTime } from 'react-intl'

// https://www.npmjs.com/package/react-image-gallery
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from 'react-image-gallery';

import styled from 'styled-components'

// import BeatLoader from 'react-spinners/BeatLoader'
// import styled, { css } from 'styled-components'   // keyframes
import MenuMember from '../_components/MenuMember'
import { JellyAnimation } from '../helpers/utilities'

import { likePhoto } from '../actions/member.actions'
import { deletePhoto } from '../actions/image.actions'
import { savePhotoComment } from '../actions/image.actions'


// width: 25px;
// height: 25px;
// margin-top: -2px;
const AnimatedCloseIcon = styled.svg`
  &:active {
    animation: scale .25s linear;
  }

  @keyframes scale {
    100% {
        transform: scale(1.1);
    }
  }
`

// Give a little feedback on a "click" 
const AnimatedIconOnClick = styled.svg`
    margin-top: -2px;
    width: 20px;
    height: 20px;

    &:active {
        animation: scale .25s ease-in-out;
      }

    @keyframes scale {
        100% {
            transform: scale(1.5);
        }
    }
`


// const navButtonStyles = base => ({
//     ...base,
//     backgroundColor: 'rgba(0, 153, 255, .5)', // '#03A9F4',
//     boxShadow: '0 1px 6px rgba(0, 0, 0, 0.18)',
//     color: 'white',

//     '&:hover, &:active': {
//         backgroundColor: 'white',
//         color: '#03A9F4',
//         opacity: .5,
//     },

//     '&:active': {
//         boxShadow: '0 1px 3px rgba(0, 0, 0, 0.14)',
//         transform: 'scale(0.96)',
//     },
// });


const GalleryContainer = styled.div`
    .image-gallery-content:not(.fullscreen) .image-gallery-image {
        object-position: center center;
        object-fit: scale-down;
        width: 100vw; 
        background-color: ${props => (props.backgroundColor)}; 
        height: ${props => (props.height)}px; 
    }

    .image-gallery-slide img {
        object-position: center center;
        object-fit: scale-down;

        width: 100vw; 
        height: 100vh; 

        display:block; 
    }
`;

const useStyles = makeStyles(theme => ({

    commentOwner: {
        cursor: 'pointer',

        // minHeight: 20, // So "no caption" is same height as "caption"

        '&:hover, &:active': {
            backgroundColor: 'rgba(0, 0, 0, 0.04)',
            // border: '1px solid',
            borderColor: 'rgba(0, 0, 0, 0.2)',
            borderRadius: 5,
        },

        '&:active': {
        },
    },

    dialogPaper: {
        margin: 12,
        height: 'auto',
        overflowY: 'hidden',
        padding: '0 8px',
    },

    gallery: {
        boxSizing: 'border-box',
        overflow: 'auto',
        padding: 0,
    },

    myMasonryGrid: {
        display: '-webkit-box', /* Not needed if autoprefixing */
        // eslint-disable-next-line no-dupe-keys
        display: '-ms-flexbox', /* Not needed if autoprefixing */
        // eslint-disable-next-line no-dupe-keys
        display: 'flex',
        marginLeft: 0, /* gutter size offset */
        marginRight: 4, /* mobile scrollbar */
        width: 'auto',
    },

    myMasonryGridColumn: {
        padding: 0, /* gutter size */
        backgroundClip: 'padding-box',
    },

    /* Style your items */
    //   .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    //     background: grey;
    //     margin-bottom: 30px;
    //   }

    iconButton: {
        color: theme.palette.primary.dark,
        padding: 4,
        marginRight: 4,
    },

    member: {
        // cursor: 'pointer',
        display: 'flex',
    },

    modalRoot: {
        background: 'rgba(0,0,0,0.05)',
    },

    photo: {
        boxSizing: 'border-box',
        borderRadius: '10px 10px 10px 10px',
        maxHeight: 300,
        maxWidth: '100%',
        width: 'auto',
    },

    photoAndText: {
        textAlign: 'left',
        overflowX: 'hidden',

        lineHeight: 1.1,
        // wordBreak: 'break-word',

        whiteSpace: 'normal',
    },

    photoWrappedWithText: {
        boxSizing: 'border-box',
        borderRadius: '10px 10px 10px 10px',
        float: 'left',

        marginTop: 2,
        marginRight: 12,
        marginBottom: 1,

        maxHeight: 300,
        maxWidth: '100%',
        width: 'auto',
    },


    timestamp: {
        color: 'Gray',
        display: 'flex',
        alignSelf: 'flex-end',
        fontSize: '.6rem'
    },

    // content
    widget: {
        backgroundColor: theme.palette.info.light,

        border: '1px solid',
        borderColor: theme.palette.info.main,
        borderRadius: 10,

        boxShadow: '0 1px 2px rgba(34, 25, 25, 0.4)',

        breakInside: 'avoid-column',  // required

        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, .8)',
        },

        '&:active': {
        },

        minWidth: 150,  // Mostly for while loading so they don't stack...

        padding: 4,
        margin: 8,
        marginBottom: '.5rem',

        textAlign: 'center',  // Center image
        transition: 'opacity .4s ease-in-out',
        // minHeight: 150,  // Mostly for while loading so they don't stack...

        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
    },

}))


// https://stackoverflow.com/questions/50819260/react-input-onchange-lag
// https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js
// https://reactjs.org/docs/hooks-reference.html#usememo
export default function Gallery(props) {
    const classes = useStyles(props)
    const intl = useIntl()

    // const theme = useTheme()
    // const galleryHeight = props.height

    // Rights (or default)
    const rights = props.rights || {
        isAdminMgr: false,   // admin of admins
        isContactMgr: false, // Can contact members
        isEventMgr: false,
        isMediaMgr: false,
        isMemberMgr: false, // Approve or Ban members
    }


    // const classCommentOwner = clsx(classes.headline, classes.commentOwner)

    const isProfileVisible = useSelector(state => state.profile.profile.isVisible)
    const userId = useSelector(state => state.profile.profile._id)

    const dispatch = useDispatch()

    const [imageGalleryIndex, setImageGalleryIndex] = useState(0)

    const [showCarousel, setShowCarousel] = useState(false)

    const [showDeleteWarning, setShowDeleteWarning] = useState(false)
    const [showEditCaption, setShowEditCaption] = useState(false)

    const [widget, setWidget] = useState(null)

    const bounceDelete = React.useCallback(debounce(debouncedDelete, 333), [])        // eslint-disable-line react-hooks/exhaustive-deps
    const bounceLikePhoto = React.useCallback(debounce(debouncedLikePhoto, 333), [])  // eslint-disable-line react-hooks/exhaustive-deps

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [showMemberMenu, setShowMemberMenu] = useState(false)

    const [likeTooltip, setLikeTooltip] = useState('')


    //-------------------------------------------
    // Load 
    //-------------------------------------------
    useEffect(() => {
        if (isProfileVisible)
            setLikeTooltip(intl.formatMessage({ id: "system.likes", defaultMessage: "Like" }))
        else {
            var pre = intl.formatMessage({ id: 'profile.yourProfileIs', defaultMessage: 'Your Profile is' })
            var post = intl.formatMessage({ id: 'system.hidden', defaultMessage: 'Hidden' })
            setLikeTooltip(pre + ' ' + post)
        }

    }, [isProfileVisible, intl])


    //-------------------------------------------
    // debounced... 
    //-------------------------------------------
    function debouncedLikePhoto(widget) {
        if (isProfileVisible && !isEmpty(props.id)) {
            dispatch(likePhoto(props.idType, props.id, widget.widgetId))
        }
    }


    function handleMenuClose() {
        setShowMemberMenu(false)
        setAnchorEl(null)
    }

    //-------------------------------------------
    // Carousel
    //-------------------------------------------

    function carouselClose(e) {
        e.preventDefault()
        setShowCarousel(false)

        if (props.showUploadImage)
            props.showUploadImage(true)
    }


    //-------------------------------------------
    // debounce Delete
    //-------------------------------------------    

    function handleShowDeletePhoto(obj) {
        if (props.allowChanges) {
            setWidget(obj)
            bounceDelete(obj)
        }

        handleMenuClose()
    }

    function debouncedDelete(obj) {
        setShowDeleteWarning(true)
    }

    // dispatch deletePhoto
    const onDeletePhoto = (e) => {
        e.preventDefault()
        e.stopPropagation()
        setShowDeleteWarning(false)

        if (!isEmpty(widget) && !isEmpty(widget.widgetId))
            dispatch(deletePhoto(props.idType, props.id, widget.widgetId))
    }


    const onDeleteWarningClose = () => {
        setShowDeleteWarning(false)
    }

    function onDeleteWarning() {
        return (
            //
            <Dialog
                open={showDeleteWarning}
                onClose={onDeleteWarningClose}
                classes={{ paper: classes.dialogPaper }}
            >
                <Grid container>
                    <Typography style={PageHeadingCentered} variant="h5">
                        <FormattedMessage id="system.ays" defaultMessage="Are you sure?" />
                    </Typography>
                </Grid>

                <DialogActions>
                    <Button variant="contained" color="primary" autoFocus
                        style={SmallButton} onClick={(e) => onDeletePhoto(e)}
                        startIcon={<DeleteForeverIcon />}
                    >
                        <FormattedMessage id="system.delete" defaultMessage="Delete" />
                    </Button>

                    <Button variant="contained" color="primary" autoFocus
                        style={SmallButton} onClick={onDeleteWarningClose}
                        startIcon={<CancelIcon />}
                    >
                        <FormattedMessage id="system.cancel" defaultMessage="Cancel" />
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }


    //-------------------------------------------
    // Edit Comment 
    //-------------------------------------------
    function handleShowEditCaption(obj) {
        if (props.allowChanges) {
            setWidget(obj)
            setShowEditCaption(true)
        }

        handleMenuClose()
    }

    // Save new Comment to local object
    const onCaptionChange = (value) => {
        setWidget(widget => ({ ...widget, headline: value }))
    }

    function handleEditCaptionSave(obj) {
        setShowEditCaption(false)

        if (!isEmpty(widget) && !isEmpty(widget.widgetId))
            dispatch(savePhotoComment(props.idType, props.id, widget.widgetId, widget.headline))
    }

    const onEditCaptionClose = (e) => {
        setShowEditCaption(false)
    }

    function onEditComment() {
        return (
            <Dialog
                open={showEditCaption}
                onClose={onEditCaptionClose}
                classes={{ paper: classes.dialogPaper }}
            >
                <Grid container>
                    <Typography style={PageHeading} variant="subtitle1">
                        {<FormattedMessage id="system.comment" defaultMessage="Comment" />}
                    </Typography>
                </Grid>

                <div>
                    {widget && <TextField fullWidth multiline id="headline"
                        style={{ minWidth: 325, whiteSpace: "pre-line", margin: '4px 0 0 0' }}
                        color="primary"
                        name="headline"
                        value={widget.headline || ""}
                        rows="16"
                        variant="outlined"
                        onChange={e => onCaptionChange(e.target.value)}

                        margin="dense"
                        size="small"
                        inputProps={{ maxLength: 1000 }}
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            style: { fontSize: '.9rem' },
                        }}
                    />}
                </div>

                <DialogActions>
                    <Button variant="contained"
                        color="primary"
                        autoFocus
                        size="small"
                        style={SmallButton}
                        onClick={handleEditCaptionSave}
                        startIcon={<CloudUploadIcon aria-label='Save' />}
                    >
                        <FormattedMessage id="image.save" defaultMessage="Save" />
                    </Button>

                    <Button variant="contained"
                        color="primary"
                        autoFocus
                        size="small"
                        style={SmallButton}
                        onClick={onEditCaptionClose}
                        startIcon={<CancelIcon aria-label='Cancel' />}
                    >
                        <FormattedMessage id="system.cancel" defaultMessage="Cancel" />
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    function closeImageGallery(props) {
        return (
            <Fab color="primary" size="small" variant="circular" style={FabButtonStyle} onClick={carouselClose}>
                <AnimatedCloseIcon>
                    <CloseIcon aria-label='Close' onClick={carouselClose} />
                </AnimatedCloseIcon>
            </Fab >
        )
    }


    //-------------------------------------------
    // Image Gallery
    // "Groups" are handled different than the "Photo Wall"
    //-------------------------------------------
    // https://github.com/xiaolin/react-image-gallery
    // https://github.com/xiaolin/react-image-gallery/issues/502
    // https://github.com/xiaolin/react-image-gallery/issues/339
    // https://github.com/xiaolin/react-image-gallery/issues/48
    const photoCarousel = () => {

        var images = []

        if (props.idType === 'group') {
            // Group can only view one image at a time because it has "posts without images"...
            // We could filter images only but it's akward:
            // images = props.images.filter(function (w) {
            //     return w.src != undefined
            // })

            images.push(props.images[imageGalleryIndex])
        }
        else {
            images = props.images
        }

        return (
            <GalleryContainer height={props.height} backgroundColor={props.backgroundColor}>
                {/* <IconButton className='close-iconButton' 
                // onClick={onRequestClose}
                >
                    <CancelIcon />
                </IconButton> */}

                <ImageGallery
                    items={images}


                    renderItem={(item) => {
                        return (
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    overflow: 'hidden',
                                    width: '100%',
                                    //   maxHeight: !isFullScreen ? 575 : '90vh',
                                    borderRadius: 5,
                                }}
                            >
                                <img
                                    className='image-gallery-image'
                                    src={item.original}
                                    style={{ borderRadius: 5 }} // styles.image
                                />

                                {/* Description */}
                                {/* <span
                                    //   style={styles.imageSliderDescription}
                                    className='image-gallery-description'
                                >
                                    {item.description}
                                </span> */}
                            </div>
                        );
                    }}

                    startIndex={props.idType === 'group' ? 0 : imageGalleryIndex}   // Group is always 0 for one image
                    lazyLoad

                    showBullets={false}
                    // showIndex  // Clips on phone
                    showFullscreenButton={props.fullscreen}
                    showPlayButton
                    showThumbnails={false}

                    slideDuration={333}

                    infinite

                    useBrowserFullscreen={false}

                    renderCustomControls={closeImageGallery}
                // () => { return <Button className='image-gallery-custom-action' /> }
                />
            </GalleryContainer>
        )

        /* {props.images && 
                props.images.map(({ widgetId, headline, likes, src }) =>
                    <div id={widgetId}>
                        <img src={src} style={{ margin: 'auto', maxHeight: 400 }} />
                    </div>
                )}
        { */
    }



    //-------------------------------------------
    // photoGallery = React.useMemo speeds this up!
    //-------------------------------------------
    const photoGallery = React.useMemo(() => {
        function carouselOpen(index, e) {
            e.preventDefault()
            setImageGalleryIndex(index)

            if (props.showUploadImage)
                props.showUploadImage(false)

            setShowCarousel(true)

        }

        //---------------------------------------
        // doesUserLike
        //---------------------------------------
        const doesUserLike = (obj) => {
            if (!isEmpty(userId) && !isEmpty(props.id)) {
                if (obj.likes.find(function (like) {
                    return like.ownerId === userId
                })) {
                    return true
                }
            }

            return false
        }

        //-------------------------------------------
        // debounce likePhoto...
        //-------------------------------------------
        function handleLikePhoto(e, widget) {
            e.stopPropagation()
            bounceLikePhoto(widget)
        }

        //---------------------------------------
        // handleShowEditComment
        //---------------------------------------
        // const handleShowEditComment = (e, obj) => {
        //     e.preventDefault()
        //     e.stopPropagation()

        //     if (props.allowChanges) {
        //         setWidget(obj)
        //         setShowEditCaption(true)
        //     }
        // }


        function onMemberMenu(e, obj) {
            e.preventDefault()
            setAnchorEl(e.currentTarget)

            setWidget(obj)
            setShowMemberMenu(true)
        }


        return (
            <div className={classes.gallery} style={{ backgroundColor: props.backgroundColor }}>

                <Masonry
                    breakpointCols={props.masonryBreakpoints}
                    className={classes.myMasonryGrid}
                    columnClassName={classes.myMasonryGridColumn}
                >
                    {/* TODO: Future: {!isLoading && <Spinner />} - DSW */}

                    {props.images && props.images.map((widget, i) => (
                        <div id='GalleryWidget' key={i} className={classes.widget} >

                            {props.showHeader && <div style={{ display: 'flex', textAlign: 'left' }}>
                                {/* Avatar (Used in Groups not in Members Photos) */}
                                {<div key={widget.ownerId} className={classes.member}>
                                    <Avatar style={{ marginBottom: -8 }} isAdmin={props.isAdmin} noMargin small gender={widget.gender} avatarUrl={widget.avatarUrl} delay={250} isOnline={widget.isOnline} alt={widget.firstname}>...</Avatar>
                                </div>}

                                <div style={{
                                    marginLeft: 8, marginTop: -2,
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                }}
                                >
                                    {/* <StyledMsg>
                                        {widget.isAdmin > 0 && <SecurityTwoToneIcon color="primary" style={{ marginBottom: -2, width: '12px', height: '12px' }} />} {widget.firstname}
                                    </StyledMsg> */}

                                    <Typography variant="caption" style={GeneralText}>
                                        {widget.isAdmin > 0 && <SecurityTwoToneIcon color="primary" style={{ marginBottom: -2, width: '12px', height: '12px' }} />} {widget.firstname}
                                    </Typography>

                                    <Typography gutterBottom className={classes.timestamp}>
                                        <FormattedDate value={widget.createdOn} year="numeric" month="short" day="2-digit" /> <br />
                                        <FormattedTime value={widget.createdOn} />
                                    </Typography>
                                </div>
                            </div>}


                            <Box>
                                {/* Posts Image + hotlink to carousel */}

                                {/* Image and Text */}
                                {widget.headline &&
                                    <div id='PhotoAndText' aria-label='Photo and Text' className={classes.photoAndText} >
                                        <Typography variant="body2" style={{ whiteSpace: 'pre-wrap' }}>

                                            {/* Image */}
                                            {widget.src && <a
                                                href={widget.src}
                                                key={i}
                                                onClick={(e) => carouselOpen(i, e)}
                                            >
                                                <img src={widget.src} className={classes.photoWrappedWithText} alt={i} />
                                            </a>}

                                            {/* Text */}
                                            {widget.headline}
                                        </Typography>
                                    </div>}

                                {/* Image alone... */}
                                {!widget.headline &&
                                    <div id='Photo' aria-label='Photo' >
                                        {/* Image */}
                                        {widget.src && <a
                                            href={widget.src}
                                            key={i}
                                            onClick={(e) => carouselOpen(i, e)}
                                        >
                                            <img src={widget.src} className={classes.photo} alt={i} />
                                        </a>}
                                    </div>}
                            </Box>


                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>

                                {/* Likes */}
                                <div id='likes' style={{ display: 'flex' }}>
                                    <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="top" title={likeTooltip} >
                                        <JellyAnimation>
                                            <IconButton
                                                aria-label="Likes"
                                                disabled={!isProfileVisible}
                                                className={classes.iconButton}
                                                onClick={(e) => { handleLikePhoto(e, widget) }}
                                                size="large"
                                            >
                                                <StyledBadge
                                                    color="secondary"
                                                    badgeContent={widget.likes.length}
                                                    max={999}
                                                >
                                                    {!doesUserLike(widget) &&
                                                        <AnimatedIconOnClick>
                                                            <ThumbUpTwoToneIcon fontSize="small" />
                                                        </AnimatedIconOnClick>
                                                    }
                                                    {doesUserLike(widget) &&
                                                        <AnimatedIconOnClick>
                                                            <FavoriteTwoToneIcon color="secondary" fontSize="small" />
                                                        </AnimatedIconOnClick>
                                                    }
                                                </StyledBadge>
                                            </IconButton>
                                        </JellyAnimation>
                                    </Tooltip>

                                    {widget.likes.length > 0 &&
                                        <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="system.likes.view" defaultMessage="View Likes" />}>
                                            <JellyAnimation>
                                                <IconButton
                                                    className={classes.iconButton}
                                                    aria-label="View Likes"
                                                    onClick={(e) => { props.viewLikes(e, widget.widgetId) }}
                                                    size="small"
                                                >
                                                    <AnimatedIconOnClick>
                                                        <ListIcon fontSize="small" />
                                                    </AnimatedIconOnClick>
                                                </IconButton>
                                            </JellyAnimation>
                                        </Tooltip>
                                    }

                                    {/* TODO: Future "more menu" containing: Remove, Report Inappropriate, etc... - DSW  */}
                                    <IconButton
                                        aria-label="More"
                                        className={classes.iconButton}
                                        style={{ position: 'absolute', bottom: 0, right: 0 }}
                                        onClick={(e) => onMemberMenu(e, widget)}
                                        size="large"
                                    >
                                        <AnimatedIconOnClick>
                                            <MoreHorizIcon fontSize="small" />
                                        </AnimatedIconOnClick>
                                    </IconButton>
                                </div>
                            </div>

                            {/* This puts the BeatLoader on 'all' photos */}
                            {/* <div id='spinner' style={{ boxSizing: "content-box", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                                <BeatLoader sizeUnit={"px"} size={50}
                                    color={theme.palette.primary.dark}
                                    loading={isLoading} />
                            </div> */}
                        </div>
                    ))
                    }
                </Masonry >
            </div >
        );
    }, [userId, props, bounceLikePhoto, likeTooltip, isProfileVisible,
        classes.gallery, classes.iconButton, classes.myMasonryGrid, classes.myMasonryGridColumn,
        classes.member, classes.photo, classes.photoAndText, classes.photoWrappedWithText, classes.timestamp, classes.widget
    ])


    const renderGallery = () => {
        if (!props.images)
            return

        return (<>
            {showCarousel && photoCarousel()}
            {!showCarousel && photoGallery}

            {showDeleteWarning && onDeleteWarning()}
            {showEditCaption && onEditComment()}

            {showMemberMenu && <MenuMember
                anchorEl={anchorEl}

                allowChanges={props.allowChanges && rights.isMediaMgr || widget.ownerId === userId}

                rights={rights}

                memberId={widget.ownerId}
                groupId={props.id}
                idType={props.idType}

                handleClose={handleMenuClose}
                handleDelete={handleShowDeletePhoto}
                handleEdit={handleShowEditCaption}

                widget={widget}
            />}
        </>)
    }


    // Slick Carousel - Old
    // New: https://www.npmjs.com/package/react-slick
    //      https://github.com/akiran/react-slick
    //      https://codesandbox.io/s/ppwkk5l6xx
    //      https://codesandbox.io/examples/package/react-slick

    // React Image Lightbox (attribution)
    // https://frontend-collective.github.io/react-image-lightbox/

    // react-image-gallery
    // https://github.com/xiaolin/react-image-gallery
    // https://www.linxtion.com/demo/react-image-gallery/
    // https://openbase.io/js/react-image-gallery
    // 
    // New react-slick
    // var settings = {
    //     className: "",

    //     dots: true,
    //     infinite: true,
    //     adaptiveHeight: true,

    //     fade: true,
    //     speed: 500,

    //     slidesToShow: 1,
    //     slidesToScroll: 1
    // };


    return (
        <div id="Gallery">
            {renderGallery()}
        </div>
    )
}


