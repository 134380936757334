// https://redux-form.com/7.4.2/examples/material-ui/

// https://appdividend.com/2018/07/18/react-redux-node-mongodb-jwt-authentication/

import React, { useEffect, useState } from "react"

import { FormattedMessage, useIntl } from 'react-intl'
import { roundN } from '../helpers/utilities'
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom'
import { createApi } from 'unsplash-js'

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

import isEmpty from '../validation/is-empty'

import { useDebounce } from 'use-debounce'

import { LayoutMax, PageHeading } from "../styles/ftfStyles"

import { Button, CircularProgress, Grid, FormControlLabel, IconButton, InputAdornment, Paper, Switch, TextField, Typography } from '@mui/material'

import { Genders } from '../constants/ftfDefinitions';

// import AccountCircle from '@mui/icons-material/AccountCircle'
// import Cake from '@mui/icons-material/Cake'
// import Email from '@mui/icons-material/Email'
// import Fingerprint from '@mui/icons-material/Fingerprint'
// import LocationOn from '@mui/icons-material/LocationOn'
import FTF_Images from './FTF_Images'

import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

// Modal Poilcies
import CodeOfConduct from '../policies/CodeOfConduct'
import CookiePolicy from '../policies/CookiePolicy'
import PrivacyPolicy from '../policies/PrivacyPolicy'
import TermsOfUseAgreement from '../policies/TermsOfUseAgreement'

// import { Countries, Genders } from '../constants/ftfDefinitions'
import { authError, authClearAllErrors, registerUser, authClearError } from '../actions/auth.actions'
import { PasswordColor, PasswordScore } from '../validation/PasswordStrength.js'

import moment from 'moment'

import { getYearBornFromAge } from '../helpers/utilities'

import "moment/locale/fr"
import "moment/locale/es"
moment.locale("en") // It's required to select default locale manually

const unsplash = createApi({ accessKey: "4164d2ead0847cffbdbd8d888359956de5048d32e90ee90cffa1007240c32d5e" })

const useStyles = makeStyles(theme => ({
    ageField: {
        marginTop: 8,
        margin: 4,
        maxWidth: 168,
        width: 75,
    },

    ageMonthYearBorn: {
        textAlign: 'center',
        // marginTop: 16,
        // marginLeft: 16,
        // minWidth: 100,
        // maxWidth: 100,
    },

    ageMonthYearBornDesc: {
        color: theme.palette.primary.dark,
        fontSize: '0.75rem',
        textAlign: 'center',
        // lineHeight: 1.2,
        // marginLeft: 16,
        // minWidth: 125,
        // maxWidth: 125,
    },

    agreeLabel: {
        color: theme.palette.primary.dark,
        fontSize: '0.75rem',
        lineHeight: 1.2,
    },

    agreeRoot: {
        marginRight: 4,
    },

    buttonSignUp: {
        minWidth: 150,
        minHeight: 32,
        margin: 8,
    },

    fieldShort: {
        marginTop: 4,
        minWidth: 168,
        maxWidth: 168,
    },

    gridCenter: {
        position: 'absolute',
        bottom: 100,
    },

    gridContainer: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: 10,
    },

    gridContainerAge: {
        display: 'flex',
        marginTop: 10,
    },

    icon: {
        color: theme.palette.primary.dark,
        flexshrink: 0,
        marginRight: 8,
        marginTop: 16,
    },

    image: {
        position: 'absolute',
        top: 0, left: 0,
    },

    logo: {
        maxHeight: window.innerWidth <= 500 ? 20 : 20,
        marginRight: 4,
        marginBottom: 3,
        alignSelf: 'center',
    },

    menu: {
        // maxHeight: 30,
        width: 250,
        // padding: 8,
    },



    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },

    paper: {
        backgroundColor: "rgba(255, 255, 255, 0.98)",
        padding: '0 8px',

        height: 'fit-content',
        maxHeight: 520,
        maxWidth: 370,

        zIndex: 9000,
        position: 'relative',
    },

    policies: {
        position: 'relative',
        bottom: 8,
        left: '50%',
        transform: 'translate(-50%, 0)',
        justifyContent: "center",
        marginTop: 8
    },

    policyButton: {
        color: theme.palette.primary.dark,
        fontSize: '0.7rem',
        minHeight: 'auto',
        width: 150,
    },

    progress: {
        margin: 0,
        marginRight: 4,
    },

}))


export default function Register(props) {
    const classes = useStyles(props)
    let navigate = useNavigate()
    const intl = useIntl()

    const isAdmin = useSelector(state => state.profile.profile.ftfAdmin)

    const isRegistering = useSelector(state => state.auth.isRegistering)
    const isRegistered = useSelector(state => state.auth.isRegistered)

    const errors = useSelector(state => state.auth.errors)

    const dispatch = useDispatch()

    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)
    const [image, setImage] = useState(null)

    const [incomplete, setIncomplete] = useState(false)

    const [showCodeOfConduct, setShowCodeOfConduct] = useState(false)
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
    const [showCookiePolicy, setShowCookiePolicy] = useState(false)
    const [showTermsOfUse, setShowTermsOfUse] = useState(false)

    const [firstname, setFirstname] = useState('')
    const [debouncedFirstname] = useDebounce(firstname, 750)

    const [lastname, setLastname] = useState('')
    const [debouncedLastname] = useDebounce(lastname, 750)

    const [password, setPassword] = useState('')
    const [debouncedPassword] = useDebounce(password, 750)

    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [debouncedPasswordConfirm] = useDebounce(passwordConfirm, 1000)

    const [passwordStrengthScore, setPasswordStrengthScore] = useState(0)

    const [showPassword, setShowPassword] = useState(false)
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false)

    const [agreeCodeOfConduct, setAgreeCodeOfConduct] = useState(false)

    // const locale = "en"

    const [user, setUser] = useState({
        // country: '',    // Not in use
        age: '',
        firstname: '',
        lastname: '',
        email: '',
        password: '',
        password_confirm: '',
        gender: 2,
        birthDate: moment(),
        lng: 0,
        lat: 0,
    })


    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight)
            setWidth(window.innerWidth)
        }

        // On mount...
        if (width === 0) {
            handleResize()
            getLocation()

            dispatch(authClearAllErrors())
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [dispatch, height, width])


    /* https://unsplash.com/documentation#supported-parameters */
    useEffect(() => {
        // We can't count on any of the Unsplash APIs so we do both...
        setImage("https://source.unsplash.com/collection/1914244/" + window.innerWidth + "x" + window.innerHeight + "/?lock/sig=" + Math.random() + "/SameSite=None")

        unsplash.photos.getRandom({
            collectionIds: ["1914244"],
            query: 'lock',
            count: 1,
        })
            .then(result => {
                if (result.errors) {
                    console.log('unsplash error occurred: ', result.errors[0])
                } else {
                    const photo = result.response[0]
                    setImage(photo.urls.raw + "&w=" + width + "&h=" + height + "&fit=clamp")
                }
            })
    }, [height, width])


    //-------------------------------------------
    // firstname Debounced
    //-------------------------------------------
    useEffect(() => {
        setUser(user => ({ ...user, firstname: debouncedFirstname }))
    }, [debouncedFirstname])


    //-------------------------------------------
    // lastname Debounced
    //-------------------------------------------
    useEffect(() => {
        setUser(user => ({ ...user, lastname: debouncedLastname }))
    }, [debouncedLastname])


    //-------------------------------------------
    // password Debounced
    //-------------------------------------------
    useEffect(() => {
        setUser(user => ({ ...user, password: debouncedPassword }))
    }, [debouncedPassword])


    //-------------------------------------------
    // passwordConfirm Debounced
    //-------------------------------------------
    useEffect(() => {
        setUser(user => ({ ...user, password_confirm: debouncedPasswordConfirm }))
    }, [debouncedPasswordConfirm])


    //-------------------------------------------
    // Validate Registration is Complete
    //-------------------------------------------
    useEffect(() => {
        // *Note - REMOVE FOR PRODUCTION! - DSW
        // Temporary for Admin to add more test users
        // if (isRegistered && !isAdmin) {
        //     navigate('/')
        // }

        // Production -  Entre vous...
        if (isRegistered) {
            navigate('/login', { replace: true })
        }

        setIncomplete(false)

        // Validate Birthday is younger than 90 years and older than 18 years
        const validBirthday = (moment(user.birthDate) > moment().subtract(90, 'years') && moment(user.birthDate) < moment().subtract(18, 'years'))

        // const validDate = moment(bday, "YYYY-MM-DD", true).isValid() 
        // console.log('agreeCodeOfConduct: ', agreeCodeOfConduct)
        // console.log('        validBirthday: ', validBirthday)
        // console.log('Registration user: ', user)

        if (!agreeCodeOfConduct) {
            setIncomplete(true)
        }

        // Validate registration completeness...
        if (!validBirthday) {
            setIncomplete(true)
        }

        // Email - Firstname - Lastname
        if (user.email.length < 10 || user.firstname.length < 2 || user.lastname.length < 2) {
            setIncomplete(true)
        }

        //---------------------------------------
        // RegExp for firstname, lastname
        // Alpha chars only plus 
        //---------------------------------------
        var validStringRegex = new RegExp("^[a-zA-Z_]*$")

        if (!validStringRegex.test(user.firstname)) {
            dispatch(authError({ firstname: "system.invalidChar" }))
            setIncomplete(true)
        } else {
            dispatch(authError({ firstname: "" }))
        }

        if (!validStringRegex.test(user.lastname)) {
            dispatch(authError({ lastname: "system.invalidChar" }))
            setIncomplete(true)
        } else {
            dispatch(authError({ lastname: "" }))
        }

        //---------------------------------------
        // Password
        //---------------------------------------
        if (isEmpty(user.password) || isEmpty(user.password_confirm)) {
            setIncomplete(true)
        }

        // Matches password_confirm && Require strength >= 2 (weak)
        setPasswordStrengthScore(PasswordScore(debouncedPassword))

        // console.log('passwordStrengthScore: ' + passwordStrengthScore)
        switch (PasswordScore(user.password)) {
            case 3:
                dispatch(authError({ password: "profile.security.passwordStrong" }))
                break

            case 2:
                dispatch(authError({ password: "profile.security.passwordWeak" }))
                break

            case -1:
                dispatch(authError({ password: "profile.security.passwordLength" }))
                setIncomplete(true)
                break

            default:
                break
        }

        // Password Confirmation
        if (user.password_confirm.length > 0 && user.password != user.password_confirm) {
            dispatch(authError({ password_confirm: "profile.security.passwordDontMatch" }))
            setIncomplete(true)
        } else {
            dispatch(authError({ password_confirm: "" }))
        }

        // Country
        // if (isEmpty(user.country))
        //     setIncomplete(true)

    }, [user, dispatch, agreeCodeOfConduct, debouncedPassword, isAdmin, isRegistered, passwordStrengthScore, navigate])


    //-------------------------------------------
    // getLocation
    // Mapping
    //-------------------------------------------
    function getLocation() {
        if (window.navigator && window.navigator.geolocation) {
            window.navigator.geolocation.getCurrentPosition(position => {

                setUser(user => ({ ...user, lng: position.coords.longitude, lat: position.coords.latitude }))
                // console.log('Your Location: ', position.coords)
            },
                error => {
                    switch (error.code) {
                        case 1:
                            console.log('getCurrentPosition: Permission Denied')
                            break
                        case 2:
                            console.log('getCurrentPosition: Position Unavailable')
                            break
                        case 3:
                            console.log('getCurrentPosition: Timeout')
                            break
                        default:
                            console.log('getCurrentPosition: ' + error.code)
                            break
                    }
                }
            )
        }

    }


    // function onCountryChange(event, country) {
    //     if (isEmpty(country))
    //         setUser(user => ({ ...user, country: "" }))
    //     else
    //         setUser(user => ({ ...user, country: country.code }))
    // }


    //-------------------------------------------
    // handleBirthDate (age and birth date)
    //-------------------------------------------
    // @param {Event} e
    function handleBirthDate(e) {
        if (isEmpty(e.target.value)) {
            setUser(user => ({ ...user, ['age']: '' }))
            setUser(user => ({ ...user, ['birthDate']: moment() }))
        }
        else {
            var v = roundN(e.target.value, 2)
            if (v > 90) v = 90
            if (v < 1) v = ''

            setUser(user => ({ ...user, ['age']: v }))
            setUser(user => ({ ...user, ['birthDate']: getYearBornFromAge(v) }))
        }
    }


    //-------------------------------------------
    // handleChange - generic usage
    //-------------------------------------------
    // @param {Event} e
    function handleChange(e) {
        e.persist()

        // Save to user object
        if (e.target && e.target.name) {
            setUser(user => ({ ...user, [e.target.name]: e.target.value }))

            // Auto-clear any previous validation error (UX)
            dispatch(authClearError(e.target.name))
        }
    }


    //-------------------------------------------
    // handleDateChange
    // Separate handler for dates because "pickers" do not forward the event
    //-------------------------------------------
    // @param {Moment} date 
    // moment(user.birthDate.toString(), "YYYYMM").format('YYYY-MM')
    // function handleDateChange(m) {
    //     if (!isEmpty(m.target.value))
    //         setUser(user => ({ ...user, birthDate: moment(m.target.value, "YYYYMMDD").format('YYYY-MM-DD') }))
    // }


    //-------------------------------------------
    // handleSubmit
    //-------------------------------------------
    // @param {Event} e
    function handleSubmit(e) {
        e.preventDefault()

        if (user.firstname && user.lastname && user.email && user.password && user.password_confirm && user.birthDate && agreeCodeOfConduct) {
            dispatch(registerUser(user, props.navigate))
        }
    }


    function handleClickShowPassword() {
        setShowPassword(!showPassword)
    }

    function handleClickShowConfirmPassword() {
        setShowPasswordConfirm(!showPasswordConfirm)
    }

    function handleCodeOfConduct(e) {
        if (e && e.target)
            setAgreeCodeOfConduct(e.target.checked)
    }

    function toggleCodeOfConduct() {
        setShowCodeOfConduct(!showCodeOfConduct)
    }

    function toggleCookiePolicy() {
        setShowCookiePolicy(!showCookiePolicy)
    }

    function togglePrivacyPolicy() {
        setShowPrivacyPolicy(!showPrivacyPolicy)
    }

    function toggleTermsOfUse() {
        setShowTermsOfUse(!showTermsOfUse)
    }

    function onPolicies() {
        return (
            <Grid container direction='row' className={classes.policies}>
                <Grid item>
                    <Button color="primary" className={classes.policyButton} onClick={toggleCodeOfConduct}>
                        <FormattedMessage id="navbar.codeofconduct" defaultMessage="Code of Conduct" />
                    </Button>
                </Grid>
                <Button color="primary" className={classes.policyButton} onClick={togglePrivacyPolicy}>
                    <FormattedMessage id="navbar.privacypolicy" defaultMessage="Privacy Policy" />
                </Button>
                <Button color="primary" className={classes.policyButton} onClick={toggleCookiePolicy}>
                    <FormattedMessage id="navbar.cookiepolicy" defaultMessage="Cookie Policy" />
                </Button>
                <Button color="primary" className={classes.policyButton} onClick={toggleTermsOfUse}>
                    <FormattedMessage id="navbar.termsofuse" defaultMessage="Terms of Use" />
                </Button>
            </Grid>
        )
    }

    return (
        <Div100vh>
            <LayoutMax style={{ display: 'flex', justifyContent: 'center' }}>

                <img alt='FindTrueFriends Registration' src={image} className={classes.image} />

                <CodeOfConduct show={showCodeOfConduct} fullscreen={false} onClose={toggleCodeOfConduct} />
                <CookiePolicy show={showCookiePolicy} fullscreen={false} onClose={toggleCookiePolicy} />
                <PrivacyPolicy show={showPrivacyPolicy} fullscreen={false} onClose={togglePrivacyPolicy} />
                <TermsOfUseAgreement show={showTermsOfUse} fullscreen={false} onClose={toggleTermsOfUse} />

                <Paper className={classes.paper}>
                    <Grid container direction='row'>
                        <img alt='Logo' src={'https://www.findtruefriends.com/logo/ftf_logo_small.png'} className={classes.logo} />
                        <Typography style={PageHeading} variant="h6">
                            <FormattedMessage id="system.ftf.registration" defaultMessage="Registration" />
                        </Typography>
                    </Grid>

                    <form onSubmit={handleSubmit}>

                        <Grid container direction='row' className={classes.gridContainer}>
                            <Grid item>
                                <TextField name="firstname" size="small"
                                    className={classes.fieldShort}
                                    label={<FormattedMessage id="member.firstname" defaultMessage="First Name" />}
                                    onChange={e => setFirstname(e.target.value.trim())}
                                    value={firstname || ""}
                                    autoFocus fullWidth required

                                    error={Boolean(errors.firstname)}
                                    helperText={isEmpty(errors) || isEmpty(errors.firstname) ? "" : <FormattedMessage id={[errors.firstname]} defaultMessage="Required" />}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>

                            <Grid item>
                                <TextField name="lastname"
                                    className={classes.fieldShort}
                                    label={<FormattedMessage id="member.lastname" defaultMessage="Last Name" />}
                                    onChange={e => setLastname(e.target.value.trim())}
                                    value={lastname || ""}
                                    fullWidth required
                                    error={Boolean(errors.lastname)}
                                    helperText={isEmpty(errors) || isEmpty(errors.lastname) ? <FormattedMessage id="member.lastnamePrivate" defaultMessage="Your last name is private" /> : <FormattedMessage id={[errors.lastname]} defaultMessage="Required" />}
                                    InputLabelProps={{ shrink: true }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container direction="row" className={classes.gridContainer}>
                            <Grid item >
                                <TextField name="password"
                                    className={classes.fieldShort}
                                    label={<FormattedMessage id="system.password" defaultMessage='Password' />}
                                    required
                                    type={showPassword ? 'text' : 'password'}

                                    value={password || ""}
                                    onChange={e => setPassword(e.target.value.trim())}

                                    error={Boolean(errors.password) && passwordStrengthScore < 2}
                                    helperText={isEmpty(errors.password) ? "" : <FormattedMessage id={[errors.password]} defaultMessage="Required" />}
                                    FormHelperTextProps={{ style: { color: PasswordColor(passwordStrengthScore) } }}

                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        autoComplete: "disabled",
                                        style: { color: PasswordColor(passwordStrengthScore) },
                                        endAdornment: (
                                            <InputAdornment variant="filled" position="end">
                                                <IconButton
                                                    aria-label="Password visibility"
                                                    onClick={handleClickShowPassword}
                                                    size="large"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item >
                                <TextField name="password_confirm"
                                    className={classes.fieldShort}
                                    label={<FormattedMessage id="system.confirm" defaultMessage='Confirm' />}
                                    required
                                    type={showPasswordConfirm ? 'text' : 'password'}

                                    value={passwordConfirm || ""}
                                    onChange={e => setPasswordConfirm(e.target.value.trim())}

                                    error={Boolean(errors.password_confirm)}
                                    helperText={isEmpty(errors.password_confirm) ? "" : <FormattedMessage id={[errors.password_confirm]} defaultMessage="Required" />}

                                    InputLabelProps={{ shrink: true }}
                                    InputProps={{
                                        autoComplete: "disabled",
                                        endAdornment: (
                                            <InputAdornment variant="filled" position="end">
                                                <IconButton
                                                    aria-label="Password visibility"
                                                    onClick={handleClickShowConfirmPassword}
                                                    size="large"
                                                >
                                                    {showPasswordConfirm ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>

                        <Grid container direction="row" className={classes.gridContainer}>
                            {/* <Grid item > */}
                            <TextField name="email" type="email"
                                style={{ margin: 4 }}
                                label={<FormattedMessage id="system.email" defaultMessage="Email" />}
                                required
                                fullWidth
                                onChange={(e) => handleChange(e)}
                                value={user.email || ""}
                                autoComplete="email"

                                error={Boolean(errors.email)}
                                helperText={isEmpty(errors) || isEmpty(errors.email) ? "" : <FormattedMessage id={[errors.email]} defaultMessage="Email error" />}

                                InputLabelProps={{ shrink: true }}
                            />
                            {/* </Grid> */}
                        </Grid>

                        <Grid container direction="row" className={classes.gridContainer}>
                            <TextField select id="gender" name="gender"
                                style={{ margin: 4, marginTop: 8 }}
                                label={<FormattedMessage id="system.gender" defaultMessage="Gender" />}
                                required
                                fullWidth
                                onChange={(e) => handleChange(e)}
                                value={user.gender}
                                InputLabelProps={{ shrink: true }}
                                SelectProps={{
                                    native: true,
                                    MenuProps: { className: classes.menu, },
                                }}

                                error={Boolean(errors.gender)}
                                helperText={isEmpty(errors) || isEmpty(errors.gender) ? "" : <FormattedMessage id={[errors.gender]} defaultMessage="Required" />}
                            >

                                {Genders.map(option => (
                                    <option key={option.value} value={option.value} sx={{ padding: 8 }} >
                                        {option.label}
                                    </option>
                                ))}

                            </TextField>
                        </Grid>

                        <Grid container direction="row" className={classes.gridContainerAge}>

                            {/* Birth Month */}
                            {/* <Grid item sx={{ mb: 1 }}>
                                <Typography style={GeneralText} variant="subtitle2">
                                    {moment(user.birthDate, "YYYYMM").format('YYYY-MM')}
                                </Typography>
                            </Grid> */}

                            {/* <Grid item>
                                <TextField required
                                    className={classes.fieldShort}

                                    format={"yyyy-MM-dd"}
                                    name="birthDate" 
                                    type="date"
                                    style={{ margin: 4, marginTop: 8 }}
                                    label={<FormattedMessage id="profile.birthday" defaultMessage="Birthday" />}

                                    // value={user.birthDate && moment(user.birthDate.toString(), "YYYYMM").format('YYYY-MM')}
                                    value={user.birthDate} // value={'2017-05'}
                                    // value={moment("2017-05-01"?.startDate).format('DD-MMM-YYYY')}
                                    onChange={e => handleDateChange(e)}
                                    autoComplete='bday'
                                    error={Boolean(errors.birthDate)}

                                    // fullWidth
                                    helperText={isEmpty(errors.birthDate) ? "" : <FormattedMessage id={[errors.birthDate]} defaultMessage="Required" />}
                                    InputLabelProps={{ shrink: true, }}
                                />
                            </Grid> */}

                            <Grid item style={{ float: 'left' }}>
                                <TextField required
                                    className={classes.ageField}
                                    label={<FormattedMessage id="system.age" defaultMessage="Age" />}
                                    name="age"
                                    type="number"

                                    value={user.age}
                                    onChange={e => handleBirthDate(e)}
                                    error={Boolean(errors.birthDate)}

                                    helperText={isEmpty(errors.birthDate) ? "" : <FormattedMessage id={[errors.birthDate]} defaultMessage="Required" />}
                                    InputLabelProps={{ shrink: true, }}
                                />
                            </Grid>

                            <Grid item direction="column" sx={{ mt: 1, display: 'flex', flexGrow: 1, flexFlow: 'column' }}>
                                <Typography className={classes.ageMonthYearBornDesc} variant='body1'>
                                    <FormattedMessage id="system.birthMonth" defaultMessage="Birth Month and Year" />
                                </Typography>

                                <Typography className={classes.ageMonthYearBorn} variant='body1'>
                                    {intl.formatDate(getYearBornFromAge(user.age), { month: "short", year: "numeric", timeZone: 'UTC' })}
                                </Typography>
                            </Grid>

                            {/* https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md */}
                            {/* <DatePicker style={{ fontSize: 14 }}
                                focusSelectedMonth
                                forceShowMonthNavigation

                                // maxDate
                                // minDate 

                                showYearDropdown
                                selected={user.birthDate}
                                onChange={date => handleDateChange(date)}
                            /> */}

                            {/* <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils} locale={locale}>
                                <DatePicker
                                    style={{ margin: '0px 8px' }}
                                    disableFuture
                                    required
                                    fullWidth
                                    label={<FormattedMessage id="member.birthDate" defaultMessage="Birth Month" />}
                                    openTo="year"
                                    views={["year", "month"]}
                                    value={user.birthDate}
                                    onChange={date => handleDateChange(date)}
                                    helperText={isEmpty(errors) || isEmpty(errors.birthDate) ? "" : <FormattedMessage id={[errors.birthDate]} defaultMessage="Required" />}
                                />
                            </MuiPickersUtilsProvider> */}

                        </Grid>

                        {/* <Grid container direction="row" className={classes.gridContainer}>
                            <Autocomplete id="country" name="country"
                                style={{ marginRight: 6 }}
                                selectOnFocus
                                size='small'
                                fullWidth
                                options={Countries}
                                onChange={(e, value, reason) => onCountryChange(e, value, reason)}

                                classes={{ option: classes.option, }}

                                autoHighlight
                                getOptionLabel={option => option.label}

                                renderOption={option => (
                                    <React.Fragment>
                                        {option.label} ({option.code})
                                    </React.Fragment>
                                )}

                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={<FormattedMessage id="system.country" defaultMessage="Country" />}

                                        inputProps={{
                                            ...params.inputProps,
                                            autoComplete: 'new-password', // disable autocomplete and autofill
                                        }}

                                        error={Boolean(errors.country)}
                                        helperText={isEmpty(errors.country) ? "" : <FormattedMessage id={[errors.country]} defaultMessage="Required" />}
                                    />
                                )}
                            />
                        </Grid> */}

                        {/* Hiding switch on password helper text because we need the room on mobile */}
                        <Grid container spacing={0} className={classes.gridContainer}
                            style={{ marginLeft: 4 }}
                        >
                            {/* <FormControlLabel classes={{ label: classes.agreeLabel }} */}
                            <FormControlLabel classes={{ label: classes.agreeLabel }} className={classes.agreeRoot}
                                control={
                                    <Switch
                                        checked={agreeCodeOfConduct}
                                        onChange={(e) => handleCodeOfConduct(e)}
                                        color="primary"
                                    />
                                }
                                label={<FormattedMessage id="navbar.youAgree" defaultMessage="You agree to our Code Of Conduct, Cookie Policy, Terms of Use, and consent to our Privacy Policy" />}
                            />
                        </Grid>

                        <Grid container sx={{ justifyContent: 'center', alignItems: 'center', flexGrow: 1 }}>
                            <Button variant="contained" className={classes.buttonSignUp}
                                color="primary"
                                disabled={incomplete || isRegistering || !agreeCodeOfConduct}
                                type="submit"
                            >
                                {isRegistering &&
                                    <CircularProgress size={18} color={"primary"} className={classes.progress} variant="indeterminate" />
                                }
                                <FormattedMessage id="system.signUp" defaultMessage="Sign Up" />
                            </Button>
                        </Grid>
                    </form>

                    {onPolicies()}
                </Paper>
            </LayoutMax>
        </Div100vh>
    )
}



// Verify that indeed the user has control over the email address he claims to be his.
// Please click this link to activate your account:
// http://www.yourwebsite.com/verify.php?email='.$email.'&hash='.$hash.'



