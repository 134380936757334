import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTheme } from '@mui/material/styles'
import isEmpty from '../validation/is-empty'

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

import Gallery from "../helpers/Gallery"
import UploadImage from "./UploadImage"
import ViewLikes from "./ViewLikes"

import FTF_Images from './FTF_Images'
import { JellyAnimation } from '../helpers/utilities'

import { FabButtonStyle, LayoutWithoutHeader, PageHeading } from "../styles/ftfStyles"

import { Fab, Typography } from '@mui/material'

import AddAPhotoIcon from '@mui/icons-material/AddAPhoto'

import { FormattedMessage } from 'react-intl'

import { runGetPhotos, getPhotoLikes } from '../actions/image.actions'

import { css } from 'styled-components'
import BeatLoader from 'react-spinners/BeatLoader'

const cssBeatLoader = css`
    display: block
    margin: 0 auto
    border-color: red
`

/* Note: Hardcoded Image limit of 12 */
const PHOTO_LIMIT = 12


// const useStyles = makeStyles(theme => ({
//     headerRegion: {
//         display: 'flex',
//         overflow: 'hidden',
//         whiteSpace: 'nowrap',
//         height: 36,

//         // Phone Landscape
//         [theme.breakpoints.down('sm')]: {
//             height: 34,
//         },

//         // Phone Portrait
//         [theme.breakpoints.down('xs')]: {
//             height: 34,
//         },
//     },
// }))


export default function WallPage(props) {
    // const classes = useStyles(props)
    const wall = useRef(null)
    const theme = useTheme()

    const userId = useSelector(state => state.profile.profile._id)
    const isWallLoaded = useSelector(state => state.image.isWallLoaded)
    const photos = useSelector(state => state.image.photos)
    // const appHeight = useSelector(state => state.app.height)

    const isLoading = useSelector(state => state.image.isLoading)

    // Only after useSelector(s) for now... - DSW
    const dispatch = useDispatch()

    const [wallHeight, setWallHeight] = useState(500)
    const [showUploadImageButton, setShowUploadImageButton] = useState(true)
    const [showUploadImage, setShowUploadImage] = useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null)
    const [viewLikes, setShowLikes] = useState(false)
    // const [editObject, setEditObject] = useState(null)


    // Upload Image Dialog
    const showUploadImageDialog = () => {
        setShowUploadImage(true)
    }

    const handleUploadImageClose = (e, reason) => {
        if (reason === 'backdropClick')
            return

        setShowUploadImage(false)
    }

    // Initializes 'photos' on first load...
    useEffect(() => {
        if (!isWallLoaded)
            dispatch(runGetPhotos())

        if (wall.current)
            setWallHeight(wall.current.clientHeight)
    }, [isWallLoaded, dispatch])


    //-------------------------------------------
    // Show Likes
    //-------------------------------------------
    const onShowLikes = (e, photoId) => {
        dispatch(getPhotoLikes('user', null, photoId))
        setAnchorEl(e.currentTarget)
        setShowLikes(true)
    }

    //-------------------------------------------
    // onShowUploadImageButton
    //-------------------------------------------
    function onShowUploadImageButton(value) {
        setShowUploadImageButton(value)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }


    const masonryBreakpoints = {
        default: 3,
        1100: 2,
        999: 2,
        800: 2,
        700: 1,
        500: 1,
    }


    return (
        <Div100vh>
            {/* <Grid container direction="row" alignItems="flex-start" className={classes.headerRegion}>
                <Typography style={PageHeading} variant="h5">{<FormattedMessage id="navbar.wall" defaultMessage="Photos" />}</Typography>
            </Grid> */}

            <div id='spinner' style={{ zIndex: 9100, boxSizing: "content-box", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <BeatLoader css={cssBeatLoader} sizeUnit={"px"} size={32} color={theme.palette.primary.dark} loading={isLoading} />
            </div>


            {/* Handle "No Photos" */}
            {isEmpty(photos) && !isLoading &&
                <div>
                    < Typography style={PageHeading} variant="subtitle2">{<FormattedMessage id="image.startAdding" defaultMessage="Start adding photos..." />}</Typography>

                    <img src={FTF_Images.CameraX} style={{ maxWidth: '50%', display: 'block', marginLeft: 'auto', marginRight: 'auto', opacity: 0.20 }} />
                </div>
            }


            <LayoutWithoutHeader ref={wall} style={{ transform: 'translateZ(0)' }}>
                {photos && userId && <Gallery
                    showUploadImage={onShowUploadImageButton}

                    masonryBreakpoints={masonryBreakpoints}

                    allowChanges

                    fullscreen={false}
                    height={wallHeight}

                    id={userId}
                    idType="user"

                    showHeader={false}
                    viewLikes={onShowLikes}

                    images={photos.map(({ _id, createdOn, avatarUrl, headline, firstname, gender, likes, url }) => ({
                        createdOn,
                        avatarUrl,
                        headline,

                        widgetId: _id,

                        isMemberAnAdmin: true,
                        ownerId: userId,

                        likes,
                        src: url,
                        original: url,

                        firstname,
                        gender,

                        // sizes : '(max-width: 100%) 100vw, 1000px',
                        // original: `${PREFIX_URL}image_set_default.jpg`,
                        // thumbnail: `${PREFIX_URL}image_set_thumb.jpg`,
                        // srcSet: `${PREFIX_URL}image_set_cropped.jpg 250w,${PREFIX_URL}image_set_cropped.jpg 500w,${PREFIX_URL}image_set_cropped.jpg 1000w`,
                    }))}
                />}
            </LayoutWithoutHeader>


            {/* FYI: ToolTip - Disabled - Wrapped - Gets lost (at bottom) */}
            {/* && <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="left" title={<FormattedMessage id="image.addPhoto" defaultMessage="Add Photo" />}> */}
            {showUploadImageButton && <Fab color="primary" size="small" variant="circular" style={FabButtonStyle} disabled={photos.length >= PHOTO_LIMIT} onClick={showUploadImageDialog}>
                <JellyAnimation>
                    <AddAPhotoIcon aria-label='Add Photo' />
                </JellyAnimation>
            </Fab>}

            {viewLikes && anchorEl && <ViewLikes anchorEl={anchorEl} handleClose={handleClose} />}

            {(photos.length < PHOTO_LIMIT && showUploadImage) &&
                <UploadImage
                    autoClose
                    id={null}
                    icon={<AddAPhotoIcon />}
                    imageRequired
                    fullscreen
                    onClose={handleUploadImageClose}
                    show={showUploadImage}
                    showComment
                    title={<FormattedMessage id="image.addPhoto" defaultMessage="Add Photo" />}
                    variant='wall'
                />}
        </Div100vh>
    )
}

