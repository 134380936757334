// auth.Reducer.js
import moment from 'moment';
import isEmpty from '../validation/is-empty';

import { CONNECTION_ERROR, CLEAR_ERRORS } from '../actions/error.actions';
import { PROFILE_GET_SUCCESS } from '../actions/profile.actions';
import { AUTH_ERROR, AUTH_CLEAR_ERROR, AUTH_CLEAR_ALL_ERRORS, REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILED } from '../actions/auth.actions';
import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILED, LOGOUT } from '../actions/auth.actions';
import { AUTH_UPDATE } from '../actions/auth.actions';
import { VALIDATE_SECURITY_PHRASE_REQUEST, VALIDATE_SECURITY_PHRASE_SUCCESS, VALIDATE_SECURITY_PHRASE_FAILED } from '../actions/auth.actions';
import { VALIDATE_NEW_PASSWORD_REQUEST, VALIDATE_NEW_PASSWORD_SUCCESS, VALIDATE_NEW_PASSWORD_FAILED } from '../actions/auth.actions';
import { FORGOT_PASSWORD_SEND_CODE_REQUEST, FORGOT_PASSWORD_SEND_CODE_SUCCESS, FORGOT_PASSWORD_SEND_CODE_FAILED } from '../actions/auth.actions';


const initialState = {
    forgotEmail: '',
    forgotSecurityPhrase: '',
    emailMask: '',
    firstname: '',

    isStealthMode: false,    // Yes: Development Stealth Mode

    isAuthenticated: false,
    isAuthenticating: false,

    isRegistered: false,
    isRegistering: false,

    isVidVerified: false,

    isNewPasswordRequired: false,

    errors: {},
    receivedAt: moment().format('lll SSS'),
}

export default function (state = initialState, action) {

    switch (action.type) {
        case AUTH_UPDATE:
            return { ...action.auth }  // REVIEW: DSW

        //--------------------------------------

        case FORGOT_PASSWORD_SEND_CODE_REQUEST:
            return {
                ...state,
                // errors: {},
                firstname: "",
                emailMask: "",
                forgotTime: new Date(),
                receivedAt: moment().format('lll SSS'),
            }

        case FORGOT_PASSWORD_SEND_CODE_SUCCESS:
            return {
                ...state,
                firstname: action.data.firstname,
                emailMask: action.data.emailMask,
                forgotTime: new Date(),
                // errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case FORGOT_PASSWORD_SEND_CODE_FAILED:
            return {
                ...state,
                firstname: "",
                emailMask: "",
                errors: action.errors,
                forgotTime: new Date(),
                receivedAt: moment().format('lll SSS'),
            }

        //---------------------------------------

        case VALIDATE_SECURITY_PHRASE_REQUEST:
            return {
                ...state,
                errors: {},
                isNewPasswordRequired: false,
                // isAuthenticated: false,
                isAuthenticating: true,
                receivedAt: moment().format('lll SSS'),
            }

        case VALIDATE_SECURITY_PHRASE_SUCCESS:
            return {
                ...state,
                isNewPasswordRequired: true,
                // isAuthenticated: true,
                isAuthenticating: false,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case VALIDATE_SECURITY_PHRASE_FAILED:
            return {
                ...state,
                isNewPasswordRequired: false,
                // isAuthenticated: false,
                isAuthenticating: false,
                errors: action.errors,
                receivedAt: moment().format('lll SSS'),
            }

        //---------------------------------------

        case VALIDATE_NEW_PASSWORD_REQUEST:
            return {
                ...state,
                errors: {},
                isNewPasswordRequired: true,
                isAuthenticated: false,
                isAuthenticating: true,
                receivedAt: moment().format('lll SSS'),
            }

        case VALIDATE_NEW_PASSWORD_SUCCESS:
            // Note: isAuthenticated is set in PROFILE_GET_SUCCESS
            return {
                ...state,
                isNewPasswordRequired: false,
                isAuthenticating: false,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case VALIDATE_NEW_PASSWORD_FAILED:
            return {
                ...state,
                isNewPasswordRequired: true,
                isAuthenticated: false,
                isAuthenticating: false,
                errors: action.errors,
                receivedAt: moment().format('lll SSS'),
            }

        //--------------------------------------

        case PROFILE_GET_SUCCESS:
            return {
                ...state,
                isAuthenticated: !isEmpty(action.profile),
                isAuthenticating: false,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        //--------------------------------------
        case AUTH_ERROR:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                errors: { ...state.errors, ...action.error },
            }

        case AUTH_CLEAR_ALL_ERRORS:
            return {
                ...state,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case AUTH_CLEAR_ERROR:
            delete state.errors[action.propName]

            return {
                ...state,
                errors: { ...state.errors },
                receivedAt: moment().format('lll SSS'),
            }

        //--------------------------------------

        case REGISTER_REQUEST:
            return {
                ...state,
                isRegistered: false,
                isRegistering: true,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case REGISTER_SUCCESS:
            return {
                ...state,
                isRegistered: true,
                isRegistering: false,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case REGISTER_FAILED:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isRegistered: false,
                isRegistering: false,
                errors: action.errors,
            }

        //--------------------------------------
        case LOGIN_REQUEST:
            return {
                ...state,
                isAuthenticating: true,
                isStealthMode: false,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case LOGIN_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                isAuthenticating: false,
                isStealthMode: false,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case LOGIN_FAILED:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isAuthenticating: false,
                isStealthMode: false,    // Yes: Development Stealth Mode
                errors: action.errors,
            }

        case LOGOUT:
            return { ...initialState }


        case CONNECTION_ERROR:
            return {
                ...state,
                isAuthenticating: false,
                isStealthMode: false,    // Yes: Development Stealth Mode
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    connection: action.errors,
                }
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                errors: {}
            }

        default:
            return state;
    }
}