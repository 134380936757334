import moment from 'moment';

import { LOGOUT } from '../actions/auth.actions'

import { MEMBER_GET_REQUEST, MEMBER_GET_SUCCESS, MEMBER_GET_FAILED } from '../actions/member.actions';
import { SUPPORT_GET_REQUEST, SUPPORT_GET_SUCCESS, SUPPORT_GET_FAILED } from '../actions/member.actions';

import { MEMBER_ADDFRIEND_REQUEST, MEMBER_ADDFRIEND_SUCCESS, MEMBER_ADDFRIEND_FAILED } from '../actions/member.actions';
import { MEMBER_GETFRIENDS_REQUEST, MEMBER_GETFRIENDS_SUCCESS, MEMBER_GETFRIENDS_FAILED } from '../actions/member.actions';

import { MEMBER_LIKEPHOTO_REQUEST, MEMBER_LIKEPHOTO_SUCCESS, MEMBER_LIKEPHOTO_FAILED } from '../actions/member.actions';

import { MEMBER_BLOCK_REQUEST, MEMBER_BLOCK_SUCCESS, MEMBER_BLOCK_FAILED } from '../actions/member.actions';

// import isEmpty from '../validation/is-empty';

const initialState = {
    isLoading: false,
    memberId: {},
    member: {},
    events: [],
    friends: [],
    error: {},
    receivedAt: moment().format('lll SSS'),
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return { ...initialState }

        case SUPPORT_GET_REQUEST:
            return {
                ...state,
                error: {},
                isLoading: true,

                member: state.memberId === action.memberId ? state.member : {},
                memberId: action.memberId,
                receivedAt: moment().format('lll SSS'),
            };

        case SUPPORT_GET_SUCCESS:
            return {
                ...state,
                error: {},
                isLoading: false,

                member: action.member,
                receivedAt: moment().format('lll SSS'),
            };

        case SUPPORT_GET_FAILED:
            return {
                ...state,
                error: action.errors,
                isLoading: false,

                // member: state.memberId === action.memberId ? state.member : {},
                receivedAt: moment().format('lll SSS'),
            };



        case MEMBER_GET_REQUEST:
            return {
                ...state,
                error: {},
                isLoading: true,

                member: state.memberId === action.memberId ? state.member : {},
                memberId: action.memberId,
                receivedAt: moment().format('lll SSS'),
            };

        case MEMBER_GET_SUCCESS:
            return {
                ...state,
                error: {},
                isLoading: false,
                member: action.member,
                receivedAt: moment().format('lll SSS'),
            };

        case MEMBER_GET_FAILED:
            return {
                ...state,
                error: action.errors,
                isLoading: false,
                member: state.memberId === action.memberId ? state.member : {},
                receivedAt: moment().format('lll SSS'),
            };



        case MEMBER_ADDFRIEND_REQUEST:
            return {
                ...state,
                error: {},
                isLoading: true,
                receivedAt: moment().format('lll SSS'),
            };

        case MEMBER_ADDFRIEND_SUCCESS:
            return {
                ...state,
                error: {},
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
            };

        case MEMBER_ADDFRIEND_FAILED:
            return {
                ...state,
                error: action.errors,
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
            };



        case MEMBER_GETFRIENDS_REQUEST:
            return {
                ...state,
                error: {},
                isLoading: true,
                receivedAt: moment().format('lll SSS'),
            };

        case MEMBER_GETFRIENDS_SUCCESS:
            return {
                ...state,
                error: {},
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
                friends: action.members,
            };

        case MEMBER_GETFRIENDS_FAILED:
            return {
                ...state,
                error: action.errors,
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
            };



        case MEMBER_LIKEPHOTO_REQUEST:
            return {
                ...state,
                error: {},
                isLoading: true,
                receivedAt: moment().format('lll SSS'),
            };

        case MEMBER_LIKEPHOTO_SUCCESS:
            return {
                ...state,
                error: {},
                isLoading: false,
                receivedAt: moment().format('lll SSS'),

            };

        case MEMBER_LIKEPHOTO_FAILED:
            return {
                ...state,
                error: action.errors,
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
            };


        case MEMBER_BLOCK_REQUEST:
            return {
                ...state,
                error: {},
                isLoading: true,
                receivedAt: moment().format('lll SSS'),
            };

        case MEMBER_BLOCK_SUCCESS:
            // remove deleted user from state
            return {
                ...state,
                error: {},
                // blocked: state.blocked.filter(user => user.id !== action.id),
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
            };

        case MEMBER_BLOCK_FAILED:
            // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
            return {
                ...state,
                error: action.errors,
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
                // blocked: state.blocked.map(user => {
                //   if (user.id === action.id) {
                //     // make copy of user without 'deleting:true' property
                //     const { deleting, ...userCopy } = user;
                //     // return copy of user with 'deleteError:[error]' property
                //     return { ...userCopy, deleteError: action.error };
                //   }

                //   return user;
                // })
            };

        default:
            return state
    }
}