// https://www.twilio.com/blog/2017/10/implement-chat-javascript-nodejs-react-apps.html
// https://blogs.msdn.microsoft.com/cdndevs/2014/09/19/a-chatroom-for-all-part-3-building-a-backend-with-node-mongo-and-socket-io/

// https://redux-form.com/7.4.2/examples/material-ui/

// https://appdividend.com/2018/07/18/react-redux-node-mongodb-jwt-authentication/

// https://daveceddia.com/react-redux-immutability-guide/#redux-update-an-object-in-an-object
// https://www.npmjs.com/package/immutable

import React, { useState } from "react"
import { useSelector } from "react-redux"
import { makeStyles } from '@mui/styles'
import isEmpty from '../validation/is-empty'

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

import { Fab } from '@mui/material'
// import { FormattedMessage } from 'react-intl'

import { JellyAnimation } from '../helpers/utilities'

import { FabButtonStyle, LayoutWithoutHeader } from "../styles/ftfStyles"

// Chats...
import ChatsPageConv from './ChatsPageConv'
import ChatsPageMsgs from './ChatsPageMsgs'

// import { alpha } from '@material-ui/core/styles

import Reply from '@mui/icons-material/Reply'

import SendMessage2 from './SendMessage2'
import { config, useSpring, animated } from "react-spring"

const useStyles = makeStyles(theme => ({
    content: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignSelf: 'flex-start',

        width: "100%",
        overflow: 'hidden',

        height: `calc(100vh - ${theme.toolbarHeight})`,

        // Phone Landscape
        [theme.breakpoints.down('md')]: {
            height: `calc(100vh - ${theme.toolbarHeight})`,
        },

        // Phone Portrait
        [theme.breakpoints.down('sm')]: {
            height: `calc(100vh - ${theme.toolbarHeight})`,
        },

        // Promote the list into his own layer on Chrome. 
        // This cost memory but helps keeping high FPS.
        transform: "translateZ(0)"
    },

    leftPanel: {
        flex: '1 80%',

        // maxWidth: 350,
        // minWidth: 125,

        overflowY: 'auto',
        overflowX: 'auto',  // hidden
        padding: 2,
    },

    rightPanel: {
        flex: '2 100%',

        overflowY: 'auto',
        overflowX: 'hidden',

        padding: "2px 0 2px 2px",
    },
}))


export default function ChatsPage(props) {
    const classes = useStyles(props)

    const isProfileVisible = useSelector(state => state.profile.profile.isVisible)

    const chatList = useSelector(state => state.chat)
    const [selectedChat, setSelectedChat] = useState({ notify: { allowChat: true } })
    const [showSendMessage, setShowSendMessage] = useState(false)

    function onReply() {
        setShowSendMessage(true)
    }

    function handleCloseSendMessage() {
        setShowSendMessage(false)
    }

    function handleConvClick(chat) {
        setSelectedChat(chat)

        // Get Member???
    }

    // useSpring to fade in/out message editor
    // https://codesandbox.io/s/0-intro-p8fsf?file=/src/App.js:84-91
    const fadeStyles = useSpring({
        config: { ...config.wobbly },
        from: { opacity: 0 },
        to: { opacity: showSendMessage ? 1 : 0 }
    })

    //===========================================
    // Chat
    //===========================================
    return (
        <Div100vh>
            <LayoutWithoutHeader>
                <div id='ChatsPage' className={classes.content}>
                    <div className={classes.leftPanel}>
                        <ChatsPageConv history={props.history} handleConvClick={handleConvClick} selectedChat={selectedChat} />
                    </div>

                    <div className={classes.rightPanel}>
                        <ChatsPageMsgs />
                    </div>
                </div >

                {showSendMessage &&
                    <animated.div style={fadeStyles} >
                        {/* Old - <SendMessage isSupportMsg={false} chat={selectedChat} show={showSendMessage} fullscreen={false} onClose={handleCloseSendMessage} /> */}
                        <SendMessage2 isSupportMsg={false} chat={selectedChat} onClose={handleCloseSendMessage} />
                    </animated.div>}

                {/* <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="system.reply" defaultMessage="Reply" />}> */}
                {/* TODO: A 'div' or 'span' here dislocates the ToolTip */}
                {!showSendMessage &&
                    <Fab disabled={!isProfileVisible || isEmpty(chatList.chats) || !selectedChat.notify.allowChat} color="primary" size="small" variant="circular" style={FabButtonStyle} onClick={onReply}>
                        <JellyAnimation>
                            <Reply aria-label='Reply' />
                        </JellyAnimation>
                    </Fab>}
            </LayoutWithoutHeader>
        </Div100vh>
    )
}
