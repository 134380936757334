// alert.Reducer.js
import moment from 'moment';
import { CONNECTION_ERROR } from '../actions/error.actions';
import { ALERT_SUCCESS, ALERT_ERROR, ALERT_CLEAR } from '../actions/alert.actions';

const initialState = {
  connectionMessage: {},

  alertType: {},
  alertMessage: {},
  receivedAt: moment().format('lll SSS'),
}

export function alertReducer(state = initialState, action) {
  switch (action.type) {

    case CONNECTION_ERROR:
      return {
        ...state,
        connectionMessage: action.message
      };

    // case ALERT_CLEAR_ERRORS:
    //   return {
    //     ...state,
    //     connectionMessage: {}
    //   };



    case ALERT_SUCCESS:
      return {
        ...state,
        alertType: 'alert-success',
        alertMessage: action.message
      };

    case ALERT_ERROR:
      return {
        ...state,
        alertType: 'alert-danger',
        alertMessage: action.message
      };

    case ALERT_CLEAR:
      return {
        ...state,
        alertType: {},
        alertMessage: {},
      };
      
    default:
      return state
  }
}