// chat.actions.js
import axios from 'axios'
import { setAuthToken } from '../setAuthToken'
import { logoutUser } from './auth.actions'
import { CONNECTION_ERROR } from '../actions/error.actions'
import { getProfile } from '../actions/profile.actions'
import isEmpty from '../validation/is-empty'
import { gaFireEvent } from '../helpers/utilities'

const moment = require('moment')

export const CHAT_GETLIST_REQUEST = 'CHAT_GETLIST_REQUEST'
export const CHAT_GETLIST_SUCCESS = 'CHAT_GETLIST_SUCCESS'
export const CHAT_GETLIST_FAILED = 'CHAT_GETLIST_FAILED'

export const CHAT_DELETE_REQUEST = 'CHAT_DELETE_REQUEST'
export const CHAT_DELETE_SUCCESS = 'CHAT_DELETE_SUCCESS'
export const CHAT_DELETE_FAILED = 'CHAT_DELETE_FAILED'

export const CHAT_SEND_REQUEST = 'CHAT_SEND_REQUEST'
export const CHAT_SEND_SUCCESS = 'CHAT_SEND_SUCCESS'
export const CHAT_SEND_FAILED = 'CHAT_SEND_FAILED'

export const CHAT_GET_MSGS_REQUEST = 'CHAT_GET_MSGS_REQUEST'
export const CHAT_GET_MSGS_SUCCESS = 'CHAT_GET_MSGS_SUCCESS'
export const CHAT_GET_MSGS_FAILED = 'CHAT_GET_MSGS_FAILED'


//-----------------------------------------------
// Entry point for Send Message...
//-----------------------------------------------
// @param {String} msgType
// @param {id} to memberId
// @param {id} chatId
// @param {String} msg
// @param {String} msgUrl
// @return {Boolean}
export function chatSendMessage(msgType, memberId, chatId, msg, msgUrl) {
    return function (dispatch, getState) {
        if (shouldSendMessage(getState())) {
            dispatch(runSendMessage(msgType, memberId, chatId, msg, msgUrl, moment.utc()))
        }
    }
}


function shouldSendMessage(state) {
    if (state.chat.isSending) {
        return false
    } else {
        return true   // chat.didInvalidate
    }
}


const runSendMessage = (msgType, memberId, chatId, msg, msgUrl, msgSent) => dispatch => {
    gaFireEvent( 'share', {
        method: 'video request',
        content_type: 'video',
        item_id: chatId,
    })

    dispatch(start())

    axios.post('/api/chat/msg', { params: { msgType: msgType, memberId: memberId, chatId: chatId, msg: msg, msgUrl: msgUrl, msgSent: msgSent } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .then(() => Promise.all([
            dispatch(chatGetConvs(chatId)),
            dispatch(chatGetMessages(chatId))
        ])
        )
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: CHAT_SEND_REQUEST } }
    function failed(error) { return { type: CHAT_SEND_FAILED, error } }
    function success(json) { return { type: CHAT_SEND_SUCCESS } }
}

//-----------------------------------------------
// Entry point for chatGetConvs...
//-----------------------------------------------
export function chatGetConvs() {
    return function (dispatch, getState) {
        if (shouldGetConvs(getState())) {
            dispatch(runGetConvs(getState()))
        }
    }
}

function shouldGetConvs(state) {
    if (state.chat.isLoading) {
        return false
    } else {
        return true   // chat.didInvalidate
    }
}

const runGetConvs = () => dispatch => {
    dispatch(start())

    axios.get('/api/chat/chats')
        .then(response => {
            if (response.status === 200) {
                const { token, chats } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(chats)) // Note: May be empty array

                dispatch(getProfile())  // Badge (hasMessages)

                if (!isEmpty(chats))
                    dispatch(chatGetMessages(chats[0].chatId))

            }
            else // Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: CHAT_GETLIST_REQUEST, } }
    function success(chats) { return { type: CHAT_GETLIST_SUCCESS, chats } }
    function failed(errors) { return { type: CHAT_GETLIST_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for chatGetMessages...
//-----------------------------------------------
export const chatGetMessages = (chatId) => dispatch => {
    gaFireEvent( 'share', {
        method: 'get messages',
        content_type: 'text',
        item_id: chatId,
    })

    dispatch(start(chatId))

    axios.get('/api/chat/msgs', { params: { chatId: chatId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success(response.data.messages))
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(chatId) { return { type: CHAT_GET_MSGS_REQUEST, chatId } }
    function success(messages) { return { type: CHAT_GET_MSGS_SUCCESS, messages } }
    function failed(errors) { return { type: CHAT_GET_MSGS_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for chatDelete...
// 
// https://stackoverflow.com/questions/51069552/axios-delete-request-with-body-and-headers
// https://appdividend.com/2018/08/30/getting-started-with-axios-tutorial-example/
// https://medium.com/@njwest/building-a-react-native-jwt-client-api-requests-and-asyncstorage-d1a20ab60cf4
//-----------------------------------------------
export const chatDelete = (chatId) => dispatch => {
    gaFireEvent( 'share', {
        method: 'delete message',
        content_type: 'text',
        item_id: chatId,
    })

    dispatch(start())

    // axios.delete('/api/chat/delete', { params: { id: chatId }})  getAuthHeader
    axios.delete('/api/chat/delete', { params: { chatId: chatId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })
        .then(() => Promise.all([
            dispatch(chatGetConvs())
        ])
        )

    function start() { return { type: CHAT_DELETE_REQUEST } }
    function success() { return { type: CHAT_DELETE_SUCCESS } }
    function failed(errors) { return { type: CHAT_DELETE_FAILED, errors } }
}
