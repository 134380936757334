// authentication.js (original)
// https://appdividend.com/2018/07/18/react-redux-node-mongodb-jwt-authentication/

import axios from 'axios';
import { setAuthToken } from '../setAuthToken';
// import jwt_decode from 'jwt-decode';

// import store from '../store';
// import configureSocket from '../_components/socket';

// import isEmpty from '../validation/is-empty'

import { gaFireEvent } from '../helpers/utilities'

import { CONNECTION_ERROR } from '../actions/error.actions';
import { PROFILE_GET_SUCCESS } from '../actions/profile.actions';

import { socketConnect } from '../actions/socket.actions';

export const AUTH_UPDATE = 'AUTH_UPDATE'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_CLEAR_ERROR = 'AUTH_CLEAR_ERROR'
export const AUTH_CLEAR_ALL_ERRORS = 'AUTH_CLEAR_ALL_ERRORS'

export const REGISTER_REQUEST = 'REGISTER_REQUEST'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILED = 'REGISTER_FAILED'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGOUT = 'LOGOUT'

export const FORGOT_PASSWORD_EMAIL_NOT_FOUND = 'FORGOT_PASSWORD_EMAIL_NOT_FOUND'

export const VALIDATE_SECURITY_PHRASE_REQUEST = 'VALIDATE_SECURITY_PHRASE_REQUEST'
export const VALIDATE_SECURITY_PHRASE_SUCCESS = 'VALIDATE_SECURITY_PHRASE_SUCCESS'
export const VALIDATE_SECURITY_PHRASE_FAILED = 'VALIDATE_SECURITY_PHRASE_FAILED'

export const VALIDATE_NEW_PASSWORD_REQUEST = 'VALIDATE_NEW_PASSWORD_REQUEST'
export const VALIDATE_NEW_PASSWORD_SUCCESS = 'VALIDATE_NEW_PASSWORD_SUCCESS'
export const VALIDATE_NEW_PASSWORD_FAILED = 'VALIDATE_NEW_PASSWORD_FAILED'

// *Note: Move to users - DSW
export const FORGOT_PASSWORD_SEND_CODE_REQUEST = 'FORGOT_PASSWORD_SEND_CODE_REQUEST'
export const FORGOT_PASSWORD_SEND_CODE_SUCCESS = 'FORGOT_PASSWORD_SEND_CODE_SUCCESS'
export const FORGOT_PASSWORD_SEND_CODE_FAILED = 'FORGOT_PASSWORD_SEND_CODE_FAILED'

export const USERS_GETALL_REQUEST = 'USERS_GETALL_REQUEST'
export const USERS_GETALL_SUCCESS = 'USERS_GETALL_SUCCESS'
export const USERS_GETALL_FAILED = 'USERS_GETALL_FAILED'

// export const USERS_DELETE_REQUEST = 'USERS_DELETE_REQUEST'
// export const USERS_DELETE_SUCCESS = 'USERS_DELETE_SUCCESS'
// export const USERS_DELETE_FAILED = 'USERS_DELETE_FAILED'


//-----------------------------------------------
// authUpdate -- TODO: This doesn't do anything? Review - DSW
//-----------------------------------------------
export function authUpdate(auth) {
    return (dispatch, getState) => {
        dispatch(auth_Update(auth));
    };
}
function auth_Update(auth) { return { type: AUTH_UPDATE, auth } }


//-----------------------------------------------
// Adds an entry error to auth.errors.nnnnnn
//-----------------------------------------------
// @param: error  property and value
export function authError(error) {
    return (dispatch, getState) => {
        dispatch(auth_Error(error))
    }
}
function auth_Error(error) { return { type: AUTH_ERROR, error } }


//-----------------------------------------------
// Adds an entry error to auth.errors.nnnnnn
//-----------------------------------------------
// @param: error  property and value
export function authClearError(propName) {
    return (dispatch, getState) => {
        dispatch(auth_ClearError(propName))
    }
}
function auth_ClearError(propName) { return { type: AUTH_CLEAR_ERROR, propName } }


//-----------------------------------------------
// Clears all auth.errors
//-----------------------------------------------
export function authClearAllErrors() {
    return (dispatch, getState) => {
        dispatch(auth_ClearAllErrors())
    }
}
function auth_ClearAllErrors() { return { type: AUTH_CLEAR_ALL_ERRORS } }


//-----------------------------------------------
// validateSecurityPhrase
// Success: Same as a Login
//----------------------------------------------
export const validateSecurityPhrase = (email, securityPhrase, navigate) => dispatch => {
    gaFireEvent('login', {
        method: 'security',
        label: 'validateSecurityPhrase',
        // email: email,
    })

    dispatch(start())

    axios.post('/api/users/validatesp', { email: email, securityPhrase: securityPhrase })
        .then(response => {
            if (response.status === 200) {
                const { token, profile } = response.data;
                setAuthToken(token);  // update token     
                dispatch(setCurrentUser(profile));
                dispatch(socketConnect(profile._id))
                dispatch(success())
            }
            else { // 206 - Validation Error(s)
                dispatch(failed(response.data))
            }
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: VALIDATE_SECURITY_PHRASE_REQUEST } }
    function success() { return { type: VALIDATE_SECURITY_PHRASE_SUCCESS } }
    function failed(errors) { return { type: VALIDATE_SECURITY_PHRASE_FAILED, errors } }
}


//-----------------------------------------------
// sendVidByEmail
//-----------------------------------------------
export const sendVidByEmail = (email, navigate) => dispatch => {
    gaFireEvent('login', {
        method: 'security',
        label: 'sendVidByEmail',
        // email: email,
    })

    // console.log('sendVidByEmail:', this)
    dispatch(start(email))

    axios.post('/api/users/validateemail', { email: email })
        .then(response => {
            if (response.status === 200) {
                dispatch(success(response.data))
            }
            else { // 206 - Validation Error(s)
                dispatch(failed(response.data))
            }
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })
        })

    function start() { return { type: FORGOT_PASSWORD_SEND_CODE_REQUEST, email } }
    function success(data) { return { type: FORGOT_PASSWORD_SEND_CODE_SUCCESS, data } }
    function failed(errors) { return { type: FORGOT_PASSWORD_SEND_CODE_FAILED, errors } }
}


//-----------------------------------------------
// validateNewPassword
// Best practices...
//----------------------------------------------
export const validateNewPassword = (email, vidCode, password, passwordConfirm, navigate) => dispatch => {
    gaFireEvent('login', {
        method: 'security',
        label: 'validateNewPassword',
        // email: email,
    })

    dispatch(start())

    axios.post('/api/users/validatenp', { email: email, vidCode: vidCode, password: password, passwordConfirm: passwordConfirm })
        .then(response => {
            if (response.status === 200) {
                dispatch(success())

                const { token, profile } = response.data
                setAuthToken(token)  // update token 
                dispatch(setCurrentUser(profile));
                dispatch(socketConnect(profile._id))
            }
            else { // 206 - Validation Error(s)
                dispatch(failed(response.data))
            }
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: VALIDATE_NEW_PASSWORD_REQUEST } }
    function success() { return { type: VALIDATE_NEW_PASSWORD_SUCCESS } }
    function failed(errors) { return { type: VALIDATE_NEW_PASSWORD_FAILED, errors } }
}


//-----------------------------------------------
// Register User
//-----------------------------------------------
export const registerUser = (user, navigate) => dispatch => {

    gaFireEvent('sign_up', { method: 'sign_up' })

    dispatch({ type: AUTH_ERROR });
    dispatch(start(user))

    axios.post('/api/users/register', user)
        .then(response => {
            if (response.status === 200) {
                dispatch(success(user))
                // navigate('/login')
            }
            else // 206 - Validation Error(s)
                dispatch(failed(response.data))
        })
        // .then(res => navigate('/login'))
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })
        })

    function start(user) { return { type: REGISTER_REQUEST, user } }
    function success(user) { return { type: REGISTER_SUCCESS, user } }
    function failed(errors) { return { type: REGISTER_FAILED, errors } }
}


//-----------------------------------------------
// Login User
//-----------------------------------------------
export const loginUser = (user) => dispatch => {

    gaFireEvent('login', { method: 'login' })

    // console.log('Login...');
    dispatch(start(user))

    // TODO: Do this in the Reducer - DSW
    // dispatch({ type: CLEAR_ERRORS });

    axios.post('/api/users/login', user)
        .then(response => {
            if (response.status === 200) {
                const { token, profile } = response.data;
                setAuthToken(token);  // update token     
                dispatch(setCurrentUser(profile));
                dispatch(socketConnect(profile._id))
                dispatch(success())
            }
            else {  // 206 - Unauthorized - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
            }
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })
        })

    function start(user) { return { type: LOGIN_REQUEST, user } }
    function success() { return { type: LOGIN_SUCCESS } }
    function failed(errors) { return { type: LOGIN_FAILED, errors } }
}


//-----------------------------------------------
// Logout User
//-----------------------------------------------
export const logoutUser = (navigate) => dispatch => {

    gaFireEvent('login', { method: 'logout' })

    // console.log('logoutUser:', this);
    dispatch(socketConnect({}))

    navigate && navigate('/')
    dispatch({ type: LOGOUT })

    // Clear token, kill socket and clear current user...
    setAuthToken(false)
}


//-----------------------------------------------
// setCurrentUser
// Set Auth & Profile
//-----------------------------------------------
export const setCurrentUser = member => {
    return { type: PROFILE_GET_SUCCESS, profile: member }
}


//-----------------------------------------------
// Update User
// NOT IN USE - DSW
//-----------------------------------------------
// export const updateUser = (user, navigate) => dispatch => {
//     axios.post('/api/users/update', user)
//             .then(res => {
//                 const { token } = res.data;
//                 localStorage.setItem('jwtToken', token);
//                 setAuthToken(token);

//                 // const decoded = jwt_decode(token);
//                 // dispatch(setCurrentUser(decoded));
//             })
//             .catch(error => {
//                 let errMsg = error.message
//                 if (error.response != null) {
//                     errMsg = error.response.status.toString() + " - "
//                     errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
//                 }

//                 console.log( 'Error: ' + errMsg )
//                 dispatch({ type: CONNECTION_ERROR, errors: errMsg })
//             });
// }


//-----------------------------------------------
// Delete User
// NOT IN USE - DSW
//-----------------------------------------------
// prefixed function name with underscore because delete is a reserved word in javascript
// function _delete(id) {
//     return dispatch => {
//         dispatch(request(id));

//         userService.delete(id)
//             .then(
//                 user => dispatch(success(id)),
//                 error => dispatch(failure(id, error.toString()))
//             );
//     };

//     function request(id) { return { type: DELETE_REQUEST, id } }
//     function success(id) { return { type: DELETE_SUCCESS, id } }
//     function failure(id, error) { return { type: DELETE_FAILED, id, error } }
// }
