// image.actions.js

import axios from 'axios'
import { getGroup } from '../actions/group.actions'
import { logoutUser } from './auth.actions'
import { setAuthToken } from '../setAuthToken';
import { CONNECTION_ERROR } from './error.actions'

// Upload
export const PHOTO_UPLOAD_REQUEST = 'PHOTO_UPLOAD_REQUEST'
export const PHOTO_UPLOAD_SUCCESS = 'PHOTO_UPLOAD_SUCCESS'
export const PHOTO_UPLOAD_FAILED = 'PHOTO_UPLOAD_FAILED'

export const PHOTOS_REQUEST = 'PHOTOS_REQUEST'
export const PHOTOS_SUCCESS = 'PHOTOS_SUCCESS'
export const PHOTOS_FAILED = 'PHOTOS_FAILED'

export const PHOTO_UPDATE_REQUEST = 'PHOTO_UPDATE_REQUEST'
export const PHOTO_UPDATE_SUCCESS = 'PHOTO_UPDATE_SUCCESS'
export const PHOTO_UPDATE_FAILED = 'PHOTO_UPDATE_FAILED'

export const PHOTO_DELETE_REQUEST = 'PHOTO_DELETE_REQUEST'
export const PHOTO_DELETE_SUCCESS = 'PHOTO_DELETE_SUCCESS'
export const PHOTO_DELETE_FAILED = 'PHOTO_DELETE_FAILED'

export const PHOTO_COMMENT_REQUEST = 'PHOTO_COMMENT_REQUEST'
export const PHOTO_COMMENT_SUCCESS = 'PHOTO_COMMENT_SUCCESS'
export const PHOTO_COMMENT_FAILED = 'PHOTO_COMMENT_FAILED'

export const PHOTO_LIKES_REQUEST = 'PHOTO_LIKES_REQUEST'
export const PHOTO_LIKES_SUCCESS = 'PHOTO_LIKES_SUCCESS'
export const PHOTO_LIKES_FAILED = 'PHOTO_LIKES_FAILED'


export const runUploadPhoto = (photos) => {
    return (dispatch, getState) => {
        if (shouldRunUploadPhoto(getState(), photos)) {
            return dispatch(doRunUploadPhoto(photos))
        }
    }
}

function shouldRunUploadPhoto(state, photos) {
    const image = state.image
    if (!image) {
        return true
    } else if (image.isLoading) {
        return false
    } else {
        return true
    }
}

const doRunUploadPhoto = (post) => dispatch => {
    // console.log('Running Upload...')
    dispatch(start(post))

    axios.post('/api/images/upload', { params: { ...post } })
        .then(response => {
            if (response.status === 200) {
                const { token, result } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(result))
            }
            else // 206 - Validation Error(s)   
                dispatch(failed(response && response.data ? response.data : response))
            // error => dispatch(failed(error.toString()))
        })
        .then(() => Promise.all([
            dispatch((post.idType === 'group') ? getGroup(post.ownerId) : runGetPhotos()) 
        ]))
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(image) { return { type: PHOTO_UPLOAD_REQUEST, image } }
    function success(result) { return { type: PHOTO_UPLOAD_SUCCESS, result } }
    function failed(errors) { return { type: PHOTO_UPLOAD_FAILED, errors } }

    // result: json.data.children.map(child => child.data),
}




export const runGetPhotos = (memberId) => {
    return (dispatch, getState) => {
        if (shouldGetPhotos(getState(), memberId)) {
            return dispatch(doGetPhotos(memberId))
        }
    }
}

function shouldGetPhotos(state, memberId) {
    const image = state.image
    if (!image) {
        return true
    } else if (image.isLoading) {
        return false
    } else {
        return true
    }
}

const doGetPhotos = (photos) => dispatch => {
    // console.log('Running Upload...')
    dispatch(start(photos))

    axios.get('/api/images/flintstone', { params: { ...photos } })
        .then(response => {
            if (response.status === 200) {
                const { token, photos } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(photos))
            }
            else // 206 - Validation Error(s)   
                dispatch(failed(response && response.data ? response.data : response))
            // error => dispatch(failed(error.toString()))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(photos) { return { type: PHOTOS_REQUEST, photos } }
    function success(photos) { return { type: PHOTOS_SUCCESS, photos } }
    function failed(errors) { return { type: PHOTOS_FAILED, errors } }
}



//-----------------------------------------------
// Entry point for deletePhoto...
//-----------------------------------------------
// photoId - Photo
//-----------------------------------------------
export const deletePhoto = (idType, id, photoId) => dispatch => {
    dispatch(start())

    axios.delete('/api/images/deletephoto', { params: { idType: idType, id: id, photoId: photoId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(photoId, response.data.toString()))
        })
        .then(() => Promise.all([
            dispatch((idType === 'group') ? getGroup(id) : runGetPhotos()) 
        ]))
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: PHOTO_DELETE_REQUEST } }
    function success() { return { type: PHOTO_DELETE_SUCCESS } }
    function failed(errors) { return { type: PHOTO_DELETE_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for savePhotoComment...
//-----------------------------------------------
// idType - "group" or "member"
// id -object Id
// photoId - Photo 
// comment - Text
//-----------------------------------------------
export const savePhotoComment = (idType, id, photoId, headline) => dispatch => {
    dispatch(start())

    axios.post('/api/images/photocomment', { params: { idType: idType, id: id, photoId: photoId, headline: headline } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(photoId, response.data.toString()))
        })
        .then(() => Promise.all([ 
            dispatch((idType === 'group') ? getGroup(id) : runGetPhotos())
        ]))
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: PHOTO_COMMENT_REQUEST } }
    function success() { return { type: PHOTO_COMMENT_SUCCESS } }
    function failed(errors) { return { type: PHOTO_COMMENT_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for getPhotoLikes...
//-----------------------------------------------
// photoId - Photo Liked
//-----------------------------------------------
export const getPhotoLikes = (idType, id, photoId) => dispatch => {
    dispatch(start(photoId))

    axios.get('/api/images/likes', { params: { idType: idType, id: id, photoId: photoId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success(response.data.members))
            else // 206 - Validation Error(s)
                dispatch(failed(photoId, response.data.toString()))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(photoId) { return { type: PHOTO_LIKES_REQUEST, photoId } }
    function success(members) { return { type: PHOTO_LIKES_SUCCESS, members } }
    function failed(errors) { return { type: PHOTO_LIKES_FAILED, errors } }
}
