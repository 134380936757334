import React from 'react';

import { makeStyles } from '@mui/styles';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { FormattedMessage } from 'react-intl'

import { DialogStyles } from "../styles/ftfStyles"
const useStyles = makeStyles(DialogStyles)

export default function CodeOfConduct(props) {
    const classes = useStyles(props)

    return (
        <Dialog
            fullScreen={props.fullscreen}
            open={props.show}
            onClose={props.onClose}
        >
            <DialogTitle className={classes.dialogTitle}>{"FindTrueFriends® Code of Conduct"}</DialogTitle>

            <DialogContent>
                <strong>Our Pledge</strong>
                <p>
                    In the interest of fostering an open and welcoming environment, we as members pledge to making participation
                    in FindTrueFriends and our community a harassment-free experience for everyone, regardless of age, body size, disability,
                    ethnicity, gender identity and expression, level of experience, education, socio-economic status, nationality, personal
                    appearance, race, religion, or sexual identity and orientation.
                </p>

                <strong>Our Standard Examples of behavior that contributes to creating a positive environment include:</strong>
                <ul>
                    <li>Using welcoming and inclusive language</li>
                    <li>Being respectful of differing viewpoints and experiences</li>
                    <li>Gracefully accepting constructive criticism</li>
                    <li>Focusing on what is best for the community</li>
                    <li>Showing empathy towards other members</li>
                </ul>

                <strong>Examples of unacceptable behavior by participants include:</strong>
                <ul>
                    <li>The use of sexualized language or imagery and unwelcome sexual attention or advances, trolling, insulting/derogatory
                        comments, and personal or political attacks</li>
                    <li>Public or private harassment</li>
                    <li>Publishing private information (either physical or electronic) is explicitly forbidden</li>
                    <li>Other conduct which could reasonably be considered inappropriate in a professional setting</li>
                    <li>Our members are expected to notify us of any instances of unacceptable behavior</li>
                    <li>If your profile contains sexual language of any kind your account will be deleted</li>
                </ul>

                <p>
                    We have the right and responsibility to remove, edit, or reject comments, commits, code, wiki
                    edits, issues, and other contributions that are not aligned to this Code of Conduct, or to ban temporarily or permanently
                    any contributor for other behaviors that are deemed inappropriate, threatening, offensive, or harmful.
                </p>

                <p>
                    The scope of this Code of Conduct applies both within both <strong>website spaces and in public spaces</strong> when
                    an individual is representing themselves as a member of our community. Examples of representing the community include posting via this or other
                    official social media account, or acting at an representative at an online or offline event.
                </p>
                <p>
                    Enforcement abusive, harassing, or otherwise unacceptable behavior can be stopped by "Blocking" the member.
                    It may also be reported to us by contacting: <a href='mailto:csr@findtruefriends.com'>csr@findtruefriends.com</a>.
                </p>
                <p>
                    All complaints will be reviewed and investigated and will result in a response that is deemed necessary and appropriate
                    to the circumstances. The FindTrueFriends team is obligated to maintain confidentiality with regard to the reporter of an incident.
                    Further details of specific enforcement policies may be posted separately.
                </p>
                <p>
                    Members who do not follow or enforce the "Code of Conduct" in good faith may face a temporary or permanent
                    ban as determined by other members and this sites leadership.
                </p>
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="primary" autoFocus >
                    <FormattedMessage id="system.close" defaultMessage="Close" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}


// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
// var injectTapEventPlugin = import "react-tap-event-plugin";
// injectTapEventPlugin();



