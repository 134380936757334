import React from "react"
import { useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom'
// import debounce from 'lodash.debounce'

import isEmpty from '../validation/is-empty'
import { FormattedMessage } from 'react-intl'

import Popover from '@mui/material/Popover'

import { Grid, List, ListItem, ListItemIcon, ListItemText } from '@mui/material'

// import StarIcon from '@mui/icons-material/Star'
// import CancelIcon from '@mui/icons-material/Cancel'
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import BlockIcon from '@mui/icons-material/Block'
import ReportProblemTwoToneIcon from '@mui/icons-material/ReportProblemTwoTone'
import StarsTwoToneIcon from '@mui/icons-material/StarsTwoTone'
import DeleteForeverTwoToneIcon from '@mui/icons-material/DeleteForeverTwoTone'
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone'

// import { SmallButton, DialogHeading } from "../styles/ftfStyles"

import { banMember, getMember, reportMember } from '../actions/member.actions'
import { groupAdmin } from '../actions/group.actions'

const useStyles = makeStyles(theme => ({
    button: {
        height: 32
    },

    //-----------------------  
    // List
    //-----------------------
    listItem: {
        padding: '0 0 0 2px',
        root: {
            padding: 0,
        }
    },

    listItemIcon: {
        color: theme.palette.primary.dark,
        flexshrink: 0,
        margin: 0,
        minWidth: 23,
    },

    paper: {
        borderColor: theme.palette.info.dark,
        borderRadius: 5,
        border: '1px solid',
        backgroundColor: 'white',
        padding: 4,
    },
}))


export default function MenuMember(props) {
    const classes = useStyles(props)
    let navigate = useNavigate()

    // const userId = useSelector(state => state.profile.profile._id)

    const dispatch = useDispatch()

    // Rights (or default)
    const rights = props.rights || {
        isAdminMgr: false,   // admin of admins
        isContactMgr: false, // Can contact members
        isEventMgr: false,
        isMediaMgr: false,
        isMemberMgr: false, // Approve or Ban members
    }


    //-------------------------------------------
    // onViewProfile
    //-------------------------------------------
    const onViewProfile = (e) => {
        if (!isEmpty(props.handleClose))
            props.handleClose()

        dispatch(getMember(props.memberId))
        navigate('/memberprofile')
    }

    //-------------------------------------------
    // onPromoteMember
    //-------------------------------------------
    const onPromoteMember = (e, id) => {
        e.stopPropagation()

        dispatch(groupAdmin(props.groupId, props.memberId, true, null))

        if (!isEmpty(props.handleClose))
            props.handleClose()
    }

    //-------------------------------------------
    // onBanMember 
    //-------------------------------------------
    function onBanMember(e, ge) {
        e.stopPropagation()

        dispatch(banMember(props.groupId, props.memberId))

        if (!isEmpty(props.handleClose))
            props.handleClose()
    }

    //-------------------------------------------
    // onReportMember
    //-------------------------------------------
    function onReportMember(e) {
        e.stopPropagation()

        dispatch(reportMember(props.groupId, props.memberId, props.widget, "" ))

        if (!isEmpty(props.handleClose))
            props.handleClose()
    }

    //-------------------------------------------
    // isUser - If member is same as user who is logged in
    //-------------------------------------------
    // function isUser() {
    //     return (userId === props.memberId)
    // }


    //-------------------------------------------
    // handleRemoveEvent (for member)
    //-------------------------------------------
    // function handleRemoveEvent(e, ge) {
    //     e.stopPropagation()
    //     dispatch(groupEventDelete(ge.eventId))
    // }

    // Popover
    const open = Boolean(props.anchorEl);

    //-------------------------------------------
    // Group Admin	
    //      All rights (G)
    //      Add, Change, or Remove Admins (A)
    //      Add, Remove, or Ban Members (U)
    //      Contact Members             (C)
    //      Schedule, Edit, or Cancel Events (E)
    //      Remove Images and Posts     (I)
    //-------------------------------------------
    return (
        <Popover
            id={props.memberId}
            open={open}
            anchorEl={props.anchorEl}
            onClose={props.handleClose}

            anchorOrigin={{
                vertical: 'center',
                horizontal: 'left',
            }}

            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}

            classes={{
                paper: classes.paper,
                // root: classes.popoverRoot, // rgba(128, 128, 128, 0.5)
            }}
        >

            <div style={{ maxWidth: 500 }}>
                {/* <Typography style={DialogHeading}>{props.widget.firstname}</Typography> */}

                <Grid container direction="column">
                    <List dense style={{ padding: 0 }}>

                        {/* Edit - Image Caption */}
                        {props.allowChanges && <ListItem button className={classes.listItem} onClick={() => props.handleEdit(props.widget)}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <EditTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="image.editCaption" defaultMessage="Edit Caption" />} />
                        </ListItem>}

                        {/* Delete - Admin or User */}
                        {props.allowChanges && <ListItem button className={classes.listItem} onClick={() => props.handleDelete(props.widget)}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <DeleteForeverTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="system.delete" defaultMessage="Delete" />} />
                        </ListItem>}

                        {/* View Profile - Group Only */}
                        {props.idType === 'group' && <ListItem button className={classes.listItem} onClick={onViewProfile}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <AccountCircleTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="system.viewProfile" defaultMessage="View Profile" />} />
                        </ListItem>}

                        {/* Promote - from Gallery Widget */}
                        {props.widget && !props.widget.isAdmin && rights.isAdminMgr && <ListItem button className={classes.listItem} onClick={onPromoteMember}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <StarsTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="system.promoteToAdmin" defaultMessage="Promote to Admin" />} />
                        </ListItem>}

                        {/* Promote: Group Member List */}
                        {!props.widget && !props.isMemberAnAdmin && rights.isAdminMgr && <ListItem button className={classes.listItem} onClick={onPromoteMember}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <StarsTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="system.promoteToAdmin" defaultMessage="Promote to Admin" />} />
                        </ListItem>}

                        {/* Report Behavior */}
                        {/* !props.allowChanges &&  */}
                        {<ListItem button className={classes.listItem} onClick={onReportMember}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <ReportProblemTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="system.reportBehavior" defaultMessage="Report Behavior" />} />
                        </ListItem>}

                        {/* Ban Member */}
                        {!props.allowChanges && !props.isMemberAnAdmin && rights.isMemberMgr && <ListItem button className={classes.listItem} onClick={onBanMember}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <BlockIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="system.ban" defaultMessage="Ban Member" />} />
                        </ListItem>}

                        {/* Close */}
                        {/* <ListItem button className={classes.listItem} onClick={props.handleClose}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <CancelIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="system.close" defaultMessage="Close" />} />
                        </ListItem> */}
                    </List>
                </Grid>
            </div>
        </Popover>
    )
}

