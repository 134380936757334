// profile.actions.js

import axios from 'axios'
import { setAuthToken } from '../setAuthToken'
import { logoutUser } from './auth.actions'

// import isEmpty from '../validation/is-empty'

import { CONNECTION_ERROR } from '../actions/error.actions'
import { gaFireEvent } from '../helpers/utilities'

export const PROFILE_GET_REQUEST = 'PROFILE_GET_REQUEST'
export const PROFILE_GET_SUCCESS = 'PROFILE_GET_SUCCESS'
export const PROFILE_GET_FAILED = 'PROFILE_GET_FAILED'

export const PROFILE_SAVE_REQUEST = 'PROFILE_SAVE_REQUEST'
export const PROFILE_SAVE_SUCCESS = 'PROFILE_SAVE_SUCCESS'
export const PROFILE_SAVE_FAILED = 'PROFILE_SAVE_FAILED'

export const PROFILE_CLEAR = 'PROFILE_CLEAR'
export const PROFILE_CLEAR_ERROR = 'PROFILE_CLEAR_ERROR'

export const PROFILE_CHANGES_BUFFER = 'PROFILE_CHANGES_BUFFER'
export const PROFILE_CHANGES_CLEAR = 'PROFILE_CHANGES_CLEAR'
export const PROFILE_CHANGES_ERROR = 'PROFILE_CHANGES_ERROR'

export const PROFILE_CANCEL_MEMBERSHIP_REQUEST = 'PROFILE_CANCEL_MEMBERSHIP_REQUEST'
export const PROFILE_CANCEL_MEMBERSHIP_SUCCESS = 'PROFILE_CANCEL_MEMBERSHIP_SUCCESS'
export const PROFILE_CANCEL_MEMBERSHIP_FAILED = 'PROFILE_CANCEL_MEMBERSHIP_FAILED'

// Get List of Blocked Members
export const PROFILE_GETBLOCKED_REQUEST = 'PROFILE_GETBLOCKED_REQUEST'
export const PROFILE_GETBLOCKED_SUCCESS = 'PROFILE_GETBLOCKED_SUCCESS'
export const PROFILE_GETBLOCKED_FAILED = 'PROFILE_GETBLOCKED_FAILED'

export const VALIDATE_NEW_EMAILVID_REQUEST = 'VALIDATE_NEW_EMAILVID_REQUEST'
export const VALIDATE_NEW_EMAILVID_SUCCESS = 'VALIDATE_NEW_EMAILVID_SUCCESS'
export const VALIDATE_NEW_EMAILVID_FAILED = 'VALIDATE_NEW_EMAILVID_FAILED'


//-----------------------------------------------
// Get member profile
//-----------------------------------------------
export const getProfile = () => dispatch => {
    // console.log('getProfile:', this)    
    dispatch(start())

    axios.get('/api/users/getblue')
        .then(response => {
            if (response.status === 200) {
                const { token, user } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(user))
            }
            else // 206 - Validation Error(s)
                dispatch(failed(response.data.toString()))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: PROFILE_GET_REQUEST } }
    function success(profile) { return { type: PROFILE_GET_SUCCESS, profile } }
    function failed(errors) { return { type: PROFILE_GET_FAILED, errors } }
}


//-----------------------------------------------
// profileClear 
// Wipes profile clean...
//-----------------------------------------------
export function profileClear() {
    return (dispatch) => {
        dispatch(profile_Clear())
    }
}
function profile_Clear() { return { type: PROFILE_CLEAR } }


//-----------------------------------------------
// profileChanges 
// Clear temp profile changes...
//-----------------------------------------------
export function profileClearChanges() {
    return (dispatch) => {
        dispatch(profile_ClearChanges())
    }
}
function profile_ClearChanges() { return { type: PROFILE_CHANGES_CLEAR } }


//-----------------------------------------------
// Saves the changes to Redux too save to the Database...
//-----------------------------------------------
export function profileChangesUpdate(profileChanges) {
    return (dispatch, getState) => {
        dispatch(profileChanges_Update(profileChanges))
    }
}
function profileChanges_Update(profileChanges) { return { type: PROFILE_CHANGES_BUFFER, profileChanges } }


//-----------------------------------------------
// Adds an entry error to profile.errors.nnnnnn
//-----------------------------------------------
// @param: error  property and value
export function profileChangesError(error) {
    return (dispatch, getState) => {
        dispatch(profileChanges_Error(error))
    }
}
function profileChanges_Error(error) { return { type: PROFILE_CHANGES_ERROR, error } }


//-----------------------------------------------
// Adds an entry error to profile.errors.nnnnnn
//-----------------------------------------------
// @param: error  property and value
export function profileClearErrors(propName) {
    return (dispatch, getState) => {
        dispatch(profileClear_Errors(propName))
    }
}
function profileClear_Errors(propName) { return { type: PROFILE_CLEAR_ERROR, propName } }



//-----------------------------------------------
// Entry point for a save to the db...
//-----------------------------------------------
export function profileChangesSave() {
    return function (dispatch, getState) {
        if (shouldSaveProfileChanges(getState())) {
            dispatch(runProfileChangesSave(getState()))
        }
    }
}


function shouldSaveProfileChanges(state) {

    if (!state.profile.profileChanges) {  // No changes
        return false

    } else if (state.profile.isSaving) {
        return false

    } else {
        return true   // profile.didInvalidate
    }
}


const runProfileChangesSave = (state) => dispatch => {

    gaFireEvent("profile", {
        event_category: "profile",
        event_label: "profile update",
    })

    dispatch(start())
    
    axios.post('/api/users/update', { params: { ...state.profile.profileChanges } })
        .then(response => {
            if (response.status === 200) {
                const { token, profile } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(profile))
            }
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: PROFILE_SAVE_REQUEST } }
    function success(profile) { return { type: PROFILE_SAVE_SUCCESS, profile } }
    function failed(errors) { return { type: PROFILE_SAVE_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for getBlocked...
//-----------------------------------------------
export const getBlocked = () => dispatch => {
    // console.log('getBlocked:', this)  

    dispatch(start())

    axios.get('/api/users/blocked')
        .then(response => {
            if (response.status === 200)
                dispatch(success(response.data.blockedMembers))
            else // 206 - Validation Error(s)
                dispatch(failed(response.data.toString()))
        })
        // .then(() => Promise.all([
        //     dispatch(chatGetConvs()) 
        //     ])
        // )
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: PROFILE_GETBLOCKED_REQUEST } }
    function success(blockedMembers) { return { type: PROFILE_GETBLOCKED_SUCCESS, blockedMembers } }
    function failed(errors) { return { type: PROFILE_GETBLOCKED_FAILED, errors } }
}


//-----------------------------------------------
// validateEmail...
//-----------------------------------------------
export const validateEmail = (operation, vidCode, email) => dispatch => {
    gaFireEvent('email', {
        method: 'validate',
        label: operation,
        // email: email,
    })

    dispatch(start())

    axios.post('/api/users/validatenev', { operation: operation, vidCode: vidCode, email: email })
        .then(response => {
            if (response.status === 200) {
                const { token, profile } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(profile))
            }
            else { // 206 - Validation Error(s)
                dispatch(failed(response.data))
            }
        })
        // .then(() => Promise.all([
        //     dispatch(getProfile())
        // ])
        // )
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: VALIDATE_NEW_EMAILVID_REQUEST } }
    function success(profile) { return { type: VALIDATE_NEW_EMAILVID_SUCCESS, profile } }
    function failed(errors) { return { type: VALIDATE_NEW_EMAILVID_FAILED, errors } }
}




//-----------------------------------------------
// Entry point for Resign Membership...
//-----------------------------------------------
export function profileResignMembership() {
    return function (dispatch, getState) {
        if (shouldResignMembership(getState())) {
            dispatch(runResignMembership(getState()))
        }
    }
}


function shouldResignMembership(state) {
    if (state.profile.isSaving)
        return false
    else
        return true
}


const runResignMembership = (state) => dispatch => {

    gaFireEvent('resigned', {
        event_category: "profile",
        event_label: "membership resigned",
    })

    dispatch(start())

    axios.post('/api/users/resign')
        .then(response => {
            if (response.status === 200) {
                dispatch(success())

                // Logout
                dispatch(logoutUser())
                window.location.href = '/login'
            }
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: PROFILE_CANCEL_MEMBERSHIP_REQUEST } }
    function failed(errors) { return { type: PROFILE_CANCEL_MEMBERSHIP_FAILED, errors } }
    function success() { return { type: PROFILE_CANCEL_MEMBERSHIP_SUCCESS } }
}


//-----------------------------------------------
// ADMIN ONLY
// profileCancelMembership
//-----------------------------------------------
export const profileCancelMembership = (memberId) => dispatch => {

    gaFireEvent('cancelled', {
        event_category: "profile",
        event_label: "membership cancelled",
    })

    dispatch(start())

    axios.post('/api/users/cancel', { params: { memberId: memberId } })
        .then(response => {
            if (response.status === 200) {
                dispatch(success())
            }
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: PROFILE_CANCEL_MEMBERSHIP_REQUEST } }
    function failed(errors) { return { type: PROFILE_CANCEL_MEMBERSHIP_FAILED, errors } }
    function success() { return { type: PROFILE_CANCEL_MEMBERSHIP_SUCCESS } }
}