// ValidateEmail

import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles';

import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'use-debounce'

import { Button, CircularProgress, Dialog, DialogActions, DialogTitle, Grid, TextField, Typography } from '@mui/material';

import isEmpty from '../validation/is-empty';

// import Email from '@mui/icons-material/Email'
import RefreshIcon from '@mui/icons-material/Refresh'
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead'
import CancelIcon from '@mui/icons-material/Cancel'

import Validator from 'validator'

import { profileChangesError, profileClearChanges, validateEmail } from '../actions/profile.actions';
import { FormattedMessage } from 'react-intl'

import { GeneralText } from "../styles/ftfStyles"
import { logoutUser } from '../actions/auth.actions'

// const useStyles = makeStyles(DialogStyles)

const useStyles = makeStyles(theme => ({
    button: {
        margin: '8px 8px 8px 0',
        minWidth: 100,
        height: 28,
    },

    fieldWide: {
        minWidth: 250,
        width: 350,
    },

    icon: {
        color: theme.palette.primary.dark,
        marginBottom: -4,
        marginRight: 8,
        padding: 0,
    },

}))


export default function ValidateEmail(props) {
    const classes = useStyles(props)
    const theme = useTheme()

    let navigate = useNavigate()

    const profile = useSelector(state => state.profile.profile)
    const isValidating = useSelector(state => state.profile.isValidating)
    const errors = useSelector(state => state.profile.errors)

    const dispatch = useDispatch()

    const [emailRequested, setEmailRequested] = useState(profile.emailRequested)
    const [debouncedEmail] = useDebounce(emailRequested, 333)

    const [vidCode, setVidCode] = useState('')
    // const [debouncedVidCode] = useDebounce(vidCode, 333)

    // DDos Prevention
    const [attempts, setAttempts] = useState(0)


    //-------------------------------------------
    // handleEmailValidation
    //-------------------------------------------
    function handleEmailValidation(e, operation) {
        e.preventDefault()

        // DoS attack prevention
        // A short time delay that increases with the number of attempts
        setAttempts(attempts + 1)

        // Add a delay after 5 attempts
        setTimeout(() => {
            dispatch(validateEmail(operation, vidCode, emailRequested))
        }, attempts < 5 ? 0 : (attempts - 4) * 1000)
    }


    //-------------------------------------------
    // onLogout
    //-------------------------------------------
    function onLogout() {
        try {
            dispatch(logoutUser(navigate))
        } catch (err) {
            console.log('FindTrueFriends Logout error: ', err)
        }
    }


    //--------------------------------------------
    // debouncedEmail
    //--------------------------------------------
    useEffect(() => {
        if (isEmpty(debouncedEmail)) {
            dispatch(profileChangesError({ email: "profile.emailIsRequired" }))
            return
        }

        if (!Validator.isEmail(debouncedEmail)) {
            dispatch(profileChangesError({ email: "profile.emailNotValid" }))
            return
        }

        dispatch(profileClearChanges())

        // Whitebox Testing
        // console.log('ValidateEmail.debouncedEmail: ' + debouncedEmail)
    }, [dispatch, debouncedEmail])


    return (
        <div>
            <Dialog open onClose={props.onClose} >
                {isValidating && <CircularProgress color="primary" size={125}
                    sx={{
                        margin: 'auto',
                        position: 'absolute',
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        // maxwidth: '100%',
                        // maxheight: '100%',
                    }}
                />}

                <DialogTitle color="primary" >
                    <MarkEmailReadIcon className={classes.icon} />
                    <FormattedMessage id="system.email.code" defaultMessage="Validate your email" />
                </DialogTitle>


                {/* Email */}
                <Grid container direction='column' style={{ alignItems: 'baseline', padding: '0px 12px 8px 12px' }}>


                    {/* Email Validation Code */}
                    <Typography style={GeneralText} variant="subtitle2">
                        <FormattedMessage id="system.email.code.enter" defaultMessage="Enter the verification code sent to: " />
                    </Typography>

                    <Typography style={GeneralText} variant="subtitle2">
                        {profile.emailRequested}
                    </Typography>

                    <Grid container direction='row' sx={{ alignItems: 'center', margin: '2px 0' }}>

                        {/* handleEmailValidation */}
                        <Button size="small" variant="contained" color="primary"
                            disabled={isEmpty(vidCode) || isValidating}
                            className={classes.button}
                            onClick={(e) => handleEmailValidation(e, 'validate')}
                        >
                            {/* {isValidating && <CircularProgress color="secondary" size={14} style={{ marginRight: 8 }} />} */}
                            {!isValidating && <MarkEmailReadIcon fontSize="small" style={{ marginRight: 6 }} />}
                            <FormattedMessage id="system.validate" defaultMessage="Validate" />
                        </Button>

                        <TextField
                            disabled={isValidating}
                            name="vidCode"
                            style={{ width: 88 }}
                            type="number"
                            value={vidCode}
                            onChange={e => setVidCode(e.target.value)}
                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                            InputProps={{ inputProps: { min: 0, max: 999999, maxLength: 6 } }}
                        />
                    </Grid>

                    {/* Validation Code Error Message */}
                    {!isEmpty(errors.vidCode) && <Typography color={theme.palette.error.main} variant="body2" style={{ marginLeft: 110 }}>
                        <FormattedMessage id={[errors.vidCode]} defaultMessage="Required" />
                    </Typography>}



                    {/* Resend Code */}
                    <Grid container direction='row' style={{ alignItems: 'center', margin: '2px 0' }}>
                        <Button size="small" variant="contained" color="primary"
                            disabled={!isEmpty(errors.email) || isValidating}
                            className={classes.button}
                            onClick={(e) => handleEmailValidation(e, 'resend')}
                        >
                            {/* {isValidating && <CircularProgress color="secondary" size={14} style={{ marginRight: 8 }} />} */}
                            {!isValidating && <RefreshIcon fontSize="small" style={{ marginRight: 4 }} />}
                            <FormattedMessage id="system.resend" defaultMessage="Re-send" />
                        </Button>

                        <TextField
                            disabled={isValidating}
                            className={classes.fieldWide}
                            name='email'
                            type='email'
                            InputLabelProps={{ shrink: true }}
                            value={emailRequested}
                            onChange={e => setEmailRequested(e.target.value)}
                            autoComplete="email"
                        />
                    </Grid>

                    {/* Email Format Error Message */}
                    {!isEmpty(errors.email) && <Typography color={theme.palette.error.main} variant="body2" style={{ marginLeft: 110 }}>
                        <FormattedMessage id={[errors.email]} defaultMessage="Required" />
                    </Typography>}
                </Grid>

                <DialogActions>
                    {/* Logout Request */}
                    <Button size="small" variant="contained" color="primary"
                        className={classes.button}
                        onClick={(e) => onLogout(e)}
                    >
                        <CancelIcon fontSize="small" style={{ marginRight: 4 }} />
                        <FormattedMessage id="system.logout" defaultMessage="Logout" />
                    </Button>
                </DialogActions>
            </Dialog>

        </div >
    )
}
