// group.Reducer.js
import moment from 'moment'
import isEmpty from '../validation/is-empty'

import { LOGOUT } from '../actions/auth.actions'

import { CONNECTION_ERROR } from '../actions/error.actions'

import { GETGROUPS_REQUEST, GETGROUPS_SUCCESS, GETGROUPS_FAILED } from '../actions/group.actions';
import { GROUP_CREATE_REQUEST, GROUP_CREATE_SUCCESS, GROUP_CREATE_FAILED } from '../actions/group.actions';
import { GROUP_GET_REQUEST, GROUP_GET_SUCCESS, GROUP_GET_FAILED } from '../actions/group.actions';
import { GROUP_SETTINGS_REQUEST, GROUP_SETTINGS_SUCCESS, GROUP_SETTINGS_FAILED } from '../actions/group.actions';
import { GROUP_DELETE_REQUEST, GROUP_DELETE_SUCCESS, GROUP_DELETE_FAILED } from '../actions/group.actions';
import { BANNED_MEMBERS_REQUEST, BANNED_MEMBERS_SUCCESS, BANNED_MEMBERS_FAILED } from '../actions/group.actions';
import { GROUP_UPDATE_REQUEST, GROUP_UPDATE_SUCCESS, GROUP_UPDATE_FAILED } from '../actions/group.actions';

// import { GROUP_LIKE_REQUEST, GROUP_LIKE_SUCCESS, GROUP_LIKE_FAILED } from '../actions/group.actions';
// import { GROUP_GET_MSGS_REQUEST, GROUP_GET_MSGS_SUCCESS, GROUP_GET_MSGS_FAILED } from '../actions/group.actions';

const initialState = {
    isLoading: false,

    groups: [],

    groupId: null,

    group: {
        isInitialized: false,  // ...not this
        groupName: '',
        groupType: 2,
        description: '',
        backgroundColor: '#fff',
        members: [],
        reputation: [],
        blocked: [],
        events: [],
        widgets: [],
        createdOn: moment(),
        isPrivate: true,
        isLocal: false,
        url: null,
        wallpaper: null,
        location: {
            coordinates: [42.1707776, -88.08038399999998], // TODO: Temp
        },
    },

    errors: {},
    receivedAt: moment().format('lll SSS'),
}

export default function (state = initialState, action) {

    switch (action.type) {
        case LOGOUT:
            return {
                initialState
            }

        // White Box Testing
        // let pChanges = { ...state.groupChanges, ...action.groupChanges }
        // console.log('group.Reducer GROUP_CHANGES_BUFFER: ', pChanges)
        // case GROUP_CHANGES_BUFFER:
        //     return {
        //         ...state,
        //         receivedAt: moment().format('lll SSS'),
        //         // Keep loaded group in sync with changes for comparision flip-flops
        //         group: { ...state.group, ...action.groupChanges },
        //         // groupChanges: { ...state.groupChanges, ...action.groupChanges },
        //         isPristine: false,
        //     }

        // case GROUP_CHANGES_CLEAR:
        //     return {
        //         ...state,
        //         groupChanges: {},
        //         errors: {},
        //         isPristine: true,
        //     }


        case GROUP_CREATE_REQUEST:
            return {
                ...state,
                error: {},
                isLoading: true,
                receivedAt: moment().format('lll SSS'),
                group: initialState.group,
            };

        case GROUP_CREATE_SUCCESS:
            return {
                ...state,
                error: {},
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
                group: action.group,
            };

        case GROUP_CREATE_FAILED:
            return {
                ...state,
                error: action.errors,
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
            };


        case GETGROUPS_REQUEST:
            return {
                ...state,
                error: {},
                // group: initialState.group,  // Hurts navigation back/forward
                groups: [],
                isLoading: true,
                receivedAt: moment().format('lll SSS'),
            };

        case GETGROUPS_SUCCESS:
            return {
                ...state,
                error: {},
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
                groups: action.groups,
            };

        case GETGROUPS_FAILED:
            return {
                ...state,
                groups: [],
                error: action.errors,
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
            };


        // getGroup
        case GROUP_GET_REQUEST:
            return {
                ...state,
                isLoading: true,
                group: (!isEmpty(state.group) && state.group._id === action.groupId) ? state.group : initialState.group,
                groupId: action.groupId,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    group: initialState.group,
                }
            }

        case GROUP_GET_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isInitialized: true,
                group: action.group,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    group: initialState.group,
                }
            }

        case GROUP_GET_FAILED:
            return {
                ...state,
                group: initialState.group,
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    group: action.error,
                },
            }



        // Group Settings (Exactly the same as GROUP_GET_REQUEST)
        case GROUP_SETTINGS_REQUEST:
            return {
                ...state,
                isLoading: true,
                group: (!isEmpty(state.group) && state.group._id === action.groupId) ? state.group : initialState.group,
                groupId: action.groupId,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    group: initialState.group,
                }
            }

        case GROUP_SETTINGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                group: action.group,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    group: initialState.group,
                }
            }

        case GROUP_SETTINGS_FAILED:
            return {
                ...state,
                group: initialState.group,
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    group: action.error,
                },
            }



        case GROUP_UPDATE_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case GROUP_UPDATE_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }

        case GROUP_UPDATE_FAILED:
            return {
                ...state,
                isLoading: false,
            }



        case GROUP_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                group: (!isEmpty(state.group) && state.group._id === action.groupId) ? state.group : initialState.group,
                groupId: action.groupId,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    group: initialState.group,
                }
            }

        case GROUP_DELETE_SUCCESS:
            return { ...initialState }

        case GROUP_DELETE_FAILED:
            return {
                ...state,
                group: initialState.group,
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    group: action.error,
                },
            }



        case BANNED_MEMBERS_REQUEST:
            return {
                ...state,
                error: {},
                blocked: [],
                isLoading: true,
                receivedAt: moment().format('lll SSS'),
            };

        case BANNED_MEMBERS_SUCCESS:
            return {
                ...state,
                error: {},
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
                blocked: action.blocked,
            };

        case BANNED_MEMBERS_FAILED:
            return {
                ...state,
                error: action.errors,
                blocked: [],
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
            };

        // case GROUP_GET_MSGS_REQUEST:
        //     return {
        //         ...state,
        //         currentGroupId: action.groupId,
        //         // messages: [], - Causes rendered flashing
        //         isLoading: true,
        //         receivedAt: moment().format('lll SSS'),
        //         errors: {
        //             ...state.errors,
        //             group: initialState.group,
        //         }
        //     }

        // case GROUP_GET_MSGS_SUCCESS:
        //     return {
        //         ...state,
        //         isLoading: false,
        //         messages: action.messages,
        //         lastUpdated: action.receivedAt,
        //         receivedAt: moment().format('lll SSS'),
        //         errors: {
        //             ...state.errors,
        //             group: initialState.group,
        //         }
        //     }

        // case GROUP_GET_MSGS_FAILED:
        //     return {
        //         ...state,
        //         currentGroupId: null,
        //         isLoading: false,
        //         receivedAt: moment().format('lll SSS'),
        //         errors: {
        //             ...state.errors,
        //             group: action.error,
        //         },
        //     }


        case CONNECTION_ERROR:
            return { ...initialState }

        default:
            return state;
    }
}