// search.Reducer.js

import moment from 'moment'

import { BodyType, Children, Drinking, Education, Ethnicity, Faith, HairColor, MaritalStatus, Pets, Politics, Relationship, Smoking, Vehicles } from '../constants/ftfDefinitions';

import { LOGOUT } from '../actions/auth.actions'

import { SEARCH_UPDATE } from '../actions/search.actions';
import { SEARCH_REQUEST, SEARCH_SUCCESS, SEARCH_FAILED } from '../actions/search.actions';
import { SEARCH_SAVE_REQUEST, SEARCH_SAVE_SUCCESS, SEARCH_SAVE_FAILED } from '../actions/search.actions';


const initialState = {
    errors: {},
    isSearching: false,
    receivedAt: moment().format('lll SSS'),
    searches: [],

    searchResults: [],

    searchHasUserPrefs: false,
    search: {
        // High Level
        searchCategory: 8,  // People
        eventType: 0,
        groupType: 0,

        gender: 0,  // use profile
        ageMin: 22, // use profile
        ageMax: 88, // use profile
        radius: 99,
        distance: 0,
        // relationship: 0,   // use profile
        secondLanguage: 0,

        heightInchesMin: 60,
        heightInchesMax: 84,

        // onlineLast: 
        // activities: [activities[0].value],
        bodyType: [BodyType[0].value],
        children: [Children[0].value],
        drinking: [Drinking[0].value],
        education: [Education[0].value],
        ethnicity: [Ethnicity[0].value],
        // eyeColor: [EyeColor[0].value],
        faith: [Faith[0].value],
        hairColor: [HairColor[0].value],
        maritalStatus: [MaritalStatus[0].value],
        pets: [Pets[0].value],
        politics: [Politics[0].value],
        smoking: [Smoking[0].value],
        relationship: [Relationship[0].value],
        vehicles: [Vehicles[0].value],
        secondLangID: 0,

        onlyUpgradedMembers: false,
        withoutPhotos: false,

        keyword: "",
    }
}

export default function (state = initialState, action) {

    switch (action.type) {
        case LOGOUT:
            return { ...initialState }

        case SEARCH_UPDATE:
            // console.log('search.Reducer SEARCH_UPDATE: ', action.search)

            return {
                ...state,
                searchHasUserPrefs: true,
                search: action.search,
                receivedAt: moment().format('lll SSS'),
            }

        case SEARCH_REQUEST:
            // White box testing...
            // console.log('search.Reducer SEARCH_REQUEST: ', action.search)

            return {
                ...state,
                isSearching: true,
                searchHasUserPrefs: true,
                search: action.search,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case SEARCH_SUCCESS:
            // White box testing...
            // console.log('search.Reducer SEARCH_SUCCESS: ', action.searchResults)

            return {
                ...state,
                isSearching: false,
                searchResults: action.searchResults,
                receivedAt: moment().format('lll SSS'),
            }

        case SEARCH_FAILED:
            return {
                // ...state,
                isSearching: false,
                searchResults: [],
                errors: action.errors,
                receivedAt: moment().format('lll SSS'),
            }



        case SEARCH_SAVE_REQUEST:
            return {
                ...state,
                isSearching: true,
                search: action.search,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case SEARCH_SAVE_SUCCESS:
            return {
                ...state,
                isSearching: false,
                searches: action.searches,
                receivedAt: moment().format('lll SSS'),
            }

        case SEARCH_SAVE_FAILED:
            return {
                // ...state,
                isSearching: false,
                searches: [],
                errors: action.errors,
                receivedAt: moment().format('lll SSS'),
            }

        default:
            return state;
    }
}


