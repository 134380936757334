// index.js

import { combineReducers } from 'redux';

import { alertReducer } from './alert.Reducer'

import adReducer from './ad.Reducer'
import appReducer from './app.Reducer'
import authReducer from './auth.Reducer'
import chatReducer from './chat.Reducer'
import groupReducer from './group.Reducer'
import imageReducer from './image.Reducer'
import memberReducer from './member.Reducer'
import profileReducer from './profile.Reducer'
import searchReducer from './search.Reducer'
import trackerReducer from './tracker.Reducer'
import socketReducer from './socket.Reducer'
import usersReducer from './users.Reducer'
import whoisReducer from './whois.Reducer'
import whoViewedMe from './whoViewedMe.Reducer'

// import eventsReducer from './events.Reducer';  // *Note: Includes Notifications
// import vdateReducer from './vdate.Reducer';

// @returns: reducer function invokes every reducer inside the 
//           "passed object" and returns a state object with the same shape.
//
//     o reducers may never return undefined for any action. 
//     o Instead, they should return their initial state if the state passed to them was undefined
//     o Return the current state for any unrecognized action. 
//
export default combineReducers({
    // events: eventsReducer,
    // vdate: vdateReducer,
    ad: adReducer,  

    app: appReducer, 

    alerts: alertReducer,       
    auth: authReducer,

    chat: chatReducer,
    search: searchReducer,
    socket: socketReducer,

    profile: profileReducer,
    member: memberReducer,
    tracker: trackerReducer,

    group: groupReducer,

    users: usersReducer,
    image: imageReducer,

    whois: whoisReducer,    
    whoViewedMe: whoViewedMe,
});