// https://michalzalecki.com/versatility-and-use-cases-of-react-use-effect-hook/

// react-leaflet
// https://react-leaflet.js.org/docs/start-installation/

import React, { useCallback, useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import isEmpty from '../validation/is-empty'
import { useIntl } from 'react-intl'

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';

// import icon from 'leaflet/dist/images/marker-icon.png'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import customIcon from '../images/Marker48x48.png'

import { getLocation } from '../actions/tracker.actions'


let CustomMarker = L.icon({
    iconUrl: customIcon,
    // iconSize: [48, 48],
    shadowUrl: iconShadow,
    iconAnchor: [24, 48],
    popupAnchor: [0, -50],
    shadowSize: [68, 95],
    shadowAnchor: [22, 94]
})

L.Marker.prototype.options.icon = CustomMarker

// https://github.com/PaulLeCam/react-leaflet/issues/453
delete L.Icon.Default.prototype._getIconUrl;

//------------------------------------------------------------------------
// L.Icon.Default.mergeOptions({
//     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//     iconUrl: require('leaflet/dist/images/marker-icon.png'),
//     shadowUrl: require('leaflet/dist/images/marker-shadow.png')
// })

// https://nominatim.org/release-docs/latest/api/Reverse/
// https://www.npmjs.com/package/node-geocoder
// https://github.com/nchaulet/node-geocoder
// http://nominatim.org/release-docs/latest/api/Reverse/

// https://javascript.plainenglish.io/an-introduction-to-geocoding-using-node-js-fe1a5d3aa05c
// https://blog.lively.software/post/geocoding-with-nodejs/
// https://www.demo2s.com/node.js/node-js-node-geocoder-nodegeocoder-reverse-object-member.html

// https://stackoverflow.com/questions/62918569/getting-locality-from-array-of-entry-objects-using-nodegeocoder-reverse-functio
// var NodeGeocoder = require('node-geocoder');
//------------------------------------------------------------------------
// L.Icon.Default.mergeOptions({
//     iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
//     iconUrl: require('leaflet/dist/images/marker-icon.png'),
//     shadowUrl: require('leaflet/dist/images/marker-shadow.png')
// });

// var nodeGeocoderOptions = {
//     provider: 'openstreetmap',
// userAgent: "FTF",
// addressdetails: 1,
// zoom: 10,  // City level detail

// Optional depending on the providers
// httpAdapter: 'https', // Default

// apiKey: 'YOUR_API_KEY', // for Mapquest, OpenCage, Google Premier
// formatter: 'gpx',         // 'gpx', 'string', ...
// }
//------------------------------------------------------------------------

export default function MapNew(props) {
    const dispatch = useDispatch()
    const intl = useIntl()

    const [coords, setCoords] = useState(props.markerPosition)
    const isEditable = props.isEditable

    const location = useSelector(state => state.tracker.location)

    const mapRef = useRef(null);

    const circleRef = useRef(null)
    const markerRef = useRef(null)

    //-------------------------------------------
    // handleLngLatChange
    //-------------------------------------------
    const handleLngLatChange = useCallback((city, state, country, zipcode) => {

        props.handleLngLatChange(coords, city, state, country, zipcode)

    }, [coords, props])


    //-----------------------------------
    // Create the map
    // Draggable - https://codepen.io/PyroStrex/pen/JKpGKv
    //-----------------------------------
    useEffect(() => {
        if (mapRef.current)
            return

        //-----------------------------------
        // L.map (Create the map)
        //-----------------------------------
        mapRef.current = L.map('map', {
            center: coords,   // Initial center of the map
            zoom: props.zoom,
            minZoom: 2.5,
        })


        //-----------------------------------
        // L.tileLayer (attribution)
        //-----------------------------------
        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(mapRef.current);


        //-----------------------------------
        // markerRef
        //-----------------------------------
        markerRef.current = L.marker(coords, { draggable: isEditable, autoPan: 'true' })
            .addTo(mapRef.current)
            .bindPopup("loading...")
            .openPopup()
            .on('dragend', e => {
                try {
                    if (!isEmpty(e.target)) {
                        dispatch(getLocation(e.target.getLatLng()))
                    }
                }
                catch (ex) {
                    console.log("dragend exception: ", ex)
                }
            })

        mapRef.current.on("click", e => {
            if (isEditable) {
                setCoords(e.latlng)
                dispatch(getLocation(e.latlng))
            }
        })


        //-----------------------------------
        // circleRef
        //-----------------------------------
        circleRef.current = L.circle(coords, {
            color: '#03a9f4',
            fillColor: '#03a9f4',
            fillOpacity: 0.05,
            radius: isEmpty(props.circleRadius) ? 10000 : props.circleRadius,  // in meters
        }).addTo(mapRef.current)


        //-----------------------------------
        // Get the textual location (City, State, Country)
        //-----------------------------------
        dispatch(getLocation(coords))   // To get the City and State

    }, [dispatch, coords, props.zoom, props.circleRadius, isEditable])


    //-------------------------------------------                                                                       
    //  This routine calculates the distance between two points (given the     
    //  latitude/longitude of those points). It is being used to calculate     
    //  the distance between two locations using GeoDataSource (TM) prodducts  
    //                                                                         
    //  Definitions:                                                           
    //    South latitudes are negative, east longitudes are positive           
    //                                                                         
    //  Passed to function:                                                    
    //    lat1, lon1 = Latitude and Longitude of point 1 (in decimal degrees)  
    //    lat2, lon2 = Latitude and Longitude of point 2 (in decimal degrees)  
    //    unit = the unit you desire for results                               
    //           where: 'M' is statute miles (default)                         
    //                  'K' is kilometers                                      
    //                  'N' is nautical miles                                  
    //-------------------------------------------
    // function distance(lat1, lon1, lat2, lon2, unit) {
    //     if ((lat1 == lat2) && (lon1 == lon2)) {
    //         return 0;
    //     }
    //     else {
    //         var radlat1 = Math.PI * lat1 / 180;
    //         var radlat2 = Math.PI * lat2 / 180;
    //         var theta = lon1 - lon2;
    //         var radtheta = Math.PI * theta / 180;
    //         var dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    //         if (dist > 1) {
    //             dist = 1;
    //         }
    //         dist = Math.acos(dist);
    //         dist = dist * 180 / Math.PI;
    //         dist = dist * 60 * 1.1515;
    //         if (unit == "K") { dist = dist * 1.609344 }
    //         if (unit == "N") { dist = dist * 0.8684 }
    //         return dist;
    //     }
    // }

    //-------------------------------------------
    // location
    // *Note spelling diff: lon vs. lng
    //-------------------------------------------
    useEffect(() => {
        // console.log(">>>---> location: " + JSON.stringify(location))
        // console.log("")

        if (!isEmpty(location)) {

            // if (!isEditable)
            // //     return

            //-----------------------------------
            // Fix the "city" if "undefined"?
            // Substitute County (not the address, or road)
            //-----------------------------------
            var validState = location.state
            var validCity = location.city

            if (!isEmpty(location.formattedAddress)) {
                var fa = location.formattedAddress.split(',')

                if (isEmpty(location.state)) {
                    if (isEmpty(location.zipcode))
                        validState = fa.length < 3 ? fa[0] : fa[fa.length - 2]
                    else
                        validState = fa.length < 4 ? fa[0] : fa[fa.length - 3]
                }

                if (isEmpty(location.city)) {
                    if (isEmpty(location.zipcode))
                        validCity = fa.length < 3 ? fa[0] : fa[fa.length - 3]
                    else
                        validCity = fa.length < 4 ? fa[0] : fa[fa.length - 4]
                }
            }


            //-----------------------------------
            // Update Marker
            //-----------------------------------
            if (markerRef.current) {
                markerRef.current
                    .setLatLng({ lat: location.latitude, lng: location.longitude })
                    .setPopupContent(validCity + ', ' + validState + ', ' + location.country)
                    .openPopup();
            }

            //-----------------------------------
            // Pass the changes to the caller
            //-----------------------------------
            handleLngLatChange(validCity, validState, location.countryCode, location.zipcode)
        }

    }, [dispatch, handleLngLatChange, location, isEditable])


    //-------------------------------------------
    // Zoom and FlyTo
    //-------------------------------------------
    useEffect(() => {
        if (mapRef.current)
            mapRef.current.flyTo(coords, props.zoom)
    }, [dispatch, coords, props.zoom])


    //-------------------------------------------
    // markerRef
    //-------------------------------------------
    useEffect(() => {
        if (mapRef.current && markerRef.current) {
            markerRef.current
                .setLatLng(coords)
                .setPopupContent(intl.formatMessage({ id: 'system.loading', defaultMessage: 'Loading...' }))
                .openPopup();
        }
    }, [dispatch, intl, coords])


    //-------------------------------------------
    // circleRadius
    //-------------------------------------------
    useEffect(() => {
        if (mapRef.current && !isEmpty(location)) {
            //-----------------------------------
            // circleRef
            //-----------------------------------
            if (circleRef.current) {
                circleRef.current.setLatLng({ lat: location.latitude, lng: location.longitude })
                    .setRadius(props.circleRadius)
            }
            else {
                circleRef.current = L.circle({ lat: location.latitude, lng: location.longitude }, {
                    color: '#03a9f4',
                    fillColor: '#03a9f4',
                    fillOpacity: 0.05,
                    radius: isEmpty(props.circleRadius) ? 10000 : props.circleRadius,  // in meters
                }).addTo(mapRef.current)
            }
        }
    }, [dispatch, location, props.circleRadius])



    //-------------------------------------------
    // isCenteringOnLocation
    //  o uses a new "props.markerPosition"
    //  o call resetCenteringOnLocation to turn it off
    //-------------------------------------------
    useEffect(() => {
        if (props.isCenteringOnLocation && isEditable) {
            dispatch(getLocation(props.markerPosition))
            props.resetCenteringOnLocation()
            setCoords(props.markerPosition)
            mapRef.current.flyTo(props.markerPosition, props.zoom)
        }

    }, [dispatch, isEditable, props, coords])



    //-----------------------------------
    // Radius Change
    //-----------------------------------
    useEffect(() => {
        if (markerRef.current && isEditable) {
            // let ll = markerRef.current.getLatLng()

            if (circleRef.current) {
                circleRef.current.setLatLng(coords).setRadius(props.circleRadius)
            }
        }
    }, [circleRef, coords, isEditable, markerRef, props.circleRadius])


    //-------------------------------------------
    // add layer
    //-------------------------------------------
    // add layer
    // const layerRef = useRef(null);
    // useEffect(() => {
    //     layerRef.current = L.layerGroup().addTo(mapRef.current);
    // }, []);

    return <div id="map" style={{ width: "100%", height: "100%", zIndex: 0 }} />
}

