import React, { useState } from "react"

import { useSelector } from "react-redux"

// import PropTypes from 'prop-types';
// import { withStyles } from '@mui/material/styles';
import { FormattedMessage } from 'react-intl'

import { makeStyles } from '@mui/styles';

import { PageHeading } from "../styles/ftfStyles"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { DialogStyles } from "../styles/ftfStyles";
const useStyles = makeStyles(DialogStyles);


export default function CookiePolicy(props) {
    const classes = useStyles(props)
    // const theme = useTheme()

    const audioStopDevices = useSelector(state => state.socket.audioStopDevices)
    const audioInputSelect = useSelector(state => state.socket.audioInputSelect)
    const audioOutputSelect = useSelector(state => state.socket.audioOutputSelect)
    const videoSelect = useSelector(state => state.socket.videoSelect)

    const [expanded, setExpanded] = useState(null)

    function handleChange(panel) {
        setExpanded(expanded === panel ? null : panel)
    }

    return (
        <Dialog fullScreen={props.fullscreen}
            open={props.show}
            onClose={props.onClose}
        >

            <DialogTitle color="primary" className={classes.dialogTitle}>{"FindTrueFriends® Devices"}</DialogTitle>

            <DialogContent className={classes.root}>
                <div className={classes.root}>

                    <Accordion expanded={expanded === 'panel1'} onChange={(e) => handleChange('panel1')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="body2" style={PageHeading}>Stop Device(s)</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography variant="body2">
                                {audioStopDevices}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel2'} onChange={(e) => handleChange('panel2')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="body2" style={PageHeading}>audioInputSelect</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                {audioInputSelect}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel3'} onChange={(e) => handleChange('panel3')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="body2" style={PageHeading}>audioOutputSelect</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                {audioOutputSelect}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel4'} onChange={(e) => handleChange('panel4')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="body2" style={PageHeading}>videoSelect</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                {videoSelect}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="primary" autoFocus>
                    <FormattedMessage id="system.close" defaultMessage="Close" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

