// whoViewedMe.Reducer.js

import moment from 'moment';

import { LOGOUT } from '../actions/auth.actions'

// import isEmpty from '../validation/is-empty';
// import { CONNECTION_ERROR } from '../actions/error.actions';

import { WHOVIEWEDME_REQUEST, WHOVIEWEDME_SUCCESS, WHOVIEWEDME_FAILED } from '../actions/whoviewedme.actions';

const initialState = {
    isLoading: false,
    members: [],
    error: {},
    receivedAt: moment().format('lll SSS'),
}

export default function (state = initialState, action) {

    switch (action.type) {
        case LOGOUT:
            return { ...initialState }

        case WHOVIEWEDME_REQUEST:
            return {
                ...state,
                isLoading: true,
            }

        case WHOVIEWEDME_SUCCESS:
            return {
                ...state,
                isLoading: false,

                members: action.members,
                receivedAt: moment().format('lll SSS'),
            }

        case WHOVIEWEDME_FAILED:
            return {
                ...state,
                isLoading: false,
                error: action.errors,
                receivedAt: moment().format('lll SSS'),
            }

        default:
            return state;
    }
}

