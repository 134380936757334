// maint.actions.js
import axios from 'axios'
import { logoutUser } from './auth.actions'
import { CONNECTION_ERROR } from './error.actions'

// const moment = require('moment')


// Groups
// export const GETGROUPS_REQUEST = 'GETGROUPS_REQUEST'
// export const GETGROUPS_SUCCESS = 'GETGROUPS_SUCCESS'
// export const GETGROUPS_FAILED = 'GETGROUPS_FAILED'


//===============================================
// Maintenance
//===============================================


//-----------------------------------------------
// cleanAllEvents
//-----------------------------------------------
export const cleanAllEvents = () => dispatch => {
    // dispatch(start())

    axios.post('/api/maint/cae', { params: "" })
        .then(response => {
            if (response.status === 200)
                console.log('cleanAllEvents was successful...')
            else // 206 - Validation Error(s)
                console.log('cleanAllEvents failed...')
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    // function start() { return { type: GROUP_LIKE_REQUEST } }
    // function success() { return { type: GROUP_LIKE_SUCCESS } }
    // function failed(errors) { return { type: GROUP_LIKE_FAILED, errors } }
}


