// setAuthToken.js

import axios from 'axios'

export function setAuthToken(token) {

    // axios.defaults.baseURL = 'http://localhost:3000/'
    axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded'

    if (token) {
        localStorage.setItem('jwtToken', token)
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
    }
    else {
        localStorage.removeItem('jwtToken')
        delete axios.defaults.headers.common['Authorization']
    }
}

export function getAuthHeader() {
    return axios.defaults.headers.common['Authorization']
}

// export default setAuthToken