import React, { useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles';

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

import { FormattedMessage } from 'react-intl'

import { useDebounce } from 'use-debounce'

import Avatar from './Avatar';

import { GeneralText, MyTabbedLayout } from "../styles/ftfStyles"

import { Grid, Button, Divider, FormControlLabel, List, ListItem, ListItemText, Switch, TextField, Typography } from '@mui/material';

import isEmpty from '../validation/is-empty';

import BlockIcon from '@mui/icons-material/Block'
import Email from '@mui/icons-material/Email'
import FilterListIcon from '@mui/icons-material/FilterList'

import { blockMember } from '../actions/member.actions'
import { getBlocked, profileChangesUpdate } from '../actions/profile.actions';

import { roundN } from '../helpers/utilities'


const useStyles = makeStyles(theme => ({
    button: {
        margin: 8,
        minWidth: 95,
        width: 'auto',
    },

    fieldAlignWithSelector: {
        marginTop: 0,
        display: 'flex-end',
    },

    fieldWide: {
        marginBottom: 8,
        minWidth: 250,
        width: 350,
    },

    fieldNumber: {
        margin: '6px 6px',
        minWidth: 60,
    },

    icon: {
        color: theme.palette.primary.dark,
        marginTop: 4,
        marginRight: 6,
        padding: 0,
    },

}))


export default function ProfileNotifications(props) {
    const classes = useStyles(props)

    const errors = useSelector(state => state.profile.errors)
    const profile = useSelector(state => state.profile.profile)

    const isLoading = useSelector(state => state.profile.isLoading)
    const blockedMembers = useSelector(state => state.profile.blockedMembers)

    const [mounted, setIsMounted] = useState(false)

    const [email, setEmail] = useState(profile.email)
    const [debouncedEmail] = useDebounce(email, 333)

    const [minAge, setMinAge] = useState(profile.targetAgeMin)
    const [debouncedMinAge] = useDebounce(minAge, 333)

    const [maxAge, setMaxAge] = useState(profile.targetAgeMax)
    const [debouncedMaxAge] = useDebounce(maxAge, 333)

    const [radius, setRadius] = useState(0)
    const [debouncedRadius] = useDebounce(radius, 333)

    const [viewBlockedMembers, setViewBlockedMembers] = useState(false)

    const dispatch = useDispatch()


    //-------------------------------------------
    // debouncedEmail
    //-------------------------------------------
    useEffect(() => {
        if (mounted) {
            if (debouncedEmail && debouncedEmail != profile.email) {
                // Whitebox Testing
                // console.log('ProfileNotifications.debouncedEmail: ' + debouncedEmail)

                // Buffer profile changes...for Save
                dispatch(profileChangesUpdate({ email: debouncedEmail }))
            }
        }
    }, [dispatch, mounted, debouncedEmail, profile.email])


    //-------------------------------------------
    // minAge Debounced
    //-------------------------------------------
    useEffect(() => {
        if (mounted) {
            if (debouncedMinAge) {
                // Sanity check
                if (parseInt(minAge) === profile.targetAgeMin)
                    return

                // Buffer profile changes...
                dispatch(profileChangesUpdate({ targetAgeMin: parseInt(minAge) }))
            }
        }
    }, [dispatch, mounted, debouncedMinAge, minAge, profile.targetAgeMin])


    //-------------------------------------------
    // maxAge Debounced
    //-------------------------------------------
    useEffect(() => {
        if (mounted) {
            if (debouncedMaxAge) {
                // Sanity check
                if (parseInt(maxAge) === profile.targetAgeMax)
                    return

                // Buffer profile changes...
                dispatch(profileChangesUpdate({ targetAgeMax: parseInt(maxAge) }))
            }
        }
    }, [dispatch, mounted, debouncedMaxAge, maxAge, profile.targetAgeMax])


    //-------------------------------------------
    // Radius Debounced
    //-------------------------------------------
    useEffect(() => {
        if (mounted) {
            if (debouncedRadius) {
                var meters = profile.isMiles ? debouncedRadius / 0.00062137 : debouncedRadius * 1000

                // Sanity check
                if (meters === profile.targetRadius)
                    return

                // Buffer profile changes...
                dispatch(profileChangesUpdate({ targetRadius: meters }))

                // Persist profile changes
                // dispatch(profileChangesSave(profile._id, 0))
            }
        }
    }, [dispatch, mounted, debouncedRadius, profile.isMiles, profile.targetRadius])


    //-------------------------------------------
    // Setup Radius (based on Units)
    //-------------------------------------------
    useEffect(() => {
        if (mounted && isEmpty(profile))
            return

        // Radius defaults to users search radius
        var standardDist = profile.isMiles ? roundN(profile.targetRadius * 0.00062137, 0) : roundN(profile.targetRadius * 0.001, 0)
        if (standardDist < 10)
            standardDist = profile.isMiles ? 10 : 15

        setRadius(standardDist)

        setIsMounted(true)

    }, [mounted, profile])


    //-------------------------------------------
    // handleLocalChange
    //-------------------------------------------
    // @param {Event} e
    function handleLocalChange(e, subject) {
        e.persist()

        if (!e.target || !e.target.name)
            return

        switch (e.target.type) {
            case "checkbox":
                // Buffer profile changes...
                if (!isEmpty(subject))
                    dispatch(profileChangesUpdate({ [subject]: { ...profile[subject], [e.target.name]: e.target.checked } }))
                else
                    dispatch(profileChangesUpdate({ [e.target.name]: e.target.checked }))
                break

            default:
                if (!isEmpty(subject))
                    dispatch(profileChangesUpdate({ [subject]: { ...profile[subject], [e.target.name]: e.target.value } }))
                else
                    dispatch(profileChangesUpdate({ [e.target.name]: e.target.value }))
        }

        // Reference...
        // Profile save
        // props.handleChange(subject, e)
    }


    //-------------------------------------------
    // toggleViewBlockedMembers
    //-------------------------------------------
    async function toggleViewBlockedMembers() {
        if (!viewBlockedMembers)
            await dispatch(getBlocked())

        setViewBlockedMembers(!viewBlockedMembers)
    }


    //-------------------------------------------
    // Unblock
    //-------------------------------------------
    async function handleUnblockMemberClick(e, id) {
        if (!isEmpty(id)) {
            await dispatch(blockMember(id))

            // Re-run original query
            dispatch(getBlocked())
        }
    }

    //-------------------------------------------
    // EmailEditor
    //-------------------------------------------
    function EmailEditor() {

        return (
            <div>
                {/* Header */}
                <Grid container wrap='nowrap' alignItems="center">
                    <Grid item>
                        <Email className={classes.icon} />
                    </Grid>
                    <Grid item>
                        <Typography style={GeneralText} variant="subtitle2">
                            {<FormattedMessage id="profile.notif.yourEmail" defaultMessage="Your Email (private)" />}
                        </Typography>
                    </Grid>                </Grid>

                {/* Email */}
                <Grid container direction='column' style={{ alignItems: 'baseline', margin: '0 8px' }}>
                    <TextField
                        className={classes.fieldWide}
                        name='email'
                        type='email'
                        InputLabelProps={{ shrink: true }}
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        autoComplete="email"
                        // required
                        error={Boolean(errors.email)}
                        helperText={isEmpty(errors.email) ? "" : <FormattedMessage id={[errors.email]} defaultMessage="Required" />}
                    />

                </Grid>
            </div >
        )
    }

    // const { classes, errors } = props;
    return (
        <Div100vh>
            <MyTabbedLayout style={{ padding: 8 }}>
                <Grid container direction="column">

                    {EmailEditor()}

                    <Grid container wrap='nowrap' alignItems="center" style={{ margin: '16px 0 0 0' }}>
                        <Grid item>
                            <Email className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography style={GeneralText} variant="subtitle2">
                                <FormattedMessage id="profile.notif.receiveEmails" defaultMessage="Receive Emails" />
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="column" style={{ margin: '0 8px' }}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={profile.notify && profile.notify.onLike}
                                        name='onLike'
                                        onChange={e => handleLocalChange(e, 'notify')}
                                        color="primary"
                                    />
                                }
                                label={<FormattedMessage id="profile.notif.email.like" defaultMessage="When I have new 'Like'" />}
                            />
                        </Grid>

                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={profile.notify && profile.notify.onMessage}
                                        name='onMessage'
                                        onChange={e => handleLocalChange(e, 'notify')}
                                        color="primary"
                                    />
                                }
                                label={<FormattedMessage id="profile.notif.email.message" defaultMessage="When I have new 'Message'" />}
                            />
                        </Grid>

                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={profile.notify && profile.notify.onFavorite}
                                        name='onFavorite'
                                        onChange={e => handleLocalChange(e, 'notify')}
                                        color="primary"
                                    />
                                }
                                label={<FormattedMessage id="profile.notif.email.favorite" defaultMessage="When someone adds me as a 'Favorite'" />}
                            />
                        </Grid>

                        {/* <Grid item>
                           <FormControlLabel
                                control={
                                    <Switch
                                        checked={profile.notify && profile.notify.onGroupEvent}
                                        name='onGroupEvent'
                                        onChange={e => handleLocalChange(e, 'notify')}
                                        color="primary"
                                    />
                                }
                                label={<FormattedMessage id="profile.notif.email.events" defaultMessage="Allow emails from Local Events" />}
                            />
                        </Grid> */}

                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={profile.notify && profile.notify.onGroupPost}
                                        name='onGroupPost'
                                        onChange={e => handleLocalChange(e, 'notify')}
                                        color="primary"
                                    />
                                }
                                label={<FormattedMessage id="profile.notif.email.grouppost" defaultMessage="When any of your groups has a 'New Post'" />}
                            />
                        </Grid>

                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={profile.notify && profile.notify.onFTF}
                                        name='onFTF'
                                        onChange={e => handleLocalChange(e, 'notify')}
                                        color="primary"
                                    />
                                }
                                label={<FormattedMessage id="profile.notif.email.ftf" defaultMessage="Allow emails from FindTrueFriends" />}
                            />
                        </Grid>
                    </Grid>

                    <Grid container wrap='nowrap' alignItems="center" style={{ margin: '16px 0 0 0' }}>
                        <Grid item>
                            <FilterListIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography style={GeneralText} variant="subtitle2">
                                {<FormattedMessage id="profile.notif.contactOptions" defaultMessage="Member Contact Options" />}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Grid container direction="row" alignItems="center" style={{ margin: '0 8px' }}>
                        <Grid item>
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={profile.notify && profile.notify.isTargetFilterOn}
                                        name='isTargetFilterOn'
                                        onChange={e => handleLocalChange(e, 'notify')}
                                        color="primary"
                                    />
                                }
                                label={<FormattedMessage id="profile.notif.filterMembers" defaultMessage="Allow only:" />}
                            />
                        </Grid>

                        <Grid item>
                            <TextField required name="targetAgeMin"
                                id="targetAgeMin"
                                label={<FormattedMessage id="system.age" defaultMessage="Age" />}

                                value={minAge}
                                onChange={e => setMinAge(e.target.value)}

                                type="number"
                                className={classes.fieldNumber}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                                InputProps={{ inputProps: { min: 18, max: 99 } }}
                            />
                        </Grid>

                        <Grid item>
                            <Typography className={classes.fieldAlignWithSelector} variant="body1">
                                <FormattedMessage id="system.to" defaultMessage="to" />
                            </Typography>
                        </Grid>

                        <Grid item>
                            <TextField required name='targetAgeMax'
                                id="maxAge"
                                label={<FormattedMessage id="system.age" defaultMessage="Age" />}

                                value={maxAge}
                                onChange={e => setMaxAge(e.target.value)}

                                type="number"
                                className={classes.fieldNumber}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                                InputProps={{ inputProps: { min: 18, max: 99 } }}
                            />
                        </Grid>

                        <Grid item>
                            <Typography className={classes.fieldAlignWithSelector} variant="body1">
                                <FormattedMessage id="system.within" defaultMessage="within" />
                            </Typography>
                        </Grid>

                        <Grid item>
                            <TextField required name='targetRadius'
                                id="target-radius"
                                label={(profile.isMiles && <FormattedMessage id="profile.location.miles" defaultMessage="Miles" />) || (!profile.isMiles && <FormattedMessage id="profile.location.kms" defaultMessage="kms" />)}

                                value={radius}
                                onChange={e => setRadius(e.target.value)}

                                type="number"
                                className={classes.fieldNumber}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                margin="normal"
                                variant="outlined"
                                InputProps={{ inputProps: { min: 1, max: 10000 } }}
                            />
                        </Grid>
                    </Grid>

                    {/* <Grid item>
                                <FormControlLabel
                                    control={
                                        <Switch 
                                            checked={user.onlyUpgradedMembers}
                                            name='onlyUpgradedMembers'
                                            onChange={e => handleLocalChange(e, 'notify')}
                                            color="primary"
                                        />
                                    }
                                    label="Only allow messages from Upgraded Members"
                                />
                            </Grid> */}

                    <Grid item style={{ margin: '0 8px' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={profile.notify && profile.notify.allowChat}
                                    name='allowChat'
                                    onChange={e => handleLocalChange(e, 'notify')}
                                    color="primary"
                                />
                            }
                            label={<FormattedMessage id="profile.notif.allowChat" defaultMessage="Allow Chat" />}
                        />
                    </Grid>

                    <Grid item style={{ margin: '0 8px' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={profile.notify && profile.notify.allowVideoChat}
                                    name='allowVideoChat'
                                    onChange={e => handleLocalChange(e, 'notify')}
                                    color="primary"
                                />
                            }
                            label={<FormattedMessage id="profile.notif.allowVideoChat" defaultMessage="Allow Video Dating" />}
                        />
                    </Grid>

                    <Grid item style={{ margin: '0 8px' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={profile.notify && profile.notify.allowWithoutPhotos}
                                    name='allowWithoutPhotos'
                                    onChange={e => handleLocalChange(e, 'notify')}
                                    color="primary"
                                />
                            }
                            label={<FormattedMessage id="profile.notif.allowWithoutPhotos" defaultMessage="Allow members without photos" />}
                        />
                    </Grid>

                    <Grid item style={{ margin: '0 8px' }}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={profile.notify && profile.notify.hideMyVisit}
                                    name='hideMyVisit'
                                    onChange={e => handleLocalChange(e, 'notify')}
                                    color="primary"
                                />
                            }
                            label={<FormattedMessage id="profile.notif.hide.visits" defaultMessage="Hide my visits to a member's profile" />}
                        />
                    </Grid>

                    {/* Blocked Members */}
                    <Grid container alignItems="center" style={{ margin: '16px 0 0 0' }}>
                        <Grid item>
                            <BlockIcon className={classes.icon} />
                        </Grid>
                        <Grid item>
                            <Typography style={GeneralText} variant="subtitle2">
                                {<FormattedMessage id="profile.notif.blockedMembers" defaultMessage="Blocked Members" />}
                            </Typography>
                        </Grid>
                    </Grid>

                    {/* <Grid item style={{ margin: '0 8px' }}>
                            <Typography variant="subtitle2">Here you view members you have blocked</Typography>
                        </Grid> */}

                    <Grid item style={{ margin: '0 8px' }}>

                        <FormControlLabel
                            control={
                                <Switch
                                    // checked={user.onGroupEvent}
                                    name='onViewBlockedMembers'
                                    onChange={toggleViewBlockedMembers}
                                    color="primary"
                                />
                            }

                            label={<FormattedMessage id="profile.notif.view.blockedMembers" defaultMessage="View list of Blocked Members" />}
                        />

                        {/* No one is blocked, don't display until loaded */}
                        {viewBlockedMembers && !isLoading && blockedMembers.length === 0 &&
                            <div>
                                {<FormattedMessage id="profile.notif.blockedNone" defaultMessage="No one is blocked" />}
                            </div>
                        }

                        {/* Blocked List */}
                        {viewBlockedMembers && <List dense>
                            {blockedMembers.map(({ avatarUrl, city, id, firstname, gender, isOnline, state }) =>
                                <div key={id + 'div'} >
                                    <ListItem key={id}>
                                        <Avatar noMargin small isOnline={isOnline} gender={gender} avatarUrl={avatarUrl} delay={250} alt={firstname}>...</Avatar>

                                        <ListItemText primary={firstname} secondary={city && '(' + city + ', ' + state + ')'} />

                                        <Button size="small" variant="contained" color="primary" className={classes.button} onClick={(e) => handleUnblockMemberClick(e, id)}>
                                            {<FormattedMessage id="profile.notif.unblock" defaultMessage="Unblock" />} {firstname}
                                        </Button>

                                        <Divider variant="inset" />
                                    </ListItem>
                                    <Divider variant="inset" component="li" />
                                </div>
                            )}
                        </List>}
                    </Grid>
                </Grid>
            </MyTabbedLayout>
        </Div100vh>
    )
}

