import React from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom'

import Avatar from './Avatar'

import Popover from '@mui/material/Popover'

import { IconButton, List, ListItem, ListItemText } from '@mui/material'

import isEmpty from '../validation/is-empty'

import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'

import { getMember } from '../actions/member.actions'


const useStyles = makeStyles(theme => ({
    iconProfile: {
        color: theme.palette.primary.dark,
        flexshrink: 0,
        margin: 0,
        minWidth: 23,
    },

    paper: {
        borderColor: theme.palette.info.dark,
        borderRadius: 5,
        border: '1px solid',
        backgroundColor: 'white',

        padding: 4,
    },
}))


export default function ViewLikes(props) {
    const classes = useStyles(props)
    let navigate = useNavigate()
    const photoLikes = useSelector(state => state.image.photoLikes)
    const dispatch = useDispatch()

    // Go to profile of a member
    const onViewProfile = (e, id) => {
        dispatch(getMember(id))

        if (props.handleClose)
            props.handleClose()

        navigate('/memberprofile')
    }

    // Popover
    const open = Boolean(props.anchorEl);

    return (
        <div>
            {!isEmpty(photoLikes) && <Popover
                id='likes'
                open={open}
                anchorEl={props.anchorEl}
                onClose={props.handleClose}

                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}

                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}

                classes={{
                    paper: classes.paper,
                }}
            >
                <div style={{ display: 'inline-block' }}>
                    {/* List of Likes */}
                    <List
                        dense
                        style={{ maxHeight: 400, overflow: 'auto', padding: 0 }}
                    >
                        {photoLikes.map(({ avatarUrl, city, id, firstname, gender, isOnline, state }) =>
                            <div key={id + 'div'} >
                                <ListItem key={id} style={{ paddingLeft: 0 }}>
                                    <Avatar noAnimation noMargin small isOnline={isOnline} gender={gender} avatarUrl={avatarUrl} delay={250} alt={firstname}>...</Avatar>

                                    <ListItemText style={{ minWidth: 150 }} primary={firstname} secondary={city && '(' + city + ', ' + state + ')'} />

                                    {/* <ListItemSecondaryAction style={{ right: 0 }} > */}
                                    <IconButton
                                        className={classes.iconProfile}
                                        onClick={(e) => onViewProfile(e, id)}
                                        edge="end"
                                        aria-label="view-profile"
                                        size="large"
                                    >
                                        <AccountCircleTwoToneIcon />
                                    </IconButton>
                                    {/* </ListItemSecondaryAction> */}
                                </ListItem>
                            </div>
                        )}
                    </List>
                </div>
            </Popover>}
        </div>
    );
}

