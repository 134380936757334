// https://redux-form.com/6.0.0-rc.3/examples/material-ui/
// https://medium.com/@foxhound87/automagically-manage-react-forms-state-with-mobx-and-automatic-validation-2b00a32b9769
// http://www.kvcodes.com/module/material-ui-login-page-design/
// https://marmelab.com/react-admin/Tutorial.html
// https://github.com/KrunalLathiya/ReactReduxAuthentication/blob/master/frontend/src/actions/authentication.js
// https://itnext.io/basics-of-react-router-v4-336d274fd9e0
// https://redux-form.com/7.4.2/examples/asyncvalidation/

// Remember Me
// https://stackoverflow.com/questions/244882/what-is-the-best-way-to-implement-remember-me-for-a-website
// https://stackoverflow.com/questions/549/the-definitive-guide-to-form-based-website-authentication#477579
// https://paragonie.com/blog/2015/04/secure-authentication-php-with-long-term-persistence#title.2


import React, { useEffect, useState } from "react"

import { useSelector, useDispatch } from "react-redux"
import { useTheme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useNavigate } from "react-router-dom"
import { createApi } from 'unsplash-js'

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

import { useDebounce } from 'use-debounce'

import { PasswordColor, PasswordScore } from '../validation/PasswordStrength.js'

import { FormattedMessage } from 'react-intl'
import isEmpty from '../validation/is-empty'

import { LayoutMax, DialogHeading, PageHeading, SmallButton } from "../styles/ftfStyles"

import { Button, Dialog, FormControlLabel, Grid, IconButton, InputAdornment, Paper, Radio, RadioGroup, Slide, TextField, Typography } from '@mui/material'

// import AccountCircle from '@mui/icons-material/AccountCircle'
// import Fingerprint from '@mui/icons-material/Fingerprint'
// import CancelIcon from '@mui/icons-material/Cancel'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import SecurityIcon from '@mui/icons-material/Security'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import FTF_Images from './FTF_Images'

import { loginUser, authClearError, authClearAllErrors, validateSecurityPhrase, validateNewPassword, sendVidByEmail } from '../actions/auth.actions'
import { authError } from '../actions/auth.actions'

import { css } from 'styled-components'
import BeatLoader from 'react-spinners/BeatLoader'
const cssBeatLoader = css`
    display: block
    margin: 0 auto
    border-color: red
`

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const unsplash = createApi({ accessKey: "4164d2ead0847cffbdbd8d888359956de5048d32e90ee90cffa1007240c32d5e" })

const useStyles = makeStyles(theme => ({
    bodyText: {
        padding: 4,
    },

    dialogPaper: {
        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        border: '2px solid',

        padding: 8,
        overflow: 'hidden',

        width: 330,
        height: 'fit-content',
        // width: 'auto',
    },

    paperLogin: {
        // backgroundColor: "rgba(255, 255, 255, 0.90)",

        boxShadow: '5px 5px 15px 0px rgba(0,0,0,0.33)',
        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        border: '2px solid',

        margin: 32,
        padding: 8,
        overflow: 'hidden',

        position: 'relative',
        zIndex: 9000,

        height: 'fit-content',
        width: 'inherit',
        maxWidth: 330,
    },

    icon: {
        color: theme.palette.primary.dark,
        margin: '4px 4px 0 0',
    },

    image: {
        position: 'absolute',
        top: 0, left: 0,
    },

    logo: {
        maxHeight: window.innerWidth <= 500 ? 20 : 20,
        marginRight: 4,
        marginBottom: 3,
        alignSelf: 'center',
    },
}))


export default function Login(props) {
    const classes = useStyles(props)
    const theme = useTheme()
    let navigate = useNavigate()

    // const contentRef = React.useRef(null)
    const [isLoginClicked, setIsLoginClicked] = useState(false)     // For DDOS: Immediately disable Login 
    const isAuthenticating = useSelector(state => state.auth.isAuthenticating)
    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    // const firstname = useSelector(state => state.auth.firstname)
    const emailMask = useSelector(state => state.auth.emailMask)
    const errors = useSelector(state => state.auth.errors)
    const isNewPasswordRequired = useSelector(state => state.auth.isNewPasswordRequired)

    const [width, setWidth] = useState(0)
    const [height, setHeight] = useState(0)

    const [email, setEmail] = useState('')
    const [debouncedEmail] = useDebounce(email, 333)

    const [password, setPassword] = useState('')
    const [debouncedPassword] = useDebounce(password, 333)

    const [passwordConfirm, setPasswordConfirm] = useState('')
    const [debouncedPasswordConfirm] = useDebounce(passwordConfirm, 333)

    // Forgot Password Dialogs
    const [showForgotPasswordEmailDialog, setShowForgotPasswordEmailDialog] = useState(false)
    const [showValidationMethodDialog, setShowValidationMethodDialog] = useState(false)
    const [showEnterSecurityPhraseDialog, setShowEnterSecurityPhraseDialog] = useState(false)
    const [showCreateNewPasswordDialog, setShowCreateNewPasswordDialog] = useState(false)

    // Password Strength
    const [passwordStrengthScore, setPasswordStrengthScore] = useState(0)

    // Forgot Password
    const [isPinValidation, setIsPinValidation] = useState(true)
    // const [isTextValidation, setIsTextValidation] = useState(false)  // Future
    const [validationMethod, setValidationMethod] = useState('pin')
    const [securityPhrase, setSecurityPhrase] = useState('')

    // Create Password with VidCode
    const [incomplete, setIncomplete] = useState(false)
    const [vidCode, setVidCode] = useState('000000')
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)

    const [image, setImage] = useState(null)

    // DDos Prevention
    const [loginAttempts, setLoginAttempts] = useState(0)

    const dispatch = useDispatch()

    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight)
            setWidth(window.innerWidth)
        }

        // On mount...
        if (width === 0) {
            handleResize()
            setVidCode('000000')
            dispatch(authClearAllErrors())
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [dispatch, width])


    useEffect(() => {
        /* https://unsplash.com/documentation#supported-parameters */

        // We can't count on any of the Unsplash APIs so we do both...
        setImage("https://source.unsplash.com/collection/1914244/" + window.innerWidth + "x" + window.innerHeight + "/?lock/sig=" + Math.random() + "/SameSite=None")


        unsplash.photos.getRandom({
            collectionIds: ["1914244"],
            query: 'lock',
            count: 1,
        })
            .then(result => {
                if (result.errors) {
                    console.log('unsplash error occurred: ', result.errors[0])
                } else {
                    const photo = result.response[0]
                    setImage(photo.urls.raw + "&w=" + width + "&h=" + height + "&fit=clamp")
                }
            })
    }, [height, width])


    //-------------------------------------------
    // isAuthenticated at Login
    //-------------------------------------------
    useEffect(() => {
        if (isAuthenticated) {
            navigate('/friends')
        }
    }, [isAuthenticated, navigate])


    //-------------------------------------------
    // email Debounced
    //-------------------------------------------
    useEffect(() => {
        dispatch(authClearError('password'))
        setEmail(debouncedEmail)
    }, [dispatch, debouncedEmail])


    //-------------------------------------------
    // password Debounced
    //-------------------------------------------
    useEffect(() => {
        dispatch(authClearError('password'))

        setPasswordStrengthScore(PasswordScore(debouncedPassword))

        // Only do for CreateNewPassword
        if (isNewPasswordRequired) {
            // console.log('passwordStrengthScore: ' + passwordStrengthScore)
            switch (passwordStrengthScore) {
                case 3:
                    dispatch(authError({ password: "profile.security.passwordStrong" }))
                    break

                case 2:
                    dispatch(authError({ password: "profile.security.passwordWeak" }))
                    break

                case -1:
                    dispatch(authError({ password: "profile.security.passwordLength" }))
                    break

                default:
                    break
            }
        }

        if (passwordStrengthScore >= 2) {
            setPassword(debouncedPassword)
        }

    }, [dispatch, isNewPasswordRequired, debouncedPassword, passwordStrengthScore])


    //-------------------------------------------
    // passwordConfirm Debounced
    //-------------------------------------------
    useEffect(() => {
        // dispatch(authClearError("passwordConfirm"))

        if (debouncedPasswordConfirm) {
            if (debouncedPasswordConfirm === password) {   // Add error msg to errors.nnnnn
                setPasswordConfirm(debouncedPasswordConfirm)
            }
            else {
                dispatch(authError({ password_confirm: "profile.security.passwordDontMatch" }))
            }
        }

    }, [dispatch, debouncedPasswordConfirm, password])


    //-------------------------------------------
    // isNewPasswordRequired
    //-------------------------------------------
    useEffect(() => {
        if (isNewPasswordRequired) {
            closeDialogs()
            setShowCreateNewPasswordDialog(true)
        }
    }, [isNewPasswordRequired])


    //-------------------------------------------
    // Validate New Password
    //-------------------------------------------
    useEffect(() => {
        if (isEmpty(password) || isEmpty(passwordConfirm)) {
            setIncomplete(true)
            return
        }

        // Matches passwordConfirm && Require strength >= 2 (weak)
        if (password !== passwordConfirm || passwordStrengthScore < 2) {
            setIncomplete(true)
            return
        }

        // 6 digits
        if (parseInt(vidCode) < 1) {
            setIncomplete(true)
            return
        }

        // Valid...
        setIncomplete(false)

    }, [password, passwordConfirm, passwordStrengthScore, vidCode])


    function handleClickShowPassword() {
        setShowPassword(!showPassword)
    }


    function handleClickShowConfirmPassword() {
        setShowConfirmPassword(!showConfirmPassword)
    }


    //-------------------------------------------
    // Cancel Login
    //-------------------------------------------
    function handleCancel(e) {
        navigate('/', { replace: true })
    }



    function handleLogin(e) {
        e.preventDefault()

        const user = {
            email: email,
            password: password,
        }

        if (isEmpty(user.email) || isEmpty(user.password))
            return


        // DoS attack prevention
        // A short time delay that increases with the number of failed loginAttempts
        setIsLoginClicked(true)
        setLoginAttempts(loginAttempts + 1)

        // Add a delay after 10 attempts
        setTimeout(() => {
            dispatch(loginUser(user))
            setIsLoginClicked(false)
        }, loginAttempts < 10 ? 0 : (loginAttempts - 10) * 1000)
    }


    //===========================================
    // toggleForgotPasswordEmailDialog
    //===========================================
    function closeDialogs() {
        setVidCode('000000')
        // setPassword('')
        // setPasswordConfirm('')

        setShowForgotPasswordEmailDialog(false)
        setShowValidationMethodDialog(false)
        setShowEnterSecurityPhraseDialog(false)
        setShowCreateNewPasswordDialog(false)
    }


    //===========================================
    // toggleForgotPasswordEmailDialog
    //===========================================
    function toggleForgotPasswordEmailDialog(e, reason) {
        if (reason === 'backdropClick')
            return

        setShowForgotPasswordEmailDialog(!showForgotPasswordEmailDialog)
    }


    //===========================================
    // toggleShowValidationMethodDialog
    //===========================================
    function toggleShowValidationMethodDialog(e, reason) {
        if (reason === 'backdropClick')
            return

        closeDialogs()
        setShowValidationMethodDialog(!showValidationMethodDialog)
    }


    //===========================================
    // toggleShowEnterSecurityPhraseDialog
    //===========================================
    function toggleShowEnterSecurityPhraseDialog(e, reason) {
        if (reason === 'backdropClick')
            return

        closeDialogs()
        setShowEnterSecurityPhraseDialog(!showEnterSecurityPhraseDialog)
    }


    //===========================================
    // toggleShowCreateNewPasswordDialog
    //===========================================
    function toggleShowCreateNewPasswordDialog(e, reason) {
        if (reason === 'backdropClick')
            return

        // setPassword('')
        // setPasswordConfirm('')

        closeDialogs()

        setShowCreateNewPasswordDialog(!showCreateNewPasswordDialog)
    }

    //===========================================
    // handleValidationMethod
    //===========================================
    function handleValidationMethod(delivery) {
        setValidationMethod(delivery)

        // Delivery method...
        if (delivery === "pin") {
            setIsPinValidation(true)
        }
        else if (delivery === "email") {
            setIsPinValidation(false)
        }
        // TODO: Future Method - DSW 
        // else if (delivery === "text") {
        //     setIsPinValidation(false)
        //     setIsEmailValidation(false)
        //     setIsTextValidation(true)
        // }
    }


    //===========================================
    // handleValidationMethodNext
    // Send Security 2FA VID by email...
    //===========================================
    function handleValidationMethodNext() {
        closeDialogs()

        // Validate Email 
        if (!isEmpty(email)) {
            if (isPinValidation) {      // Validate Security Phrase...
                setShowEnterSecurityPhraseDialog(true)
            }
            else {                      // Send VID by email...
                // An email was sent to: ****D.@gmail.com
                // Check your email anter enter the Verification Id
                dispatch(sendVidByEmail(email, props.navigate))
                toggleShowCreateNewPasswordDialog()
            }
        }
    }


    //===========================================
    // handleSecurityPhrase
    // Validate Security Phrase
    //===========================================
    function handleValidateSecurityPhrase(e) {
        e.preventDefault()

        if (!isEmpty(email) && !isEmpty(securityPhrase)) {

            // DoS attack prevention
            // A short time delay that increases with the number of failed loginAttempts
            setIsLoginClicked(true)
            setLoginAttempts(loginAttempts + 1)

            // Add a delay after 10 attempts
            setTimeout(() => {
                dispatch(validateSecurityPhrase(email, securityPhrase, props.navigate))
                setIsLoginClicked(false)
            }, loginAttempts < 10 ? 0 : (loginAttempts - 10) * 1000)
        }
    }


    //===========================================
    // handleUpdateNewPassword
    //===========================================
    function handleUpdateNewPassword(e) {
        e.preventDefault();

        if (!isEmpty(password) && !isEmpty(passwordConfirm)) {
            dispatch(validateNewPassword(email, vidCode, password, passwordConfirm, props.navigate))
        }
    }


    //-------------------------------------------
    // onLogin
    //-------------------------------------------
    function onLogin() {
        return (
            <Paper className={classes.paperLogin}>
                <Grid container direction='row'>
                    <img alt='Logo' src={'https://www.findtruefriends.com/logo/ftf_logo_small.png'} className={classes.logo} />
                    <Typography style={PageHeading} variant="h5">
                        <FormattedMessage id="system.login" defaultMessage="Login" />
                    </Typography>
                </Grid>

                <form onSubmit={handleLogin}>

                    <Grid container direction='column' style={{ marginTop: 24 }}>
                        <Grid item md sm xs>
                            <TextField id='email' name="email"
                                autoFocus
                                fullWidth
                                required

                                label={<FormattedMessage id="system.email" defaultMessage='Email' />}
                                type="email"

                                onChange={e => setEmail(e.target.value)}
                                error={Boolean(errors.email)}
                                helperText={isEmpty(errors.email) ? "" : <FormattedMessage id={[errors.email]} defaultMessage="Required" />}
                                InputLabelProps={{ shrink: true }}
                            />
                        </Grid>

                        <Grid item md sm xs style={{ marginTop: 20 }}>
                            <TextField id='password' name="password"
                                fullWidth
                                required

                                label={<FormattedMessage id="system.password" defaultMessage='Password' />}
                                type={showPassword ? 'text' : 'password'}

                                onChange={e => setPassword(e.target.value)}
                                error={Boolean(errors.password)}
                                helperText={isEmpty(errors.password) ? "" : <FormattedMessage id={[errors.password]} defaultMessage="Required" />}

                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    // style: { color: PasswordColor(passwordStrengthScore) },

                                    endAdornment: (
                                        <InputAdornment variant="filled" position="end">
                                            <IconButton
                                                aria-label="Password visibility"
                                                onClick={handleClickShowPassword}
                                                size="large"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    {/* Buttons */}
                    <Grid container style={{ justifyContent: 'space-evenly', margin: '16px 0 8px' }}>
                        <Grid item>
                            <Button
                                aria-label='loginSubmit'
                                type="submit"
                                autoFocus
                                color="primary"
                                disabled={isAuthenticating || isLoginClicked}
                                size="large"
                                id='loginSubmit'
                                // onClick={handleLogin}
                                style={SmallButton}
                                variant="contained"
                            >
                                <FormattedMessage id="system.go" defaultMessage="Enter" />
                            </Button>
                        </Grid>

                        <Grid item>
                            <Button aria-label='Cancel'
                                variant="contained" color="primary"
                                style={SmallButton}
                                disabled={isAuthenticating}
                                size="large"
                                onClick={handleCancel}
                            >
                                <FormattedMessage id="system.cancel" defaultMessage="Cancel" />
                            </Button>
                        </Grid>
                    </Grid>

                    {/* Error Messages */}
                    <Grid container style={{ justifyContent: "center", alignItems: "center" }}>
                        <Typography style={{ color: theme.palette.primary.dark, fontSize: '.9rem' }} >

                            {/* Connection Issue... */}
                            {errors.connection &&
                                <React.Fragment>
                                    <FormattedMessage id="system.cannotConnect1" defaultMessage="Cannot connect..." />
                                    <br />
                                    <FormattedMessage id="system.cannotConnect2" defaultMessage="Please try again in a little while..." />
                                </React.Fragment>
                            }

                            {/* Forgot Password... */}
                            {errors.password &&
                                <Button variant="text" color="primary" onClick={toggleForgotPasswordEmailDialog}>
                                    <FormattedMessage id="system.password.forgot" defaultMessage="Forgot your Password?" />
                                </Button>}
                        </Typography>
                    </Grid>

                </form>
            </Paper>
        )
    }


    //-------------------------------------------
    // onForgotPasswordEmail
    //-------------------------------------------
    function onForgotPasswordEmail() {
        return (
            <Dialog classes={{ paper: classes.dialogPaper }}
                // 
                open={showForgotPasswordEmailDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={toggleForgotPasswordEmailDialog}
            >
                <Typography style={DialogHeading} variant="h6">
                    <FormattedMessage id="system.password.forgot" defaultMessage="Forgot your password?" />
                </Typography>

                <Typography className={classes.bodyText} variant="body2">
                    <FormattedMessage id="system.password.createEmail" defaultMessage="Enter your registered email (ID):" />
                </Typography>

                <Grid container>
                    <TextField name="email" type="email" autoFocus fullWidth required
                        style={{ margin: 12 }}
                        label={<FormattedMessage id="system.email" defaultMessage='Email' />}
                        variant="outlined"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        error={Boolean(errors.email)}
                        helperText={isEmpty(errors.email) ? "" : <FormattedMessage id={[errors.email]} defaultMessage="Required" />}
                        margin='dense'
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>

                {/* Buttons */}
                <Grid container style={{ justifyContent: 'space-evenly', margin: '16px 0 8px' }}>
                    <Button disableFocusRipple disableRipple size="large"
                        variant="contained" color="primary"
                        style={SmallButton}
                        startIcon={<NavigateBeforeIcon />}
                        onClick={toggleForgotPasswordEmailDialog}
                    >
                        <FormattedMessage id="system.back" defaultMessage='Back' />
                    </Button>

                    <Button disableFocusRipple disableRipple size="large"
                        variant="contained" color="primary"
                        disabled={isEmpty(email)}
                        style={SmallButton}
                        endIcon={<NavigateNextIcon />}
                        onClick={toggleShowValidationMethodDialog}
                    >
                        <FormattedMessage id="system.next" defaultMessage='Next' />
                    </Button>

                </Grid>
            </Dialog>
        )
    }


    //-------------------------------------------
    // onValidationMethod
    //-------------------------------------------
    function onValidationMethod() {
        return (
            <Dialog classes={{ paper: classes.dialogPaper }}
                // 
                open={showValidationMethodDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={toggleShowValidationMethodDialog}
            >
                <Typography style={DialogHeading} variant="h6">
                    <FormattedMessage id="system.password.confirmMethod" defaultMessage='Proceed by:' />
                </Typography>

                <RadioGroup aria-label="validation Method"
                    style={{ marginLeft: 12 }}
                    value={validationMethod}
                    onChange={e => handleValidationMethod(e.target.value)}
                >
                    <FormControlLabel color="primary" value="pin" control={<Radio />} label={<FormattedMessage id="system.password.confirmMethod.pin" defaultMessage='Entering your Security Phrase' />} />
                    <FormControlLabel color="primary" value="email" control={<Radio />} label={<FormattedMessage id="system.password.confirmMethod.email" defaultMessage='Reset your password by email' />} />
                    {/* <FormControlLabel color="primary" value="text" control={<Radio />} label="Sending a text" /> */}
                </RadioGroup>

                <Grid container style={{ justifyContent: 'space-evenly', margin: '16px 0 8px' }}>
                    <Button disableFocusRipple disableRipple size="large"
                        variant="contained" color="primary"
                        style={SmallButton}
                        startIcon={<NavigateBeforeIcon />}
                        onClick={toggleShowValidationMethodDialog}
                    >
                        <FormattedMessage id="system.back" defaultMessage='Back' />
                    </Button>

                    <Button disabled={isEmpty(validationMethod)} disableFocusRipple disableRipple size="large"
                        variant="contained" color="primary"
                        style={SmallButton}
                        endIcon={<NavigateNextIcon />}
                        onClick={handleValidationMethodNext}
                    >
                        <FormattedMessage id="system.next" defaultMessage='Next' />
                    </Button>
                </Grid>

                {/* <Typography className={classes.bodyText} variant="caption">By continuing, you confirm that you are authorized to use this phone number and agree to receive text messages to confirm your identity.  Carrier fees may apply.</Typography> */}
            </Dialog>
        )
    }


    //-------------------------------------------
    // onEnterSecurityPhrase
    //-------------------------------------------
    function onEnterSecurityPhrase() {
        return (
            <Dialog classes={{ paper: classes.dialogPaper }}
                open={showEnterSecurityPhraseDialog}
                TransitionComponent={Transition}
                keepMounted
                onClose={toggleShowEnterSecurityPhraseDialog}
            >
                <Typography style={DialogHeading} variant="h6">
                    <FormattedMessage id="system.password.securityPhrase" defaultMessage='Enter your Security Phrase' />
                </Typography>

                <TextField name="securityPhrase"
                    autoFocus
                    fullWidth multiline
                    required
                    variant="outlined"
                    type='text'
                    // className={classes.textFieldCode}
                    label={<FormattedMessage id="profile.security.pin" defaultMessage="Required" />}

                    onChange={e => setSecurityPhrase(e.target.value)}
                    margin="dense"

                    // rows="3"
                    maxRows="3"

                    error={Boolean(errors.securityPhrase)}
                    helperText={isEmpty(errors.securityPhrase) ? "" : <FormattedMessage id={[errors.securityPhrase]} defaultMessage="Required" />}
                    InputLabelProps={{ shrink: true }}
                />

                {/* The Email was not found in the system */}
                {onEmailNotFound()}

                <Grid container style={{ justifyContent: 'space-evenly', margin: '16px 0 8px' }}>
                    <Button disableFocusRipple disableRipple size="large"
                        variant="contained" color="primary"
                        style={SmallButton}
                        startIcon={<NavigateBeforeIcon />}
                        onClick={toggleShowEnterSecurityPhraseDialog}
                    >
                        <FormattedMessage id="system.back" defaultMessage='Back' />
                    </Button>

                    <Button disabled={isEmpty(securityPhrase) || Boolean(errors.email) || isAuthenticating || isLoginClicked} disableFocusRipple disableRipple size="large"
                        variant="contained" color="primary"
                        style={SmallButton}
                        onClick={e => handleValidateSecurityPhrase(e)}
                    >
                        <FormattedMessage id="system.go" defaultMessage="Enter" />
                    </Button>
                </Grid>
            </Dialog>
        )
    }


    //-------------------------------------------
    // onEmailNotFound
    //-------------------------------------------
    function onEmailNotFound() {
        if (errors.email)
            return (
                <div>
                    <Typography style={DialogHeading} variant="h6">
                        <FormattedMessage id='system.problem' defaultMessage="There was a problem...." />
                    </Typography>

                    <Typography className={classes.bodyText} variant="body2">
                        {email}
                    </Typography>

                    <Typography className={classes.bodyText} color='error' variant="body2">
                        <FormattedMessage id={[errors.email]} defaultMessage="Email does not exist" />
                    </Typography>
                </div>
            )
    }


    //-------------------------------------------
    // onEmailFound
    //-------------------------------------------
    function onEmailFound() {
        if (!errors.email)
            return (
                <div>
                    <Grid container direction='row'>
                        <SecurityIcon className={classes.icon} />

                        <Typography style={DialogHeading} variant="h6">
                            <FormattedMessage id='system.password.new' defaultMessage="Create a New Password" />
                        </Typography>

                        <Typography className={classes.bodyText} variant="body2">
                            <FormattedMessage id="system.email.code.enter" defaultMessage="Enter the verification code sent to: " />
                        </Typography>

                        <Typography className={classes.bodyText} variant="body2">
                            <i>{emailMask}</i>
                        </Typography>
                    </Grid>

                    <Grid container style={{ margin: '12px 0' }}>
                        <Grid item style={{ margin: '4px' }}>
                            <TextField name="vidCode"
                                label={<FormattedMessage id="system.email.code" defaultMessage='Verification ID' />}
                                required

                                value={vidCode}

                                onChange={e => setVidCode(e.target.value)}

                                error={Boolean(errors.vidCode)}

                                helperText={isEmpty(errors.vidCode) ? "" : <FormattedMessage id={[errors.vidCode]} defaultMessage="Required" />}

                                InputLabelProps={{ shrink: true }}
                                InputProps={{ inputProps: { min: 100000, max: 999999, maxLength: 6 } }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container style={{ margin: '12px 0' }}>

                        <Grid item style={{ margin: '4px' }} md sm xs>
                            <TextField name="password"
                                label={<FormattedMessage id="profile.security.enterPassword" defaultMessage='Enter a new password' />}
                                fullWidth autoFocus required
                                type={showPassword ? 'text' : 'password'}

                                value={password}
                                onChange={e => setPassword(e.target.value)}

                                // error={Boolean(errors.password) && passwordStrengthScore < 2}
                                // error={passwordStrengthScore < 2}

                                // helperText={isEmpty(errors.password) ? "" : <FormattedMessage id={[errors.password]} defaultMessage="Required" />}
                                // helperText={<FormattedMessage id={[errors.password]} defaultMessage="Required" />}

                                FormHelperTextProps={{ style: { color: PasswordColor(passwordStrengthScore) } }}

                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    style: { color: PasswordColor(passwordStrengthScore) },

                                    endAdornment: (
                                        <InputAdornment variant="filled" position="end">
                                            <IconButton
                                                aria-label="Password visibility"
                                                onClick={handleClickShowPassword}
                                                size="large"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>

                    <Grid container style={{ margin: '12px 0' }}>
                        {/* <Grid item style={{ margin: '4px' }}>
                            <Fingerprint className={classes.icon} />
                        </Grid> */}

                        <Grid item style={{ margin: '4px' }} md sm xs>
                            <TextField name="passwordConfirm"
                                label={<FormattedMessage id="profile.security.newPasswordConfirm" defaultMessage='Confirm new password' />}
                                type={showConfirmPassword ? 'text' : 'password'}
                                required fullWidth

                                value={passwordConfirm}
                                onChange={e => setPasswordConfirm(e.target.value)}

                                error={Boolean(errors.passwordConfirm)}
                                helperText={isEmpty(errors.passwordConfirm) ? "" : <FormattedMessage id={[errors.passwordConfirm]} defaultMessage="Required" />}

                                InputLabelProps={{ shrink: true }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment variant="filled" position="end">
                                            <IconButton
                                                aria-label="Password visibility"
                                                onClick={handleClickShowConfirmPassword}
                                                size="large"
                                            >
                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Grid>
                    </Grid>
                </div>
            );
    }


    //-------------------------------------------
    // onCreateNewPassword
    //-------------------------------------------
    function onCreateNewPassword() {
        return (
            <Dialog classes={{ paper: classes.dialogPaper }}
                // 
                open={showCreateNewPasswordDialog}
                TransitionComponent={Transition}
                // keepMounted
                onClose={toggleShowCreateNewPasswordDialog}
            >
                {/* Users's Email was not found in the system */}
                {onEmailNotFound()}
                {onEmailFound()}

                <Grid container style={{ justifyContent: 'space-evenly', margin: '12px 0 8px' }}>
                    <Button disableFocusRipple disableRipple size="large"
                        variant="contained" color="primary"
                        style={SmallButton}
                        startIcon={<NavigateBeforeIcon />}
                        onClick={toggleShowCreateNewPasswordDialog}
                    >
                        <FormattedMessage id="system.back" defaultMessage='Back' />
                    </Button>

                    <Button disabled={incomplete || isAuthenticating || Boolean(errors.email)}
                        disableFocusRipple disableRipple size="large"
                        variant="contained" color="primary"
                        style={SmallButton}
                        endIcon={<NavigateNextIcon />}
                        onClick={e => handleUpdateNewPassword(e)}
                    >
                        <FormattedMessage id="system.next" defaultMessage="Next" />
                    </Button>
                </Grid>
            </Dialog>
        )
    }


    return (
        <Div100vh>
            <LayoutMax style={{ display: 'flex', justifyContent: 'center' }}>
                <img alt='FindTrueFriends' src={image} className={classes.image} />

                {onLogin()}

                {onForgotPasswordEmail()}

                {onValidationMethod()}

                {onEnterSecurityPhrase()}

                {onCreateNewPassword()}

                <div id='spinner' style={{ boxSizing: "content-box", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <BeatLoader css={cssBeatLoader} sizeUnit={"px"} size={32} color={theme.palette.primary.dark} loading={isAuthenticating} />
                </div>
            </LayoutMax>
        </Div100vh>
    )
}


