// search.actions.js

import axios from 'axios'
import { logoutUser } from './auth.actions'
import { setAuthToken } from '../setAuthToken';
import { CONNECTION_ERROR } from '../actions/error.actions'
import { gaFireEvent } from '../helpers/utilities'

export const SEARCH_UPDATE = 'SEARCH_UPDATE'
export const SEARCH_REQUEST = 'SEARCH_REQUEST'
export const SEARCH_SUCCESS = 'SEARCH_SUCCESS'
export const SEARCH_FAILED = 'SEARCH_FAILED'

export const SEARCH_SAVE_REQUEST = 'SEARCH_SAVE_REQUEST'
export const SEARCH_SAVE_SUCCESS = 'SEARCH_SAVE_SUCCESS'
export const SEARCH_SAVE_FAILED = 'SEARCH_SAVE_FAILED'



//-----------------------------------------------
// savedSearches
// search: search options
// command: "add", "delete", "get"
//-----------------------------------------------
export const savedSearches = (search, command) => dispatch => {

    gaFireEvent('search', {
        event_category: 'saved',
        search_term: search,
    })

    dispatch(start(search))

    axios.post('/api/users/searches', { params: { search: search, command: command } })
        .then(response => {
            if (response.status === 200) {
                const { token, search } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(search))
            }
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(search) { return { type: SEARCH_SAVE_REQUEST, search } }
    function success(search) { return { type: SEARCH_SAVE_SUCCESS, search } }
    function failed(errors) { return { type: SEARCH_SAVE_FAILED, errors } }
}



// https://react-redux.js.org/api/hooks#stale-props-and-zombie-children
export const updateSearch = (search) => dispatch => {
    gaFireEvent('search', {
        event_category: 'update',
        search_term: search,
    })

    return dispatch({ type: SEARCH_UPDATE, search })
}


export const runSearch = (search) => {
    return (dispatch, getState) => {
        if (shouldRunSearch(getState(), search)) {
            return dispatch(doRunSearch(search))
        }
    }
}


function shouldRunSearch(state, search) {
    if (state.isSearching)
        return false
    else
        return true
}


const doRunSearch = (search) => dispatch => {
    gaFireEvent('search', {
        event_category: 'search',
        label: 'search',
        search_term: search,
    })

    // console.log('Running Search...')
    dispatch(start(search))

    axios.post('/api/users/search', { params: { ...search } })
        .then(response => {
            if (response.status === 200) {
                const { token, searchResults } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(searchResults))
            }
            else // 206 - Validation Error(s)   
            {
                dispatch(failed(response && response.data ? response.data : response))
            }
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(search) { return { type: SEARCH_REQUEST, search } }
    function success(searchResults) { return { type: SEARCH_SUCCESS, searchResults } }
    function failed(errors) { return { type: SEARCH_FAILED, errors } }
}

