import isEmpty from '../validation/is-empty'
import moment from 'moment'

import { Genders, GroupType } from '../constants/ftfDefinitions';

import { styled } from '@mui/material/styles'

import { Box } from '@mui/material';

// getScale
// export function getScale(isMiles) {
//     return isMiles ? "Miles" : "km"
// }

// export function isMobile() {
//     const isAndroid = /Android/i.test(navigator.userAgent);
//     const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
//     return isAndroid || isiOS;
// }

// https://github.com/styled-components/styled-elements
// https://medium.com/eedi/animating-svg-with-styled-components-34284a7429a3
// https://medium.com/@rossbulat/working-with-svgs-in-react-d09d1602a219
// https://spectrum.chat/styled-components?tab=posts
// https://stackoverflow.com/questions/47635991/styled-components-props-for-hover
export const JellyAnimation = styled(Box)`  
    display: flex;
    align-items: center;

    &:hover {
        animation: gelatine 0.5s;
    }

    @keyframes gelatine {
        from, to { transform: scale(1, 1); }
        25% { transform: scale(0.9, 1.1); }
        50% { transform: scale(1.1, 0.9); }
        75% { transform: scale(0.95, 1.05); }
      }
  `;

export const ActionAnimation = styled(Box)`  
    // display: flex;
    // align-items: center;

    height: auto;
    width:  100px;
    background-color: white;

    // for shine & gradient effects to work
    // position: relative;
    // overflow: hidden;

    // for background color shift
    // background-image: (linear-gradient(270deg, #8e9ac2, #42579a));
    // background-size: 400% 400%;

    animation: TransitioningBackground 1s;
    animation-duration: 10s;
    animation-iteration-count: infinite;

    // to ease the button growth on hover
    // transition: 0.6s;

    @keyframes TransitioningBackground {
        from {background-color: black;}
        to {background-color: darkgray;}
      }
  `;


export const GA_TRACKING_ID = "G-CC950TJG3M"

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
// Takes a history.location object
export const gaFirePageview = location => {
    window.gtag('config', GA_TRACKING_ID, {
        page_title: location.pathname,
        page_path: location.pathname,
        // page_location: location.url,
    })
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
// https://developers.google.com/analytics/devguides/collection/ga4/reference/events#share
export const gaFireEvent = (action, options) => {
    window.gtag('event', action, { ...options })
}


export function getChipLabel(defs, value) {
    var item = defs.filter(d => d.value === value)

    if (item.length && !isEmpty(item[0].id)) {
        return { id: item[0].id, defaultMessage: item[0].label }
    } else {    // No answer
        return { id: 'system.noAnswer', defaultMessage: 'No answer' }
    }
}


export function getSelection(defs, value) {
    var item = defs.find(d => d.value === value)

    if (!isEmpty(item)) {
        return { id: item.id, defaultMessage: item.label }
    } else {    // No answer
        return { id: 'system.noAnswer', defaultMessage: "No answer" }
    }
}



// Round Numbers (accurately)
export function roundN(num, n) {
    return Number(parseFloat(Math.round(num * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n))
}


// getAge
export function getAge(dateString) {
    var today = new Date()
    var birthDate = new Date(dateString)
    var age = today.getFullYear() - birthDate.getFullYear()
    var m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age
}


// getYearBornFromAge
export function getYearBornFromAge(age) {
    // var birthDate = moment.utc().subtract(parseFloat(age).toFixed(1), 'years').format('MMM YYYY')

    var birthDate = moment.utc().subtract(parseFloat(age).toFixed(2), 'years')
    return birthDate
}


// getGender
export function getGender(id) {
    // Filtering out Couples
    if (id != null)
        return Genders.find(({ value }) => value === id).id
    // else
    //     return 2  // Default is Female for men (fm)
}


// getRelationship
// export function getRelationship(id) {
//     if (id != null)
//         return Relationship.find(({value}) => value === id).id
// }


// getGroupType
export function getGroupType(id) {
    if (id != null && id > 0)
        return GroupType.find(({ value }) => value === id).id
}


/**
 * Simple "is" object check
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
    return (item && typeof item === 'object' && !Array.isArray(item) && item !== null);
}


/**
 * Deep merge two objects.
 * @param target
 * @param source
 
 * Example usage:
        mergeDeep(this, { a: { b: { c: 123 } } });
             or
        const merged = mergeDeep({a: 1}, { b : { c: { d: { e: 12345}}}});  
        console.dir(merged); // { a: 1, b: { c: { d: [Object] } } }
 */
export function mergeDeep(target, source) {
    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }
    return target;
}






