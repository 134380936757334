
// https://blog.logrocket.com/how-to-use-bootstrap-with-react-a354715d1121
// https://material-ui.com/demos/drawers/

import React from 'react'
import ReactDOM from 'react-dom'

import ThemeApp from "./styles/ThemeApp";

import { BrowserRouter } from 'react-router-dom'

import App from './App.jsx'

import * as serviceWorkerRegistration from './serviceWorkerRegistration'

// TODO: DSW
// https://create-react-app.dev/docs/measuring-performance/
// With the reportWebVitals function, you can send any of results to an analytics endpoint to measure and track real user performance on your site...
// import reportWebVitals from './reportWebVitals';

import { Provider } from 'react-redux'
import store from './store'

// Take out for Production 
// import DevTools from './_components/DevTools'

// import './scss/ftf.scss'

//-----------------------------------------------
// FTF Theme
//-----------------------------------------------
import CssBaseline from '@mui/material/CssBaseline'
// import createBreakpoints from '@mui/material/styles/createBreakpoints'
import { ThemeProvider, StyledEngineProvider, responsiveFontSizes } from '@mui/material/styles';  // responsiveFontSizes

// TODO - Old browser support? - DSW

//-----------------------------------------------
// Localization
//-----------------------------------------------
// https://medium.com/ableneo/internationalize-react-apps-done-right-using-react-intl-library-82978dbe175e
// https://stackoverflow.com/questions/35776663/react-intl-multi-language-app-changing-languages-and-translations-storage
// https://www.smashingmagazine.com/2017/01/internationalizing-react-apps/

import { IntlProvider } from 'react-intl'
import messages from './constants/intlMessages'


// Localization override...
var locale = localStorage.locale ||
    (navigator.languages && navigator.languages[0]) || navigator.language
    || navigator.userLanguage
    || 'es-ES'


//-----------------------------------------------
// responsiveFontSizes
// Note: Only works for Typography (10/19)
//-----------------------------------------------
const theme = responsiveFontSizes(ThemeApp, {factor: 8} )


ReactDOM.render(
    <IntlProvider locale={locale} messages={messages[locale]}>
        <Provider store={store}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <CssBaseline />

                    <BrowserRouter>
                        <App />
                    </BrowserRouter>

                    {/* Take out for Production */}
                    {/* <DevTools /> */}
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    </IntlProvider>, document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
