// group.actions.js
import axios from 'axios'
import { setAuthToken } from '../setAuthToken'
import { logoutUser } from './auth.actions'
import { CONNECTION_ERROR } from './error.actions'

// const moment = require('moment')


// Groups
export const GETGROUPS_REQUEST = 'GETGROUPS_REQUEST'
export const GETGROUPS_SUCCESS = 'GETGROUPS_SUCCESS'
export const GETGROUPS_FAILED = 'GETGROUPS_FAILED'

// Group Create
export const GROUP_CREATE_REQUEST = 'GROUP_CREATE_REQUEST'
export const GROUP_CREATE_SUCCESS = 'GROUP_CREATE_SUCCESS'
export const GROUP_CREATE_FAILED = 'GROUP_CREATE_FAILED'

// Group
export const GROUP_GET_REQUEST = 'GROUP_GET_REQUEST'
export const GROUP_GET_SUCCESS = 'GROUP_GET_SUCCESS'
export const GROUP_GET_FAILED = 'GROUP_GET_FAILED'

export const GROUP_DELETE_REQUEST = 'GROUP_DELETE_REQUEST'
export const GROUP_DELETE_SUCCESS = 'GROUP_DELETE_SUCCESS'
export const GROUP_DELETE_FAILED = 'GROUP_DELETE_FAILED'

// export const GROUP_NEW_MSG = 'GROUP_NEW_MSG'
export const GROUP_UPDATE_REQUEST = 'GROUP_UPDATE_REQUEST'
export const GROUP_UPDATE_SUCCESS = 'GROUP_UPDATE_SUCCESS'
export const GROUP_UPDATE_FAILED = 'GROUP_UPDATE_FAILED'

export const GROUP_GET_MSGS_REQUEST = 'GROUP_GET_MSGS_REQUEST'
export const GROUP_GET_MSGS_SUCCESS = 'GROUP_GET_MSGS_SUCCESS'
export const GROUP_GET_MSGS_FAILED = 'GROUP_GET_MSGS_FAILED'

// Group Likes
export const GROUP_LIKE_REQUEST = 'GROUP_LIKE_REQUEST'
export const GROUP_LIKE_SUCCESS = 'GROUP_LIKE_SUCCESS'
export const GROUP_LIKE_FAILED = 'GROUP_LIKE_FAILED'

// Group Event - Like
export const GROUP_EVENTLIKE_REQUEST = 'GROUP_EVENTLIKE_REQUEST'
export const GROUP_EVENTLIKE_SUCCESS = 'GROUP_EVENTLIKE_SUCCESS'
export const GROUP_EVENTLIKE_FAILED = 'GROUP_EVENTLIKE_FAILED'

// Group Event - Share
export const GROUP_EVENTSHARE_REQUEST = 'GROUP_EVENTSHARE_REQUEST'
export const GROUP_EVENTSHARE_SUCCESS = 'GROUP_EVENTSHARE_SUCCESS'
export const GROUP_EVENTSHARE_FAILED = 'GROUP_EVENTSHARE_FAILED'

// Group Event - Delete
export const GROUP_EVENTDELETE_REQUEST = 'GROUP_EVENTDELETE_REQUEST'
export const GROUP_EVENTDELETE_SUCCESS = 'GROUP_EVENTDELETE_SUCCESS'
export const GROUP_EVENTDELETE_FAILED = 'GROUP_EVENTDELETE_FAILED'

// Group Create
export const GROUP_CLEAR = 'GROUP_CLEAR'
export const GROUP_CLEAR_ERROR = 'GROUP_CLEAR_ERROR'
export const GROUP_CHANGES_BUFFER = 'GROUP_CHANGES_BUFFER'
export const GROUP_CHANGES_CLEAR = 'GROUP_CHANGES_CLEAR'

// Group Settings
export const GROUP_SETTINGS_REQUEST = 'GROUP_SETTINGS_REQUEST'
export const GROUP_SETTINGS_SUCCESS = 'GROUP_SETTINGS_SUCCESS'
export const GROUP_SETTINGS_FAILED = 'GROUP_SETTINGS_FAILED'

// Bannmed Members
export const BANNED_MEMBERS_REQUEST = 'BANNED_MEMBERS_REQUEST'
export const BANNED_MEMBERS_SUCCESS = 'BANNED_MEMBERS_SUCCESS'
export const BANNED_MEMBERS_FAILED = 'BANNED_MEMBERS_FAILED'

//===============================================
// GROUPS
//===============================================

//-----------------------------------------------
// TODO: Investigate and remove? - DSW
// Saves the changes to Redux too save to the Database...
//-----------------------------------------------
export function groupChangesUpdate(groupChanges) {
    return (dispatch, getState) => {
        dispatch(groupChanges_Update(groupChanges))
    }
}
function groupChanges_Update(groupChanges) { return { type: GROUP_CHANGES_BUFFER, groupChanges } }



//-----------------------------------------------
// Entry point for getGroups...
//-----------------------------------------------
export const getGroups = () => dispatch => {
    // console.log('getGroups:', this)    
    dispatch(start())

    axios.get('/api/users/groups')
        .then(response => {
            if (response.status === 200)
                dispatch(success(response.data.groups))
            else // 206 - Validation Error(s)
                dispatch(failed(response.data.toString()))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: GETGROUPS_REQUEST } }
    function success(groups) { return { type: GETGROUPS_SUCCESS, groups } }
    function failed(errors) { return { type: GETGROUPS_FAILED, errors } }
}

//-----------------------------------------------
// Entry point for groupCreate...
//-----------------------------------------------
// group - Group Demographics
export const groupCreate = (group) => dispatch => {
    dispatch(start())

    axios.post('/api/users/gc', { params: { group: group } })
        .then(response => {
            if (response.status === 200)
                dispatch(success(response.data.group))
            else // 206 - Validation Error(s)
                dispatch(failed(group, response.data.toString()))
        })
        .then(() => Promise.all([
            dispatch(getGroups())
        ])
        )
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: GROUP_CREATE_REQUEST } }
    function success(group) { return { type: GROUP_CREATE_SUCCESS, group } }
    function failed(errors) { return { type: GROUP_CREATE_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for getGroup...
//-----------------------------------------------
export const getGroup = (groupId) => dispatch => {
    dispatch(start(groupId))

    axios.get('/api/users/group', { params: { groupId: groupId } })
        .then(response => {
            if (response.status === 200) {
                const { token, group } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(group))
            }
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(groupId) { return { type: GROUP_GET_REQUEST, groupId } }
    function success(group) { return { type: GROUP_GET_SUCCESS, group } }
    function failed(errors) { return { type: GROUP_GET_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for GroupLike...
//-----------------------------------------------
// Group Liked
//-----------------------------------------------
export const groupLike = (groupId) => dispatch => {
    dispatch(start(groupId))

    axios.post('/api/users/gl', { params: { groupId: groupId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(groupId, response.data.toString()))
        })
        .then(() => Promise.all([
            dispatch(getGroup(groupId))
            // dispatch(getGroups())
        ])
        )
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: GROUP_LIKE_REQUEST } }
    function success() { return { type: GROUP_LIKE_SUCCESS } }
    function failed(errors) { return { type: GROUP_LIKE_FAILED, errors } }
}


//-----------------------------------------------
// groupSettings
// TODO: Slow this down (debounce saves)
//-----------------------------------------------
export const groupSettings = (groupId, xxx) => dispatch => {
    dispatch(start(groupId))

    axios.post('/api/users/gs', { params: { groupId, ...xxx } })
        .then(response => {
            if (response.status === 200) {
                const { token, group } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(group))
            }
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(groupId) { return { type: GROUP_SETTINGS_REQUEST, groupId } }
    function success(group) { return { type: GROUP_SETTINGS_SUCCESS, group } }
    function failed(errors) { return { type: GROUP_SETTINGS_FAILED, errors } }
}


//-----------------------------------------------
// groupAdmin
//-----------------------------------------------
export const groupAdmin = (groupId, memberId, isAdmin, rights) => dispatch => {
    dispatch(start())

    axios.post('/api/users/ga', { params: { groupId, memberId, isAdmin, rights } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(groupId, response.data.toString()))
        })
        .then(() => Promise.all([
            dispatch(getGroup(groupId))
        ])
        )
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: GROUP_UPDATE_REQUEST } }
    function success() { return { type: GROUP_UPDATE_SUCCESS } }
    function failed(errors) { return { type: GROUP_UPDATE_FAILED, errors } }
}


//-----------------------------------------------
// GROUP EVENTS
//-----------------------------------------------
// eventId - GroupEvent Liked
//-----------------------------------------------
export const groupEventLike = (groupId, eventId) => dispatch => {
    dispatch(start(groupId))

    axios.post('/api/users/gel', { params: { groupId: eventId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(eventId, response.data.toString()))
        })
        // Causes a refresh
        // .then(() => Promise.all([
        //     dispatch(getGroup(eventId)) 
        //     ])
        // ) 
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: GROUP_EVENTLIKE_REQUEST } }
    function success() { return { type: GROUP_EVENTLIKE_SUCCESS } }
    function failed(errors) { return { type: GROUP_EVENTLIKE_FAILED, errors } }
}

//-----------------------------------------------
// eventId - GroupEvent Shared
//-----------------------------------------------
export const groupEventShare = (groupId, eventId) => dispatch => {
    dispatch(start(groupId))

    axios.post('/api/users/ges', { params: { eventId: eventId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(eventId, response.data.toString()))
        })
        // Causes HUGE refresh
        // .then(() => Promise.all([
        //     dispatch(getMember(memberId)) 
        //     ])
        // ) 
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: GROUP_EVENTSHARE_REQUEST } }
    function success() { return { type: GROUP_EVENTSHARE_SUCCESS } }
    function failed(errors) { return { type: GROUP_EVENTSHARE_FAILED, errors } }
}

//-----------------------------------------------
// eventId - GroupEvent to Remove
//-----------------------------------------------
export const groupEventDelete = (groupId, eventId) => dispatch => {
    dispatch(start(groupId))

    axios.post('/api/users/ged', { params: { eventId: eventId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(eventId, response.data.toString()))
        })
        // .then(() => Promise.all([
        //     dispatch(getMember(memberId)) 
        //     ])
        // ) 
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: GROUP_EVENTDELETE_REQUEST } }
    function success() { return { type: GROUP_EVENTDELETE_SUCCESS } }
    function failed(errors) { return { type: GROUP_EVENTDELETE_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for getGroupMessages...
//-----------------------------------------------
export const groupGetMessages = (groupId) => dispatch => {

    dispatch(start(groupId))

    axios.get('/api/users/msgs', { params: { groupId: groupId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success(response.data.messages))
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(groupId) { return { type: GROUP_GET_MSGS_REQUEST, groupId } }
    function success(messages) { return { type: GROUP_GET_MSGS_SUCCESS, messages } }
    function failed(errors) { return { type: GROUP_GET_MSGS_FAILED, errors } }
}


//-----------------------------------------------
// getBannedMembers
//-----------------------------------------------
export const getBannedMembers = (groupId) => dispatch => {

    dispatch(start())

    axios.get('/api/users/gbms', { params: { groupId: groupId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success(response.data.blocked))
            else // 206 - Validation Error(s)
                dispatch(failed(response.data.toString()))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log(errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: "Cannot connect to the server" })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: BANNED_MEMBERS_REQUEST } }
    function success(blocked) { return { type: BANNED_MEMBERS_SUCCESS, blocked } }
    function failed(errors) { return { type: BANNED_MEMBERS_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for groupDelete...
// 
// https://stackoverflow.com/questions/51069552/axios-delete-request-with-body-and-headers
// https://appdividend.com/2018/08/30/getting-started-with-axios-tutorial-example/
// https://medium.com/@njwest/building-a-react-native-jwt-client-api-requests-and-asyncstorage-d1a20ab60cf4
//-----------------------------------------------
export const groupDelete = (groupId) => dispatch => {
    dispatch(start(groupId))

    axios.post('/api/users/gd', { params: { groupId: groupId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .then(() => Promise.all([
            dispatch(getGroups())
        ])
        )
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })
        .then(() => Promise.all([
            dispatch(getGroup())
        ])
        )

    function start() { return { type: GROUP_DELETE_REQUEST } }
    function failed(errors) { return { type: GROUP_DELETE_FAILED, errors } }
    function success() { return { type: GROUP_DELETE_SUCCESS } }
}
