// https://developers.google.com/maps/documentation/geocoding/intro#ReverseGeocoding

import React, { useState } from "react"

import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles';
import isEmpty from '../validation/is-empty'
import { useTheme } from '@mui/material/styles'

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'
import { JellyAnimation } from '../helpers/utilities'

import { MyTabbedLayout, PageHeading } from "../styles/ftfStyles"

import { Box, Grid, Button, CircularProgress, Tab, Tabs, Tooltip, Typography, Zoom } from '@mui/material'
import { FormattedMessage } from 'react-intl'

// import { lightBlue, purple, pink, grey } from '@mui/material/colors'

import NotificationsIcon from '@mui/icons-material/Notifications'
import InfoIcon from '@mui/icons-material/Info'
import DescriptionIcon from '@mui/icons-material/Description'
import EditLocationIcon from '@mui/icons-material/EditLocation'
import SecurityIcon from '@mui/icons-material/Security'

const ProfileBasics = React.lazy(() => import("./ProfileBasics"))
const ProfileSecurity = React.lazy(() => import("./ProfileSecurity"))

import ProfileDesc from './ProfileDesc'

import ProfileLocation from './ProfileLocation'
import ProfileNotifications from './ProfileNotifications'


import CloudUploadIcon from '@mui/icons-material/CloudUpload';

// Save
import { profileChangesSave } from '../actions/profile.actions'

import { css } from 'styled-components'
import BeatLoader from 'react-spinners/BeatLoader'


const cssBeatLoader = css`
    display: block
    margin: 0 auto
    border-color: red
`

const useStyles = makeStyles(theme => ({
    buttonSave: {
        margin: '0 12px',
        padding: '2px 8px',
        minWidth: 130,
    },

    headerRegion: {
        display: 'flex',
        marginLeft: 4,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        height: 34,
        alignItems: 'center',

        // Phone Landscape
        [theme.breakpoints.down('md')]: {
            height: 34,
        },

        // Phone Portrait
        [theme.breakpoints.down('sm')]: {
            height: 34,
        },
    },

    tabStyle: {
        backgroundColor: '#FFFFFF',
        overflow: 'hidden',
        width: '100%',
    },

    // Note: Done in ThemeApp - Left as an example - DSW
    // tab: {
    //     background: theme.palette.primary.main,
    //     '&.mui-selected': {
    //         background: lightBlue[600]
    //     },
    //     '&.hover': {
    //         background: pink[600]
    //     }
    // },
}))

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <span>{children}</span>
                </Box>
            )}
        </div>
    );
}


export default function Profile(props) {
    const theme = useTheme()
    const classes = useStyles(props)

    const profile = useSelector(state => state.profile.profile)
    const isLoading = useSelector(state => state.profile.isLoading)
    const isSaving = useSelector(state => state.profile.isSaving)
    const isPristine = useSelector(state => state.profile.isPristine)

    const dispatch = useDispatch()

    const [tabIndex, setTabIndex] = useState(0)


    //===========================================
    // handleProfileSave
    //===========================================
    function handleProfileSave(e) {
        e.preventDefault()
        dispatch(profileChangesSave())
    }


    //===========================================
    // Tabs
    //===========================================
    function handleTabChange(tabIndex) {
        setTabIndex(tabIndex)
    }


    return (
        <Div100vh>
            <div>
                <div id='spinner' style={{ zIndex: 9100, boxSizing: "content-box", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <BeatLoader css={cssBeatLoader} sizeUnit={"px"} size={32} color={theme.palette.primary.dark} loading={isLoading} />
                </div>

                <form onSubmit={e => handleProfileSave(e)}>
                    <Grid container direction="row" wrap='nowrap' alignItems="flex-start" className={classes.headerRegion}>
                        <Typography variant="h5" style={PageHeading}>
                            {profile && <FormattedMessage id="profile.profilePlural" defaultMessage="'s Profile" values={{ firstname: profile.firstname }} />}
                        </Typography>

                        <Button type="submit" variant="contained" color="primary" disabled={isSaving || isPristine} className={classes.buttonSave}>
                            {isSaving && <CircularProgress size={24} color="secondary" style={{ marginRight: 8, padding: 0 }} />}
                            {isSaving && <FormattedMessage id="profile.saving" defaultMessage="Saving..." />}
                            {!isSaving && <CloudUploadIcon style={{ marginRight: 8, padding: 0 }} />}
                            {!isSaving && <FormattedMessage id="profile.saveChanges" defaultMessage="Save" />}
                        </Button>

                        {/* <Button variant="contained" color="primary" disabled={isSaving || isPristine} className={classes.buttonSave}>
                             {!isSaving && <FormattedMessage id="profile.saveChanges" defaultMessage="Cancel" />}
                        </Button> */}
                    </Grid>

                    <Tabs aria-label='Tabs' selectionFollowsFocus variant="fullWidth" value={tabIndex} onChange={(e, value) => handleTabChange(value)}>
                        <Tooltip arrow enterDelay={1500} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="profile.aboutYourself" defaultMessage="About Yourself" />}>
                            <Tab value={0} fontSize="large" icon={
                                <JellyAnimation>
                                    <InfoIcon />
                                </JellyAnimation>}
                            />
                        </Tooltip>

                        <Tooltip arrow enterDelay={1500} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="profile.describe" defaultMessage="Describe Yourself" />}>
                            <Tab value={1} fontSize="large" icon={
                                <JellyAnimation>
                                    <DescriptionIcon />
                                </JellyAnimation>}
                            />
                        </Tooltip>

                        <Tooltip arrow enterDelay={1500} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="profile.location" defaultMessage="Your Location" />}>
                            <Tab value={2} fontSize="large" icon={
                                <JellyAnimation>
                                    <EditLocationIcon />
                                </JellyAnimation>}
                            />
                        </Tooltip>

                        <Tooltip arrow enterDelay={1500} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="profile.notify" defaultMessage="Notify Settings" />}>
                            <Tab value={3} fontSize="large" icon={
                                <JellyAnimation>
                                    <NotificationsIcon />
                                </JellyAnimation>}
                            />
                        </Tooltip>

                        <Tooltip arrow enterDelay={1500} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="profile.security" defaultMessage="Security Settings" />}>
                            <Tab value={4} fontSize="large" icon={
                                <JellyAnimation>
                                    <SecurityIcon />
                                </JellyAnimation>}
                            />
                        </Tooltip>
                    </Tabs>

                    {!isEmpty(profile) && <MyTabbedLayout>
                        <TabPanel value={tabIndex} index={0}>
                            <React.Suspense fallback={<div />}>
                                <div className={classes.tabStyle}>
                                    <ProfileBasics />
                                </div>
                            </React.Suspense>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={1}>
                            <div className={classes.tabStyle}>
                                <ProfileDesc />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={2}>
                            <div className={classes.tabStyle}>
                                <ProfileLocation />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={3}>
                            <div className={classes.tabStyle}>
                                <ProfileNotifications />
                            </div>
                        </TabPanel>
                        <TabPanel value={tabIndex} index={4}>
                            <React.Suspense fallback={<div />}>
                                <div className={classes.tabStyle}>
                                    <ProfileSecurity />
                                </div>
                            </React.Suspense>
                        </TabPanel>
                    </MyTabbedLayout>}
                </form>
            </div >
        </Div100vh>
    )
}

