import React from "react"
import { makeStyles } from '@mui/styles';
import Iframe from 'react-iframe'

import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { FormattedMessage } from 'react-intl'

import { DialogStyles } from "../styles/ftfStyles"

const useStyles = makeStyles(DialogStyles)


export default function TermsOfUseAgreement(props) {
    const classes = useStyles(props)

    return (
        <Dialog classes={{ paper: classes.dialogPaper }}
            fullScreen={props.fullscreen} open={props.show} 
            onClose={props.onClose}
        >
            <DialogTitle className={classes.dialogTitle}>{"FindTrueFriends® TERMS OF USE AGREEMENT"}</DialogTitle>
            <DialogContent style={{ padding: '0px 8px', overflow: 'hidden' }} >
                {/* http://www.youtube.com/embed/xDMP3i36naA */}
                {/* https://findtruefriends.com/TermsOfUseAgreement.html */}

                <Iframe className={classes.iframeStyle}
                    url="https://findtruefriends.com/TermsOfUseAgreement.html"
                    width="100%" 
                    height="100%" 
                    display="initial"
                    position="relative" 
                />
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="primary" autoFocus>
                    <FormattedMessage id="system.close" defaultMessage="Close" />
                </Button>
            </DialogActions>
        </Dialog>
    )
}

// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
// var injectTapEventPlugin = import "react-tap-event-plugin"
// injectTapEventPlugin()
