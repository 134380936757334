// ad.actions.js

import axios from 'axios'
import { logoutUser } from './auth.actions'
import { setAuthToken } from '../setAuthToken';
import { CONNECTION_ERROR } from './error.actions'


export const AD_REQUEST = 'AD_REQUEST'
export const AD_SUCCESS = 'AD_SUCCESS'
export const AD_FAILED = 'AD_FAILED'

export const ADS_REQUEST = 'ADS_REQUEST'
export const ADS_SUCCESS = 'ADS_SUCCESS'
export const ADS_FAILED = 'ADS_FAILED'

export const AD_BLOCK_REQUEST = 'AD_BLOCK_REQUEST'
export const AD_BLOCK_SUCCESS = 'AD_BLOCK_SUCCESS'
export const AD_BLOCK_FAILED = 'AD_BLOCK_FAILED'

export const AD_CLICKS_REQUEST = 'AD_CLICKS_REQUEST'
export const AD_CLICKS_SUCCESS = 'AD_CLICKS_SUCCESS'
export const AD_CLICKS_FAILED = 'AD_CLICKS_FAILED'




export const getAd = (variant, height, width) => dispatch => {
    // console.log('Running Get...')
    dispatch(start())

    axios.get('/api/ads/ad', { params: { variant: variant, height: height, width: width } })
        .then(response => {
            if (response.status === 200) {
                const { token, ad } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(ad))
            }
            else // 206 - Validation Error(s)   
                dispatch(failed(response && response.data ? response.data : response))
            // error => dispatch(failed(error.toString()))
        })
        .catch(error => {
            // let errMsg = error.message
            // if (error.response != null) {
            //     errMsg = error.response.status.toString() + " - "
            //     errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            // }

            // console.log('Error: ' + errMsg)
            // dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            //     // Logout
            //     dispatch(logoutUser())
            //     window.location.href = '/login'
        })

    function start() { return { type: AD_REQUEST } }
    function success(ad) { return { type: AD_SUCCESS, ad } }
    function failed(errors) { return { type: AD_FAILED, errors } }

    // result: json.data.children.map(child => child.data),
}




export const runGetAds = (height, width) => {
    return (dispatch, getState) => {
        if (shouldGetAds(getState(), height, width)) {
            return dispatch(doGetAds(height, width))
        }
    }
}

function shouldGetAds(state, memberId) {
    const ad = state.ad
    if (!ad) {
        return true
    } else if (ad.isLoading) {
        return false
    } else {
        return true
    }
}

const doGetAds = (ads) => dispatch => {
    // console.log('Running Get...')
    dispatch(start(ads))

    axios.get('/api/ads/allAds', { params: { ...ads } })
        .then(response => {
            if (response.status === 200) {
                const { token, ads } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(ads))
            }
            else // 206 - Validation Error(s)   
                dispatch(failed(response && response.data ? response.data : response))
            // error => dispatch(failed(error.toString()))
        })
        .catch(error => {
            // let errMsg = error.message
            // if (error.response != null) {
            //     errMsg = error.response.status.toString() + " - "
            //     errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            // }

            // console.log('Error: ' + errMsg)
            // dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // // Logout
            // dispatch(logoutUser())
            // window.location.href = '/login'
        })

    function start(ads) { return { type: AD_REQUEST, ads } }
    function success(ads) { return { type: AD_SUCCESS, ads } }
    function failed(errors) { return { type: AD_FAILED, errors } }
}



//-----------------------------------------------
// Entry point for blockAd...
//-----------------------------------------------
// vendorId - Vendor Id
// adId - Ad Id
//-----------------------------------------------
export const blockAd = (vendorId, adId) => dispatch => {
    dispatch(start())

    axios.delete('/api/ads/blockAd', { params: { vendorId: vendorId, adId: adId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success())
            else // 206 - Validation Error(s)
                dispatch(failed(adId, response.data.toString()))
        })
        // .then(() => Promise.all([
        //     dispatch((idType === 'group') ? getGroup(id) : runGetAds()) 
        // ]))
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start() { return { type: AD_BLOCK_REQUEST } }
    function success() { return { type: AD_BLOCK_SUCCESS } }
    function failed(errors) { return { type: AD_BLOCK_FAILED, errors } }
}


//-----------------------------------------------
// Entry point for getAdClicks...
// TODO: Future
//-----------------------------------------------
export const getAdClicks = (idType, id, photoId) => dispatch => {
    dispatch(start(photoId))

    axios.get('/api/ads/clicks', { params: { idType: idType, id: id, photoId: photoId } })
        .then(response => {
            if (response.status === 200)
                dispatch(success(response.data.members))
            else // 206 - Validation Error(s)
                dispatch(failed(photoId, response.data.toString()))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(photoId) { return { type: AD_CLICKS_REQUEST, photoId } }
    function success(members) { return { type: AD_CLICKS_SUCCESS, members } }
    function failed(errors) { return { type: AD_CLICKS_FAILED, errors } }
}
