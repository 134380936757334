// whois.actions.js

import axios from 'axios';
import { CONNECTION_ERROR } from '../actions/error.actions';
import { logoutUser } from './auth.actions';

export const WHOIS_ONLINE_REQUEST = 'WHOIS_ONLINE_REQUEST'
export const WHOIS_ONLINE_SUCCESS = 'WHOIS_ONLINE_SUCCESS'
export const WHOIS_ONLINE_FAILED = 'WHOIS_ONLINE_FAILED'


export const whoIsOnline = () => dispatch => {
    // console.log('whoIsOnline:', this);
    dispatch(start())

    axios.get('/api/users/whois')
        .then(response => {
            if (response.status === 200) 
                dispatch(success(response.data.members))
            else // 206 - Validation Error(s)
                dispatch(failed(response.data.toString()))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log( 'Error: ' + errMsg )
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser());
            window.location.href = '/login'
        })

    function start()          { return { type: WHOIS_ONLINE_REQUEST } }
    function success(members) { return { type: WHOIS_ONLINE_SUCCESS, members } }
    function failed(errors)   { return { type: WHOIS_ONLINE_FAILED, errors } }
}


