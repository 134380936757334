import React from "react"
import { useDispatch } from "react-redux"
import { useNavigate } from 'react-router-dom'
import { makeStyles } from '@mui/styles';
import { StyledBadge } from "../styles/ftfStyles"

import { lighten } from '@mui/material/styles';
import { lightBlue } from '@mui/material/colors'

import isEmpty from '../validation/is-empty';
import Masonry from 'react-masonry-css'

import { Tooltip, Typography, Zoom } from '@mui/material'

import NotificationsNoneTwoToneIcon from '@mui/icons-material/NotificationsNoneTwoTone'
import SecurityTwoToneIcon from '@mui/icons-material/SecurityTwoTone'
import ThumbUpTwoToneIcon from '@mui/icons-material/ThumbUpTwoTone'
import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone'

import { FormattedMessage } from 'react-intl'

// import BeatLoader from 'react-spinners/BeatLoader'
// import styled, { css } from 'styled-components'   // keyframes

import { getGroup } from '../actions/group.actions'

// Masonry
// https://codepen.io/LasseStilvang/pen/BrpqKx?editors=1100
// https://codepen.io/asistapl/pen/yPGqWK

// https://masonry.desandro.com/layout.html
// https://codepen.io/chriscoyier/pen/NeRNBO
// https://codepen.io/balazs_sziklai/pen/mOwoLg

const useStyles = makeStyles(theme => ({
    groupDetails: {
        // marginRight: 4,
        // textAlign: 'right',
        fontSize: '.6rem',
    },

    groupName: {
        color: theme.palette.primary.dark,
        marginTop: -4,
        wordBreak: 'break-all',
    },

    iconButton: {
        marginRight: 8,
        padding: 0
    },

    favIcon: {
        color: theme.palette.secondary.main
    },

    gallery: {
        boxSizing: 'border-box',
        overflow: 'auto',
        padding: 4,
    },

    // https://codepen.io/dudleystorey/pen/yqrhw

    myMasonryGrid: {
        display: '-webkit-box', /* Not needed if autoprefixing */
        // eslint-disable-next-line no-dupe-keys
        display: '-ms-flexbox', /* Not needed if autoprefixing */
        // eslint-disable-next-line no-dupe-keys
        display: 'flex',
        marginLeft: 0, /* gutter size offset */
        marginRight: 4, /* mobile scrollbar */
        width: 'auto',
    },

    myMasonryGridColumn: {
        padding: 0, /* gutter size */
        backgroundClip: 'padding-box',
    },

    // actual <img />
    photo: {
        boxSizing: 'border-box',
        borderRadius: '10px 10px 0 0',
        maxWidth: '100%',
        // height: 'auto',

        // width: '100%',
        // height: 'auto',
        // borderBottom: '1px solid #ccc',
        // paddingBottom: '15px',
        // marginBottom: '5px',
    },

    // content
    widget: {
        background: theme.palette.info.light,

        border: '1px solid',
        borderColor: theme.palette.info.main,
        borderRadius: 10,

        boxShadow: '0 1px 2px rgba(34, 25, 25, 0.4)',

        breakInside: 'avoid-column',  // required

        cursor: 'pointer',
        '&:hover, &:active': {
            backgroundColor: lighten(lightBlue[100], 0.75),
            // backgroundColor: 'rgba(0, 0, 0, 0.04)',
        },

        '&:active': {
        },

        // display: 'inline-block',
        // columnBreakInside: 'avoid',

        minWidth: 150,  // Mostly for while loading so they don't stack...

        padding: 0,
        margin: 8,
        marginBottom: '.5rem',

        textAlign: 'center',  // Center image
        transition: 'opacity .4s ease-in-out',

        // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
        transform: "translateZ(0)",
    },

}))


// https://stackoverflow.com/questions/50819260/react-input-onchange-lag
// https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js
// https://reactjs.org/docs/hooks-reference.html#usememo
export default function GroupsGallery(props) {
    const classes = useStyles(props)
    let navigate = useNavigate()

    // const isLoading = useSelector(state => state.member.isLoading)

    // const userId = useSelector(state => state.profile.profile._id)
    const dispatch = useDispatch()

    // const [showDeleteWarning, setShowDeleteWarning] = useState(false)
    // const bouncedDelete = React.useCallback(debounce(debouncedDelete, 333), []);


    //-------------------------------------------
    // Delete Group
    // TODO: pr noyt TODO? - DSW
    //-------------------------------------------
    // const onDeleteWarningClose = () => {
    //     setShowDeleteWarning(false)
    // }

    // function onDeleteWarning() {
    //     return (
    //         //
    //         <Dialog
    //             open={showDeleteWarning}
    //             onClose={onDeleteWarningClose}
    //         >
    //             <Grid container style={{ display: 'flex', justifyContent: "center" }}>
    //                 <Typography style={PageHeading} variant="h5">
    //                     <FormattedMessage id="system.confirm" defaultMessage="Confirm" />
    //                 </Typography>
    //             </Grid>

    //             <Divider />

    //             <DialogActions>
    //                 <Button variant="contained" color="primary" autoFocus style={SmallButton} onClick={(e) => onDeletePhoto(e)}>
    //                     <FormattedMessage id="system.delete" defaultMessage="Delete" />
    //                 </Button>
    //                 <Button variant="contained" color="primary" autoFocus style={SmallButton} onClick={onDeleteWarningClose}>
    //                     <FormattedMessage id="system.cancel" defaultMessage="Cancel" />
    //                 </Button>
    //             </DialogActions>
    //         </Dialog>
    //     )
    // }

    //-------------------------------------------
    // groupGallery = React.useMemo speeds this up!
    //-------------------------------------------
    const groupGallery = React.useMemo(() => {

        function onSelectGroup(groupId, e) {
            navigate('/group')
            dispatch(getGroup(groupId))
        }


        const masonryBreakpoints = {
            default: 6,
            1100: 5,
            999: 4,
            800: 3,
            700: 1,
            500: 1,
        }

        return (
            <div className={classes.gallery} >

                <Masonry
                    breakpointCols={masonryBreakpoints}
                    className={classes.myMasonryGrid}
                    columnClassName={classes.myMasonryGridColumn}
                >
                    {/* TODO: Future: {!isLoading && <Spinner />} - DSW */}

                    {props.groups.map((g, i) => (
                        <div id='widget' key={i} className={classes.widget} onClick={(e) => onSelectGroup(g.id, e)}>

                            <img src={g.src} className={classes.photo} alt={i} />

                            {/* Like and Headline */}
                            {!isEmpty(g.memberCount) && <div id='Group' style={{ textAlign: 'left', padding: '0 4px' }}>

                                {/* groupName */}
                                <Typography className={classes.groupName} variant="subtitle2">
                                    {g.groupName}
                                </Typography>

                                {/* Details */}
                                {/* {obj.memberCount === 1 && <Typography className={classes.groupDetails}>
                                    {obj.memberCount} {FormattedMessage({ id: 'system.member', defaultMessage: 'Member' })}<br />
                                </Typography>} */}

                                {/* {obj.memberCount > 1 && <Typography className={classes.groupDetails}>
                                    {obj.memberCount} {FormattedMessage({ id: 'system.members', defaultMessage: 'Members' })}<br />
                                </Typography>} */}

                                {g.isLocal && !isEmpty(g.city) && <Typography className={classes.groupDetails}>
                                    {g.city}, {g.state}<br />
                                </Typography>}

                                <div style={{ height: 25 }} >
                                    {/* TODO: Future - Go to Group Events */}
                                    {/* <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="navbar.events" defaultMessage="Events" />}>
                                        <Icon className={classes.icon} aria-label="Events" >
                                            <DateRangeIcon fontSize="small" />
                                        </Icon>
                                    </Tooltip> */}

                                    {/* Admin */}
                                    {g.isAdminMgr && <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="system.admin" defaultMessage="Admin" />}>
                                        <SecurityTwoToneIcon color="primary" fontSize="small" />
                                    </Tooltip>}

                                    {/* Notifications */}
                                    {/* Should be dirty for each member until seen! */}
                                    {g.hasGroupPosts && <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="system.likes" defaultMessage="Posts" />}>
                                        <StyledBadge aria-label="Posts"
                                            color="secondary"
                                            badgeContent={g.hasGroupPosts} max={999}
                                        >
                                            <NotificationsNoneTwoToneIcon color="primary" fontSize="small" />
                                        </StyledBadge>
                                    </Tooltip>}

                                    {/* Likes */}
                                    <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="system.likes" defaultMessage="Likes" />}>
                                        <StyledBadge aria-label="Likes"
                                            color="secondary"
                                            badgeContent={g.memberCount} max={999}
                                        >
                                            {!g.isMember && <ThumbUpTwoToneIcon color="primary" fontSize="small" />}
                                            {g.isMember && <FavoriteTwoToneIcon color="secondary" fontSize="small" />}
                                        </StyledBadge>
                                    </Tooltip>
                                </div>

                                {/* Delete */}
                                {/* <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="tooltip.remove" defaultMessage="Remove" />}>
                                    <IconButton className={classes.icon} onClick={e => handleShowDeletePhoto(e)} aria-label="Delete" color="primary">
                                        <CancelOutlinedIcon fontSize="small" />
                                    </IconButton>
                                </Tooltip> */}
                            </div>}
                        </div>
                    ))}
                </Masonry>
            </div >
        )
    }, [dispatch, navigate, props, classes.gallery, classes.groupDetails, classes.groupName, classes.myMasonryGrid, classes.myMasonryGridColumn, classes.photo, classes.widget])


    // * TODO: S/B Delete Group *

    const renderGallery = () => {
        if (!props.groups)
            return

        return (<>
            {groupGallery}
            {/* {showDeleteWarning && onDeleteWarning()} */}
        </>)
    }


    return (
        <div>
            {renderGallery()}
        </div>
    );
}


