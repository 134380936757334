// image.Reducer.js

import moment from 'moment'

import { LOGOUT } from '../actions/auth.actions'

import { PHOTO_LIKES_REQUEST, PHOTO_LIKES_SUCCESS, PHOTO_LIKES_FAILED } from '../actions/image.actions';
// import { PHOTO_COMMENT_REQUEST, PHOTO_COMMENT_SUCCESS, PHOTO_COMMENT_FAILED } from '../actions/image.actions';
// import { PHOTO_REMOVE_REQUEST, PHOTO_REMOVE_SUCCESS, PHOTO_REMOVE_FAILED } from '../actions/image.actions';
import { PHOTOS_REQUEST, PHOTOS_SUCCESS, PHOTOS_FAILED } from '../actions/image.actions';
import { PHOTO_UPLOAD_REQUEST, PHOTO_UPLOAD_SUCCESS, PHOTO_UPLOAD_FAILED } from '../actions/image.actions';


const initialState = {
    isLoading: false,
    isWallLoaded: false,
    photo: null,
    photos: [],
    photoLikes: [],
    errors: {},
    receivedAt: moment().format('lll SSS'),
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return { ...initialState }

        case PHOTO_LIKES_REQUEST:
            return {
                ...state,
                isLoading: true,
                photoLikes: [],
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case PHOTO_LIKES_SUCCESS:
            return {
                ...state,
                isLoading: false,
                photoLikes: action.members,
                results: action.results,
                receivedAt: moment().format('lll SSS'),
            }

        case PHOTO_LIKES_FAILED:
            return {
                // ...state,
                isLoading: false,
                photoLikes: [],
                errors: action.errors,
                receivedAt: moment().format('lll SSS'),
            }


        case PHOTOS_REQUEST:
            return {
                ...state,
                isLoading: true,
                isWallLoaded: false,
                memberId: action.memberId,
                // photos: [],
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case PHOTOS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                isWallLoaded: true,
                photos: action.photos,
                receivedAt: moment().format('lll SSS'),
            }

        case PHOTOS_FAILED:
            return {
                // ...state,
                isLoading: false,
                isWallLoaded: false,
                photos: [],
                errors: action.errors,
                receivedAt: moment().format('lll SSS'),
            }


        case PHOTO_UPLOAD_REQUEST:
            return {
                ...state,
                isLoading: true,
                photo: action.photo,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case PHOTO_UPLOAD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                photo: null,
                results: action.results,
                receivedAt: moment().format('lll SSS'),
            }

        case PHOTO_UPLOAD_FAILED:
            return {
                // ...state,
                isLoading: false,
                photo: null,
                errors: action.errors,
                receivedAt: moment().format('lll SSS'),
            }


        default:
            return state;
    }
}


