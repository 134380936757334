// whoViewedMe.actions.js

import axios from 'axios';
import { CONNECTION_ERROR } from './error.actions';
import { logoutUser } from './auth.actions';
import { getProfile } from '../actions/profile.actions'

export const WHOVIEWEDME_REQUEST = 'WHOVIEWEDME_REQUEST'
export const WHOVIEWEDME_SUCCESS = 'WHOVIEWEDME_SUCCESS'
export const WHOVIEWEDME_FAILED = 'WHOVIEWEDME_FAILED'


export const whoViewedMe = () => dispatch => {
    // console.log('whoViewedMe:', this);
    dispatch(start())

    axios.get('/api/users/whoviewedme')
        .then(response => {
            if (response.status === 200) {
                dispatch(success(response.data.members))
                dispatch(getProfile())  // Badge (hasViews)
            }
            else // 206 - Validation Error(s)
                dispatch(failed(response.data.toString()))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log( 'Error: ' + errMsg )
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser());
            window.location.href = '/login'
        })

    function start()          { return { type: WHOVIEWEDME_REQUEST } }
    function success(members) { return { type: WHOVIEWEDME_SUCCESS, members } }
    function failed(errors)   { return { type: WHOVIEWEDME_FAILED, errors } }
}


