// alert.Reducer.js
import moment from 'moment';
import { APP_HEIGHT, APP_WIDTH, SHOW_VIDEO_CHAT, TOGGLE_DRAWER } from '../actions/app.actions';

const initialState = {

    // TODO: Move to socketReducer - DSW
    // VideoChat 
    // accessId: null,
    
    user: null,
    isDrawerOpen: false,
    isRoomCreator: false,
    showVideoChat: false,

    // App Size
    height: '100vh',
    width: '100%',

    receivedAt: moment().format('lll SSS'),
}

export default function appReducer(state = initialState, action) {
    switch (action.type) {

        case APP_HEIGHT:
            return {
                ...state,
                height: action.height,
            }

        case APP_WIDTH:
            return {
                ...state,
                width: action.width,
            }

        case SHOW_VIDEO_CHAT:
            return {
                ...state,
                // accessId: action.accessId,
                user: action.user,
                isRoomCreator: action.isRoomCreator,
                showVideoChat: action.showVideoChat,
            }

        case TOGGLE_DRAWER:
            return {
                ...state,
                isDrawerOpen: action.isDrawerOpen !== null ? action.isDrawerOpen : !state.isDrawerOpen 
            }

        default:
            return state
    }
}