// profile.Reducer.js
import moment from 'moment';

// import { CONNECTION_ERROR } from '../actions/error.actions';
import { LOGOUT } from '../actions/auth.actions'

import { LOCATION_REQUEST, LOCATION_SUCCESS, LOCATION_FAILED } from '../actions/tracker.actions'
import { LOCATION_SAVE_REQUEST, LOCATION_SAVE_SUCCESS, LOCATION_SAVE_FAILED } from '../actions/tracker.actions'
import { LOCATION_PATH_REQUEST, LOCATION_PATH_SUCCESS, LOCATION_PATH_FAILED } from '../actions/tracker.actions'


const initialState = {
    isLoading: false,
    
    // memberId: {},
    coords: {},
    location: {},
    
    // Future Tracker
    isSaved: false,
    path: [],

    errors: {},
    receivedAt: moment().format('lll SSS'),
}

export default function (state = initialState, action) {

    switch (action.type) {
        case LOGOUT:
            return { ...initialState }

        // Get Location Info

        case LOCATION_REQUEST:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                // memberId: action.memberId,
                coords: action.coords,
                location: {},
                isLoading: true,
                errors: {},
            }

        case LOCATION_SUCCESS:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                // location: state.memberId === action.memberId ? action.location : {},  // Simple validation
                location: action.location,
                isLoading: false,
                errors: {},
            }

        case LOCATION_FAILED:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                location: {},
                isLoading: false,
                errors: action.errors,
            }


        // FUTURE - Tracker
        case LOCATION_SAVE_REQUEST:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                // memberId: action.memberId,
                coords: action.coords,
                location: {},
                isLoading: true,
                isSaved: false,
                // Leave the original path alone...
                errors: {},
            }

        case LOCATION_SAVE_SUCCESS:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isLoading: false,
                isSaved: true,
                path: action.path,
                errors: {},
            }

        case LOCATION_SAVE_FAILED:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                location: {},
                isLoading: false,
                isSaved: false,
                // Leave the original path alone...
                errors: action.errors,
            }


        // FUTURE - Path
        case LOCATION_PATH_REQUEST:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                // memberId: action.memberId,
                coords: action.coords,
                location: {},
                isLoading: true,
                isSaved: false,
                // Leave the original path alone...
                errors: {},
            }

        case LOCATION_PATH_SUCCESS:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                isLoading: false,
                path: action.path,
                errors: {},
            }

        case LOCATION_PATH_FAILED:
            return {
                ...state,
                receivedAt: moment().format('lll SSS'),
                location: {},
                isLoading: false,
                path: [],
                errors: action.errors,
            }

        default:
            return state;
    }
}