// https://www.twilio.com/blog/2017/10/implement-chat-javascript-nodejs-react-apps.html
// https://blogs.msdn.microsoft.com/cdndevs/2014/09/19/a-chatroom-for-all-part-3-building-a-backend-with-node-mongo-and-socket-io/

import React, { useCallback, useEffect, useState } from "react"

import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'

import { useTheme } from '@mui/material/styles'

import isEmpty from '../validation/is-empty'

import { ImageList, ImageListItem, Typography } from "@mui/material"

import ChatCard from "./ChatCard"
import AdContainer from "./AdContainer"
import BlockMember from "./BlockMember"

import { FormattedMessage } from 'react-intl'  // , FormattedHTMLMessage

// Services  // Message
import { chatGetConvs, chatGetMessages, chatDelete } from '../actions/chat.actions'

import { addFriend, getMember } from '../actions/member.actions'

const useStyles = makeStyles(theme => ({
    imageList: {
        overflow: 'hidden',
        margin: 0,
    },

    noRows: {
        color: theme.palette.primary.dark,
        textAlign: "left",
        marginLeft: 8,
    },
}))


export default function ChatsPageConv(props) {
    const classes = useStyles(props)
    const theme = useTheme()
    let navigate = useNavigate()

    const chatList = useSelector(state => state.chat)
    const isLoading = useSelector(state => state.chat.isLoading)
    // const errors = useSelector(state => state.chat.errors)

    const dispatch = useDispatch()

    const [isBlocking, setIsBlocking] = useState(false)
    const [isLoaded, setIsLoaded] = useState(false)

    const breakpoints = ["xs", "sm", "md", "lg", "xl"]
    // const cols = { xs: 1, sm: 1, md: 1, lg: 1, xl: 1 }
    // const cellHeight = { xs: 100, sm: 100, md: 150, lg: 200, xl: 240 }


    function handleAddFriendClick(e, m) {
        dispatch(addFriend(m.memberId))
    }

    // Go to profile of: m.memberId   
    function handleGotoProfileClick(e, m) {
        dispatch(getMember(m.memberId))
        navigate('/memberprofile')
    }

    //-------------------------------------------

    function handleBlockMemberClick(e, chat) {
        setIsBlocking(true)
    }

    function handleClose(e) {
        setIsBlocking(false)
    }

    function handleBlockAndClose(e, m) {
        setIsBlocking(false)

        // Reload conversations
        dispatch(chatGetConvs())
        dispatch(chatGetMessages(null))
    }

    //-------------------------------------------
    // 
    //-------------------------------------------
    function handleDeleteChatClick(chat) {
        dispatch(chatDelete(chat.chatId))
    }


    //-------------------------------------------
    // handleGotoMsgsClick
    //-------------------------------------------
    const handleGotoMsgsClick = useCallback((chat) => {
        if (!isEmpty(chat)) {
            props.handleConvClick(chat)
            dispatch(chatGetMessages(chat.chatId))
        }

    }, [dispatch, props])


    //-------------------------------------------
    // Load the latest Chats
    //-------------------------------------------
    useEffect(() => {
        dispatch(chatGetConvs())

        // Get ads...
        // (window.adsbygoogle = window.adsbygoogle || []).push({})
    }, [dispatch])


    //-------------------------------------------
    // Load the messages
    //-------------------------------------------
    useEffect(() => {
        if (!isLoaded && !isEmpty(chatList.chats)) {
            if (chatList.chats.length) {
                setIsLoaded(true)

                props.handleConvClick(chatList.chats[0])

                // Old - Redundant - Replaced in runGetConvs()
                // handleGotoMsgsClick(chatList.chats[0]) 
            }
        }
    }, [isLoaded, chatList, handleGotoMsgsClick, props])


    return (
        <div>
            {!isEmpty(props.selectedChat) && <ImageList className={classes.imageList}
                cols={1}            // cols={cols[props.width]}
                cellheight={150}    // cellHeight={cellHeight[width]}
                gap={breakpoints.indexOf(props.width) > 2 ? 4 : 2}
            >

                {/* Google Adsense */}
                {/* https://support.google.com/adsense/answer/181950 */}
                {/* https://stackoverflow.com/questions/46035999/how-to-embed-google-adsense-in-react-js */}

                {isEmpty(chatList.chats) && !isLoading && (
                    <Typography className={classes.noRows}>
                        <FormattedMessage id="system.chats.none" defaultMessage="No chats..." />
                    </Typography>)
                }

                {chatList.chats && chatList.chats.map((c, i) => {
                    return (
                        <ImageListItem key={i} style={{ height: 'auto', padding: 0 }}>
                            {!c.isAd && (<ChatCard
                                key={'chatId' + i}
                                chat={c}

                                selectedColor={c.chatId === props.selectedChat.chatId ? theme.palette.primary.light : "white"}

                                handleAddFriendClick={handleAddFriendClick}
                                handleGotoProfileClick={handleGotoProfileClick}
                                handleBlockMemberClick={handleBlockMemberClick}
                                handleDeleteChatClick={handleDeleteChatClick}
                                handleGotoMsgsClick={handleGotoMsgsClick}
                            />)}

                            {/* Insert an Ad... */}
                            {c.isAd && <AdContainer variant="chat" borderRadius={4} height={84} margin={1} width={chatList.width}/>}

                        </ImageListItem>
                    )
                })}
            </ImageList>}

            {!isEmpty(props.selectedChat) && <BlockMember
                id={props.selectedChat.memberId}
                firstname={props.selectedChat.firstname}
                objectId={props.selectedChat.chatId}
                objectType="chatId"
                showWarning={isBlocking}
                handleBlockAndClose={handleBlockAndClose}
                handleClose={handleClose}
            />}
        </div>
    )
}

