// Languages

// Material UI 
// https://material-ui.com/guides/localization/

// import { FormattedMessage } from 'react-intl';
// https://github.com/formatjs/react-intl/blob/master/docs/Getting-Started.md

// Flags
// https://codesandbox.io/s/6w41wlvj8z

import React from "react"
import { useNavigate } from 'react-router-dom'

import { makeStyles } from '@mui/styles';
import { PageHeading } from "../styles/ftfStyles"
import { Button, Dialog, Divider, Grid, Slide, Typography } from '@mui/material';

import CancelIcon from '@mui/icons-material/Cancel'
import Language from '@mui/icons-material/Language'

import { SmallButton } from "../styles/ftfStyles"

import { FormattedMessage } from 'react-intl'

// import { addAppLocaleData } from "./localeData";
// addAppLocaleData();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
})


const useStyles = makeStyles(theme => ({
    buttonCancel: {
        display: 'flex',
        fontWeight: '400',
        padding: '6px 12px',
        justifySelf: 'center',
        margin: 'auto',

    },

    buttonWide: {
        fontWeight: '400',
        padding: '6px 12px',
        margin: 2,
        maxWidth: 150,
        minWidth: 150,
        justifySelf: 'center',
    },

    dialogPaper: {
        margin: 16,
        padding: 8,
        overflow: 'hidden',

        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        border: '2px solid',
    },

    icon: {
        color: theme.palette.primary.dark,
        flexshrink: 0,
        marginRight: 8,
        marginTop: 3,
    },
}))



export default function Languages(props) {
    const classes = useStyles(props)
    let navigate = useNavigate()


    function handleLanguage(e, lang) {
        // Cookie.set('locale', lang, { expires: 365 })

        localStorage.setItem('locale', lang)

        props.onClose()

        // TODO: Save language to users profile??? - DSW
        navigate('/', { replace: true })
        window.location.reload()
    }


    // Render nothing if the "show" prop is false
    // if (!props.show) { return null; }

    return (
        <Dialog classes={{ paper: classes.dialogPaper }}
            fullScreen={props.fullscreen}
            open={props.show}
            onClose={props.onClose}
            TransitionComponent={Transition}
        >
            <Grid container style={{ display: 'flex', justifyContent: "center" }}>
                <Typography className={classes.icon} variant="h5">
                    <Language />
                </Typography>

                <Typography style={PageHeading} variant="h5">
                    {<FormattedMessage id="navbar.language" defaultMessage="Language" />}
                </Typography>
            </Grid>

            {/* TODO: Future - <ul>
                    {["en-US", "en-GB", "de", "fr", "es", "cs", "sk", "ja"].map(locale => (
                        <IntlProvider locale={locale}>
                            <li>
                                <h3>
                                    <img
                                        src="blank.gif"
                                        class={`flag flag-${locale}`}
                                        alt="Czech Republic"
                                    />{" "}
                                    locale="{locale}"
                                </h3>
                                <span>
                                
                                </span>
                            </li>
                        </IntlProvider>
                    ))}
                </ul> */}


            <Grid container style={{ display: 'flex', justifyContent: "center" }}>

                <Button variant="contained" color="primary" size="large" className={classes.buttonWide} onClick={(e) => handleLanguage(e, 'en-US')}>
                    English (US)
                </Button>

                {/* <Button variant="contained" color="primary" size="large" className={classes.buttonWide} onClick={(e) => handleLanguage(e, 'es-ES')}>  
                        Deutsch
                    </Button> */}

                <Button variant="contained" color="primary" size="large" className={classes.buttonWide} onClick={(e) => handleLanguage(e, 'es-ES')}>
                    Español
                </Button>

                <Button variant="contained" color="primary" size="large" className={classes.buttonWide} onClick={(e) => handleLanguage(e, 'fr-FR')}>
                    Français (France)
                </Button>

                {/* <Button variant="contained" color="primary" size="large" className={classes.buttonWide} onClick={(e) => handleLanguage(e, 'es-ES')}>  
                        Português
                    </Button> */}

                {/* <Button variant="contained" color="primary" size="large" className={classes.buttonWide} onClick={(e) => handleLanguage(e, 'es-ES')}>  
                        Sweden
                    </Button>   */}

                {/* <Button variant="contained" color="primary" size="large" className={classes.buttonWide} onClick={(e) => handleLanguage(e, 'es-ES')}>  
                        Dansk
                    </Button>   */}

                {/* <Button variant="contained" color="primary" size="large" className={classes.buttonWide} onClick={(e) => handleLanguage(e, 'es-ES')}>  
                        India
                    </Button>   */}
            </Grid>

            <Divider style={{ margin: 8 }} />

            <Grid container style={{ display: 'flex', justifyContent: "center" }}>
                <Button variant="contained" color="primary"
                    style={SmallButton} onClick={props.onClose}
                    startIcon={<CancelIcon aria-label='Close' />}
                >
                    <FormattedMessage id="system.close" defaultMessage="Close" />
                </Button>
            </Grid>
        </Dialog>
    )
}

// Dansk
// Deutsch
// English
// English (UK)
// Español
// Español (España)
// Ελληνικά
// Français
// 한국어
// Hrvatski
// Italiano
// Nederlands
// 日本語
// Magyar
// Norsk
// Polski
// Português
// Português do Brasil
// Română
// Русский
// Suomi
// Svenska
// Српски
// ภาษาไทย
// Türkçe
// 中文(台灣)
// 中文(简体)



// Needed for onTouchTap
// http://stackoverflow.com/a/34015469/988941
// var injectTapEventPlugin = import "react-tap-event-plugin";
// injectTapEventPlugin();


