// intlMessages.js
// https://gist.github.com/eveningkid/6df7d35d1884854a6d9ecaaac89fd2ae

// We're using dots for nesting and camelCase for each name      
//
// You can use placeholders using curly braces ({variable})
// 'user.hello': "Hi {username}, how are you?",
//
// ...and also HTML
// 'user.signIn': "Please, <em>sign in</em>'

// Manager
// https://www.npmjs.com/package/react-intl-translations-manager

export default {
    'en-US': {
        'bodyType.slender': "Slender",
        'bodyType.athletic': "Athletic/Toned",
        'bodyType.muscular': "Muscular",
        'bodyType.average': "Average",
        'bodyType.extraPounds': "A few extra pounds",
        'bodyType.curvy': "Curvy",
        'bodyType.stout': "Stout",
        'bodyType.heavyset': "Heavyset",
        'bodyType.big': "Big",

        'children.none': "I do not have Children",
        'children.yes': "I have Children",
        'children.want': "I want Children",
        'children.under18': "My kids are under 18",
        'children.over18': "My kids are over 18",
        'children.atHome': "They live at home",
        'children.notHome': "They do not live at home",

        'drinking.none': "Never",
        'drinking.social': "Socially",
        'drinking.couple': "Couple times a week",
        'drinking.daily': "Daily",

        'ethnicity.asian': "Asian",
        'ethnicity.caucasian': "Caucasian",
        'ethnicity.black': "Black",
        'ethnicity.european': "European",
        'ethnicity.hispanic': "Hispanic",
        'ethnicity.indian': "Indian",
        'ethnicity.middleEastern': "Middle Eastern",
        'ethnicity.unique': "Mixed (unique)",
        'ethnicity.nativeAmerican': "Native American",
        'ethnicity.northAmerican': "North American",
        'ethnicity.oceania': "Oceania",
        'ethnicity.southAmerican': "South American",

        'education.trade': "Trade School",
        'education.high': "High School",
        'education.assocs': "Associates Degree",
        'education.bachs': "Bachelors Degree",
        'education.masts': "Masters Degree",
        'education.phD': "PhD",

        'eyeColor.amber': "Amber",
        'eyeColor.blue': "Blue",
        'eyeColor.lightBrown': "Brown (light)",
        'eyeColor.darkBrown': "Brown (dark)",
        'eyeColor.green': "Green",
        'eyeColor.gray': "Gray",
        'eyeColor.hazel': "Hazel",

        'exercise.zero': "Not too much",
        'exercise.one': "1-2 times per week",
        'exercise.three': "3-4 times per week",
        'exercise.alot': "5+ times per week",

        'faith.agnostic': "Agnostic",
        'faith.angelican': "Angelican",
        'faith.atheist': "Atheist",
        'faith.baptist': "Baptist",
        'faith.buddist': "Buddist",
        'faith.catholic': "Catholic",
        'faith.christian': "Christian",
        'faith.hindu': "Hindu",
        'faith.islam': "Islam",
        'faith.jewish': "Jewish",
        'faith.lutheran': "Lutheran",
        'faith.methodist': "Methodist",
        'faith.mormon': "Mormon",
        'faith.newAge': "New Age",
        'faith.sikh': "Sikh",
        'faith.spiritual': "Spiritual",
        'faith.presbyterian': "Presbyterian",
        'faith.other': "Other",

        'genders.fm': "Woman interested in men",
        'genders.mf': "Man interested in women",
        'genders.ff': "Woman interested in women",
        'genders.mm': "Man interested in men",
        'genders.coupleFM': "Couple (F, M)",
        'genders.coupleFF': "Couple (F, F)",
        'genders.coupleMM': "Couple (M, M)",

        'hairColor.bald': "Bald",
        'hairColor.black': "Black",
        'hairColor.blond': "Blond",
        'hairColor.brown': "Brown",
        'hairColor.colored': "Colored",
        'hairColor.grey': "Grey",
        'hairColor.red': "Red",
        'hairColor.shaved': "Shaved",
        'hairColor.silver': "Silver",
        'hairColor.white': "White",

        'height.0': "under 5' (152 cm)",
        'height.152': "5' 0 (152 cm)",
        'height.155': "5' 1 (155 cm)",
        'height.157': "5' 2 (157 cm)",
        'height.160': "5' 3 (160 cm)",
        'height.163': "5' 4 (163 cm)",
        'height.165': "5' 5 (165 cm)",
        'height.168': "5' 6 (168 cm)",
        'height.170': "5' 7 (170 cm)",
        'height.173': "5' 8 (173 cm)",
        'height.175': "5' 9 (175 cm)",
        'height.178': "5' 10 (178 cm)",
        'height.180': "5' 11 (180 cm)",
        'height.183': "6' 0 (183 cm)",
        'height.185': "6' 1 (185 cm)",
        'height.188': "6' 2 (188 cm)",
        'height.191': "6' 3 (191 cm)",
        'height.193': "6' 4 (193 cm)",
        'height.196': "6' 5 (196 cm)",
        'height.198': "6' 6 (198 cm)",
        'height.201': "6' 7 (201 cm)",
        'height.203': "6' 8 (203 cm)",
        'height.206': "6' 9 (206 cm)",
        'height.208': "6' 10 (208 cm)",
        'height.211': "6' 11 (211 cm)",
        'height.213': "7' 0 (213 cm)",
        'height.999': "over 7' (213 cm)",

        'income.0': "< $25k",
        'income.25': "> $25k",
        'income.50': "> $50k",
        'income.75': "> $75k",
        'income.100': "> $100k",
        'income.125': "> $125k",
        'income.150': "> $150k",
        'income.200': "> $200k",
        'income.300': "> $300k",

        'maritalStatus.divorced': "Divorced",
        'maritalStatus.married': "Married",
        'maritalStatus.separated': "Separated",
        'maritalStatus.single': "Single",
        'maritalStatus.widowed': "Widowed",

        'pets.none': "No Pets",
        'pets.bird': "Bird(s)",
        'pets.cat': "Cat(s)",
        'pets.dog': "Dog(s)",
        'pets.ferret': "Ferret(s)",
        'pets.fish': "Fish",
        'pets.farm': "Farm Animals",
        'pets.hampster': "Hampster/Guinea Pig",
        'pets.horse': "Horse(s)",
        'pets.poultry': "Poultry",
        'pets.rabbit': "Rabbit(s)",
        'pets.reptile': "Reptile(s)",

        'politics.apolitical': "Apolitical",
        'politics.conserv': "Conservative",
        'politics.indie': "Independent",
        'politics.liberal': "Liberal",
        'politics.moderate': "Moderate",
        'politics.progessive': "Progressive",
        'politics.other': "Other",

        'relationship.active': "Staying Active",
        'relationship.art': "Art/Crafts",
        'relationship.casual': "Casual Relationship",
        'relationship.companionship': "Companionship",
        'relationship.daytime': "Daytime",
        'relationship.dining': "Dining",
        'relationship.evenings': "Evenings",
        'relationship.hangout': "Hanging Out",
        'relationship.longterm': "Long Term Relationship",
        'relationship.music': "Enjoying Music",
        'relationship.travel': "Travel",
        'relationship.workout': "Working Out",

        'roles.member': "Member",
        'roles.eventHost': "Event Host",
        'roles.cop': "Cop",
        'roles.groupAdmin': "Group Admin",
        'roles.ftfAdmin': "FTF Admin",

        'secLang.none': "None",
        'secLang.arabic': "Arabic",
        'secLang.bengali': "Bengali",
        'secLang.chinese': "Chinese",
        'secLang.english': "English",
        'secLang.french': "French",
        'secLang.german': "German",
        'secLang.hindi': "Hindi",
        'secLang.italian': "Italian",
        'secLang.japanese': "Japanese",
        'secLang.javanese': "Javanese",
        'secLang.korean': "Korean",
        'secLang.mandarin': "Mandarin",
        'secLang.malay': "Malay",
        'secLang.polish': "Polish",
        'secLang.portuguese': "Portuguese",
        'secLang.punjabi': "Punjabi",
        'secLang.russian': "Russian",
        'secLang.spanish': "Spanish",
        'secLang.telugu': "Telugu",
        'secLang.urdu': "Urdu",
        'secLang.turkish': "Turkish",
        'secLang.thai': "Thai",
        'secLang.vietnamese': "Vietnamese",

        'smoking.none': "Non-Smoker",
        'smoking.cigarettes': "Cigarettes",
        'smoking.cigars': "Cigars",
        'smoking.420': "420 Friendly",
        'smoking.dateSmoker': "Date a Smoker",

        'vehicles.airplane': "Airplane",
        'vehicles.auto': "Automobile",
        'vehicles.bicycle': "Bicycle",
        'vehicles.boat': "Boat",
        'vehicles.jetski': "Jetski/Waverunner",
        'vehicles.Kayak': "Kayak",
        'vehicles.motorcycle': "Motorcycle",
        'vehicles.quad': "Quad",
        'vehicles.rv': "RV",
        'vehicles.truck': "Truck",
        'vehicles.snowmobile': "Snowmobile",

        'image.addPhoto': "Add Photo",
        'image.aspectRatio': "Aspect Ratio",
        'image.caption': "Caption",
        'image.editCaption': "Edit",
        'image.instructFace': "Faces must be clear in your photos",
        'image.instructSmile': "Turn your shoulders and smile",
        'image.instructBackground': "Consider your background",
        'image.instructNudity': "Do not upload nudity. Keep it classy!",
        'image.largeGif': "Gif is too large",
        'image.largeGifSize': "Gifs must be less than 3mb",
        'image.rotateLeft': "Rotate Left",
        'image.rotateRight': "Rotate Right",
        'image.save': "Save",
        'image.scale': "Scale",
        'image.selectPhoto': "Select a new photo",
        'image.startAdding': "Start adding photos...",

        'group.admin.rights': "Admin Rights",
        'group.admin.admin': "Administrator",
        'group.admin.contact': "Contact Members",
        'group.admin.events': "Schedule, Edit, or Cancel Events",
        'group.admin.images': "Remove Posts",
        'group.admin.members': "Accept or Ban Members",

        'group.issue.banOffender': "Ban Offender",
        'group.issue.ding': "Ding Reputation: {ding}",
        'group.issue.removePost': "Remove Post",
        'group.issue.removeIssue': "Remove Issue",
        'group.issue.sendWarning': "Send a Warning",

        'group.about': "About the Group",
        'group.addAnImage': "Add an Image",
        'group.banned': "View banned members",
        'group.bannedNone': "No one is banned",

        'group.create': "Create a Group",
        'group.create.step1': "Details",
        'group.create.step2': "Photo",
        'group.create.step3': "Location",

        'group.createPost': "Create a Post",
        'group.events': "Events",
        'group.goto': "Go to Group",
        'group.issueReports': "Issue Reports",
        'group.join': "Join Group",
        'group.joinHint': "Search for a Group...",
        'group.leave': "Leave Group",
        'group.makeLocalGroup': "Make this a local Group",
        'group.memberList': "Members",
        'group.name': "Group Name",
        'group.nameShort': "Group Name is too short",
        'group.photo': "Group Photo",
        'group.remove': "Remove Group",
        'group.settings': "Group Settings",
        'group.type': "Group Type",
        'group.yourGroupIs': "Your group is",

        // Search Group
        'group.filter': "Group Filter",
        'groupType.competitive': "Competitive",
        'groupType.personal': "Personal",
        'groupType.professional': "Professional",
        'groupType.recreational': "Recreational",

        'events.messages': "Messages",
        'events.visits': "Visits",
        'events.summary': "Summary",
        'events.distance': "Distance",
        'events.likes': "Likes",
        'events.favorite': "Favorites",
        'events.views': "Views",

        'member.covid19': "Covid 19 Vaccinated",
        'member.scale': "Distances in Miles or kms",
        'member.firstname': "First Name",
        'member.firstname.length': "Must be between 2 and 30 chars",

        'member.lastname': "Last Name",
        'member.lastnamePrivate': "Always private",

        'member.photo.count': "{count, plural, =0 {no Photos} one {# Photo} other {# Photos}}",
        'member.profile': "Profile",
        'member.relationship': "Relationship",

        'navbar.dashboard': "Dashboard",
        'navbar.events': "Events",
        'navbar.chats': "Chats",
        'navbar.friends': "Friends",
        'navbar.groups': "Groups",
        'navbar.help': "Help",
        'navbar.groupevents': "Events",
        'navbar.language': "Language",
        'navbar.lockOpen': "Lock Open",
        'navbar.profile': "Profile",
        'navbar.support': "Support",
        'navbar.search': "Search",
        'navbar.viewedme': "Views",
        'navbar.whoisonline': "Who's Online",
        'navbar.vdate': "Video Date",
        'navbar.wall': "Photos",
        'navbar.widgets': "Widgets",
        'navbar.updatePhoto': "Profile Photo",

        'navbar.codeofconduct': "Code of Conduct",
        'navbar.privacypolicy': "Privacy Policy",
        'navbar.cookiepolicy': "Cookie Policy",
        'navbar.termsofuse': "Terms of Use",
        'navbar.youAgree': "You agree to our Code Of Conduct, Cookie Policy, Terms of Use, and consent to our Privacy Policy",

        // Profile Email
        'profile.emailNotUnique': "Email is already in use",
        'profile.emailNotValid': "Email not valid",
        'profile.emailIsRequired': "Email is required",
        'profile.for': "for",

        // Profile Tabs
        'profile.about': "About",
        'profile.aboutYourself': "About Yourself",
        'profile.describe': "Describe Yourself",
        'profile.location': "Location",
        'profile.notify': "Notifications",
        'profile.security': "Security",
        'profile.wall': "Photos",

        // Profile Basics
        'profile.birthDateInvalid': "You must be over 18 to use this site",
        'profile.birthday': "Age, Birth Month and Year",

        'profile.profilePlural': "{firstname}'s Profile",

        'profile.yourProfileIs': "Your Profile is",

        'profile.youAreA': "You are a ",
        'profile.lookingFor': "Looking for ",
        'profile.lookingForLC': "looking for ",
        'profile.relationship': "Relationship Goals",

        'profile.name': "Name",

        'profile.yourFriends': "You and your Friends",
        'profile.yourGoals': "Your Goals",
        'profile.appearance': "Appearance",
        'profile.bodyType': "Body Type",
        'profile.ethnicity': "Ethnicity",
        'profile.education': "Education",
        'profile.eyeColor': "Eye Color",
        'profile.exercise': "Exercise",
        'profile.drinking': "Drinking",
        'profile.hairColor': "Hair Color",
        'profile.maritalStatus': "Marital Status",
        'profile.faith': "Faith",
        'profile.politics': "Politics",
        'profile.profession': "Profession",
        'profile.secondLang': "Second Language",

        'profile.children': "Children",
        'profile.pets': "Pets",
        'profile.smoking': "Smoking",
        'profile.vehicles': "Vehicles",
        'profile.wallpaper': "Wallpaper",

        // Profile Describe Yourself
        'profile.desc.headline': "Short Headline (searchable)",
        'profile.desc.desc': "Searchable description of your interests, hobbies, music, etc...",

        // Profile Location
        'profile.location.useLocation': "Use Location",
        'profile.location.kms': "kms",
        'profile.location.miles': "Miles",
        'profile.location.range': "Range",

        'profile.describeYourself': "Describe Yourself",
        'profile.searchRadius': "Search Radius",
        'profile.saveChanges': "Save",
        'profile.saving': "Saving...",
        'profile.notificationSettings': "Notification Settings",
        'profile.accountSecurity': "Account Security",

        // Profile Notifications 
        'profile.notif.allowChat': "Allow Chat",
        'profile.notif.allowVideoChat': "Allow Video Dating",
        'profile.notif.allowWithoutPhotos': "Allow members without photos",
        'profile.notif.yourEmail': "Your Email (private)",
        'profile.notif.receiveEmails': "Receive Emails",
        'profile.notif.contactOptions': "Member Contact Options",
        'profile.notif.filterMembers': "Allow only:",
        'profile.notif.blockedMembers': "Blocked Members",
        'profile.notif.blockedNone': "No one is blocked",
        'profile.notif.unblock': "Unblock",
        'profile.notif.email.events': "Allow emails from Local Events",
        'profile.notif.email.favorite': "When someone adds me as a 'Favorite'",
        'profile.notif.email.ftf': "Allow emails from FindTrueFriends",
        'profile.notif.email.like': "When I have new photo 'Like'",
        'profile.notif.email.grouppost': "When any of your groups has a 'New Post'",
        'profile.notif.email.message': "When I have new 'Message'",
        'profile.notif.hide.visits': "Hide my visits to a member's profile",
        'profile.notif.view.blockedMembers': "View list of Blocked Members",

        // Profile Security
        'profile.security.changePassword': "Change Password",
        'profile.security.oldPassword': "Old Password",
        'profile.security.oldPasswordBad': "Old Password is not accepted",
        'profile.security.newPassword': "New Password",
        'profile.security.newPasswordConfirm': "Confirm New Password",
        'profile.security.enterPassword': "Enter a new password",

        'profile.security.passwordDontMatch': "Passwords do not match",
        'profile.security.passwordLength': "Requires: 1 uppercase character, 1 numeric character (Strong: special characters)",
        'profile.security.passwordStrong': "Strong Password",
        'profile.security.passwordWeak': "Weak Password",

        'profile.security.pin': "Security Phrase",
        'profile.security.pinDesc': "Record your Security Phrase.  You'll need this to retrieve your password",
        'profile.security.pinCode': "Security Phrase",
        'profile.security.pinCodeConfirm': "Confirm Security Phrase",
        'profile.security.pinCodesDontMatch': "Codes do not match",

        'profile.security.cancelMembership': "Cancel Membership",
        'profile.security.cancelMembershipDesc1': "We will remove your profile, images, and posts",
        'profile.security.cancelMembershipDesc2': "If you administer any groups, please assign another administrator",

        'profile.security.sorryLeaving': "We're sorry to see you go {firstname}!",

        // Search Filter
        'search.category': "Category",
        'search.filter': "Search Filter",
        'search.match': "match",
        'search.matches': "matches",
        'search.searches': "Searches",
        'search.searching': "Searching...",
        'searchType.all': "All",
        'searchType.events': "Events",
        'searchType.groups': "Groups",
        'searchType.people': "People",

        // System Messaging
        'system.msg.supportCategory': "Support Category",
        'system.msg.enterMsg': "Enter your message...",
        'system.msg.featureRequest': "Feature Request",
        'system.msg.issueReport': "Issue Report",
        'system.msg.memberIssue': "Member Issue",
        'system.msg.feedback': "Feedback",
        'system.msg.welcome': "Welcome...",
        'system.msg.pleaseLogin': "Please log in or sign up to access our services",
        'system.msg.likedPhoto': "{firstname} liked your photo",
        'system.msg.likedPost': "{firstname} liked your post",
        'system.msg.favoredYou': "Favorited you",
        'system.msg.viewedProfile': "{firstname} viewed your profile",
        'system.msg.reportLogged': "Report Logged",
        'system.msg.sentMessage': "{firstname} sent you a message",
        'system.msg.bumped': "Your reputation was bumped up!",
        'system.msg.onlyOnceAnHour': "Allowed only once an hour",

        // System
        'system.advertise': "Regional advertising available...",
        'system.any': "Any",
        'system.noAnswer': "No Answer",

        'system.GDPR': "We are always working to improve this website for our members. To do this, we use the anonymous data provided by cookies.  By using this site, you agree to our use of cookies.",
        'system.GDPRHeader': "Our website uses cookies",
        'system.acceptCookies': "Accept",

        'system.accept': "Accept",
        'system.leave': "Leave",
        'system.reject': "Reject",

        'system.admin': "Admin",
        'system.admins': "Administrators",
        'system.age': "Age",
        'system.ays': "Are you sure?",
        'system.back': "Back",
        'system.background': "Background:",
        'system.ban': "Ban Member",
        'system.banned': "Banned",
        'system.birthMonth': "Birth Month and Year",
        'system.blocked': "Blocked",
        'system.blockName': "Block {firstname}?",
        'system.blockAndReportName': "Block & Report {firstname}?",
        'system.blockText1': "• You will not be Friends",
        'system.blockText2': "• You will not be able Chat",
        'system.blockText3': "• You will not see each others Posts",
        'system.blockText4': "• You will not see each other in Searches",
        'system.writeComment': "Write a comment",
        'system.cancel': "Cancel",
        'system.cannotConnect1': "Cannot connect...",
        'system.cannotConnect2': "Please try again in a little while...",
        'system.chats.none': "No chats...",
        'system.clear': "Clear",
        'system.close': "Close",
        'system.comment': "Comment",
        'system.comments': "Comments",
        'system.confirm': "Confirm",
        'system.create': "Create",
        'system.country': "Country",
        'system.delete': "Delete",
        'system.descShort': "Description is too short",
        'system.remove': "Remove",

        'system.email': "Email",
        'system.email.code': "Email Verification",
        'system.email.code.enter': "Enter the verification code sent to: ",
        'system.email.code.resend': "Re-send email verification code to: ",
        'system.email.code.cancel': "Keep your original email",
        'system.email.code.invalid': "Verification code is expired or invalid",

        'system.frontpage.panelLeft': "Have a night out!",
        'system.frontpage.panelLeft.text': "Connect with the kind of people you want to meet using the most flexible search criteria in the dating industry",

        'system.frontpage.paneCenter': "Text and Video Dating",
        'system.frontpage.paneCenter.text': "We give you the ability to meet people through searchable profiles, messaging, and video dating",

        'system.frontpage.paneRight.text': "FindTrueFriends is a new social dating platform that allows people to connect with each other for free",
        'system.frontpage.paneRight.bullet1': "We are completely 'free' to use",
        'system.frontpage.paneRight.bullet2': "Private messaging and video dating",
        'system.frontpage.paneRight.bullet3': "Searchable profiles",
        'system.frontpage.paneRight.bullet4': "Interest groups",
        'system.frontpage.paneRight.bullet5': "We're new.  Give us a try!",
        'system.frontpage.paneRight.bullet6': "the tools they need to help take the lottery out of love",

        'system.ftf': "FindTrueFriends",
        'system.ftf.registration': "Registration",
        'system.gender': "Gender",
        'system.global': "Global",
        'system.go': "Enter",
        'system.guest': "Guest",
        'system.height': "Height",
        'system.height.min': "Min Height",
        'system.height.max': "Max Height",
        'system.hidden': "Hidden",
        'system.hint.friends': "Search for Friends...",
        'system.invalidChar': "Invalid character",
        'system.likes': "Likes",
        'system.likes.view': "View Likes",
        'system.loading': "Loading...",
        'system.kick': "Kick {name} off the FTF platform?",
        'system.local': "Local",
        'system.login': "Sign In",
        'system.logout': "Sign Out",
        'system.member': "Member",
        'system.members': "Members",
        'system.menu': "Menu",
        'system.newsFeed': "News Feed",
        'system.next': "Next",
        'system.no': "No",
        'system.no.error': "",
        'system.photos': "Photos",
        'system.photos.none': "No photos...",
        'system.private': "Private",
        'system.problem': "There was a problem...",
        'system.promoteToAdmin': "Promote to Admin",
        'system.public': "Public",
        'system.reply': "Reply",
        'system.removeAdmin': "Remove Admin",

        'system.reportBehavior': "Report behavior",
        'system.reportBehaviorDesc': "Inappropriate or abusive behavior 'on' or 'off' the site",
        'system.reportPhoto': "Inappropriate Photo or Video",
        'system.reportPhotoDesc': "Obscene photo or video, photos or videos of illegal acts, photos or videos of minors alone",
        'system.reportProfile': "Inappropriate or Fake Profile",
        'system.reportProfileDesc': "Inappropriate or vulgar text, includes email or phone number, solicitations or urls to other sites, referencing illegal activities, pressured to move communication off the site, asked for financials, requested money, photos appear to be of someone else",
        'system.reportUnderage': "Underage",
        'system.reportUnderageDesc': "User under 18 years of age",

        'system.rating': "Rating",
        'system.ratingDown': "Bump Rating Down",
        'system.ratingUp': "Bump Rating Up",
        'system.yourRating': "Your Rating: ",

        'system.required': "Required",
        'system.resend': "Re-send",

        'system.save': "Save",
        'system.searchable': "(searchable)",
        'system.send': "Send",
        'system.signUp': "Sign Up",
        'system.stats': "Stats",
        'system.support': "Support",
        'system.textSearch.helperText': "Text Search",
        'system.textSearch.placeholder': "keyword, keyword, ...",
        'system.to': "to",
        'system.type': "Type:",
        'system.update': "Update",
        'system.use': "Use",
        'system.validate': "Validate",
        'system.viewProfile': "View Profile",
        'system.views': "Views",
        'system.views.none': "No recent views...",
        'system.visible': "Visible",
        'system.welcome': "Welcome!",
        'system.within': "within",
        'system.yes': "Yes",
        'system.youAreNear': "You are near:",

        //============================
        'system.password': "Password",
        'system.password.confirmMethod': "Proceed by:",
        'system.password.confirmMethod.email': "Resetting your password",

        'system.password.createEmail': "Enter your registered email (ID):",
        'system.password.new': "Create a New Password",
        'system.password.forgot': "Forgot your Password?",
        'system.password.length': "Password must have 8 chars",

        'system.password.confirmMethod.pin': "Entering your Security Phrase",
        'system.password.securityPhrase': "Enter your Security Phrase",
        'system.password.securityPhrase.mismatch': "Security phrase doesn't match",

        'system.password.wrong': "Email or password is incorrect",
        'system.password.wrong.email': "Email does not exist",

        //============================

        'table.member': "Member",
        'table.online': "Online",
        'table.viewDate': "Viewed",

        //============================

        'tooltip.addremove': "Add or Remove Friend",
        'tooltip.block': "Block",
        'tooltip.blockAndReport': "Block & Report",
        'tooltip.deleteConv': "Delete Conversation",
        'tooltip.kick': "Kick",
        'tooltip.more': "More/Less",
        'tooltip.profile': "Go to Profile",
        'tooltip.reset': "Reset",
        'tooltip.remove': "Remove",
        'tooltip.sendMsg': "Chat",
        'tooltip.searchFilter': "Search Filter",
        'tooltip.videoChat': "Video Chat",

        //============================

        'videoChat.pause': "Pause",
        'videoChat.mute': "Mute",
        'videoChat.closing': "Closing video session",
        'videoChat.leftRoom': "Member has left",
        'videoChat.notAccepted': "Video chat was not accepted",
        'videoChat.notAvailable': "Video chat is not available",
        'videoChat.notOnline': "Member is not online",
        'videoChat.request': "Video Chat Request",
        'videoChat.videoAcceesError': "Could not start your video",
    },

    'fr-FR': {
        'bodyType.slender': "Mince",
        'bodyType.athletic': "Athlétique/Tonique",
        'bodyType.muscular': "Courbé",
        'bodyType.average': "Moyenne",
        'bodyType.extraPounds': "Quelques kilos en trop",
        'bodyType.curvy': "Courbé",
        'bodyType.stout': "Bière",
        'bodyType.heavyset': "Costaud",
        'bodyType.big': "Grand",

        'children.none': "Je ne ai pas d'enfants",
        'children.yes': "'J'ai des enfants",
        'children.want': "Je veux des enfants",
        'children.under18': "Mes enfants ont moins de 18 ans",
        'children.over18': "Mes enfants ont plus de 18 ans",
        'children.atHome': "Ils vivent à la maison",
        'children.notHome': "Ils ne vivent pas à la maison",

        'drinking.none': "Jamais",
        'drinking.social': "Socialement",
        'drinking.couple': "Quelques fois par semaine",
        'drinking.daily': "Quotidien",

        'ethnicity.asian': "Asiatique",
        'ethnicity.caucasian': "Caucasien",
        'ethnicity.black': "Noir",
        'ethnicity.european': "Européen",
        'ethnicity.hispanic': "Hispanique",
        'ethnicity.indian': "Indien",
        'ethnicity.middleEastern': "Moyen-Orient",
        'ethnicity.unique': "Mixte (unique)",
        'ethnicity.nativeAmerican': "Américain de Naissance",
        'ethnicity.northAmerican': "Nord Américain",
        'ethnicity.oceania': "Océanie",
        'ethnicity.southAmerican': "Sud Américain",

        'education.trade': "Une École de Commerce",
        'education.high': "École Secondaire",
        'education.assocs': "Diplôme d'Associé",
        'education.bachs': "Licence",
        'education.masts': "Une Maîtrise",
        'education.phD': "Doctorat",

        'eyeColor.amber': "Ambre",
        'eyeColor.blue': "Bleu",
        'eyeColor.lightBrown': "Marron (clair)",
        'eyeColor.darkBrown': "Marron (foncé)",
        'eyeColor.green': "Vert",
        'eyeColor.gray': "Gris",
        'eyeColor.hazel': "Noisette",

        'exercise.zero': "Pas trop",
        'exercise.one': "1 à 2 fois par semaine",
        'exercise.three': "3-4 fois par semaine",
        'exercise.alot': "5+ fois par semaine",

        'faith.agnostic': "Agnostique",
        'faith.angelican': "Angelican",
        'faith.atheist': "Athée",
        'faith.baptist': "Baptiste",
        'faith.buddist': "Bouddhiste",
        'faith.catholic': "Catholique",
        'faith.christian': "Christian",
        'faith.hindu': "Hindou",
        'faith.islam': "Islam",
        'faith.jewish': "Juif",
        'faith.lutheran': "Luthérien",
        'faith.methodist': "Méthodiste",
        'faith.mormon': "Mormon",
        'faith.newAge': "Nouvel Age",
        'faith.sikh': "Sikh",
        'faith.spiritual': "Spirituel",
        'faith.presbyterian': "Presbytérien",
        'faith.other': "Autre",

        'genders.fm': "Femme intéressée par les hommes",
        'genders.mf': "Homme intéressé par les femmes",
        'genders.ff': "Femme intéressée par les femmes",
        'genders.mm': "Homme intéressé par les hommes",
        'genders.coupleFM': "Coupler (F,M)",
        'genders.coupleFF': "Coupler (F,F)",
        'genders.coupleMM': "Coupler (M,M)",

        'hairColor.bald': "Chauve",
        'hairColor.black': "Noir",
        'hairColor.blond': "Blond",
        'hairColor.brown': "Marron",
        'hairColor.colored': "Coloré",
        'hairColor.grey': "Gris",
        'hairColor.red': "Rouge",
        'hairColor.shaved': "Rasé",
        'hairColor.silver': "Argent",
        'hairColor.white': "Blanc",

        'height.0': "moins de 5' (152 cm)",
        'height.152': "5' 0 (152 cm)",
        'height.155': "5' 1 (155 cm)",
        'height.157': "5' 2 (157 cm)",
        'height.160': "5' 3 (160 cm)",
        'height.163': "5' 4 (163 cm)",
        'height.165': "5' 5 (165 cm)",
        'height.168': "5' 6 (168 cm)",
        'height.170': "5' 7 (170 cm)",
        'height.173': "5' 8 (173 cm)",
        'height.175': "5' 9 (175 cm)",
        'height.178': "5' 10 (178 cm)",
        'height.180': "5' 11 (180 cm)",
        'height.183': "6' 0 (183 cm)",
        'height.185': "6' 1 (185 cm)",
        'height.188': "6' 2 (188 cm)",
        'height.191': "6' 3 (191 cm)",
        'height.193': "6' 4 (193 cm)",
        'height.196': "6' 5 (196 cm)",
        'height.198': "6' 6 (198 cm)",
        'height.201': "6' 7 (201 cm)",
        'height.203': "6' 8 (203 cm)",
        'height.206': "6' 9 (206 cm)",
        'height.208': "6' 10 (208 cm)",
        'height.211': "6' 11 (211 cm)",
        'height.213': "7' 0 (213 cm)",
        'height.999': "plus de 7' (213 cm)",

        'income.0': "< $25k",
        'income.25': "> $25k",
        'income.50': "> $50k",
        'income.75': "> $75k",
        'income.100': "> $100k",
        'income.125': "> $125k",
        'income.150': "> $150k",
        'income.200': "> $200k",
        'income.300': "> $300k",

        'maritalStatus.divorced': "Divorcé",
        'maritalStatus.married': "Marié",
        'maritalStatus.separated': "Séparé",
        'maritalStatus.single': "Célibataire",
        'maritalStatus.widowed': "Veuf",

        'pets.none': "Pas d'Animaux de Compagnie",
        'pets.bird': "Oiseau(s)",
        'pets.cat': "Chat(s)",
        'pets.dog': "Chien(s)",
        'pets.ferret': "Furet(s)",
        'pets.fish': "Poisson",
        'pets.farm': "Animaux de la Ferme",
        'pets.hampster': "Hampster/Cochon d'Inde",
        'pets.horse': "Cheval(s)",
        'pets.poultry': "la Volaille",
        'pets.rabbit': "Lapin(s)",
        'pets.reptile': "Reptile(s)",

        'politics.apolitical': "Apolitique",
        'politics.conserv': "Conservateur",
        'politics.indie': "Indépendant",
        'politics.liberal': "Libéral",
        'politics.moderate': "Modérer",
        'politics.progessive': "Progressif",
        'politics.other': "Autre",

        'relationship.active': "Rester actif",
        'relationship.art': "Projets d'art",
        'relationship.casual': "Relation décontractée",
        'relationship.companionship': "Camaraderie",
        'relationship.daytime': "Jour",
        'relationship.dining': "À manger",
        'relationship.evenings': "Soirs",
        'relationship.hangout': "Traîner",
        'relationship.longterm': "Relation à long terme",
        'relationship.music': "Profiter de la musique",
        'relationship.travel': "Voyager",
        'relationship.workout': "S'entraîner",

        'roles.member': "Membre",
        'roles.eventHost': "Hôte de l'événement",
        'roles.cop': "Flic",
        'roles.groupAdmin': "Admin du Groupe",
        'roles.ftfAdmin': "Administrateur FTF",

        'secLang.none': "Aucun",
        'secLang.arabic': "Arabe",
        'secLang.bengali': "Bengali",
        'secLang.chinese': "Chinois",
        'secLang.english': "Anglais",
        'secLang.french': "Français",
        'secLang.german': "Allemand",
        'secLang.hindi': "Hindi",
        'secLang.italian': "Italien",
        'secLang.japanese': "Japonais",
        'secLang.javanese': "Javanais",
        'secLang.korean': "Coréen",
        'secLang.mandarin': "Mandarin",
        'secLang.malay': "Malay",
        'secLang.polish': "Polonais",
        'secLang.portuguese': "Portugais",
        'secLang.punjabi': "Punjabi",
        'secLang.russian': "Russe",
        'secLang.spanish': "Espagnol",
        'secLang.telugu': "Telugu",
        'secLang.urdu': "Ourdou",
        'secLang.turkish': "Turc",
        'secLang.thai': "Thaïlandais",
        'secLang.vietnamese': "Vietnamien",

        'smoking.none': "Non Fumeur",
        'smoking.cigarettes': "Cigarettes",
        'smoking.cigars': "Les Cigares",
        'smoking.420': "420 Amical",
        'smoking.dateSmoker': "Sortir avec un Fumeur",

        'vehicles.airplane': "Avion",
        'vehicles.auto': "Voiture",
        'vehicles.bicycle': "Vélo",
        'vehicles.boat': "Bateau",
        'vehicles.jetski': "Jetski/Waverunner",
        'vehicles.Kayak': "Kayak",
        'vehicles.motorcycle': "Moto",
        'vehicles.quad': "Quad",
        'vehicles.rv': "RV",
        'vehicles.truck': "Un Camion",
        'vehicles.snowmobile': "Motoneige",


        'image.addPhoto': "Ajouter une Photo",
        'image.aspectRatio': "Ratio d'Aspect",
        'image.caption': "Légende",
        'image.editCaption': "Éditer",
        'image.instructFace': "Faces must be clear in your photos",
        'image.instructSmile': "Tournez vos épaules et souriez",
        'image.instructBackground': "Tenez compte de vos antécédents",
        'image.instructNudity': "Ne téléchargez pas de nudité. Avec classe!",
        'image.largeGif': "Le GIF est trop grand",
        'image.largeGifSize': "Les gifs doivent être inférieurs à 3 Mo",
        'image.rotateLeft': "Tourne à Gauche",
        'image.rotateRight': "Tourner à Droite",
        'image.save': "Sauver",
        'image.scale': "Scale",
        'image.selectPhoto': "Sélectionnez une nouvelle photo",
        'image.startAdding': "Commencez à ajouter des photos ...",

        'group.admin.rights': "Droits d'Administrateur",
        'group.admin.admin': "Administrateur",
        'group.admin.contact': "Contacter les Membres",
        'group.admin.events': "Programmer, Modifier ou Annuler des Événements",
        'group.admin.images': "Supprimer les messages",
        'group.admin.members': "Accepter ou interdire les membres",

        'group.issue.banOffender': "Interdire le contrevenant",
        'group.issue.ding': "Réputation de Ding: {ding}",
        'group.issue.removePost': "Supprimer la publication",
        'group.issue.removeIssue': "Supprimer le problème",
        'group.issue.sendWarning': "Envoyer un avertissement",

        'group.about': "À propos du Groupe",
        'group.addAnImage': "Ajouter une Image",
        'group.banned': "Voir les membres bannis",
        'group.bannedNone': "Personne n'est banni",
        
        'group.create': "Créer un Groupe",
        'group.create.step1': "Détail",
        'group.create.step2': "Photo",
        'group.create.step3': "Emplacement",

        'group.createPost': "Créer un Message",
        'group.events': "Événements",
        'group.goto': "Aller au Groupe",
        'group.issueReports': "Rapports de problème",
        'group.join': "Rejoindre le Groupe",
        'group.joinHint': "Rechercher un Groupe...",
        'group.leave': "Quitter le groupe",
        'group.makeLocalGroup': "Faites-en un groupe local",
        'group.memberList': "Membres",
        'group.name': "Nom de Groupe",
        'group.nameShort': "Le nom du groupe est trop court",
        'group.photo': "Photo de Groupe",
        'group.remove': "Supprimer le Groupe",
        'group.settings': "Paramètres de Groupe",
        'group.type': "Type de Groupe",
        'group.yourGroupIs': "Votre groupe est",

        // Search Group
        'group.filter': "Filtre de groupe",
        'groupType.competitive': "Compétitif",
        'groupType.personal': "Personnel",
        'groupType.professional': "Professionnel",
        'groupType.recreational': "Récréatif",

        'events.messages': "Messages",
        'events.visits': "Les Visites",
        'events.summary': "Résumé",
        'events.distance': "Distance",
        'events.likes': "Aimers",
        'events.favorite': "Favoris",
        'events.views': "Des vues",

        'member.covid19': "Covid 19 Vacciné",
        'member.scale': "Distances en milles ou kms",
        'member.firstname': "Prénom",
        'member.firstname.length': "Doit être compris entre 2 et 30 caractères",

        'member.lastname': "Nom de Famille",
        'member.lastnamePrivate': "Votre nom est privé",

        'member.photo.count': "{count, plural, =0 {no Photos} one {# Photo} other {# Photos}}",
        'member.profile': "Profil",
        'member.relationship': "Relation",

        'navbar.dashboard': "Tableau de bord",
        'navbar.events': "Événements",
        'navbar.chats': "Chats",
        'navbar.friends': "Copains",
        'navbar.groups': "Groupes",
        'navbar.help': "Aidez-moi",
        'navbar.groupevents': "Evénements",
        'navbar.language': "Langues",
        'navbar.lockOpen': "Verrouiller Ouvert",
        'navbar.profile': "Profil",
        'navbar.support': "Soutien",
        'navbar.search': "Rechercher",
        'navbar.viewedme': "Vues",
        'navbar.whoisonline': "Qui est en Ligne",
        'navbar.vdate': "Date de la Vidéo",
        'navbar.wall': "Photos",
        'navbar.widgets': "Widgets",
        'navbar.updatePhoto': "Mettez à jour votre photo",

        'navbar.codeofconduct': "Code de Conduite",
        'navbar.privacypolicy': "Politique de Confidentialité",
        'navbar.cookiepolicy': "Politique de Cookie",
        'navbar.termsofuse': "Conditions d'Utilisation",
        'navbar.youAgree': "Vous acceptez notre code de conduite, notre politique en matière de cookies, nos conditions d'utilisation et acceptez notre politique de confidentialité",

        // Profile Email
        'profile.emailNotUnique': "Cet email est déjà utilisé",
        'profile.emailNotValid': "Courriel non valide",
        'profile.emailIsRequired': "L'email est requis",
        'profile.for': "pour",

        // Profile Tabs
        'profile.about': "Sur",
        'profile.aboutYourself': "A Propos de Toi",
        'profile.describe': "Décrivez-vous",
        'profile.location': "Ton Emplacement",
        'profile.notify': "Paramètres de Notification",
        'profile.security': "Les Paramètres de Sécurité",
        'profile.wall': "Photos",

        // Profile Basics
        'profile.birthDateInvalid': "Vous devez avoir plus de 18 ans pour utiliser ce site",
        'profile.birthday': "Âge, Mois de Naissance et Année",

        'profile.profilePlural': "Profil de {firstname}",

        'profile.yourProfileIs': "Votre Profil est",

        'profile.youAreA': "Tu es un ",
        'profile.lookingFor': "À la recherche de ",
        'profile.lookingForLC': "à la recherche de ",
        'profile.relationship': "Objectifs de Relations",

        'profile.name': "Nom",

        'profile.yourFriends': "Toi et tes Amis",
        'profile.yourGoals': "Tes Objectifs",
        'profile.appearance': "Apparence",
        'profile.bodyType': "Type de Corps",
        'profile.ethnicity': "Ethnicité",
        'profile.education': "Éducation",
        'profile.eyeColor': "Couleur des Yeux",
        'profile.exercise': "Exercer",
        'profile.drinking': "En Buvant",
        'profile.hairColor': "Couleur de Cheveux",
        'profile.maritalStatus': "État Civil",
        'profile.faith': "Foi",
        'profile.politics': "Politique",
        'profile.profession': "Métier",
        'profile.secondLang': "Deuxième Langue",

        'profile.children': "Les Enfants",
        'profile.pets': "Animaux Domestiques",
        'profile.smoking': "Fumeur",
        'profile.vehicles': "Véhicules",
        'profile.wallpaper': "Fond d'écran",

        // Profile Describe Yourself
        'profile.desc.headline': "Titre court (interrogeable)",
        'profile.desc.desc': "Description consultable de vos intérêts, passe-temps, musique, etc...",

        // Profile Location
        'profile.location.useLocation': "Utiliser le lieu",
        'profile.location.kms': "kms",
        'profile.location.miles': "Milles",
        'profile.location.range': "Gamme",

        'profile.describeYourself': "Décrivez-vous",
        'profile.searchRadius': "Rayon de Recherche",
        'profile.saveChanges': "Sauvegarder",
        'profile.saving': "Économie...",
        'profile.notificationSettings': "Paramètres de Notification",
        'profile.accountSecurity': "Sécurité du Compte",

        // Profile Notifications
        'profile.notif.allowChat': "Autoriser le Chat",
        'profile.notif.allowVideoChat': "Autoriser les Rencontres Vidéo",
        'profile.notif.allowWithoutPhotos': "Autoriser les membres sans photos",
        'profile.notif.yourEmail': "Votre e-mail (privé)",
        'profile.notif.receiveEmails': "Recevoir des e-mails",
        'profile.notif.contactOptions': "Options de contact des membres",
        'profile.notif.filterMembers': "Autoriser uniquement:",
        'profile.notif.blockedMembers': "Membres bloqués",
        'profile.notif.blockedNone': "Personne n'est bloqué",
        'profile.notif.unblock': "Débloquer",
        'profile.notif.email.events': "Autoriser les e-mails des événements locaux",
        'profile.notif.email.favorite': "Quand quelqu'un m'ajoute comme 'Favori'",
        'profile.notif.email.ftf': "Autoriser les e-mails de FindTrueFriends",
        'profile.notif.email.grouppost': "Lorsque l'un de vos groupes a un 'Nouveau Message'",
        'profile.notif.email.like': "Quand j'ai une nouvelle photo 'J'aime'",
        'profile.notif.email.message': "Quand j'ai un nouveau 'Message'",
        'profile.notif.hide.visits': "Masquer mes visites sur le profil d'un membre",
        'profile.notif.view.blockedMembers': "Afficher la liste des membres bloqués",

        // Profile Security
        'profile.security.changePassword': "Changer le mot de Passe",
        'profile.security.oldPassword': "Ancien mot de Passe",
        'profile.security.oldPasswordBad': "L'ancien mot de passe n'est pas accepté",
        'profile.security.newPassword': "Nouveau mot de Passe",
        'profile.security.newPasswordConfirm': "Confirmer le Nouveau mot de Passe",
        'profile.security.enterPassword': "Entrer un nouveau mot de passe",

        'profile.security.passwordDontMatch': "Les mots de passe ne correspondent pas",
        'profile.security.passwordLength': "Nécessite au moins 1 caractère majuscule, 1 caractère numérique (Fort - caractères spéciaux)",
        'profile.security.passwordStrong': "Mot de passe fort",
        'profile.security.passwordWeak': "Mot de passe faible",

        'profile.security.pin': "Code de Securité",
        'profile.security.pinDesc': "FAIRE: Enregistrez votre code PIN. (Nous vous demanderons ce code PIN si vous devez récupérer votre mot de passe)",
        'profile.security.pinCode': "Code PIN",
        'profile.security.pinCodeConfirm': "Confirmer le Code PIN",
        'profile.security.pinCodesDontMatch': "Les codes ne correspondent pas",

        'profile.security.cancelMembership': "Annuler l'adhésion",
        'profile.security.cancelMembershipDesc1': "Nous supprimerons votre profil, vos images et vos postes",
        'profile.security.cancelMembershipDesc2': "Si vous administrez des groupes, veuillez assigner un autre administrateur",

        'profile.security.sorryLeaving': "Nous sommes désolés de te voir partir {firstname}!",

        // Search Filter
        'search.category': "Catégorie",
        'search.filter': "Filtre de Recherche",
        'search.match': "appareiller",
        'search.matches': "appareillers",
        'search.searches': "Recherches",
        'search.searching': "Recherche...",
        'searchType.all': "Tout",
        'searchType.events': "Événements",
        'searchType.groups': "Groupes",
        'searchType.people': "Gens",

        // System Messaging
        'system.msg.supportCategory': "Catégorie de support",
        'system.msg.enterMsg': "Entrez votre message...",
        'system.msg.featureRequest': "Demande de fonctionnalité",
        'system.msg.issueReport': "Rapport de problème",
        'system.msg.memberIssue': "Problème de membre",
        'system.msg.feedback': "Retour d'information",
        'system.msg.welcome': "Bienvenu...",
        'system.msg.pleaseLogin': "S'il vous plaît connectez-vous ou inscrivez-vous pour accéder à nos services",
        'system.msg.likedPhoto': "{firstname} j'ai aimé ta photo",
        'system.msg.likedPost': "{firstname} aimé votre message",
        'system.msg.favoredYou': "Vous a favorisé",

        'system.msg.viewedProfile': "{firstname} vu votre profil",
        'system.msg.reportLogged': "Rapport Consigné",
        'system.msg.sentMessage': "{firstname} vous a envoyé un message",
        'system.msg.bumped': "Votre réputation a été augmentée!",
        'system.msg.onlyOnceAnHour': "Autorisé seulement une fois par heure",

        // System
        'system.advertise': "Publicité régionale disponible...",
        'system.any': "Tout",
        'system.noAnswer': "Pas de réponse",

        'system.GDPR': "Nous travaillons toujours à améliorer ce site Web pour nos membres. Pour ce faire, nous utilisons les données anonymes fournies par les cookies. En utilisant ce site, vous acceptez notre utilisation des cookies.",
        'system.GDPRHeader': "Notre site utilise des cookies",
        'system.acceptCookies': "J'accepte",

        'system.accept': "J'accepte",
        'system.leave': "Quitter",
        'system.reject': "Rejeter",

        'system.admin': "Admin",
        'system.admins': "Administrateurs",
        'system.age': "Âge",
        'system.ays': "Êtes-vous sûr?",
        'system.back': "Arrière",
        'system.background': "Fond:",
        'system.ban': "Membre Banni",
        'system.banned': "Banni",
        'system.birthMonth': "Mois et Année de Naissance",
        'system.blocked': "Bloqué",
        'system.blockName': "Bloquer {firstname} ?",
        'system.blockAndReportName': "Bloquer et Signaler {firstname} ?",
        'system.blockText1': "• Vous ne serez pas Amis",
        'system.blockText2': "• Vous ne pourrez pas Discuter",
        'system.blockText3': "• Vous ne vous verrez Pas",
        'system.blockText4': "• Vous ne vous verrez pas dans les Recherches",
        'system.writeComment': "Écrire un commentaire",
        'system.cancel': "Annuler",
        'system.cannotConnect1': "Peut pas se connecter...",
        'system.cannotConnect2': "Veuillez réessayer dans quelques instants...",
        'system.chats.none': "Pas de chats...",
        'system.clear': "Clair",
        'system.close': "Fermer",
        'system.comment': "Commentaire",
        'system.comments': "Commentaires",
        'system.confirm': "Confirmer",
        'system.create': "Créer",
        'system.country': "Pays",
        'system.delete': "Supprimer",
        'system.descShort': "La description est trop courte",
        'system.remove': "Retirer",

        'system.email': "Email",
        'system.email.code': "Code de vérification",
        'system.email.code.enter': "Entrez le code de vérification envoyé à: ",
        'system.email.code.resend': "Re-envoyer le code de vérification des e-mails à: ",
        'system.email.code.cancel': "Gardez votre email d'origine",
        'system.email.code.invalid': "Le code de vérification a expiré ou n'est pas valide",

        'system.frontpage.panelLeft': "Amusez-vous ce soir!",
        'system.frontpage.panelLeft.text': "Connectez-vous avec le type de personnes que vous souhaitez rencontrer en utilisant les critères de recherche les plus flexibles de l'industrie des rencontres",

        'system.frontpage.paneCenter': "Datation de Texte et Vidéo",
        'system.frontpage.paneCenter.text': "Nous vous donnons la possibilité de rencontrer des gens à travers des profils consultables, des messages et des rencontres vidéo",

        'system.frontpage.paneRight': "",
        'system.frontpage.paneRight.text': "FindTrueFriends est une nouvelle plate-forme de rencontres sociales qui permet aux gens de se connecter gratuitement",
        'system.frontpage.paneRight.bullet1': "Nous sommes totalement 'libres' à utiliser",
        'system.frontpage.paneRight.bullet2': "Messagerie privée et rencontres vidéo",
        'system.frontpage.paneRight.bullet3': "Profils Consultables",
        'system.frontpage.paneRight.bullet4': "Groupes d'Intérêt",
        'system.frontpage.paneRight.bullet5': "Nous sommes nouveaux. Essayez-nous!",
        'system.frontpage.paneRight.bullet6': "",

        'system.ftf': "FindTrueFriends",
        'system.ftf.registration': "Inscription",
        'system.gender': "Le sexe",
        'system.global': "Global",
        'system.go': "Entrer",
        'system.guest': "Invité",
        'system.height': "La Taille",
        'system.height.min': "Hauteur Min",
        'system.height.max': "Hauteur Max",
        'system.hidden': "Caché",
        'system.hint.friends': "Rechercher des amis ...",
        'system.invalidChar': "Caractére non valide",
        'system.likes': "Aimers",
        'system.likes.view': "Voir des Aimers",
        'system.loading': "Chargement...",
        'system.kick': "Kick {name} hors de la plate-forme FTF?",
        'system.local': "Local",
        'system.login': "Se Connecter",
        'system.logout': "Se Déconnecter",
        'system.member': "Membre",
        'system.members': "Membres",
        'system.menu': "Menu",
        'system.newsFeed': "Fil d'Actualité",
        'system.next': "Prochain",
        'system.no': "Non",
        'system.no.error': "",
        'system.photos': "Pas de photos...",
        'system.photos.none': "No photos...",
        'system.private': "Privé",
        'system.problem': "Il y avait un problème...",
        'system.promoteToAdmin': "Promouvoir en tant qu'administrateur",
        'system.public': "Public",
        'system.reply': "Répondre",
        'system.removeAdmin': "Supprimer l'Admin",

        'system.reportBehavior': "Signaler un comportement",
        'system.reportBehaviorDesc': "Comportement inapproprié ou abusif « sur » ou « hors » du site",
        'system.reportPhoto': "Photo ou vidéo inappropriée",
        'system.reportPhotoDesc': "Photo ou vidéo obscène, photos ou vidéos d'actes illégaux, photos ou vidéos de mineurs seuls",
        'system.reportProfile': "Profil inapproprié ou faux",
        'system.reportProfileDesc': "Texte inapproprié ou vulgaire, comprenant un e-mail ou un numéro de téléphone, des sollicitations ou des URL vers d'autres sites, faisant référence à des activités illégales, des pressions pour déplacer la communication hors du site, des demandes d'informations financières, des demandes d'argent, les photos semblent être de quelqu'un d'autre",
        'system.reportUnderage': "Mineur/Mineure",
        'system.reportUnderageDesc': "Utilisateur de moins de 18 ans",

        'system.rating': "Évaluation",
        'system.ratingDown': "Note de choc vers le bas",
        'system.ratingUp': "Augmentation de la cote de choc",
        'system.yourRating': "Votre note: ",

        'system.required': "Champs obligatoires",
        'system.resend': "Retentir",

        'system.save': "Sauver",
        'system.searchable': "(interrogeable)",
        'system.send': "Envoyer",
        'system.signUp': "S'inscrire",
        'system.stats': "Statistiques",
        'system.support': "Soutien",
        'system.textSearch.helperText': "Recherche: Descriptions et Titres",
        'system.textSearch.placeholder': "mot-clé, mot-clé, ...",
        'system.to': "à",
        'system.type': "Taper:",
        'system.update': "Mettre à jour",
        'system.use': "Utiliser",
        'system.validate': "Valider",
        'system.viewProfile': "Voir le Profil",
        'system.views': "Affichage",
        'system.views.none': "Pas de vues récentes ...",
        'system.visible': "Visible",
        'system.welcome': "Bienvenue!",
        'system.within': "dans",
        'system.yes': "Oui",
        'system.youAreNear': "Vous êtes à proximité:",

        //============================
        'system.password': "Mot de Passe",
        'system.password.confirmMethod': "Procéder par:",
        'system.password.confirmMethod.email': "Réinitialisation de votre mot de passe",

        'system.password.createEmail': "Entrez votre email enregistré (ID):",
        'system.password.new': "Crée un nouveau mot de passe",
        'system.password.forgot': "Mot de passe oublié?",
        'system.password.length': "Le mot de passe doit avoir 8 caractères",

        'system.password.confirmMethod.pin': "Saisie de votre phrase de sécurité",
        'system.password.securityPhrase': "Entrez votre phrase de sécurité",
        'system.password.securityPhrase.mismatch': "La phrase de sécurité ne correspond pas",

        'system.password.wrong': "E-mail ou mot de passe incorrect",
        'system.password.wrong.email': "L'email n'existe pas",

        //============================

        'table.member': "Membre",
        'table.online': "En Ligne",
        'table.viewDate': "Vu",

        //============================

        'tooltip.addremove': "Ajouter ou Supprimer un Ami",
        'tooltip.block': "Bloquer",
        'tooltip.blockAndReport': "Bloquer et Signaler",
        'tooltip.deleteConv': "Supprimer la Conversation",
        'tooltip.kick': "Botter",
        'tooltip.more': "Plus/Moins",
        'tooltip.profile': "Profil",
        'tooltip.reset': "Réinitialiser",
        'tooltip.remove': "Retirer",
        'tooltip.sendMsg': "Message",
        'tooltip.searchFilter': "Filtre de recherche",
        'tooltip.videoChat': "Video chat",

        //============================

        'videoChat.pause': "Pause",
        'videoChat.mute': "Muet",
        'videoChat.closing': "Clôture de la session vidéo",
        'videoChat.leftRoom': "Le membre est parti",
        'videoChat.notAccepted': "Le chat vidéo n'a pas été accepté",
        'videoChat.notAvailable': "Le chat vidéo n'est pas disponible",
        'videoChat.notOnline': "Le membre n'est pas en ligne",
        'videoChat.request': "Demande de Chat Vidéo",
        'videoChat.videoAcceesError': "Impossible de démarrer votre vidéo",
    },

    'es-ES': {
        'bodyType.slender': "Esbelto",
        'bodyType.athletic': "Atlético/Tonificado",
        'bodyType.average': "Promedio",
        'bodyType.extraPounds': "Unos kilos de más",
        'bodyType.muscular': "Muscular",
        'bodyType.curvy': "Curva",
        'bodyType.stout': "Grueso",
        'bodyType.heavyset': "Corpulento",
        'bodyType.big': "Grande",

        'children.none': "Yo no tengo hijos",
        'children.yes': "Tengo hijos",
        'children.want': "Quiero hijos",
        'children.under18': "Mis hijos son menores de 18 años.",
        'children.over18': "Mis hijos son mayores de 18 años.",
        'children.atHome': "Ellos viven en casa",
        'children.notHome': "No viven en casa",

        'drinking.none': "Nunca",
        'drinking.social': "Socialmente",
        'drinking.couple': "Un par de veces a la semana",
        'drinking.daily': "Diario",

        'ethnicity.asian': "Asiático",
        'ethnicity.caucasian': "Caucásico",
        'ethnicity.black': "Negro",
        'ethnicity.european': "Europeo",
        'ethnicity.hispanic': "Hispano",
        'ethnicity.indian': "Indio",
        'ethnicity.middleEastern': "Medio Este",
        'ethnicity.unique': "Mixto (único)",
        'ethnicity.nativeAmerican': "Nativo Americano",
        'ethnicity.northAmerican': "Norteamericana",
        'ethnicity.oceania': "Oceanía",
        'ethnicity.southAmerican': "Sudamericano",

        'education.trade': "Escuela de Comercio",
        'education.high': "Escuela Secundaria",
        'education.assocs': "Grado Asociado",
        'education.bachs': "Licenciatura",
        'education.masts': "Maestría",
        'education.phD': "Doctor",

        'eyeColor.amber': "Ámbar",
        'eyeColor.blue': "Azul",
        'eyeColor.lightBrown': "Marrón (claro)",
        'eyeColor.darkBrown': "Marrón (oscuro)",
        'eyeColor.green': "Verde",
        'eyeColor.gray': "Gris",
        'eyeColor.hazel': "Color Avellana",

        'exercise.zero': "No demasiado",
        'exercise.one': "1-2 veces por semana",
        'exercise.three': "3-4 veces por semana",
        'exercise.alot': "5+ veces por semana",

        'faith.agnostic': "Agnóstico",
        'faith.angelican': "Angelican",
        'faith.atheist': "Ateo",
        'faith.baptist': "Bautista",
        'faith.buddist': "Budista",
        'faith.catholic': "Católico",
        'faith.christian': "Cristiano",
        'faith.hindu': "Hindú",
        'faith.islam': "Islam",
        'faith.jewish': "Judío",
        'faith.lutheran': "Luterano",
        'faith.methodist': "Metodista",
        'faith.mormon': "Mormón",
        'faith.newAge': "Nueva Era",
        'faith.sikh': "Sij",
        'faith.spiritual': "Espiritual",
        'faith.presbyterian': "Presbiteriano",
        'faith.other': "Otro",

        'genders.fm': "Mujer interesada en hombres",
        'genders.mf': "Hombre interesado en mujeres",
        'genders.ff': "Mujer interesada en las mujeres",
        'genders.mm': "Hombre interesado en hombres",
        'genders.coupleFM': "Pareja (H,M)",
        'genders.coupleFF': "Pareja (H,H)",
        'genders.coupleMM': "Pareja (M,M)",

        'hairColor.bald': "Calvo",
        'hairColor.black': "Negro",
        'hairColor.blond': "Rubio",
        'hairColor.brown': "Brown",
        'hairColor.colored': "Marrón",
        'hairColor.grey': "Gris",
        'hairColor.red': "Rojo",
        'hairColor.shaved': "Afeitado",
        'hairColor.silver': "Plata",
        'hairColor.white': "Blanco",

        'height.0': "debajo 5' (152 cm)",
        'height.152': "5' 0 (152 cm)",
        'height.155': "5' 1 (155 cm)",
        'height.157': "5' 2 (157 cm)",
        'height.160': "5' 3 (160 cm)",
        'height.163': "5' 4 (163 cm)",
        'height.165': "5' 5 (165 cm)",
        'height.168': "5' 6 (168 cm)",
        'height.170': "5' 7 (170 cm)",
        'height.173': "5' 8 (173 cm)",
        'height.175': "5' 9 (175 cm)",
        'height.178': "5' 10 (178 cm)",
        'height.180': "5' 11 (180 cm)",
        'height.183': "6' 0 (183 cm)",
        'height.185': "6' 1 (185 cm)",
        'height.188': "6' 2 (188 cm)",
        'height.191': "6' 3 (191 cm)",
        'height.193': "6' 4 (193 cm)",
        'height.196': "6' 5 (196 cm)",
        'height.198': "6' 6 (198 cm)",
        'height.201': "6' 7 (201 cm)",
        'height.203': "6' 8 (203 cm)",
        'height.206': "6' 9 (206 cm)",
        'height.208': "6' 10 (208 cm)",
        'height.211': "6' 11 (211 cm)",
        'height.213': "7' 0 (213 cm)",
        'height.999': "terminado 7' (213 cm)",

        'income.0': "< $25k",
        'income.25': "> $25k",
        'income.50': "> $50k",
        'income.75': "> $75k",
        'income.100': "> $100k",
        'income.125': "> $125k",
        'income.150': "> $150k",
        'income.200': "> $200k",
        'income.300': "> $300k",

        'maritalStatus.divorced': "Divorciado",
        'maritalStatus.married': "Casado",
        'maritalStatus.separated': "Apartado",
        'maritalStatus.single': "Soltero",
        'maritalStatus.widowed': "Viudo",

        'pets.none': "Sin Mascotas",
        'pets.bird': "Pájaro(s)",
        'pets.cat': "Gato(s)",
        'pets.dog': "Perro(s)",
        'pets.ferret': "Hurón(s)",
        'pets.fish': "Pescado",
        'pets.farm': "Animales de Granja",
        'pets.hampster': "Hampster/Conejillo de Indias",
        'pets.horse': "Caballo(s)",
        'pets.poultry': "Aves de Corral",
        'pets.rabbit': "Conejo(s)",
        'pets.reptile': "Reptil(s)",

        'politics.apolitical': "Apolítico",
        'politics.conserv': "Conservador",
        'politics.indie': "Independiente",
        'politics.liberal': "Liberal",
        'politics.moderate': "Moderado",
        'politics.progessive': "Progresivo",
        'politics.other': "Otro",

        'relationship.active': "Quedarse activo",
        'relationship.art': "Arte / Artesanía",
        'relationship.casual': "Relación casual",
        'relationship.companionship': "Compañerismo",
        'relationship.daytime': "Tiempo de día",
        'relationship.dining': "Comida",
        'relationship.evenings': "Noches",
        'relationship.hangout': "Pasando el rato",
        'relationship.longterm': "Relación a largo plazo",
        'relationship.music': "Disfrutando de la música",
        'relationship.travel': "Viajar",
        'relationship.workout': "Hacer ejercicio",

        'roles.member': "Miembro",
        'roles.eventHost': "Anfitrión del Evento",
        'roles.cop': "Policía",
        'roles.groupAdmin': "Administrador del Grupo",
        'roles.ftfAdmin': "Administrador FTF",

        'secLang.none': "None",
        'secLang.arabic': "Arabic",
        'secLang.bengali': "Bengali",
        'secLang.chinese': "Chinese",
        'secLang.english': "English",
        'secLang.french': "French",
        'secLang.german': "German",
        'secLang.hindi': "Hindi",
        'secLang.italian': "Italian",
        'secLang.japanese': "Japanese",
        'secLang.javanese': "Javanese",
        'secLang.korean': "Korean",
        'secLang.mandarin': "Mandarin",
        'secLang.malay': "Malay",
        'secLang.polish': "Polish",
        'secLang.portuguese': "Portuguese",
        'secLang.punjabi': "Punjabi",
        'secLang.russian': "Russian",
        'secLang.spanish': "Spanish",
        'secLang.telugu': "Telugu",
        'secLang.urdu': "Urdu",
        'secLang.turkish': "Turkish",
        'secLang.thai': "Thai",
        'secLang.vietnamese': "Vietnamese",

        'smoking.none': "No Fumador",
        'smoking.cigarettes': "Cigarrillos",
        'smoking.cigars': "Cigarros",
        'smoking.420': "420 Amistoso",
        'smoking.dateSmoker': "Fecha un Fumador",

        'vehicles.airplane': "Avión",
        'vehicles.auto': "Automóvil",
        'vehicles.bicycle': "Bicicleta",
        'vehicles.boat': "Barco",
        'vehicles.jetski': "Jetski/Waverunner",
        'vehicles.Kayak': "Kayac",
        'vehicles.motorcycle': "Motocicleta",
        'vehicles.quad': "Quad",
        'vehicles.rv': "RV",
        'vehicles.truck': "Camión",
        'vehicles.snowmobile': "Motonieve",

        'image.addPhoto': "Añadir Foto",
        'image.aspectRatio': "Relación de Aspecto",
        'image.caption': "Subtítulo",
        'image.editCaption': "Editar",
        'image.instructFace': "Las caras deben estar claras en tus fotos",
        'image.instructSmile': "Voltea tus hombros y sonríe",
        'image.instructBackground': "Considera tus antecedentes",
        'image.instructNudity': "No subas desnudos. ¡Mantenlo con clase!",
        'image.largeGif': "El GIF es demasiado grande",
        'image.largeGifSize': "Los gifs deben ser menos de 3mb",
        'image.rotateLeft': "Girar a la Izquierda",
        'image.rotateRight': "Gira a la Derecha",
        'image.save': "Salvar",
        'image.scale': "Scale",
        'image.selectPhoto': "Seleccione una nueva foto",
        'image.startAdding': "Empezar a agregar fotos ...",

        'group.admin.rights': "Derechos de Administrador",
        'group.admin.admin': "Administrador",
        'group.admin.contact': "Miembros de Contacto",
        'group.admin.events': "Programar, Editar o Cancelar Eventos",
        'group.admin.images': "Eliminar publicaciones",
        'group.admin.members': "Aceptar o prohibir miembros",

        'group.issue.banOffender': "Prohibir delincuente",
        'group.issue.ding': "Reputación Ding: {ding}",
        'group.issue.removePost': "Eliminar publicación",
        'group.issue.removeIssue': "Eliminar problema",
        'group.issue.sendWarning': "Enviar una advertencia",

        'group.about': "Sobre el Grupo",
        'group.addAnImage': "Agregar una Imagen",
        'group.banned': "Ver miembros prohibidos",
        'group.bannedNone': "Nadie está prohibido",

        'group.create': "Crear un Grupo",
        'group.create.step1': "Detalles",
        'group.create.step2': "Foto",
        'group.create.step3': "Ubicación",

        'group.createPost': "Crear una Publicación",
        'group.events': "Eventos",
        'group.issueReports': "Informes de problemas",
        'group.join': "Unirse al Grupo",
        'group.joinHint': "Buscar un Grupo...",
        'group.goto': "Ir al Grupo",
        'group.leave': "Dejar grupo",
        'group.makeLocalGroup': "Haz de este un Grupo local",
        'group.memberList': "Miembros",
        'group.name': "Nombre del Grupo",
        'group.nameShort': "El nombre del grupo es demasiado corto",
        'group.photo': "Foto de Grupo",
        'group.remove': "Quitar Grupo",
        'group.settings': "Configuración de Grupo",
        'group.type': "Tipo de Grupo",
        'group.yourGroupIs': "Su grupo es",

        // Search Group
        'group.filter': "Filtro de grupo",
        'groupType.competitive': "Competitivo",
        'groupType.personal': "Personal",
        'groupType.professional': "Profesional",
        'groupType.recreational': "Recreativo",

        'events.messages': "Mensajes",
        'events.visits': "Visitas",
        'events.summary': "Resumen",
        'events.distance': "Distancia",
        'events.likes': "Gustar",
        'events.favorite': "Favoritos",
        'events.views': "Puntos de vista",

        'member.covid19': "Covid 19 Vacunado",
        'member.scale': "Distancias en millas o kms",
        'member.firstname': "Nombre de pila",
        'member.firstname.length': "Debe tener entre 2 y 30 caracteres",

        'member.lastname': "Apellido",
        'member.lastnamePrivate': "Tu apellido es privado",

        'member.photo.count': "{count, plural, =0 {no Fotografías} one {# Foto} other {# Fotografías}}",
        'member.profile': "Perfil",
        'member.relationship': "Relación",

        'navbar.dashboard': "Tablero",
        'navbar.events': "Eventos",
        'navbar.chats': "Chats",
        'navbar.friends': "Amigos",
        'navbar.groups': "Grupos",
        'navbar.help': "Ayuda",
        'navbar.groupevents': "Eventos",
        'navbar.language': "Idioma",
        'navbar.lockOpen': "Bloqueo Abierto",
        'navbar.profile': "Perfil",
        'navbar.support': "Apoyo",
        'navbar.search': "Buscar",
        'navbar.viewedme': "Puntos de vista",
        'navbar.whoisonline': "Quién está en línea",
        'navbar.vdate': "Fecha de video",
        'navbar.wall': "Photos",
        'navbar.widgets': "Widgets",
        'navbar.updatePhoto': "Actualiza tu foto",

        'navbar.codeofconduct': "Código de Conducta",
        'navbar.privacypolicy': "Política de privacidad",
        'navbar.cookiepolicy': "Política de cookies",
        'navbar.termsofuse': "Términos de Uso",
        'navbar.youAgree': "Usted acepta nuestro Código de conducta, Política de cookies, Términos de uso y acepta nuestra Política de privacidad",

        // Profile Email
        'profile.emailNotUnique': "Correo electrónico ya está en uso",
        'profile.emailNotValid': "Correo electrónico no válido",
        'profile.emailIsRequired': "Correo electronico es requerido",
        'profile.for': "por",

        // Profile Tabs
        'profile.about': "Acerca de",
        'profile.aboutYourself': "Acerca de ti mismo",
        'profile.describe': "Describete",
        'profile.location': "Tu ubicación",
        'profile.notify': "Notificar configuración",
        'profile.security': "Configuraciones de seguridad",
        'profile.wall': "Photos",

        // Profile Basics
        'profile.birthDateInvalid': "Debes tener más de 18 años para usar este sitio",
        'profile.birthday': "Edad, Mes de Nacimiento y Año",
        
        'profile.profilePlural': "Perfil de {firstname}",

        'profile.yourProfileIs': "Su perfil es",

        'profile.youAreA': "Eres un ",
        'profile.lookingFor': "Buscando ",
        'profile.lookingForLC': "buscando ",
        'profile.relationship': "Metas de la Relación",

        'profile.name': "Nombre",

        'profile.yourFriends': "Tu y tus Amigos",
        'profile.yourGoals': "Tus Metas",
        'profile.appearance': "Apariencia",
        'profile.bodyType': "Tipo de cuerpo",
        'profile.ethnicity': "Etnicidad",
        'profile.education': "Educación",
        'profile.eyeColor': "Color de los ojos",
        'profile.exercise': "Ejercicio",
        'profile.drinking': "Bebida",
        'profile.hairColor': "Color de Pelo",

        'profile.maritalStatus': "Estado Civil",
        'profile.faith': "Fe",
        'profile.politics': "Política",
        'profile.profession': "Profesión",
        'profile.secondLang': "Segundo Lenguaje",

        'profile.children': "Niños",
        'profile.pets': "Mascotas",
        'profile.smoking': "De Fumar",
        'profile.vehicles': "Vehículos",
        'profile.wallpaper': "Papel Pintado",

        // Profile Describe Yourself
        'profile.desc.headline': "Titular corto (búsqueda)",
        'profile.desc.desc': "Descripción de búsqueda de sus intereses, pasatiempos, música, etc...",

        // Profile Location
        'profile.location.useLocation': "Usar ubicación",
        'profile.location.kms': "kms",
        'profile.location.miles': "Millas",
        'profile.location.range': "Distancia",

        'profile.describeYourself': "Describete",
        'profile.searchRadius': "Radio de búsqueda",
        'profile.saveChanges': "Guardar",
        'profile.saving': "Ahorro...",
        'profile.notificationSettings': "Configuración de las notificaciones",
        'profile.accountSecurity': "Seguridad de cuenta",

        // Profile Notifications
        'profile.notif.allowChat': "Permitir Chat",
        'profile.notif.allowVideoChat': "Permitir Citas por Video",
        'profile.notif.allowWithoutPhotos': "Permitir miembros sin fotos.",
        'profile.notif.yourEmail': "Tu correo electrónico (privado)",
        'profile.notif.receiveEmails': "Recibir correos electrónicos",
        'profile.notif.contactOptions': "Opciones de contacto para miembros",
        'profile.notif.filterMembers': "Permitir sólo:",
        'profile.notif.blockedMembers': "Miembros bloqueados",
        'profile.notif.blockedNone': "Nadie esta bloqueado",
        'profile.notif.unblock': "Desatascar",
        'profile.notif.email.events': "Permitir correos electrónicos de eventos locales",
        'profile.notif.email.favorite': "Cuando alguien me agrega como 'Favorito'",
        'profile.notif.email.ftf': "Permitir correos electrónicos de FindTrueFriends",
        'profile.notif.email.grouppost': "Cuando alguno de sus grupos tiene una 'Publicación Nueva'",
        'profile.notif.email.like': "Cuando tengo una nueva foto 'Me gusta'",
        'profile.notif.email.message': "Cuando tengo un nuevo 'Mensaje'",
        'profile.notif.hide.visits': "Ocultar mis visitas al perfil de un miembro",
        'profile.notif.view.blockedMembers': "Ver lista de miembros bloqueados",

        // Profile Security
        'profile.security.changePassword': "Cambia la Contraseña",
        'profile.security.oldPassword': "Contraseña Anterior",
        'profile.security.oldPasswordBad': "No se acepta la contraseña anterior",
        'profile.security.newPassword': "Nueva Password",
        'profile.security.newPasswordConfirm': "Confirmar Nueva Contraseña",
        'profile.security.enterPassword': "Introduzca una nueva contraseña",

        'profile.security.passwordDontMatch': "Las contraseñas no coinciden",
        'profile.security.passwordLength': "La contraseña debe tener entre 8 y 30 caracteres",
        'profile.security.passwordStrong': "Contraseña segura",
        'profile.security.passwordWeak': "Contraseña debil",

        'profile.security.pin': "PIN Seguridad",
        'profile.security.pinDesc': "QUE HACER: Grabe su PIN. (Le pediremos este PIN si necesita recuperar su contraseña)",
        'profile.security.pinCode': "Código PIN",
        'profile.security.pinCodeConfirm': "Confirmar Código PIN",
        'profile.security.pinCodesDontMatch': "Los códigos no coinciden",

        'profile.security.cancelMembership': "Cancelar Membresía",
        'profile.security.cancelMembershipDesc1': "Eliminaremos su perfil, imágenes y publicaciones",
        'profile.security.cancelMembershipDesc2': "Si administra cualquier grupo, por favor, asigne otro administrador",

        'profile.security.sorryLeaving': "¡Sentimos verte partir {firstname}!",

        // Search Filter
        'search.category': "Categoría",
        'search.filter': "Filtro de búsqueda",
        'search.match': "igualar",
        'search.matches': "igualars",
        'search.searches': "Búsquedas",
        'search.searching': "Buscando...",
        'searchType.all': "Todas",
        'searchType.events': "Eventos",
        'searchType.groups': "Grupos",
        'searchType.people': "Personas",

        // System Messaging
        'system.msg.supportCategory': "Categoría de soporte",
        'system.msg.enterMsg': "Ingrese su mensaje...",
        'system.msg.featureRequest': "Solicitud de función",
        'system.msg.issueReport': "Informe de problema",
        'system.msg.memberIssue': "Problema de miembro",
        'system.msg.feedback': "Realimentación",
        'system.msg.welcome': "Bienvenidos...",
        'system.msg.pleaseLogin': "Por favor inicie sesión o regístrese para acceder a nuestros servicios",
        'system.msg.likedPhoto': "{firstname} me gustó tu foto",
        'system.msg.likedPost': "{firstname} me gustó tu publicación",
        'system.msg.favoredYou': "Te marcó como favorito",
        'system.msg.viewedProfile': "{firstname} visto tu perfil",
        'system.msg.reportLogged': "Informe Registrado",
        'system.msg.sentMessage': "{firstname} te envió un mensaje",
        'system.msg.bumped': "¡Tu reputación aumentó!",
        'system.msg.onlyOnceAnHour': "Permitido solo una vez por hora",

        // System
        'system.advertise': "Publicidad regional disponible...",
        'system.any': "Ninguno",
        'system.noAnswer': "Sin respuesta",

        'system.GDPR': "Siempre estamos trabajando para mejorar este sitio web para nuestros miembros. Para ello, utilizamos los datos anónimos proporcionados por las cookies. Al utilizar este sitio, acepta nuestro uso de cookies.",
        'system.GDPRHeader': "Nuestro sitio web utiliza cookies",
        'system.acceptCookies': "Aceptar",

        'system.accept': "Aceptar",
        'system.leave': "Salir",
        'system.reject': "Rechazar",

        'system.admin': "Administración",
        'system.admins': "Administradores",
        'system.age': "Años",
        'system.ays': "¿Estás seguro?",
        'system.back': "Atrás",
        'system.background': "Fondo:",
        'system.ban': "Prohibir Miembro",
        'system.banned': "Prohibido",
        'system.birthMonth': "Mes y Año de Nacimiento",
        'system.blocked': "Obstruido",
        'system.blockName': "¿Bloquer {firstname}?",
        'system.blockAndReportName': "¿Bloquear e Informar {firstname}?",
        'system.blockText1': "• No seras amigos",
        'system.blockText2': "• No podrás Chatear",
        'system.blockText3': "• No verás las Publicaciones de los demás",
        'system.blockText4': "• No se verán en Búsquedas",
        'system.writeComment': "Escribir un comentario",
        'system.cancel': "Cancelar",
        'system.cannotConnect1': "No se puede conectar...",
        'system.cannotConnect2': "Inténtelo de nuevo en un momento...",
        'system.chats.none': "No chats...",
        'system.clear': "Claro",
        'system.close': "Cerca",
        'system.comment': "Comentario",
        'system.comments': "Comentarios",
        'system.confirm': "Confirmar",
        'system.create': "Crear",
        'system.country': "País",
        'system.delete': "Eliminar",
        'system.descShort': "La description est trop courte",
        'system.remove': "Eliminar",

        'system.email': "Email",
        'system.email.code': "Código de verificación",
        'system.email.code.enter': "Ingrese el código de verificación enviado a: ",
        'system.email.code.resend': "Re-Enviar código de verificación de correo electrónico a: ",
        'system.email.code.cancel': "Mantenga su correo electrónico original",
        'system.email.code.invalid': "El código de verificación ha caducado o no es válido",

        'system.frontpage.panelLeft': "Diviértete esta noche!",
        'system.frontpage.panelLeft.text': "Conéctese con el tipo de personas que desea conocer utilizando los criterios de búsqueda más flexibles en la industria de las citas",

        'system.frontpage.paneCenter': "Citas de Texto y Video",
        'system.frontpage.paneCenter.text': "Le damos la capacidad de conocer personas a través de perfiles de búsqueda, mensajes y citas en video",

        'system.frontpage.paneRight': "",
        'system.frontpage.paneRight.text': "FindTrueFriends es una nueva plataforma de citas sociales que permite a las personas conectarse entre sí de forma gratuita",
        'system.frontpage.paneRight.bullet1': "Somos completamente 'libres' de usar",
        'system.frontpage.paneRight.bullet2': "Mensajería privada y citas por vídeo",
        'system.frontpage.paneRight.bullet3': "Perfiles de Búsqueda",
        'system.frontpage.paneRight.bullet4': "Grupos de Interés",
        'system.frontpage.paneRight.bullet5': "Somos nuevos ¡Danos una oportunidad!",
        'system.frontpage.paneRight.bullet6': "",

        'system.ftf': "FindTrueFriends",
        'system.ftf.registration': "Registro",
        'system.gender': "Género",
        'system.global': "Global",
        'system.go': "Entrar",
        'system.guest': "Invitado",
        'system.height': "Altura",
        'system.height.min': "Altura Mín",
        'system.height.max': "Altura Máx",
        'system.hidden': "Caché",
        'system.hint.friends': "Buscar amigos...",
        'system.invalidChar': "Carácter invalido",
        'system.likes': "Gustars",
        'system.likes.view': "Ver Gustars",
        'system.loading': "Cargando...",
        'system.kick': "Kick {name} fuera de la plataforma FTF?",
        'system.local': "Local",
        'system.login': "Iniciar Sesión",
        'system.logout': "Cerrar Sesión",
        'system.member': "Miembro",
        'system.members': "Miembros",
        'system.menu': "Menú",
        'system.newsFeed': "Noticias",
        'system.next': "Próximo",
        'system.no': "No",
        'system.no.error': "",
        'system.photos': "Fotos",
        'system.photos.none': "Sin fotos...",
        'system.private': "Privado",
        'system.problem': "Había un problema...",
        'system.promoteToAdmin': "Promocionar a Administrador",
        'system.public': "Público",
        'system.reply': "Respuesta",
        'system.removeAdmin': "Quitar Admin",

        'system.reportBehavior': "Informar comportamiento",
        'system.reportBehaviorDesc': "Comportamiento inapropiado o abusivo 'dentro' o 'fuera' del sitio",
        'system.reportPhoto': "Foto o video inapropiado",
        'system.reportPhotoDesc': "Foto o video obsceno, fotos o videos de actos ilegales, fotos o videos de menores solos",
        'system.reportProfile': "Perfil inapropiado o falso",
        'system.reportProfileDesc': "Texto inapropiado o vulgar, incluye correo electrónico o número de teléfono, solicitudes o direcciones URL a otros sitios, referencias a actividades ilegales, presión para mover la comunicación fuera del sitio, solicitud de información financiera, solicitud de dinero, fotos que parecen ser de otra persona",
        'system.reportUnderage': "Menor de edad",
        'system.reportUnderageDesc': "Usuario menor de 18 años",

        'system.rating': "Clasificación",
        'system.ratingDown': "Bajar la calificación de golpe",
        'system.ratingUp': "Aumento de la calificación",
        'system.yourRating': "Tu clasificación: ",

        'system.required': "Necesario",
        'system.resend': "Volver a enviar",

        'system.save': "Salvar",
        'system.searchable': "(se puede buscar)",
        'system.send': "Enviar",
        'system.signUp': "Inscribirse",
        'system.stats': "Estadísticas",
        'system.support': "Apoyo",

        'system.textSearch.helperText': "Búsqueda: Descripciones y Titulares",
        'system.textSearch.placeholder': "palabra clave, palabra clave, ...",
        'system.to': "a",
        'system.type': "Escribe:",
        'system.update': "Actualizar",
        'system.use': "Usar",
        'system.validate': "Validar",
        'system.viewProfile': "Ver Perfil",
        'system.views': "Vistas",
        'system.views.none': "No hay vistas recientes ...",
        'system.visible': "Oculto",
        'system.welcome': "¡Bienvenidos!",
        'system.within': "dentro",
        'system.yes': "Sí",
        'system.youAreNear': "Tú estás cerca:",

        //============================
        'system.password': "Contraseña",
        'system.password.confirmMethod': "Continuar por:",
        'system.password.confirmMethod.email': "Restablecimiento de su contraseña",

        'system.password.createEmail': "Ingrese su correo electrónico registrado (ID):",
        'system.password.new': "Crear una nueva contraseña",
        'system.password.forgot': "¿Olvidaste tu contraseña?",
        'system.password.length': "La contraseña debe tener 8 caracteres",

        'system.password.confirmMethod.pin': "Ingresando su Frase de Seguridad",
        'system.password.securityPhrase': "Ingrese su frase de seguridad",
        'system.password.securityPhrase.mismatch': "La frase de seguridad no coincide",

        'system.password.wrong': "Correo electrónico o la contraseña son incorrectos",
        'system.password.wrong.email': "El correo no existe",

        //============================

        'table.member': "Miembro",
        'table.online': "En Línea",
        'table.viewDate': "Visto",

        //============================

        'tooltip.addremove': "Agregar o Quitar Amigo",
        'tooltip.block': "Bloquear",
        'tooltip.blockAndReport': "Bloquear e Informar",
        'tooltip.deleteConv': "Eliminar la Conversación",
        'tooltip.kick': "Patear",
        'tooltip.more': "Más/Menos",
        'tooltip.profile': "Perfil",
        'tooltip.reset': "Reiniciar",
        'tooltip.remove': "Eliminar",
        'tooltip.sendMsg': "Mensaje",
        'tooltip.searchFilter': "Filtro de búsqueda",
        'tooltip.videoChat': "Video Chat",

        //============================

        'videoChat.pause': "Pausa",
        'videoChat.mute': "Mudo",
        'videoChat.closing': "Sesión de video de cierre",
        'videoChat.leftRoom': "El miembro se ha ido",
        'videoChat.notAccepted': "No se aceptó el chat de video",
        'videoChat.notAvailable': "El chat de video no está disponible",
        'videoChat.notOnline': "Miembro no está en línea",
        'videoChat.request': "Solicitud de Chat de Video",
        'videoChat.videoAcceesError': "No se pudo iniciar tu video",
    }
};

