import React, { useState } from "react"

import { makeStyles } from '@mui/styles';

import { DialogStyles } from "../styles/ftfStyles"

import { PageHeading } from "../styles/ftfStyles"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { FormattedMessage } from 'react-intl'

const useStyles = makeStyles(DialogStyles);


export default function PrivacyPolicy(props) {
    const classes = useStyles(props)

    const [expanded, setExpanded] = useState(null)

    function handleChange(panel) {
        setExpanded(expanded === panel ? null : panel)
    }

    return (
        <Dialog fullScreen={props.fullscreen}
            open={props.show}
            onClose={props.onClose}
        >

            <DialogTitle color="primary" className={classes.dialogTitle}>{"FindTrueFriends® Privacy Policy"}</DialogTitle>

            <DialogContent className={classes.root}>
                <div className={classes.root}>

                    <Accordion expanded={expanded === 'panel1'} onChange={(e) => handleChange('panel1')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>OUR COMMITMENT TO YOU</Typography>
                        </AccordionSummary>

                        <AccordionDetails>
                            <Typography variant="body2">
                                At FindTrueFriends, your privacy is our top priority
                                Your privacy is at the core of the way we design and build the services and products you know and love, so that you can fully trust them and focus on building meaningful connections.
                                We appreciate that you put your trust in us when you provide us with your information and we do not take this lightly.<br /><br />

                                <strong>We do not compromise with your privacy</strong>
                                <p>We design all of our products and services with your privacy in mind. We involve experts from various fields, including legal, security, engineering, product design and others to make sure that no decision is taken without respect for your privacy.</p>

                                <strong>We strive to be transparent in the way we process your data</strong>
                                <p>Because we use many of the same online services you do, we know that insufficient information and overly complicated language are common issues in privacy policies. We take the exact opposite approach: we have written our Privacy Policy and related documents in plain language. We actually want you to read our policies and understand our privacy practices!</p>

                                <strong>We work hard to keep your information secure</strong>
                                <p>We have teams dedicated to keeping your data safe and secure. We constantly update our security practices and invest in our security efforts to enhance the safety of your information.</p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel2'} onChange={(e) => handleChange('panel2')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>PRIVACY POLICY</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                Thank you for taking the time to read FindTrueFriends's Privacy Policy.
                                We appreciate that you trust us with your information and we intend to always keep that trust.
                                This starts with making sure you understand the information we collect, why we collect it, how it is used and your choices regarding your information. This Policy describes our privacy practices in plain language, keeping legal and technical jargon to a minimum.
                                PRIVACY POLICY EFFECTIVE DATE: January 1st, 2019
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    {/* <Accordion expanded={expanded === 'panel3'} onChange={(e) => handleChange('panel3')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>WHO WE ARE</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                Bright Object, Inc.
                                PO Box 777
                                Lake Zurich, IL USA  60047
                            </Typography>
                        </AccordionDetails>
                    </Accordion> */}

                    <Accordion expanded={expanded === 'panel4'} onChange={(e) => handleChange('panel4')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>WHERE THIS PRIVACY POLICY APPLIES</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                This Privacy Policy applies to our website, apps, events and other services operated by FindTrueFriends.
                                We refer to all of these as the "services" in this Privacy Policy.
                                To make it extra clear, we've added links to this Privacy Policy on all applicable services.
                                Some services may require their own unique privacy policy in which case that policy applies.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>


                    <Accordion expanded={expanded === 'panel5'} onChange={(e) => handleChange('panel5')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>THE INFORMATION WE COLLECT</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                It goes without saying, we can't help you develop meaningful connections without some information about you, such as basic profile details and the types of people you'd like to meet. We also collect information generated as you use our services, for example access logs, as well as information from third parties, like when you access our services through a social media account. If you want additional info, we go into more detail below.<br /><br />
                                <h3><strong>Information you give us</strong></h3>
                                <p>
                                    You choose to give us certain information when using our services. This includes:<br /><br />
                                    When you create an account, you provide us with at least your login credentials, as well as some basic details necessary for the service to work, such as your gender and month of birth.
                                </p>
                                <p>
                                    When you complete your profile, you can share with us additional information, such as details on your personality, lifestyle, interests and other details about you, as well as content such as photos and videos. To add certain content, like pictures or videos, you may allow us to access your camera or photo album. Some of the information you choose to provide us may be considered "special" or "sensitive" in certain jurisdictions, for example your racial or ethnic origins, sexual orientation and religious beliefs. By choosing to provide this information, you consent to our processing of that information.
                                </p>
                                <p>
                                    When you subscribe to a paid service or make a purchase directly from us (rather than through a platform such as iOS or Android), you provide us or our payment service provider with information, such as your debit or credit card number or other financial information.
                                </p>
                                <p>
                                    When you participate in surveys or focus groups, you give us your insights into our products and services, responses to our questions and testimonials.
                                </p>
                                <p>
                                    When you choose to participate in our promotions, events or contests, we collect the information that you use to register or enter.
                                </p>
                                <p>
                                    If you contact our customer care team, we collect the information you give us during the interaction. Sometimes, we monitor or record these interactions for training purposes and to ensure a high quality of service.
                                </p>
                                <p>
                                    If you ask us to communicate with or otherwise process information of other people (for example, if you ask us to send an email on your behalf to one of your friends), we collect the information about others that you give us in order to complete your request.
                                </p>
                                <p>
                                    Of course, we also process your chats with other users as well as the content you publish, as part of the operation of the services.
                                </p>

                                <strong>Information we receive from others</strong>
                                <p>
                                    In addition to the information you provide us directly, we receive information about you from others, including:<br />
                                </p>

                                <strong>Other Users</strong>
                                <p>
                                    Other users may provide information about you as they use our services. For instance, we may collect information about you from other users if they contact us about you.
                                </p>

                                <strong>Social Media</strong>
                                <p>
                                    You may be able to use your social media login (such as Facebook Login) to create and log into your FindTrueFriends account. This saves you from having to remember yet another user name and password and allows you to share some information from your social media account with us.
                                </p>
                                <strong>Other Partners</strong>
                                <p>
                                    We may receive info about you from our partners, for instance when FindTrueFriends accounts can be created through a partner's websites (in which case they pass along registration information to us) or where FindTrueFriends ads are published on a partner's websites and platforms (in which case they may pass along details on a campaign's success).
                                </p>
                                <strong>Information collected when you use our services</strong>
                                <p>
                                    When you use our services, we collect information about which features you've used, how you've used them and the devices you use to access our services. See below for more details:
                                </p>
                                <strong>Usage Information</strong>
                                <p>
                                    We collect information about your activity on our services, for instance how you use them (e.g., date and time you logged in, features you've been using, searches, clicks and pages which have been shown to you, referring webpage address, advertising that you click on) and how you interact with other users (e.g., users you connect and interact with, time and date of your exchanges, number of messages you send and receive).
                                </p>
                                <strong>Device information</strong>
                                <p>
                                    We collect information from and about the device(s) you use to access our services, including:
                                    hardware and software information such as IP address, device ID and type, device-specific and apps settings and characteristics, app crashes, advertising IDs (such as Google's AAID and Apple's IDFA, both of which are randomly generated numbers that you can reset by going into your device' settings), browser type, version and language, operating system, time zones, identifiers associated with cookies or other technologies that may uniquely identify your device or browser (e.g., IMEI/UDID and MAC address);
                                    information on your wireless and mobile network connection, like your service provider and signal strength;
                                    information on device sensors such as accelerometers, gyroscopes and compasses.
                                </p>
                                <strong>Other information with your consent</strong>
                                <p>
                                    If you give us permission, we can collect your precise geolocation (latitude and longitude) through various means, depending on the service and device you're using, including GPS, Bluetooth or WiFi connections. The collection of your geolocation may occur in the background even when you aren't using the services if the permission you gave us expressly permits such collection. If you decline permission for us to collect your geolocation, we will not collect it.
                                    Similarly, if you consent, we may collect your photos and videos (for instance, if you want to publish a photo, video or streaming on the services).
                                </p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel6'} onChange={(e) => handleChange('panel6')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>COOKIES AND OTHER SIMILAR DATA COLLECTION TECHNOLOGIES</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                We use and may allow others to use cookies and similar technologies (e.g., web beacons, pixels) to recognize you and/or your device(s). You may read our Cookie Policy for more information on why we use them (such as authenticating you, remembering your preferences and settings, analyzing site traffic and trends, delivering and measuring the effectiveness of advertising campaigns, allowing you to use social features) and how you can better control their use, through your browser settings and other tools.
                                Some web browsers (including Safari, Internet Explorer, Firefox and Chrome) have a "Do Not Track" ("DNT") feature that tells a website that a user does not want to have his or her online activity tracked. If a website that responds to a DNT signal receives a DNT signal, the browser can block that website from collecting certain information about the browser's user. Not all browsers offer a DNT option and DNT signals are not yet uniform. For this reason, many businesses, including FindTrueFriends, do not currently respond to DNT signals.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel7'} onChange={(e) => handleChange('panel7')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>HOW WE USE INFORMATION</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                The main reason we use your information is to deliver and improve our services. Additionally, we use your info to help keep you safe and to provide you with advertising that may be of interest to you. Read on for a more detailed explanation of the various reasons we use your information, together with practical examples.<br /><br />

                                <strong>To administer your account and provide our services to you</strong>
                                <p>Create and manage your account
                                    Provide you with customer support and respond to your requests
                                    Complete your transactions
                                    Communicate with you about our services, including order management and billing
                                </p>

                                <strong>To help you connect with other users</strong>
                                <p>
                                    Analyze your profile and that of other users to recommend meaningful connections
                                    Show users' profiles to one another
                                </p>

                                <strong>To ensure a consistent experience across your devices</strong>
                                <p>
                                    Link the various devices you use so that you can enjoy a consistent experience of our services on all of them. We do this by linking devices and browser data, such as when you log into your account on different devices or by using partial or full IP address, browser version and similar data about your devices to help identify and link them.
                                </p>

                                <strong>To serve you relevant offers and ads</strong>
                                <p>
                                    Administer sweepstakes, contests, discounts or other offers
                                    Develop, display and track content and advertising tailored to your interests on our services and other sites
                                    Communicate with you by email, phone, social media or mobile device about products or services that we think may interest you
                                </p>

                                <strong>To improve our services and develop new ones</strong>
                                <p>
                                    Administer focus groups and surveys
                                    Conduct research and analysis of users' behavior to improve our services and content (for instance, we may decide to change the look and feel or even substantially modify a given feature based on users' behavior)
                                    Develop new features and services (for example, we may decide to build a new interests-based feature further to requests received from users).
                                </p>

                                <strong>To prevent, detect and fight fraud or other illegal or unauthorized activities</strong>
                                <p>
                                    Address ongoing or alleged misbehavior on and off-platform
                                    Perform data analysis to better understand and design countermeasures against these activities
                                    Retain data related to fraudulent activities to prevent against recurrences
                                </p>

                                <strong>To ensure legal compliance</strong>
                                <p>
                                    Comply with legal requirements
                                    Assist law enforcement
                                    Enforce or exercise our rights, for example our Terms
                                </p>

                                <strong>To process your information as described above, we rely on the following legal bases:</strong>
                                <p>
                                    Provide our service to you: Most of the time, the reason we process your information is to perform the contract that you have with us. For instance, as you go about using our service to build meaningful connections, we use your information to maintain your account and your profile, to make it viewable to other users and recommend other users to you.
                                </p>
                                <p>
                                    Legitimate interests: We may use your information where we have legitimate interests to do so. For instance, we analyze users' behavior on our services to continuously improve our offerings, we suggest offers we think might interest you, and we process information for administrative, fraud detection and other legal purposes.
                                </p>
                                <p>
                                    Consent: From time to time, we may ask for your consent to use your information for certain specific reasons. You may withdraw your consent at any time by contacting us at the address provided at the end of this Privacy Policy.
                                </p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel8'} onChange={(e) => handleChange('panel8')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>HOW WE SHARE INFORMATION</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                Since our goal is to help you make meaningful connections, the main sharing of users' information is, of course, with other users.
                                We DO NOT share users' information with any other service providers or partners.<br /><br />

                                <strong>With other users</strong>
                                <p>
                                    You share information with other users when you voluntarily disclose information on the service (including your public profile). Please be careful with your information and make sure that the content you share is stuff that you're comfortable being publically viewable since neither you nor we can control what others do with your information once you share it.<br /><br />
                                    If you choose to limit the audience for all or part of your profile or for certain content or information about you, then it will be visible according to your settings.
                                </p>

                                <strong>With our service providers and partners</strong>
                                <p>
                                    We use third parties to help us operate and improve our services. These third parties assist us with various tasks, including data hosting and maintenance, analytics, customer care, marketing, advertising, payment processing and security operations.
                                </p>
                                <p>
                                    We may also share information with partners who distribute and assist us in advertising our services. For instance, we may share limited information on you in hashed, non-human readable form to advertising partners.
                                </p>
                                <p>
                                    We follow a strict vetting process prior to engaging any service provider or working with any partner. All of our service providers and partners must agree to strict confidentiality obligations.
                                </p>

                                <strong>For corporate transactions</strong>
                                <p>
                                    We may transfer your information if we are involved, whether in whole or in part, in a merger, sale, acquisition, divestiture, restructuring, reorganization, dissolution, bankruptcy or other change of ownership or control.
                                </p>

                                <strong>When required by law</strong>
                                <p>
                                    We may disclose your information if reasonably necessary: (i) to comply with a legal process, such as a court order, subpoena or search warrant, government / law enforcement investigation or other legal requirements; (ii) to assist in the prevention or detection of crime (subject in each case to applicable law); or (iii) to protect the safety of any person.
                                </p>

                                <strong>To enforce legal rights</strong>
                                <p>
                                    We may also share information: (i) if disclosure would mitigate our liability in an actual or threatened lawsuit; (ii) as necessary to protect our legal rights and legal rights of our users, business partners or other interested parties; (iii) to enforce our agreements with you; and (iv) to investigate, prevent, or take other action regarding illegal activity, suspected fraud or other wrongdoing.
                                </p>

                                <strong>With your consent or at your request</strong>
                                <p>
                                    We may ask for your consent to share your information with third parties. In any such case, we will make it clear why we want to share the information.
                                    We may use and share non-personal information (meaning information that, by itself, does not identify who you are such as device information, general demographics, general behavioral data, geolocation in deidentified form), as well as personal information in hashed, non-human readable form, under any of the above circumstances.
                                    We may also share this information with third parties advertisers to develop and deliver targeted advertising on our services and on websites or applications of third parties, and to analyze and report on advertising you see. We may combine this information with additional non-personal information or personal information in hashed, non-human readable form collected from other sources.
                                    More information on our use of cookies and similar technologies can be found in our Cookie Policy.
                                </p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel9'} onChange={(e) => handleChange('panel9')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>CROSS-BORDER DATA TRANSFERS</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                Sharing of information sometimes involves cross-border data transfers, for instance to the United States of America and other jurisdictions. As an example, where the service allows for users to be located in the European Economic Area ("EEA"), their personal information is transferred to countries outside of the EEA. We use standard contract clauses approved by the European Commission or other suitable safeguard to permit data transfers from the EEA to other countries. Standard contractual clauses are commitments between companies transferring personal data, binding them to protect the privacy and security of your data.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel10'} onChange={(e) => handleChange('panel10')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>YOUR RIGHTS</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                We have provided you with the following tools to control your:<br /><br />
                                <strong>Notifications</strong><br />
                                <p>Go to Profile, Notifications to adjust email, chat, and video settings to help you to control how you are contacted within the service.</p>

                                {/* <strong>Device permissions</strong><br />Mobile platforms have permission systems for specific types of device data and notifications, such as phone book and location services as well as push notifications. You can change your settings on your device to either consent or oppose the collection of the corresponding information or the display of the corresponding notifications. Of course, if you do that, certain services may lose full functionality.<br /><br /> */}

                                <strong>Account Deletion</strong>
                                <p>You can delete your account and all of it's data by using the "Cancel Membership" functionality under Profile, Security, "Cancel Membership".</p>

                                <strong>Reviewing your Privacy rights</strong>
                                <p>Applicable privacy laws may give you the right to review the personal information we keep about you.
                                    The only data we capture is the the data you enter on your Profile.  You have full control over it.
                                </p>

                                <p>If you believe that the information we hold about you is inaccurate or that we are no longer entitled to use it and want to request its rectification, deletion or object to its processing, please contact us here.</p>

                                <p>For your protection and the protection of all of our users, we may ask you to provide proof of identity before we can answer the above requests.</p>

                                <p>Keep in mind, we may reject requests for certain reasons, including if the request is unlawful or if it may infringe on trade secrets or intellectual property or the privacy of another user. If you wish to receive information relating to another user, such as a copy of any messages you received from him or her through our service, the other user will have to contact our Privacy Officer to provide their written consent before the information is released.
                                    Also, we may not be able to accommodate certain requests to object to the processing of personal information, notably where such requests would not allow us to provide our service to you anymore. For instance, we cannot provide our service if we do not have your Month of birth. </p>

                                <strong>Accountability</strong>
                                <p>In certain countries, including in the European Union, you have a right to lodge a complaint with the appropriate data protection authority if you have concerns about how we process your personal information. The data protection authority you can lodge a complaint with notably may be that of your habitual residence, where you work or where we are established.</p>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel11'} onChange={(e) => handleChange('panel11')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>RESIDENTS OF CALIFORNIA</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                If you are a California resident, you can request a notice disclosing the categories of personal information about you that we have shared with third parties for their direct marketing purposes during the preceding calendar year. To request this notice, please submit your request here. Please allow 30 days for a response. For your protection and the protection of all of our users, we may ask you to provide proof of identity before we can answer such a request.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel12'} onChange={(e) => handleChange('panel12')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>HOW WE PROTECT YOUR INFORMATION</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                We work hard to protect you from unauthorized access to or alteration, disclosure or destruction of your personal information. As with all technology companies, although we take steps to secure your information, we do not promise, and you should not expect, that your personal information will always remain secure. We regularly monitor our systems for possible vulnerabilities and attacks and regularly review our information collection, storage and processing practices to update our physical, technical and organizational security measures.
                                We may suspend your use of all or part of the services without notice if we suspect or detect any breach of security. If you believe that your account or information is no longer secure, please notify us immediately.<br /><br />
                                <a href='mailto:csr@findtruefriends.com'>csr@findtruefriends.com</a>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel13'} onChange={(e) => handleChange('panel13')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>HOW LONG WE RETAIN YOUR INFORMATION</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                We keep your personal information only as long as we need it for legitimate business purposes (as laid out in Section 5 and as permitted by applicable law).<br /><br />
                                In practice, we delete or anonymize your information upon deletion of your account or after two years of continuous inactivity, unless:
                                <ol>
                                    <li>We must keep it to comply with applicable law</li>
                                    <li>We must keep it to evidence our compliance with applicable law</li>
                                    <li>There is an outstanding issue, claim or dispute requiring us to keep the relevant information until it is resolved or</li>
                                    <li>The information must be kept for our legitimate business interests, such as fraud prevention and enhancing users' safety and security. For example, information may need to be kept to prevent a user who was banned for unsafe behavior or security incidents from opening a new account.</li>
                                    Keep in mind that even though our systems are designed to carry out data deletion processes according to the above guidelines, we cannot promise that all data will be deleted within a specific timeframe due to technical constraints.
                                </ol>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel14'} onChange={(e) => handleChange('panel14')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>CHILDREN'S PRIVACY</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                Our services are restricted to users who are 18 years of age or older.
                                We do not permit users under the age of 18 on our platform and we do not knowingly 
                                collect personal information from anyone under the age of 18. 
                                If you suspect that a user is under the age of 18, please report them through to our Customer Support service: <a href='mailto:csr@findtruefriends.com'>csr@findtruefriends.com</a>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel15'} onChange={(e) => handleChange('panel15')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>PRIVACY POLICY CHANGES</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                Because we're always looking for new and innovative ways to help you build meaningful connections, this policy may change over time. We will notify you before any material changes take effect so that you have time to review the changes.
                            </Typography>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion expanded={expanded === 'panel16'} onChange={(e) => handleChange('panel16')}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Typography variant="subtitle2" style={PageHeading}>HOW TO CONTACT US</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography variant="body2">
                                You can contact us at: <a href='mailto:csr@findtruefriends.com'>csr@findtruefriends.com</a>
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </DialogContent>

            <DialogActions>
                <Button onClick={props.onClose} color="primary" autoFocus>
                    <FormattedMessage id="system.close" defaultMessage="Close" />
                </Button>
            </DialogActions>
        </Dialog>
    );
}

