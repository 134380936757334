// chat.Reducer.js
import moment from 'moment';

import { LOGOUT } from '../actions/auth.actions'

import { CONNECTION_ERROR } from '../actions/error.actions';
import { CHAT_DELETE_REQUEST, CHAT_DELETE_SUCCESS, CHAT_DELETE_FAILED } from '../actions/chat.actions';
import { CHAT_GETLIST_REQUEST, CHAT_GETLIST_SUCCESS, CHAT_GETLIST_FAILED } from '../actions/chat.actions';
import { CHAT_SEND_REQUEST, CHAT_SEND_SUCCESS, CHAT_SEND_FAILED } from '../actions/chat.actions';
import { CHAT_GET_MSGS_REQUEST, CHAT_GET_MSGS_SUCCESS, CHAT_GET_MSGS_FAILED } from '../actions/chat.actions';

// import isEmpty from '../validation/is-empty';

const initialState = {
    isLoading: false,
    isSending: false,

    chats: [],
    currentChatId: null,

    messages: [],
    msg: "",

    errors: {},
    receivedAt: moment().format('lll SSS'),
}

export default function (state = initialState, action) {

    switch (action.type) {
        case LOGOUT:
            return {
                initialState
            }

        case CHAT_SEND_REQUEST:
            return {
                ...state,
                isLoading: true,
                isSending: true,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    chat: {},
                }
            }

        case CHAT_SEND_SUCCESS:
            return { ...initialState }


        case CHAT_SEND_FAILED:
            return { ...initialState }



        case CHAT_GETLIST_REQUEST:
            return {
                ...state,
                isLoading: true,
                // chats: [], - Causes rendered flashing
                // currentChatId: null,
                // messages: [], - Causes current chat messages to disappear (if in a chat)
                msg: "",
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    chat: {},
                }
            }

        case CHAT_GETLIST_SUCCESS:
            return {
                ...state,
                isLoading: false,
                chats: action.chats,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    chat: {},
                }
            }

        case CHAT_GETLIST_FAILED:
            return {
                ...state,
                isLoading: false,
                chats: [],
                currentChatId: null,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    chat: action.error,
                },
            }


            
        case CHAT_DELETE_REQUEST:
            return {
                ...state,
                isLoading: true,
                // chats: [], - Causes rendered flashing
                currentChatId: null,
                messages: [],
                msg: "",
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    chat: {},
                }
            }

        case CHAT_DELETE_SUCCESS:
            return {
                ...state,
                currentChatId: null,
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    chat: {},
                }
            }

        case CHAT_DELETE_FAILED:
            return {
                ...state,
                currentChatId: null,
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    chat: action.error,
                },
            }



        case CHAT_GET_MSGS_REQUEST:
            return {
                ...state,
                currentChatId: action.chatId,
                // messages: [], // - Causes rendered flashing
                isLoading: true,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    chat: {},
                }
            }

        case CHAT_GET_MSGS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                messages: action.messages,
                lastUpdated: action.receivedAt,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    chat: {},
                }
            }

        case CHAT_GET_MSGS_FAILED:
            return {
                ...state,
                currentChatId: null,
                isLoading: false,
                receivedAt: moment().format('lll SSS'),
                errors: {
                    ...state.errors,
                    chat: action.error,
                },
            }


        case CONNECTION_ERROR:
            return { ...initialState }

        default:
            return state;
    }
}