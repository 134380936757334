// PasswordStrength.js

import ThemeApp from '../styles/ThemeApp'

// http://regexlib.com/Search.aspx?k=password&c=-1&m=5&ps=20

// Check a password between 8 to 15 characters which contain at least one lowercase letter, one uppercase letter, one numeric digit, and one special character.
// var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,15})")
// var strongRegex = new RegExp("^([a-zA-Z0-9@*$#]{8,30})$")

// The string must contain at least 1 lowercase alphabetical character
// (?=.*[A-Z])	The string must contain at least 1 uppercase alphabetical character
// (?=.*[0-9])	The string must contain at least 1 numeric character
// (?=.*[!@#$%^&*])	The string must contain at least one special character, but we are escaping reserved RegEx characters to avoid conflict

var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,30})")  // WTF
var mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,30})")   // WTF

// import ThemeApp from "../styles/ThemeApp";

// var mediumRegex = new RegExp("^(?=.*\d).{6,30}$")
// var mediumRegex = new RegExp("^([a-zA-Z0-9@*#]{8,30})$")


function strength(password) {
    if (password.length === 0)
        return 0    // Enter a Password

    if (strongRegex.test(password))
        return 3    // Strong

    if (mediumRegex.test(password))
        return 2    // Weak

    return -1  // Rejected char...  
}


export const PasswordColor = function (score) {
    switch (score) {
        case -1:
            return ThemeApp.palette.error.main
        case 1:
            return ThemeApp.palette.error.main
        case 2:
            return ThemeApp.palette.primary.main
        case 3:
            return "green"
        default:
            return ThemeApp.palette.error.main
    }
}


export const PasswordScore = function (password) {
    return strength(password)
}


// export const PasswordText = function (score) {
//     return "needs some verbiage"
// }
