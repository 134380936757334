// AdContainer

// ImageList
// https://codesandbox.io/s/5yjpp339w4
// https://codesandbox.io/s/rzmz5jnom

// Animation
// https://medium.com/capriza-engineering/implementing-material-design-animation-on-cards-list-using-react-js-86994b6a4112

// https://medium.com/the-react-native-log/tips-for-react-native-images-or-saying-goodbye-to-trial-and-error-b2baaf0a1a4d
// https://material.io/tools/icons/?style=baseline
// https://materialdesignicons.com/

// How to use responsive ad tag parameters
// https://support.google.com/adsense/answer/9183460?hl=en

import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import isEmpty from '../validation/is-empty'
import { makeStyles } from '@mui/styles'
import { Typography } from '@mui/material'
import { FormattedMessage } from 'react-intl'  // , FormattedHTMLMessage
import { getAd } from '../actions/ad.actions'

// Using 'theme' and 'props' in makeStyles
// https://stackoverflow.com/questions/56111294/how-to-use-theme-and-props-in-makestyles
const useStyles = makeStyles(theme => ({
    adBlock: {
        zIndex: 7020,
        position: 'absolute',
        left: 4,
        bottom: 5,
        lineHeight: 1.2,
        wordBreak: 'break-word',
        whiteSpace: 'normal',
        // background: 'rgba(255, 255, 255, .8)', // '#03A9F4',
        padding: '2px 4px',

        // Phone Landscape
        [theme.breakpoints.down('md')]: {
            fontSize: '.875rem',
        },

        // Phone Portrait
        [theme.breakpoints.down('sm')]: {
            fontSize: '.75rem',
        },
    },

    // adBlockshadow: {
    //     zIndex: 7010,
    //     position: 'absolute',
    //     left: 5,
    //     bottom: 4,
    //     // lineHeight: 1.2,
    //     // wordBreak: 'break-word',
    //     // whiteSpace: 'normal',
    //     // textShadow: '0 0 9px rgba(0,0,0,0.9)',

    //     background: 'pink',
    //     // Phone Landscape
    //     // [theme.breakpoints.down('md')]: {
    //     //     fontSize: '.875rem',
    //     // },

    //     // Phone Portrait
    //     // [theme.breakpoints.down('sm')]: {
    //     //     fontSize: '.75rem',
    //     // },
    // },

    adImage: props => ({
        position: 'absolute',
        top: 0, left: 0,

        borderRadius: props.borderRadius || 0,
        height: '100%',
        width: '100%',
        maxHeight: '100%',
        objectFit: 'cover'  // cover fill
    }),

    adContainer: props => ({
        background: theme.palette.info.main,
        color: theme.palette.info.contrastText,
        display: 'flex',
        justifyContent: 'center',
        position: 'relative',

        variant: props.variant || "",
        margin: props.margin || 0,
        height: props.height,
        width: props.width,
    }),
}))

// https://codesandbox.io/s/wizardly-breeze-8ty87?file=/src/useWidth.js:364-377

//-----------------------------------------------
// AdContainer
// { borderRadius, height, width, margin, slot } = props
//-----------------------------------------------
export default function AdContainer(props) {
    const dispatch = useDispatch()

    const classes = useStyles(props)

    const refElem = useRef(null)

    const [imageWidth, setImageWidth] = useState(0)
    const [imageHeight, setImageHeight] = useState(0)

    const [adImage, setAdImage] = useState("")

    const ads = useSelector(state => state.ad)
    // const isLoading = useSelector(state => state.ad.isLoading)

    // adsbygoogle
    // useEffect(() => {
    //     try {
    //         (window.adsbygoogle = window.adsbygoogle || []).push({})
    //     } catch (err) {
    //         // console.log("AdContainer Exception: " + err)
    //     }
    // }, [slot])


    useEffect(() => {
        // API to load vendor ads...  
        if (imageHeight > 0 && imageWidth > 0)
            dispatch(getAd(props.variant, imageHeight, imageWidth))

    }, [dispatch, imageHeight, imageWidth, props.variant])


    useEffect(() => {
        switch (props.variant) {
            case 'footer':
                if (!isEmpty(ads.adFooterUrl))
                    setAdImage(ads.adFooterUrl)
                break

            case "chat":
                if (!isEmpty(ads.adChatUrl))
                    setAdImage(ads.adChatUrl)
                break

            case "rect":
                if (!isEmpty(ads.adRectUrl))
                    setAdImage(ads.adRectUrl)
                break

            default:
                break
        }


        // 475811

    }, [ads, props.variant])


    useEffect(() => {
        function updateDimensions() {
            if (refElem && refElem.current) {
                const { height, width } = refElem.current.getBoundingClientRect();
                setImageHeight(Math.round(height))
                setImageWidth(Math.round(width))
            }
        }

        // On mount...
        if (imageWidth || imageHeight === 0)
            updateDimensions()

        window.addEventListener('resize', updateDimensions)

        return () => {
            window.removeEventListener('resize', updateDimensions)
        }
    }, [imageHeight, imageWidth])


    return (
        <div ref={refElem} className={classes.adContainer}>
            <Typography className={classes.adBlock} color='white' variant="subtitle2">
                <FormattedMessage id="system.advertise" defaultMessage="Advertise..." />
            </Typography>

            {/* <div className={classes.adBlockshadow}>


            </div> */}

            {/* <Typography className={classes.adBlockshadow} style={{ color: 'black' }} variant="subtitle2">
                <FormattedMessage id="system.advertise" defaultMessage="Regional advertising available..." />
            </Typography> */}

            <a href="https://www.google.com/" target="_blank" rel="noreferrer">
                <img src={adImage} className={classes.adImage} />
                {/* <img src={props.widget.url} className={classes.photo} alt={props.widget._id} /> */}
            </a>

            {/* <div style={{ backgroundColor: 'pink' }}>
                <ins
                    className="adsbygoogle"
                    // style={{ display: 'block' }}
                    data-ad-client='ca-pub-4905740395340473'
                    data-ad-slot={slot}
                    data-ad-format='auto' //  "rectangle", "vertical", "horizontal" or any combination of these separated by a comma, e.g."rectangle, horizontal".
                // data-full-width-responsive="true" 
                />
            </div> */}
        </div>
    )
}

