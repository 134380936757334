// Filet Find Destructuring
// https://www.reddit.com/r/javascript/comments/8fbudv/using_lodash_how_can_i_return_the_objects_if_a/

import React, { useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles'
import { useNavigate } from 'react-router-dom'

import throttle from 'lodash.throttle'

import isEmpty from '../validation/is-empty'
import { useTheme } from '@mui/material/styles'

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

import { MemberProfileTabbedLayout } from "../styles/ftfStyles"

import { styled } from '@mui/material/styles'
import { JellyAnimation } from '../helpers/utilities'
// import styled from 'styled-components'

import { Box, Button, ButtonGroup, Grid, Rating, Tab, Tabs, Tooltip, Typography, Zoom } from '@mui/material';
import { Dialog, Slide } from '@mui/material'

// import AccountCircle from '@mui/icons-material/AccountCircle'
import Block from '@mui/icons-material/Block'
import ChatIcon from '@mui/icons-material/Chat'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import FavoriteIcon from '@mui/icons-material/Favorite'
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
// import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
// import NavigateNextIcon from '@mui/icons-material/NavigateNext'

import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined'
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined'

import VoiceChatIcon from '@mui/icons-material/VoiceChat'

import ImageIcon from '@mui/icons-material/Image'
import InfoIcon from '@mui/icons-material/Info'
import LocationOnIcon from '@mui/icons-material/LocationOn'

import { FormattedMessage, useIntl } from 'react-intl'

import MemberProfileBasics from './MemberProfileBasics'
import MemberProfileLocation from './MemberProfileLocation'
import MemberProfileWall from './MemberProfileWall'

import Avatar from './Avatar'
import BlockMember from "./BlockMember"

import { getAge, getGender } from '../helpers/utilities'
import { DialogHeadingCentered, SmallButton } from "../styles/ftfStyles"

import SendMessage2 from './SendMessage2'

// Services  // Message
import { addFriend, bumpReputation } from '../actions/member.actions'
import { showVideoChatDialog } from '../actions/app.actions'
import { profileCancelMembership } from '../actions/profile.actions'

import { css } from 'styled-components'
import BeatLoader from 'react-spinners/BeatLoader'

// https://github.com/styled-components/styled-elements
// https://medium.com/eedi/animating-svg-with-styled-components-34284a7429a3
// https://medium.com/@rossbulat/working-with-svgs-in-react-d09d1602a219
// https://spectrum.chat/styled-components?tab=posts
// https://stackoverflow.com/questions/47635991/styled-components-props-for-hover
// https://codepen.io/nelledejones/pen/gOOPWrK


const cssBeatLoader = css`
    display: block
    margin: 0 auto
    border-color: red
`


const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#0277bd',
        fontSize: '.9rem',
    },
    '& .MuiRating-iconHover': {
        color: '#ea80fc',
        fontSize: '.9rem',
    },
});


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})



const useStyles = makeStyles(theme => ({
    bullet: {
        color: theme.palette.primary.dark,
        fontSize: '1.1rem',
        margin: '-5px 2px',
    },

    dialogPaper: {
        margin: 8,
        padding: 8,
        overflow: 'hidden',

        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        border: '2px solid',
    },

    favIcon: {
        color: theme.palette.secondary.light
    },

    headerFirstname: {
        color: theme.palette.primary.dark,
        // lineHeight: 'unset',
        textAlign: "left",
    },

    headerDetails: {
        // lineHeight: 'unset',
        color: theme.palette.primary.dark,
        fontSize: '1rem',
        // marginLeft: 3,
        textAlign: "left",
        whiteSpace: 'pre-wrap',

        // Phone Landscape
        [theme.breakpoints.down('md')]: {
            fontSize: '.9rem',
        },

        // Phone Portrait
        [theme.breakpoints.down('sm')]: {
            fontSize: '.75rem',
        },
    },

    headerMember: {
        paddingLeft: 8,
        // paddingTop: 4,
        display: 'flex',
        flexDirection: 'column',
    },

    headerRegion: {
        display: 'flex',
        overflow: 'hidden',
        padding: 2,

        // height: theme.memberProfileHeaderHeight,

        // Must be done in "styles" if implemented 
        // // Phone Landscape
        // [theme.breakpoints.down('sm')]: {
        //     height: 75,
        // },

        // // Phone Portrait
        // [theme.breakpoints.down('xs')]: {
        //     height: 75,
        // },
    },

    memberAvatar: {
        alignItems: 'center',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
    },

    moreButton: {
        margin: '0 16px',
        color: theme.palette.primary.dark,
        padding: 4,
    },

    slideContainer: {
        // backgroundColor: 'orange',
        overflow: 'hidden',
        height: '100%',
        width: '100%',
    },

    slideStyleX: {
        backgroundColor: '#FFFFFF',
        overflow: 'hidden',
        width: '100%',
    },

    toolBar: {
        position: 'absolute',
        right: 1,
        top: 0,
        // justifyContent: 'space-between',
    },

    toolbarButton: {
        color: 'white',
        margin: .5,

        [theme.breakpoints.up('xs')]: {
            padding: 3,
        },

        [theme.breakpoints.up('sm')]: {
            padding: 5,
        },
    },
}))


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <span>{children}</span>
                </Box>
            )}
        </div>
    );
}


export default function MemberProfile(props) {
    const theme = useTheme()
    const classes = useStyles(props)
    const intl = useIntl()
    let navigate = useNavigate()

    const dispatch = useDispatch()

    // const userId = useSelector(state => state.profile.profile._id)
    const profile = useSelector(state => state.profile.profile)
    const isProfileVisible = useSelector(state => state.profile.profile.isVisible)

    const isLoading = useSelector(state => state.member.isLoading)
    const member = useSelector(state => state.member.member)
    // const ratingHoverValue = 0

    const [showAreYouSure, setShowAreYouSure] = useState(false)
    const [tabIndex, setTabIndex] = useState(0)
    const [isBlocking, setIsBlocking] = useState(false)
    const [showSendMessage, setShowSendMessage] = useState(false)
    const [ratingBumped, setRatingBumped] = useState(0)

    const bull = <span className={classes.bullet}>•</span>
    const [showMoreToolbar, setShowMoreToolbar] = useState(false)

    //-------------------------------------------
    // Handle a "page fresh"
    //-------------------------------------------
    useEffect(() => {
        // Do only once, Need a profile
        if (isLoading)
            return

        // Handle a "page fresh"
        if (isEmpty(member)) {
            navigate(-1)    // Go back one
        }
    }, [isLoading, navigate, member])


    //---------------------------------------------
    // Tabs
    //---------------------------------------------
    function handleTabChange(event, tabIndex) {
        setTabIndex(tabIndex)
    }

    // function handleChangeIndex(tabIndex) {
    //     setTabIndex(tabIndex)
    // }

    //---------------------------------------------
    // Add Friend
    //---------------------------------------------
    function handleAddFriendClick(e, id) {
        e.stopPropagation()
        dispatch(addFriend(id))  // Update DB
    }

    //---------------------------------------------
    // FavoriteTwoToneIcon
    //---------------------------------------------
    function isFavorite(id) {
        return (profile.favorites.find(function (f) {
            return f.ownerId == id
        }))
    }


    //-------------------------------------------
    // handleBumpReputation
    //-------------------------------------------
    const handleBumpReputation = React.useCallback((e, points) => {
        e.stopPropagation()

        setRatingBumped(points)

        dispatch(bumpReputation(member._id, points))

    }, [dispatch, member._id])


    // Throttle down to once=-a-minute
    const throttledBumpReputation = useMemo(
        (e, points) => throttle(handleBumpReputation, 60000)
        , [handleBumpReputation]
    )


    //-------------------------------------------
    // Send Message
    //-------------------------------------------
    function handleSendMessageClick(e) {
        e.stopPropagation()
        setShowSendMessage(true)
    }

    function handleCloseSendMessage() {
        setShowSendMessage(false)
    }


    //-------------------------------------------
    // Open VideoChat with friend
    //-------------------------------------------
    function handleVideoChatClick(e, member) {
        e.stopPropagation()
        dispatch(showVideoChatDialog(true, true, member))
    }


    //-------------------------------------------
    // Block
    //-------------------------------------------
    function handleBlockMemberClick(e) {
        e.stopPropagation()
        setIsBlocking(true)
    }


    //-------------------------------------------
    // Kick Member Off System (Administrators Only)
    //-------------------------------------------
    function handleKickMemberClick(e) {
        e.stopPropagation()
        setShowAreYouSure(true)
    }

    function handleCancelMembership(e) {
        e.stopPropagation()
        setShowAreYouSure(false)

        dispatch(profileCancelMembership(member._id))
        navigate(-1)    // Go back one
    }

    function toggleCancelMembershipDialog(e, reason) {
        if (reason === 'backdropClick')
            return

        setShowAreYouSure(!showAreYouSure)
    }



    function handleClose(e) {
        setIsBlocking(false)
    }


    async function handleBlockAndClose(e, reportType) {
        setIsBlocking(false)

        navigate(-1)

        // Re-run original query
        // if (!isEmpty(props.refresh))
        //     props.refresh()
    }

    function onToolbar(e, obj) {
        e.preventDefault()
        setShowMoreToolbar(!showMoreToolbar)
    }

    //-------------------------------------------
    // Verify Kick Member (Yes/No)  
    //-------------------------------------------
    function onVerifyCancelMembershipDialog() {
        return (
            <Dialog classes={{ paper: classes.dialogPaper }}
                open={showAreYouSure}
                TransitionComponent={Transition}
                onClose={toggleCancelMembershipDialog}
            >
                <Typography style={DialogHeadingCentered} variant="h6">
                    {<FormattedMessage id="system.kick" defaultMessage="Kick them off of the FTF platform?"  values={{ name: member.firstname }}/>}
                </Typography>

                <Grid container direction="row" style={{ justifyContent: "flex-end" }}>

                    <Button variant="contained" color="primary" size="small"
                        // disabled={isCancelling}
                        style={SmallButton}
                        onClick={handleCancelMembership}
                        startIcon={<DeleteForeverIcon aria-label='Cancel Membership' />}
                    >
                        <FormattedMessage id="system.yes" defaultMessage="Yes" />
                    </Button>

                    <Button variant="contained" color="primary" size="small"
                        style={SmallButton}
                        onClick={toggleCancelMembershipDialog}
                        startIcon={<FavoriteIcon className={classes.favIcon} aria-label='Go Back' />}
                    >
                        <FormattedMessage id="system.no" defaultMessage="No" />
                    </Button>
                </Grid>
            </Dialog>
        )
    }


    return (
        <Div100vh>
            <div>
                {showSendMessage && <SendMessage2 isSupportMsg={false} chat={member} onClose={handleCloseSendMessage} />}

                <div id='spinner' style={{ zIndex: 9100, boxSizing: "content-box", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                    <BeatLoader css={cssBeatLoader} sizeUnit={"px"} size={32} color={theme.palette.primary.dark} loading={isLoading} />
                </div>

                {/* getMember(id) 206 - Blocked or Hidden Profile */}
                {!member.isVisible && !isLoading && <div className={classes.headerRegion}>
                    <Typography variant='h6' className={classes.headerFirstname}>
                        <FormattedMessage id="member.profile" defaultMessage="Profile" />: <FormattedMessage id="system.hidden" defaultMessage="Hidden" />
                    </Typography>
                </div>}


                {member.isVisible && <div className={classes.headerRegion}>

                    <div className={classes.memberAvatar}>
                        <Avatar gender={member.gender} avatarUrl={member.avatarUrl} isOnline={member.isOnline} delay={250} alt={member.firstname}>...</Avatar>
                    </div>

                    {/* className={classes.headerMember} */}
                    {member.firstname && <div style={{ marginLeft: 8 }}>
                        <Grid container direction="row" wrap='nowrap' alignItems="flex-start">
                            <Typography variant='h6' className={classes.headerFirstname}>{member && member.firstname}</Typography>
                        </Grid>

                        <Grid container direction="row" alignItems="flex-start" style={{ marginTop: 4, alignItems: 'baseline' }}>
                            <Typography variant='caption' className={classes.headerDetails}>
                                <FormattedMessage id="system.age" defaultMessage="Age" />: {getAge(member.birthDate)}
                            </Typography>

                            <Typography variant='caption' className={classes.bullet}>{bull}</Typography>

                            <Typography variant='caption' className={classes.headerDetails}>
                                {intl.formatMessage({ id: getGender(member.gender), defaultMessage: 'Gender' })}
                            </Typography>

                            {/* {(member.targetGender > 0) && <Typography variant='caption' className={classes.headerDetails}>
                                {intl.formatMessage({ id: getGender(member.targetGender), defaultMessage: 'Gender' })}
                            </Typography>} */}

                            {/* <Typography variant='caption' className={classes.bullet}>{bull}</Typography> */}

                            <Typography variant='caption' className={classes.bullet}>{bull}</Typography>

                            {/* Reputation */}
                            {/* <Typography variant='caption' className={classes.headerDetails}>
                                    <FormattedMessage id="system.rating" defaultMessage="Rating" />:
                                </Typography> */}

                            <StyledRating readOnly name="Rating" color='primary'
                                precision={0.5} size='small'
                                defaultValue={5} value={member.reputationTotal / 200.0}

                            // onChange={(event, newValue) => { throttledBumpReputation(newValue) }}
                            // onChangeActive={(e, newHover) => {
                            //     setRatingHoverValue(newHover)
                            // }}
                            />

                            {/* Your "hard" rating */}
                            {/* {member.reputationTotal &&
                                <Typography color='primary' variant='caption' style={{ fontSize: '.7rem' }}>
                                    {member.reputationTotal}
                                </Typography>} */}

                        </Grid>

                        {/* Headline */}
                        {/* <Typography variant='caption' className={classes.headerDetails}>{member.headline}</Typography> */}
                    </div>}

                    <div >
                        <ButtonGroup className={classes.toolBar}
                            disableElevation
                            variant="contained"
                            color="primary"
                            aria-label="Member Toolbar"
                        >
                            <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="tooltip.addremove" defaultMessage="Add or Remove Friend" />}>
                                <span>
                                    <Button size="small"
                                        className={classes.toolbarButton}
                                        onClick={(e) => handleAddFriendClick(e, member)}
                                        aria-label="Add or Remove Friend"
                                        color="primary"
                                    >
                                        <JellyAnimation>
                                            {!isFavorite(member._id) && <FavoriteBorderOutlinedIcon fontSize="small" />}
                                            {isFavorite(member._id) && <FavoriteIcon fontSize="small" className={classes.favIcon} />}
                                        </JellyAnimation>
                                    </Button>
                                </span>
                            </Tooltip>

                            <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="tooltip.videoChat" defaultMessage="Video Chat" />}>
                                <span>
                                    <Button size="small"
                                        className={classes.toolbarButton}
                                        // *Note: If "either" party disallows "video chat" then video chat is disabled  
                                        // *Note: Not needed: || member['isOnline'] === 0

                                        disabled={!isProfileVisible || !profile.notify.allowVideoChat || member.notify['allowVideoChat'] === false}

                                        onClick={(e) => handleVideoChatClick(e, member)}
                                        aria-label="Video Chat"
                                        color="primary"
                                    >
                                        <JellyAnimation>
                                            <VoiceChatIcon fontSize="small" />
                                        </JellyAnimation>
                                    </Button>
                                </span>
                            </Tooltip>

                            <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="tooltip.sendMsg" defaultMessage="Messaging" />}>
                                <span>
                                    <Button size="small"
                                        className={classes.toolbarButton}
                                        // *Note: If either party disallows "chat" it's disabled
                                        disabled={!isProfileVisible || !profile.notify.allowChat || member.notify['allowChat'] === false}
                                        onClick={(e) => handleSendMessageClick(e)}
                                        aria-label="Message"
                                        color="primary"
                                    >
                                        <JellyAnimation>
                                            <ChatIcon fontSize="small" />
                                        </JellyAnimation>
                                    </Button>
                                </span>
                            </Tooltip>

                            {showMoreToolbar &&
                                <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="system.ratingDown" defaultMessage="Bump Rating -5" />}>
                                    <span>
                                        <Button size="small"
                                            className={classes.toolbarButton}
                                            disabled={ratingBumped != 0}
                                            onClick={(e) => throttledBumpReputation(e, -2.5)}
                                            aria-label="Bump Down"
                                            color="primary"
                                        >
                                            <JellyAnimation>
                                                <ThumbDownOutlinedIcon fontSize="small" />
                                            </JellyAnimation>
                                        </Button>
                                    </span>
                                </Tooltip>}

                            {showMoreToolbar &&
                                <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="system.ratingUp" defaultMessage="Bump Rating 5" />}>
                                    <span>
                                        <Button size="small"
                                            className={classes.toolbarButton}
                                            disabled={ratingBumped != 0}
                                            onClick={(e) => throttledBumpReputation(e, 2.5)}
                                            aria-label="Bump Up"
                                            color="primary"
                                        >
                                            <JellyAnimation>
                                                <ThumbUpOutlinedIcon fontSize="small" />
                                            </JellyAnimation>
                                        </Button>
                                    </span>
                                </Tooltip>}

                            {showMoreToolbar && <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="tooltip.block" defaultMessage="Block Member" />}>
                                <span>
                                    <Button
                                        size="small"
                                        className={classes.toolbarButton}
                                        disabled={isBlocking}
                                        onClick={(e) => handleBlockMemberClick(e)} aria-label="Block" color="primary"
                                    >
                                        <JellyAnimation>
                                            <Block fontSize="small" />
                                        </JellyAnimation>
                                    </Button>
                                </span>
                            </Tooltip>}

                            {/* This allows any FTF Admin or Support person to "kick" a member off of the platform */}
                            {showMoreToolbar && (profile.ftfAdmin || profile.ftfSupport) && <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="tooltip.kick" defaultMessage="Kick" />}>
                                <span>
                                    <Button
                                        size="small"
                                        className={classes.toolbarButton}
                                        onClick={(e) => handleKickMemberClick(e)} aria-label="Kick" color="primary"
                                    >
                                        <JellyAnimation>
                                            <DeleteForeverIcon fontSize="small" />
                                        </JellyAnimation>
                                    </Button>
                                </span>
                            </Tooltip>}


                            <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="tooltip.more" defaultMessage="More" />}>
                                <span>
                                    <Button
                                        size="small"
                                        className={classes.toolbarButton}
                                        onClick={(e) => onToolbar(e)} aria-label="Block" color="primary"
                                    >
                                        <JellyAnimation>
                                            <MoreHorizIcon fontSize="small" />
                                        </JellyAnimation>
                                    </Button>
                                </span>
                            </Tooltip>
                        </ButtonGroup>
                    </div>
                </div>}

                {/* <div className={classes.regionDesc}>
                    <Typography style={{ maxHeight: '100px' }}>{profile && profile.description}</Typography>
                </div> */}

                {member.isVisible && <Tabs variant="fullWidth" value={tabIndex} onChange={handleTabChange}>
                    <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="profile.wall" defaultMessage="Photos" />}>
                        <Tab fontSize="large" icon={
                            <JellyAnimation>
                                <ImageIcon style={{ color: 'white' }} />
                            </JellyAnimation>}
                        />
                    </Tooltip>

                    <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="profile.about" defaultMessage="About" />}>
                        <Tab fontSize="large" icon={
                            <JellyAnimation>
                                <InfoIcon style={{ color: 'white' }} />
                            </JellyAnimation>}
                        />
                    </Tooltip>

                    <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="profile.location" defaultMessage="Location" />}>
                        <Tab fontSize="large" icon={
                            <JellyAnimation>
                                <LocationOnIcon style={{ color: 'white' }} />
                            </JellyAnimation>}
                        />
                    </Tooltip>
                </Tabs>}

                {member.isVisible && <MemberProfileTabbedLayout>
                    <TabPanel value={tabIndex} index={0}>
                        <MemberProfileWall className={classes.slideStyle} />
                    </TabPanel>

                    <TabPanel value={tabIndex} index={1}>
                        <MemberProfileBasics className={classes.slideStyle} />
                    </TabPanel>

                    <TabPanel value={tabIndex} index={2}>
                        <MemberProfileLocation className={classes.slideStyle} />
                    </TabPanel>
                </MemberProfileTabbedLayout>}

                {onVerifyCancelMembershipDialog()}

                <BlockMember
                    id={member._id}
                    firstname={member.firstname}
                    objectId={null}
                    objectType="table"

                    showWarning={isBlocking}
                    handleBlockAndClose={handleBlockAndClose}
                    handleClose={handleClose}
                />
            </div>
        </Div100vh >
    )
}





