//-----------------------------------------------
// ftfStyles
//-----------------------------------------------
// import blue from '@mui/material/colors/blue'
// import lightBlue from '@mui/material/colors/lightBlue'
// import pink from '@mui/material/colors/pink'
// import { lighten } from '@mui/material/styles/colorManipulator'

// Theme Customization
// https://material-ui.com/guides/typescript/#customization-of-theme

import React from 'react'

import ThemeApp from "./ThemeApp"

import { styled } from '@mui/material/styles'
import withStyles from '@mui/styles/withStyles';
import { Badge, Button, Box } from '@mui/material'


export const BreadcrumbSeparator = ({ children, ...props }) => (
    <li className='breadcrumb-separator' {...props}>
        {children}
    </li>
)


export const MyButton = styled(Button)({
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    border: 0,
    borderRadius: 3,
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    color: 'white',
    height: 48,
    padding: '0 30px',
})


export const LayoutMax = styled(Box)({
    height: '100%',
    width: '100%',
    margin: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    id: 'LayoutMax',

    marginTop: 50,

    // Phone Landscape
    [ThemeApp.breakpoints.down('md')]: {
        // height: `calc(100% - ${ThemeApp.toolbarHeight})`,
        marginTop: 4,
    },

    // Phone Portrait
    [ThemeApp.breakpoints.down('sm')]: {
        marginTop: 4,
    },
})


export const LayoutWithoutHeader = styled(Box)({
    width: '100%',
    margin: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    id: 'LayoutWithoutHeader',

    height: `calc(100% - ${ThemeApp.toolbarHeight})`,

    // Phone Landscape
    [ThemeApp.breakpoints.down('md')]: {
        height: `calc(100% - ${ThemeApp.toolbarHeight})`,
    },

    // Phone Portrait
    [ThemeApp.breakpoints.down('sm')]: {
        height: `calc(100% - ${ThemeApp.toolbarHeight})`,
    },

    // backgroundColor: props.backgroundColor,
    // flex: '1 1 auto',
})


//-----------------------------------------------
// Used to keep "header" in view during scrolling
//-----------------------------------------------
export const LayoutWithHeader = styled(Box)({
    width: '100%',
    margin: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    id: 'LayoutWithHeader',

    // height: `calc(100% - ${ThemeApp.toolbarHeight} - ${ThemeApp.headerHeight})`,
    height: `calc(100% - ${ThemeApp.toolbarHeight} - ${ThemeApp.headerHeight})`,

    // Phone Landscape
    [ThemeApp.breakpoints.down('md')]: {
        height: `calc(100% - ${ThemeApp.toolbarHeight} - ${ThemeApp.headerHeight})`,
    },

    // Phone Portrait
    [ThemeApp.breakpoints.down('sm')]: {
        height: `calc(100% - ${ThemeApp.toolbarHeight} - ${ThemeApp.headerHeight})`,
    },

    // backgroundColor: props.backgroundColor,
    // flex: '1 1 auto',
})


export const MyTabbedLayout = styled(Box)({
    overflowY: 'auto',
    overflowX: 'hidden',

    // Wide Screen
    height: `calc(100% - ${ThemeApp.smallTabHeight} - ${ThemeApp.toolbarHeight} - ${ThemeApp.headerHeight})`,

    // Phone Landscape
    [ThemeApp.breakpoints.down('md')]: {
        height: `calc(100% - ${ThemeApp.smallTabHeight} - ${ThemeApp.toolbarHeight} - ${ThemeApp.headerHeight})`,
    },

    // Phone Portrait
    [ThemeApp.breakpoints.down('sm')]: {
        height: `calc(100% - ${ThemeApp.smallTabHeight} - ${ThemeApp.toolbarHeight} - ${ThemeApp.headerHeight})`,
    },
})


export const MemberProfileTabbedLayout = styled(Box)({
    overflowY: 'auto',
    overflowX: 'hidden',

    // Wide Screen
    height: `calc(100% - ${ThemeApp.smallTabHeight} - ${ThemeApp.toolbarHeight} - ${ThemeApp.memberProfileHeaderHeight})`,

    // Phone Landscape
    [ThemeApp.breakpoints.down('md')]: {
        height: `calc(100% - ${ThemeApp.smallTabHeight} - ${ThemeApp.toolbarHeight} - ${ThemeApp.memberProfileHeaderHeight})`,
    },

    // Phone Portrait
    [ThemeApp.breakpoints.down('sm')]: {
        height: `calc(100% - ${ThemeApp.smallTabHeight} - ${ThemeApp.toolbarHeight} - ${ThemeApp.memberProfileHeaderHeight})`,

    },
})


// Usage Example:
// import { SmallButton, ... } from "../styles/ftfStyles"
// style={FabButtonStyle}
//-----------------------------------------------
// Buttons
//-----------------------------------------------

export const SmallButton = {
    padding: '6px 10px',
    margin: '4px 8px',

    minWidth: 80,
}

// export const ButtonStyle = {
//     float: 'right',
//     height: 32,
//     margin: '4px 6px 0 0',

//     minWidth: 110,
//     maxWidth: 200,
// }

export const WideButton = {
    fontWeight: '400',
    padding: '6px 10px',
    margin: 4,

    minWidth: 170,
}

export const FabButtonStyle = {
    position: 'absolute',
    right: 16,
    top: 8,
    zIndex: 99,
}

export const FabButtonStyle2 = {
    position: 'absolute',
    right: 60,
    top: 8,
}


// export const DialogStyles = {
//     root: {
//         width: '100%',
//         padding: 4,
//     },

//     dialogTitle: {
//         alignSelf: 'left',
//         padding: '12px 12px 10px',
//         color: ThemeApp.palette.info.main,
//         textAlign: "left",
//         width: '100%',
//     },

//     headingSecondary: {
//         color: ThemeApp.palette.primary.dark,
//     },
// }



// Example:
// const useStyles = makeStyles(DialogStyles)
// className={classes.root}
export const DialogStyles = {
    root: {
        width: '100%',
        padding: 4,
    },

    dialogTitle: {
        color: ThemeApp.palette.primary.dark,
        width: '100%',
        padding: '4px 12px 4px',
    },

    headingSecondary: {
        color: ThemeApp.palette.primary.dark,
    },

    dialogPaper: {
        margin: 12,
        height: '100%',
        width: '100%',
        overflow: 'hidden',
    },

    dialogPaperTight: {
        margin: 12,
        width: '100%',
        overflow: 'hidden',
    },

    dialogContent: {
        padding: '0 12px',
    },

    iframeStyle: {
        borderWidth: 1,
        borderStyle: 'groove',
        borderColor: 'lightgrey',
        width: "100%",
        height: "100%",
    },
}


// Usage Example:
// import { DialogHeading, ... } from "../styles/ftfStyles"
// style={DialogHeading}

export const AccordianHeading = {
    alignSelf: 'center',
    color: ThemeApp.palette.primary.dark,
    // height: ThemeApp.headerHeight,
    // marginLeft: 16,
    flex: '1 0 auto',
    // lineHeight: ThemeApp.headerHeight,
    padding: 2,
    textAlign: "left",
}

export const DialogHeading = {
    alignSelf: 'left',
    color: ThemeApp.palette.primary.dark,
    height: ThemeApp.headerHeight,
    textAlign: "left",
    lineHeight: ThemeApp.headerHeight,
}

export const DialogHeadingCentered = {
    alignSelf: 'center',
    color: ThemeApp.palette.primary.dark,
    height: ThemeApp.headerHeight,
    textAlign: "left",
}

export const PageHeading = {
    alignSelf: 'center',
    color: ThemeApp.palette.primary.dark,
    flex: '1 0 auto',
    padding: 2,
    textAlign: "left",
}

export const PageHeadingCentered = {
    alignSelf: 'center',
    color: ThemeApp.palette.primary.dark,
    flex: '1 0 auto',
    padding: 2,
    textAlign: "center",
}

export const PageHeadingRight = {
    alignSelf: 'center',
    color: ThemeApp.palette.primary.dark,
    flex: '1 0 auto',
    padding: 2,
    textAlign: "right",
}

export const PageSubheading = {
    textAlign: 'left',
    color: ThemeApp.palette.primary.dark,
    flex: '1 0 auto',
    fontSize: '.7rem',
    lineHeight: ThemeApp.headerHeight,
    margin: '0 8px',
}

export const PageSubheadingRight = {
    textAlign: 'right',
    color: ThemeApp.palette.primary.dark,
    flex: '1 0 auto',
    fontSize: '.7rem',
    lineHeight: ThemeApp.headerHeight,
    margin: '0 8px',
}

export const Icon = {
    color: ThemeApp.palette.primary.dark,
    flexshrink: 0,
    marginRight: 8,
    marginTop: 16,
}


export const GeneralText = {
    alignSelf: 'left',
    color: ThemeApp.palette.primary.dark,
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
}


export const WidgetText = {
    alignSelf: 'left',
    color: ThemeApp.palette.primary.dark,
    maxHeight: '400',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflowX: 'hidden',
}


export const MultiselectFormControl = {
    minWidth: 340,
    maxWidth: 340,
}


export const StyledBadge = withStyles((theme) => ({
    badge: {
        border: `1px solid ${theme.palette.background.paper}`,
        fontSize: '0.65rem', 
        height: 17,
        right: (props) => props.offset || -5,
        top: 2,
        width: "auto",
        // padding: '0 4px',
    },
}))(Badge);


