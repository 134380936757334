import React, { useEffect, useState } from "react"

import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles';

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

import { useDebounce } from 'use-debounce'

// import isEmpty from '../validation/is-empty'

import { GeneralText, MyTabbedLayout } from "../styles/ftfStyles"

import { TextField, Typography } from '@mui/material'

import { FormattedMessage } from 'react-intl'

import { profileChangesUpdate } from '../actions/profile.actions';


const useStyles = makeStyles(theme => ({
    icon: {
        color: theme.palette.primary.dark,
        flexshrink: 0,
        marginTop: 24,
    },

    textFieldDesc: {
        lineHeight: 1.5,
    },
}))


export default function ProfileDesc(props) {
    const classes = useStyles(props)

    // const errors = useSelector(state => state.profile.errors)
    const profile = useSelector(state => state.profile.profile)

    const dispatch = useDispatch()

    const [mounted, setIsMounted] = useState(false)

    // const [headline, setMyHeadline] = useState(profile.headline)
    // const [debouncedMyHeadline] = useDebounce(headline, 1500)

    const [description, setMyDescription] = useState(profile.description)
    const [debouncedMyDescription] = useDebounce(description, 1500)

    // Debounce Solutions:
    // 
    // useDebounce:
    // https://github.com/xnimorz/use-debounce#simple-values-debouncing
    // https://github.com/xnimorz/use-debounce#debounced-callbacks
    // https://github.com/xnimorz/use-debounce/issues/27#issuecomment-496828063
    //
    // https://github.com/ryanseddon/redux-debounced
    // https://medium.com/@justintulk/debouncing-reacts-controlled-textareas-w-redux-lodash-4383084ca090
    // https://blog.isquaredsoftware.com/2017/01/practical-redux-part-7-forms-editing-reducers/
    // https://stackoverflow.com/questions/23123138/perform-debounce-in-react-js/24679479#24679479
    // 
    //
    // Debounce callback - Not tightly coupled!
    // const [debouncedCallback] = useDebouncedCallback(
    //     // function
    //     (value) => {
    //         // Sanity check
    //         if (value === profile.headline)
    //             return

    //         console.log(">>>---> debouncedCallback")

    //         // Buffer profile changes...
    //         dispatch(profileChangesUpdate({ headline: headline }))

    //     },
    //     // delay in ms
    //     1000
    // );



    //-------------------------------------------
    // headline Debounced
    //-------------------------------------------
    // useEffect(() => {
    //     if (mounted && debouncedMyHeadline) {
    //         // Sanity check
    //         if (debouncedMyHeadline === profile.headline)
    //             return

    //         // Buffer profile changes...
    //         dispatch(profileChangesUpdate({ headline: debouncedMyHeadline }))
    //     }
    // }, [dispatch, mounted, profile.headline, debouncedMyHeadline])


    //-------------------------------------------
    // description Debounced
    //-------------------------------------------
    useEffect(() => {
        if (mounted && debouncedMyDescription) {
            // Sanity check
            if (debouncedMyDescription === profile.description)
                return

            // Buffer profile changes...
            dispatch(profileChangesUpdate({ description: debouncedMyDescription }))
        }
    }, [dispatch, mounted, debouncedMyDescription, profile.description])


    //-------------------------------------------
    // Setup Defaults
    //-------------------------------------------
    useEffect(() => {
        // Do only once, Need a profile
        if (mounted)
            return

        // Sync Debounced Values
        // setMyHeadline(profile.headline) - Removed
        setMyDescription(profile.description)
        setIsMounted(true)
    }, [mounted, profile.description])


    // handleDebouncedChange = () => event => {
    //     event.persist()

    //     if (event.target.name === 'description')
    //         setState({ description: event.target.value })

    //     if (event.target.name === 'headline')
    //         setState({ headline: event.target.value })

    //     debouncedOnChange(event)
    // }


    // debouncedOnChange = event => {
    //     props.handleDebouncedChange(event)
    // }


    return (
        <Div100vh>
            <MyTabbedLayout style={{ padding: 8 }}>
                <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                    {/* <Grid item>
                        <Edit className={classes.icon} />
                        </Grid> */}

                    {/* <div style={{ height: 'auto', flex: '0 1 110px' }}>
                        <Typography style={DialogHeading} variant="h6">
                            {<FormattedMessage id="profile.desc.headline" defaultMessage="Headline" />}
                        </Typography>

                        <TextField fullWidth multiline id="headline"
                            // label={<FormattedMessage id="profile.desc.headline" defaultMessage="Headline"/>} 
                            name="headline"
                            value={headline}
                            rows="2"
                            variant="outlined"
                            onChange={(e) => setMyHeadline(e.target.value)}

                            margin="dense"
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                style: { lineHeight: 1.5 },
                            }}
                        />
                    </div> */}

                    {/* <Grid item>
                                <Edit className={classes.icon} />
                            </Grid> */}

                    <Typography style={GeneralText} variant="subtitle2">
                        {<FormattedMessage id="profile.desc.desc" defaultMessage="Describe yourself and your interests (searchable)" />}
                    </Typography>

                    <TextField fullWidth multiline id="description" className={classes.textFieldDesc}
                        // label="" />}
                        name="description"
                        value={description}
                        rows={20}
                        variant="outlined"
                        onChange={(e) => setMyDescription(e.target.value)}

                        margin="dense"
                        InputLabelProps={{ shrink: true }}
                        InputProps={{
                            style: { lineHeight: 1.5, marginBottom: 12 },
                        }}
                    />
                </div>
            </MyTabbedLayout>
        </Div100vh>
    )
}


