// tracker.actions.js

import axios from 'axios'
import { setAuthToken } from '../setAuthToken'
import { logoutUser } from './auth.actions'

// import isEmpty from '../validation/is-empty'

import { CONNECTION_ERROR } from '../actions/error.actions'
import { gaFireEvent } from '../helpers/utilities'

export const LOCATION_REQUEST = 'LOCATION_REQUEST'
export const LOCATION_SUCCESS = 'LOCATION_SUCCESS'
export const LOCATION_FAILED = 'LOCATION_FAILED'

// FUTURE - Tracker
export const LOCATION_SAVE_REQUEST = 'LOCATION_SAVE_REQUEST'
export const LOCATION_SAVE_SUCCESS = 'LOCATION_SAVE_SUCCESS'
export const LOCATION_SAVE_FAILED = 'LOCATION_SAVE_FAILED'

// FUTURE - Path
export const LOCATION_PATH_REQUEST = 'LOCATION_PATH_REQUEST'
export const LOCATION_PATH_SUCCESS = 'LOCATION_PATH_SUCCESS'
export const LOCATION_PATH_FAILED = 'LOCATION_PATH_FAILED'

// import { getLocation } from '../actions/tracker.actions'
// dispatch(getLocation(coords))

//-----------------------------------------------
// Get member location
//-----------------------------------------------
export const getLocation = (coords) => dispatch => {
    console.log(">>>---> getLocation: ")


    gaFireEvent("geocode", { 
        event_category: "reverse",
        event_label: "city",
    })

    dispatch(start(coords))

    axios.post('/api/users/locate', { params: { ...coords } })
        .then(response => {
            if (response.status === 200) {
                const { token, location } = response.data
                setAuthToken(token)  // update token 
                dispatch(success(location))
            }
            else // 206 - Validation Error(s)
                dispatch(failed(response && response.data ? response.data : response))
        })
        .catch(error => {
            let errMsg = error.message
            if (error.response != null) {
                errMsg = error.response.status.toString() + " - "
                errMsg += error.response.data.message != null ? error.response.data.message : "Cannot connect to the server"
            }

            console.log('Error: ' + errMsg)
            dispatch({ type: CONNECTION_ERROR, errors: errMsg })

            // Logout
            dispatch(logoutUser())
            window.location.href = '/login'
        })

    function start(coords) { return { type: LOCATION_REQUEST, coords } }
    function success(location) { return { type: LOCATION_SUCCESS, location } }
    function failed(errors) { return { type: LOCATION_FAILED, errors } }
}



//-----------------------------------------------
// Location Save
//-----------------------------------------------



//-----------------------------------------------
// Get Path
//-----------------------------------------------