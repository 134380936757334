// store.js

//===============================================
// Redux-Saga
//===============================================
// https://decembersoft.com/posts/redux-thunk-vs-redux-saga/
// Summary: Thunks can never act in response to an action. Redux-Saga, on the other hand, 
// subscribes to the store and can trigger a saga to run or continue when a certain action is dispatched.

// http://thecodebarbarian.com/redux-saga-vs-async-await
// https://decembersoft.com/posts/what-is-the-right-way-to-do-asynchronous-operations-in-redux/
// https://redux-saga.js.org/docs/advanced/

// Saga Login flow
// https://hackernoon.com/redux-saga-tutorial-for-beginners-and-dog-lovers-aa69a17db645
// https://github.com/stjepangolemac/monitor-saga

// https://github.com/sotojuan/saga-login-flow
// https://blog.bitsrc.io/complex-app-logic-with-redux-and-redux-saga-write-an-authentication-monitor-2f5672303d7d


import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
import rootReducer from './reducers';
import DevTools from './_components/DevTools';

const inititalState = {};

const enhancer = compose(
    // Middleware you want to use in development:
    applyMiddleware(thunkMiddleware),
    // Required! Enable Redux DevTools with the monitors you chose
    DevTools.instrument()
);

const store = createStore(
        rootReducer,
        inititalState,
        enhancer,

        // window.REDUX_DEVTOOLS_EXTENSION_COMPOSE ? window.REDUX_DEVTOOLS_EXTENSION_COMPOSE({
        //     // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        //     }) : compose(applyMiddleware(thunk))

        // old
        // compose(applyMiddleware(thunk), window.__REDUX_DEVTOOLS_EXTENSION__&& window.__REDUX_DEVTOOLS_EXTENSION__()));
);

// Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
if (module.hot) {
    module.hot.accept('./reducers', () =>
        store.replaceReducer(require('./reducers')/*.default if you use Babel 6+ */)
    );
}

// export default store;

// const store = configureStore() {
//     // Note: only Redux >= 3.1.0 supports passing enhancer as third argument.
//     // See https://github.com/reactjs/redux/releases/tag/v3.1.0
//     // const store = createStore(rootReducer, initialState, enhancer);

//     // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
//     if (module.hot) {
//         module.hot.accept('./reducers', () =>
//             store.replaceReducer(require('./reducers')/*.default if you use Babel 6+ */)
//         );
//     }

//     return store;
// }

export default store;