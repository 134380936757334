

// https://www.freecodecamp.org/news/how-to-structure-your-project-and-manage-static-resources-in-react-native-6f4cfc947d92/

const FTF_Images = {
    // Logo
    // logo: require('../images/ftf_logo_small.png'), 

    // Generic Avatars
    genderF: require('../images/genderF.png'), 
    genderM: require('../images/genderM.png'), 
    genderMM: require('../images/genderMM.png'),
    genderFF: require('../images/genderFF.png'),

    // Lights
    LED_Green: require('../images/LED_GreenFlash.png'),
    LED_Off: require('../images/LED_Off.png'),

    // Actions
    tickle: require('../images/Feather_BlueGreen_32x32.png'), 

    // Flags
    flagBlue: require('../images/Flag_system_Blue.png'), 
    flagGreen: require('../images/Flag_system_Green.png'), 
    flagPurple: require('../images/Flag_system_Purple.png'), 
    flagRed: require('../images/Flag_system_red.png'), 
    flagYellow: require('../images/Flag_system_yellow.png'), 

    // Markers
    Marker48x48: require("../images/Marker48x48.png"), 

    NoPhotos: require("../images/NoPhotos.png"), 
    CameraX: require("../images/Camera.png"), 

    // Generic Group
    // group1: require('../images/group.jpg'), 
    // group2: require('../images/group2.jpg'), 
    // group3: require('../images/group3.jpg'), 
    // group4: require('../images/group4.jpg'), 
    // group5: require('../images/group5.jpg'), 
}

export default FTF_Images


