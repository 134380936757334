import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
// import { makeStyles } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

import GroupCreate from "./GroupCreate"
import GroupsGallery from "../helpers/GroupsGallery"

import { FabButtonStyle, LayoutWithoutHeader } from "../styles/ftfStyles"
import { useTheme } from '@mui/material/styles'
import { Button, Fab, Tooltip, Typography, Zoom } from '@mui/material'
import { JellyAnimation } from '../helpers/utilities'

import isEmpty from '../validation/is-empty';

// import CancelIcon from '@mui/icons-material/Cancel'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import SearchIcon from '@mui/icons-material/Search'

import { FormattedMessage } from 'react-intl'

import { getGroups } from '../actions/group.actions'

import { css } from 'styled-components'
import BeatLoader from 'react-spinners/BeatLoader'

const cssBeatLoader = css`
    display: block
    margin: 0 auto
    border-color: red
`


export default function GroupsPage(props) {
    const theme = useTheme()

    // const classes = useStyles(props)
    let navigate = useNavigate()
    const page = useRef(null)
    const userId = useSelector(state => state.profile.profile._id)
    const groups = useSelector(state => state.group.groups)
    const isLoading = useSelector(state => state.group.isLoading)

    // const isWallLoaded = useSelector(state => state.image.isWallLoaded)
    // const isLoading = useSelector(state => state.image.isLoading)

    // Only after useSelector(s) for now... - DSW
    const dispatch = useDispatch()

    const [showGroupCreate, setshowGroupCreate] = useState(false)
    const [pageHeight, setPageHeight] = useState(500)


    useEffect(() => {
        dispatch(getGroups())

        if (page.current)
            setPageHeight(page.current.clientHeight)

    }, [dispatch])


    //-------------------------------------------
    // onSearchForGroup
    //-------------------------------------------
    function onSearchForGroup() {
        navigate('/search?groups')  // Navigate to the "Search" page
    }

    //-------------------------------------------
    // onGroupCreate
    //-------------------------------------------
    function onGroupCreate() {
        setshowGroupCreate(true)
    }

    //-------------------------------------------
    // handleGroupCreateClose
    //-------------------------------------------
    const handleGroupCreateClose = () => {
        setshowGroupCreate(false)
    }

    return (
        <Div100vh>
            {/* <Grid container direction="row" alignItems="flex-start" className={classes.headerRegion}>
                <Typography style={PageHeading} variant="h5">{<FormattedMessage id="navbar.groups" defaultMessage="Groups" />}</Typography>
            </Grid> */}

            {/* No Groups - Button to Search */}
            {isEmpty(groups) && !isLoading &&
                <Button variant="contained" color="primary" style={{ margin: 6 }} onClick={onSearchForGroup}>
                    <SearchIcon /><Typography variant="subtitle2">{<FormattedMessage id="group.joinHint" defaultMessage="Search for a group..." />}</Typography>
                </Button>
            }

            <LayoutWithoutHeader ref={page} style={{ transform: 'translateZ(0)' }}>
                {groups && userId && <GroupsGallery
                    memberId={userId}
                    allowChanges
                    height={pageHeight}

                    groups={groups.map(({ id, groupName, createdOn, headline, isAdminMgr, isLocal, isMember, isPrivate, memberCount, reputation, city, state, country, url }) => ({
                        id: id,
                        groupName,
                        createdOn,

                        headline,

                        isAdminMgr,
                        isLocal,
                        isMember,
                        isPrivate,

                        memberCount,
                        reputation,

                        city,
                        state,
                        country,

                        src: url,
                    }))}

                    spinnerColor={'#00D8FF'}
                    spinnersize={50}
                />}
            </LayoutWithoutHeader>

            {/* TODO: Need to Debounce */}
            <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="left" title={<FormattedMessage id="group.create" defaultMessage="Create a Group" />}>
                <Fab color="primary" size="small" variant="circular" style={FabButtonStyle} onClick={onGroupCreate}>
                    <JellyAnimation>
                        <AddCircleIcon aria-label='Create Group' />
                    </JellyAnimation>
                </Fab>
            </Tooltip>

            {showGroupCreate && <GroupCreate id={null} show={showGroupCreate} fullscreen onClose={handleGroupCreateClose} />}

            <div id='spinner' style={{ boxSizing: "content-box", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                <BeatLoader css={cssBeatLoader} sizeUnit={"px"} size={32} color={theme.palette.primary.dark} loading={isLoading} />
            </div>
        </Div100vh>
    )
}

