// Dashboard

// Charting Tools
// https://github.com/d3/d3
// https://www.npmjs.com/package/react-google-charts#installation
// https://jsfiddle.net/api/post/library/pure/
// https://developers.google.com/chart/interactive/docs/gallery/linechart

// ChartJS & React
// https://blog.bitsrc.io/customizing-chart-js-in-react-2199fa81530a
// https://github.com/chartjs/awesome#resources

import React, { useEffect, useRef } from "react"
import { useSelector } from "react-redux"
import { useTheme } from '@mui/material/styles';

import { makeStyles } from '@mui/styles';

// import AdContainer from "./AdContainer"

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'
// import { use100vh } from 'react-div-100vh'

// import CalendarEvent from '../helpers/CalendarEvent'
// import ViewEvent from "./ViewEvent"

import { FormattedMessage, useIntl } from 'react-intl';

import isEmpty from '../validation/is-empty';

import { LayoutWithHeader, PageHeading } from "../styles/ftfStyles"

import { Grid, Typography } from '@mui/material';

// import { alpha } from '@mui/material/style
// import { MediaQueryDesktop, MediaQueryTablet, MediaQueryPhoneLandscape } from '../helpers/mediaquery'

import Chart from "chart.js/auto"

// import { groupEventLike, groupEventShare, groupEventDelete } from '../actions/group.actions'

import "react-big-calendar/lib/css/react-big-calendar.css";
// import { Calendar, momentLocalizer } from 'react-big-calendar'
// import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import moment from 'moment'


// require('globalize/lib/cultures/globalize.culture.es')
// require('globalize/lib/cultures/globalize.culture.fr')

// Setup the localizer by providing the moment (or globalize) Object
// to the correct localizer.
// const localizer = momentLocalizer(moment)

// import { getProfile } from '../actions/profile.actions';


var groupBy = require('lodash.groupby');

var myLikesChart
var myFavoritesChart
var myViewsChart
var myMessagesChart

const useStyles = makeStyles(theme => ({

    AdMobile300x250: {
        position: 'relative',
        minWidth: 300,
        minHeight: 250,
        color: theme.palette.secondary.contrastText,
        background: theme.palette.secondary.main,
    },

    adBlock: {
        selfAlign: 'center',
        justifyContent: 'center',
    },

    badge: {
        // right: -3,
        // top: 13,
        // border: `1px solid pink`,
        padding: '0 4px 0 0',
    },

    calendar: {
        padding: 8,

        position: 'relative',  // Required for ChartJS
        textAlign: 'center',
        justifyContent: 'center',
    },

    chart: {
        maxWidth: 350,

        paddingTop: 8,
        paddingLeft: 8,
        paddingRight: 8,

        position: 'relative',  // Required for ChartJS
        textAlign: 'center',
        justifyContent: 'center',
    },
}))

//--Chart Style Options--//
Chart.defaults.font.family = "'PT Sans', sans-serif"
// Chart.defaults.legend.display = true;
// Chart.defaults.elements.line.tension = 0;
//--Chart Style Options--//

// const CalendarEventContainer = ({ onPopoverButtonClick }) => props => {
//     return <CalendarEvent event={props} onPopoverButtonClick={onPopoverButtonClick} />
// }

export default function DashboardPage(props) {
    const classes = useStyles(props)
    const theme = useTheme()

    const intl = useIntl()

    const profile = useSelector(state => state.profile.profile)

    // const userId = useSelector(state => state.profile.profile._id)
    // const profileGroupEvents = useSelector(state => state.profile.profile)
    // const errors = useSelector(state => state.chat.errors)

    // const dispatch = useDispatch()

    // Calendar Features
    // const [anchorEl, setAnchorEl] = React.useState(null)
    // const [eventId, setEventId] = React.useState(null)
    // const [event, setEvent] = React.useState(null)

    // const [showAddModal, setShowAddModal] = React.useState(false);
    // const [showEditModal, setShowEditModal] = React.useState(false);
    // const [calendarEvent, setCalendarEvent] = React.useState({});

    const lineTension = .1

    const refFavorites = useRef(null)
    const refLikes = useRef(null)
    const refViews = useRef(null)
    const refMessages = useRef(null)

    useEffect(() => {
        // when component unmounts
        return () => {
            myLikesChart.destroy()
            myFavoritesChart.destroy()
            myViewsChart.destroy()
            myMessagesChart.destroy()
        }
    }, [])

    //---------------------------------------------------
    // Load the latest Chart events from the User
    // https://react-google-charts.com/data-sources/from-api    
    // https://developers.google.com/chart/interactive/docs/reference#DataView
    //---------------------------------------------------
    useEffect(() => {
        // Sanity check
        if (isEmpty(profile))
            return

        var startdate = moment();
        startdate = startdate.subtract(10, "days");

        // Filter (last 10 days)
        const myEvents = profile.events.filter(e => e.eventDate >= startdate.format('YYYY-MM-DD'))

        // Fill in any missing days...
        var day = startdate;
        while (day <= moment()) {
            let event = {
                eventType: 0,
                eventDate: new Date(day),
            }
            myEvents.push(event)
            day.add(1, "days");
        }

        // GroupBy Date
        var eventsGrouped = groupBy(myEvents, function (e) {
            return moment(e.eventDate).startOf('day').format('YYYY-MM-DD');
        })

        // Turn into arrays (these have to match the cols (below))
        const eventsGroupedArrays = Object.keys(eventsGrouped).map((date) => {
            return {
                date,

                likeCount: eventsGrouped[date].filter(e => e.eventType === 220 || e.eventType === 230).length,
            }
        })

        // sort by date ascending
        eventsGroupedArrays.sort(function (a, b) {
            var dateA = new Date(a.date), dateB = new Date(b.date)
            return dateA.getTime() - dateB.getTime()
        })


        // Build rows for the graph
        let labels = []
        let dataLikes = []

        for (let row of eventsGroupedArrays) {
            const { date, likeCount } = row

            const momentSimple = moment.utc(date);
            const defaultFormatSimple = intl.formatDate(momentSimple, { month: "short", day: "2-digit", timeZone: 'UTC' })
            labels.push([defaultFormatSimple])

            // labels.push([moment(date).format('MMM D')])
            dataLikes.push(likeCount)
        }

        // setChartData([eventsGroupedArrays])
        // console.log('Events >>>---> chartData Complete')    

        //--------------------------------------------------------------------
        // ChartJS
        //--------------------------------------------------------------------
        const myLikesChartRef = refLikes.current.getContext("2d");

        // const { data, average, labels } = props;

        if (typeof myLikesChart !== "undefined")
            myLikesChart.destroy();

        myLikesChart = new Chart(myLikesChartRef, {
            type: "line",
            data: {
                labels: labels,

                datasets: [
                    {
                        label: intl.formatMessage({ id: 'events.likes', defaultMessage: 'Likes' }),
                        data: dataLikes,
                        fill: false,
                        lineTension: lineTension,
                        pointRadius: 3,

                        borderWidth: 1,
                        borderColor: theme.palette.primary.main,
                        pointBorderColor: theme.palette.primary.main,
                        backgroundColor: theme.palette.primary.main,
                    },
                ]
            },

            options: {
                plugins: {
                    legend: {
                        // position: "bottom",

                        labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            boxWidth: 3,
                            color: theme.palette.primary.main,
                            font: {
                                // weight: 'bold',
                                size: 14
                            }
                        }
                    },
                    tooltip: {
                        backgroundColor: theme.palette.primary.light,
                        bodyColor: 'rgb(0, 0, 0)',
                        titleColor: 'rgb(0, 0, 0)',
                        usePointStyle: true,
                        boxWidth: 7,
                        callbacks: {
                            labelPointStyle: function (context) {
                                return {
                                    pointStyle: 'circle',
                                    rotation: 0
                                };
                            },

                            label: function (context) {
                                var label = '  '
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US').format(context.parsed.y);
                                }
                                return label;
                            }
                        }
                    }
                },

                responsive: true,
                aspectRatio: 1.5,
                maintainAspectRatio: false,

                layout: {
                    padding: {
                        top: 5,
                        left: 15,
                        right: 15,
                        bottom: 15
                    }
                },

                // https://www.chartjs.org/docs/latest/axes/cartesian/linear.html#step-size
                scales: {
                    y: {
                        grid: {
                            drawBorder: false,
                        },

                        autoSkip: true,
                        beginAtZero: true,
                        // stepSize: 1,
                        stepValue: 1,
                        fontSize: 11,
                        fontColor: theme.palette.primary.dark,
                        min: 0,
                        suggestedMax: 10,
                        precision: 0,
                    },

                    x: {
                        ticks: {
                            // autoSkip: false,
                            // fontColor: theme.palette.primary.dark,
                            // bold: true,
                            color: 'rgb(0, 0, 0)',
                        },
                    },
                },
            }
        })

    }, [intl, profile, lineTension,
        theme.palette.primary.dark, theme.palette.primary.light, theme.palette.primary.main, theme.palette.secondary.light, theme.palette.secondary.main
    ])


    //---------------------------------------------------
    // Load the latest Chart events for the User
    // https://react-google-charts.com/data-sources/from-api    
    // https://developers.google.com/chart/interactive/docs/reference#DataView
    //---------------------------------------------------
    useEffect(() => {
        // Sanity check
        if (isEmpty(profile))
            return

        var startdate = moment();
        startdate = startdate.subtract(10, "days");

        // Filter (last 10 days)
        const myEvents = profile.events.filter(e => e.eventDate >= startdate.format('YYYY-MM-DD'))

        // Fill in any missing days...
        var day = startdate;
        while (day <= moment()) {
            let event = {
                eventType: 0,
                eventDate: new Date(day),
            }
            myEvents.push(event)
            day.add(1, "days");
        }

        // GroupBy Date
        var eventsGrouped = groupBy(myEvents, function (e) {
            return moment(e.eventDate).startOf('day').format('YYYY-MM-DD');
        })

        // Turn into arrays (these have to match the cols (below))
        const eventsGroupedArrays = Object.keys(eventsGrouped).map((date) => {
            return {
                date,
                favoriteCount: eventsGrouped[date].filter(e => e.eventType === 250).length,
            }
        });

        // sort by date ascending
        eventsGroupedArrays.sort(function (a, b) {
            var dateA = new Date(a.date), dateB = new Date(b.date)
            return dateA.getTime() - dateB.getTime()
        })

        // Build rows for the graph
        let labels = []
        let dataFavorite = []

        for (let row of eventsGroupedArrays) {
            const { date, favoriteCount } = row

            const momentSimple = moment.utc(date);
            const defaultFormatSimple = intl.formatDate(momentSimple, { month: "short", day: "2-digit", timeZone: 'UTC' })
            labels.push([defaultFormatSimple])

            // labels.push([moment(date).format('MMM D')])
            dataFavorite.push(favoriteCount)
        }

        // setChartData([eventsGroupedArrays])
        // console.log('Events >>>---> chartData Complete')    

        //--------------------------------------------------------------------
        // ChartJS
        //--------------------------------------------------------------------
        const myFavoritesChartRef = refFavorites.current.getContext("2d");

        // const { data, average, labels } = props;

        if (typeof myFavoritesChart !== "undefined")
            myFavoritesChart.destroy();

        myFavoritesChart = new Chart(myFavoritesChartRef, {
            type: "line",
            data: {
                labels: labels,

                datasets: [
                    {
                        label: intl.formatMessage({ id: 'events.favorite', defaultMessage: 'Favorite' }),
                        data: dataFavorite,
                        fill: false,
                        lineTension: lineTension,
                        pointRadius: 3,

                        borderWidth: 1,
                        borderColor: theme.palette.secondary.main,
                        pointBorderColor: theme.palette.secondary.main,
                        backgroundColor: theme.palette.secondary.main,
                    },
                ]
            },

            options: {
                plugins: {
                    legend: {
                        // position: "bottom",

                        labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            boxWidth: 3,
                            color: theme.palette.secondary.main,
                            font: {
                                // weight: 'bold',
                                size: 14
                            }
                        }
                    },
                    tooltip: {
                        backgroundColor: theme.palette.primary.light,
                        bodyColor: 'rgb(0, 0, 0)',
                        titleColor: 'rgb(0, 0, 0)',
                        usePointStyle: true,
                        boxWidth: 7,
                        callbacks: {
                            labelPointStyle: function (context) {
                                return {
                                    pointStyle: 'circle',
                                    rotation: 0
                                };
                            },

                            label: function (context) {
                                var label = '  '
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US').format(context.parsed.y);
                                }
                                return label;
                            }
                        }
                    }
                },

                responsive: true,
                aspectRatio: 1.5,
                maintainAspectRatio: false,

                layout: {
                    padding: {
                        top: 5,
                        left: 15,
                        right: 15,
                        bottom: 15
                    }
                },

                // https://www.chartjs.org/docs/latest/axes/cartesian/linear.html#step-size
                scales: {
                    y: {
                        autoSkip: true,
                        beginAtZero: true,
                        // stepSize: 1,
                        stepValue: 1,
                        fontSize: 11,
                        fontColor: theme.palette.primary.dark,
                        min: 0,
                        suggestedMax: 10,
                        precision: 0,
                    },
                    x: {
                        ticks: {
                            // autoSkip: false,
                            // fontColor: theme.palette.primary.dark,
                            // bold: true,
                            color: 'rgb(0, 0, 0)',
                        },
                    },
                },

                tooltips: {
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data['labels'][tooltipItem[0]['index']]
                        },
                        label: function (tooltipItem, data) {
                            var label = ' '
                            label += data['datasets'][0]['data'][tooltipItem['index']]
                            return label
                        },
                    }
                }
            }
        })

    }, [intl, profile, lineTension,
        theme.palette.primary.dark, theme.palette.primary.light, theme.palette.primary.main, theme.palette.secondary.light, theme.palette.secondary.main
    ])


    //---------------------------------------------------
    // Views
    // https://react-google-charts.com/data-sources/from-api    
    // https://developers.google.com/chart/interactive/docs/reference#DataView
    //---------------------------------------------------
    useEffect(() => {
        // Sanity check
        if (isEmpty(profile))
            return

        var startdate = moment();
        startdate = startdate.subtract(10, "days");

        // Filter (last 10 days)
        const myEvents = profile.events.filter(e => e.eventDate >= startdate.format('YYYY-MM-DD'))

        // Fill in any missing days...
        var day = startdate;
        while (day <= moment()) {
            let event = {
                eventType: 0,
                eventDate: new Date(day),
            }
            myEvents.push(event)
            day.add(1, "days");
        }

        // GroupBy Date
        var eventsGrouped = groupBy(myEvents, function (e) {
            return moment(e.eventDate).startOf('day').format('YYYY-MM-DD');
        })

        // Turn into arrays (these have to match the cols (below))
        const eventsGroupedArrays = Object.keys(eventsGrouped).map((date) => {
            return {
                date,
                viewCount: eventsGrouped[date].filter(e => e.eventType === 210).length,
            }
        });

        // sort by date ascending
        eventsGroupedArrays.sort(function (a, b) {
            var dateA = new Date(a.date), dateB = new Date(b.date)
            return dateA.getTime() - dateB.getTime()
        })


        // Build rows for the graph
        let labels = []
        let dataViews = []

        for (let row of eventsGroupedArrays) {
            const { date, viewCount } = row

            const momentSimple = moment.utc(date);
            const defaultFormatSimple = intl.formatDate(momentSimple, { month: "short", day: "2-digit", timeZone: 'UTC' })
            labels.push([defaultFormatSimple])

            // labels.push([moment(date).format('MMM D')])
            dataViews.push(viewCount)
        }

        // setChartData([eventsGroupedArrays])
        // console.log('Events >>>---> chartData Complete')    

        //--------------------------------------------------------------------
        // ChartJS
        //--------------------------------------------------------------------
        const myViewsChartRef = refViews.current.getContext("2d")

        if (typeof myViewsChart !== "undefined")
            myViewsChart.destroy();

        myViewsChart = new Chart(myViewsChartRef, {
            type: "line",
            data: {
                labels: labels,

                datasets: [
                    {
                        label: intl.formatMessage({ id: 'events.views', defaultMessage: 'Views' }),
                        data: dataViews,
                        fill: false,
                        lineTension: lineTension,
                        pointRadius: 3,

                        borderWidth: 1,
                        borderColor: "green",
                        pointBorderColor: "green",
                        backgroundColor: 'green',
                    }
                ]
            },

            options: {
                plugins: {
                    legend: {
                        // position: "bottom",

                        labels: {
                            // fontColor: "green",
                            usePointStyle: true,
                            pointStyle: 'circle',
                            boxWidth: 3,
                            color: "green",
                            font: {
                                // weight: 'bold',
                                size: 14
                            }
                        },
                    },
                    tooltip: {
                        backgroundColor: theme.palette.primary.light,
                        bodyColor: 'rgb(0, 0, 0)',
                        titleColor: 'rgb(0, 0, 0)',
                        usePointStyle: true,
                        boxWidth: 7,
                        callbacks: {
                            labelPointStyle: function (context) {
                                return {
                                    pointStyle: 'circle',
                                    rotation: 0
                                };
                            },

                            label: function (context) {
                                var label = '  '
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US').format(context.parsed.y);
                                }
                                return label;
                            }
                        }
                    }
                },

                responsive: true,
                aspectRatio: 1.5,
                maintainAspectRatio: false,

                layout: {
                    padding: {
                        top: 5,
                        left: 15,
                        right: 15,
                        bottom: 15
                    }
                },

                // https://www.chartjs.org/docs/latest/axes/cartesian/linear.html#step-size
                scales: {
                    y: {
                        autoSkip: true,
                        beginAtZero: true,
                        // stepSize: 1,
                        stepValue: 1,
                        fontSize: 11,
                        fontColor: theme.palette.primary.dark,
                        min: 0,
                        suggestedMax: 10,
                        precision: 0,
                    },
                    x: {
                        ticks: {
                            // autoSkip: false,
                            // fontColor: theme.palette.primary.dark,
                            // bold: true,
                            color: 'rgb(0, 0, 0)',
                        },
                    },
                },

                tooltips: {
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data['labels'][tooltipItem[0]['index']]
                        },
                        label: function (tooltipItem, data) {
                            var label = ' '
                            label += data['datasets'][0]['data'][tooltipItem['index']]
                            return label
                        },
                    }
                }
            }
        })

    }, [intl, profile, lineTension,
        theme.palette.primary.dark, theme.palette.primary.light, theme.palette.primary.main, theme.palette.secondary.light, theme.palette.secondary.main
    ])


    //---------------------------------------------------
    // Messages
    // https://react-google-charts.com/data-sources/from-api    
    // https://developers.google.com/chart/interactive/docs/reference#DataView
    //---------------------------------------------------
    useEffect(() => {
        // Sanity check
        if (isEmpty(profile))
            return

        var startdate = moment();
        startdate = startdate.subtract(10, "days");

        // Filter (last 10 days)
        const myEvents = profile.events.filter(e => e.eventDate >= startdate.format('YYYY-MM-DD'))

        // Fill in any missing days...
        var day = startdate;
        while (day <= moment()) {
            let event = {
                eventType: 0,
                eventDate: new Date(day),
            }
            myEvents.push(event)
            day.add(1, "days");
        }

        // GroupBy Date
        var eventsGrouped = groupBy(myEvents, function (e) {
            return moment(e.eventDate).startOf('day').format('YYYY-MM-DD');
        })

        // Turn into arrays (these have to match the cols (below))
        const eventsGroupedArrays = Object.keys(eventsGrouped).map((date) => {
            return {
                date,
                viewCount: eventsGrouped[date].filter(e => e.eventType === 310).length,
            }
        });

        // sort by date ascending
        eventsGroupedArrays.sort(function (a, b) {
            var dateA = new Date(a.date), dateB = new Date(b.date)
            return dateA.getTime() - dateB.getTime()
        })


        // Build rows for the graph
        let labels = []
        let dataMessages = []

        for (let row of eventsGroupedArrays) {
            const { date, viewCount } = row

            const momentSimple = moment.utc(date);
            const defaultFormatSimple = intl.formatDate(momentSimple, { month: "short", day: "2-digit", timeZone: 'UTC' })
            labels.push([defaultFormatSimple])

            // labels.push([moment(date).format('MMM D')])
            dataMessages.push(viewCount)
        }

        // setChartData([eventsGroupedArrays])
        // console.log('Events >>>---> chartData Complete')    

        //--------------------------------------------------------------------
        // ChartJS
        //--------------------------------------------------------------------
        const myMessagesChartRef = refMessages.current.getContext("2d")

        if (typeof myMessagesChart !== "undefined")
            myMessagesChart.destroy();

        myMessagesChart = new Chart(myMessagesChartRef, {
            type: "line",
            data: {
                labels: labels,

                datasets: [
                    {
                        label: intl.formatMessage({ id: 'events.messages', defaultMessage: 'Messages' }),
                        data: dataMessages,
                        fill: false,
                        lineTension: lineTension,
                        pointRadius: 3,

                        borderWidth: 1,
                        borderColor: "saddlebrown",
                        pointBorderColor: "saddlebrown",
                        backgroundColor: 'saddlebrown',
                    }
                ]
            },

            options: {
                plugins: {
                    legend: {
                        // // position: "bottom",

                        labels: {
                            usePointStyle: true,
                            pointStyle: 'circle',
                            boxWidth: 3,
                            color: "saddlebrown",
                            font: {
                                // weight: 'bold',
                                size: 14
                            }
                        },
                    },
                    tooltip: {
                        backgroundColor: theme.palette.primary.light,
                        bodyColor: 'rgb(0, 0, 0)',
                        titleColor: 'rgb(0, 0, 0)',
                        usePointStyle: true,
                        boxWidth: 7,
                        callbacks: {
                            labelPointStyle: function (context) {
                                return {
                                    pointStyle: 'circle',
                                    rotation: 0
                                };
                            },

                            label: function (context) {
                                var label = '  '
                                if (context.parsed.y !== null) {
                                    label += new Intl.NumberFormat('en-US').format(context.parsed.y);
                                }
                                return label;
                            }
                        }
                    }
                },

                responsive: true,
                aspectRatio: 1.5,
                maintainAspectRatio: false,

                layout: {
                    padding: {
                        top: 5,
                        left: 15,
                        right: 15,
                        bottom: 15
                    }
                },

                // https://www.chartjs.org/docs/latest/axes/cartesian/linear.html#step-size
                scales: {
                    y: {
                        autoSkip: true,
                        beginAtZero: true,
                        // stepSize: 1,
                        stepValue: 1,
                        fontSize: 11,
                        fontColor: theme.palette.primary.dark,
                        min: 0,
                        suggestedMax: 10,
                        precision: 0,
                    },
                    x: {
                        ticks: {
                            // autoSkip: false,
                            // fontColor: theme.palette.primary.dark,
                            // bold: true,
                            color: 'rgb(0, 0, 0)',
                        },
                    },
                },

                tooltips: {
                    callbacks: {
                        title: function (tooltipItem, data) {
                            return data['labels'][tooltipItem[0]['index']]
                        },
                        label: function (tooltipItem, data) {
                            var label = ' '
                            label += data['datasets'][0]['data'][tooltipItem['index']]
                            return label
                        },
                    }
                }
            }
        })

    }, [intl, profile, lineTension,
        theme.palette.primary.dark, theme.palette.primary.light, theme.palette.primary.main, theme.palette.secondary.light, theme.palette.secondary.main
    ])

    //-------------------------------------------
    // TODO: DSW
    // handleLikeEvent (Debounced)
    //-------------------------------------------

    // function handleViewGroupEvent(e, ge) {
    //     e.stopPropagation()

    //     // dispatch(groupEventDelete(ge.eventId))
    // }

    // function handleLikeEvent(e, ge) {
    //     e.stopPropagation()
    //     // bouncedLikeEvent(obj)
    //     dispatch(groupEventLike(ge.eventId))
    // }

    // function debouncedLikeEvent(ge) {
    //     // if (!isEmpty(props.memberId) && props.memberId !== userId)
    //     //     dispatch(likeEvent(props.memberId, obj.widgetId))
    //     // else {
    //     //     props.viewLikes(obj.widgetId)
    //     // }
    // }

    // function handleShareEvent(e, ge) {
    //     e.stopPropagation()
    //     dispatch(groupEventShare(ge.eventId))
    // }

    // function handleRemoveEvent(e, ge) {
    //     e.stopPropagation()
    //     dispatch(groupEventDelete(ge.eventId))
    // }


    //-------------------------------------------
    // doesUserLike
    //-------------------------------------------
    // @param obj widget
    // const doesUserLike = (obj) => {
    //     if (!isEmpty(userId) && !isEmpty(props.memberId)) {
    //         // User's Wall
    //         if (props.memberId === userId) {
    //             if (obj.likes.length)
    //                 return true

    //             return false
    //         }

    //         // Member's wall
    //         if (obj.likes.find(function (like) {
    //             return like.ownerId === userId
    //         })) {
    //             return true
    //         }

    //         return false
    //     }
    // }


    return (
        <Div100vh>
            <Typography style={PageHeading} variant="h5">{<FormattedMessage id="navbar.dashboard" defaultMessage="Dashboard" />}</Typography>
            {/* <Typography style={PageHeading} variant="h5">{<FormattedMessage id="system.stats" defaultMessage="Stats" />}</Typography> */}

            <LayoutWithHeader>
                <Grid container direction="column" alignItems="flex-start">
                    <Grid container direction="row">
                        <Grid item className={classes.chart} xs={12} sm={12} md={6} lg={6}>
                            {profile && <Typography component="div" >
                                <canvas id="myLikes" ref={refLikes} />
                            </Typography>}
                        </Grid>

                        <Grid item className={classes.chart} xs={12} sm={12} md={6} lg={6}>
                            {profile && <Typography component="div" >
                                <canvas id="myFavorites" ref={refFavorites} />
                            </Typography>}
                        </Grid>

                        <Grid item className={classes.chart} xs={12} sm={12} md={6} lg={6}>
                            {profile && <Typography component="div" >
                                <canvas id="myMessages" ref={refMessages} />
                            </Typography>}
                        </Grid>

                        <Grid item className={classes.chart} xs={12} sm={12} md={6} lg={6}>
                            {profile && <Typography component="div" >
                                <canvas id="myViews" ref={refViews} />
                            </Typography>}
                        </Grid>

                    </Grid>

                    {/* className={classes.adGrid} */}
                    <Grid item id='AdBlock' className={classes.adBlock} xs={12} sm={12} md={6} lg={6}>

                        {/* <AdContainer variant="rect" borderRadius={4} height={250} width={300} margin={'20px 0 20px 30px'} /> */}


                        {/* <MediaQueryDesktop>
                            <Typography className={classes.AdMobile300x250} variant="subtitle2">
                                <div style={{ position: 'absolute', top: '45%', left: '30%' }}>
                                    Desktop Ad 300x250
                                </div>
                            </Typography>
                        </MediaQueryDesktop>

                        <MediaQueryTablet>
                            <Typography className={classes.AdMobile300x250} variant="subtitle2">
                                <div style={{ position: 'absolute', top: '45%', left: '30%' }}>
                                    Desktop Ad 300x250
                                </div>
                            </Typography>
                        </MediaQueryTablet>

                        <MediaQueryPhoneLandscape>
                            <Typography className={classes.AdMobile300x250} variant="subtitle2">
                                <div style={{ position: 'absolute', top: '45%', left: '30%' }}>
                                    Desktop Ad 300x250
                                </div>
                            </Typography>
                        </MediaQueryPhoneLandscape> */}

                        {/* <MediaQueryPhonePortrait>
                            <Typography className={classes.AdMobile300x100} variant="subtitle2">AdMobile300x100</Typography>
                        </MediaQueryPhonePortrait> */}
                    </Grid>
                </Grid>
            </LayoutWithHeader>
        </Div100vh>
    )
}

