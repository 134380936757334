// Navbar.js (original)
// http://ramblings.mcpher.com/Home/excelquirks/reactredux/appnavdrawers
// https://stackoverflow.com/questions/49615742/how-to-properly-layout-main-contents-with-material-ui-next-mini-variant-drawer
// https://stackoverflow.com/questions/51046418/material-ui-drawer-overflow-causing-scrollbar-on-body
// https://marmelab.com/react-admin/Authentication.html

// https://codesandbox.io/s/2393m2k5rj
// https://stackoverflow.com/questions/47663765/typeerror-cannot-read-property-root-of-undefined
// https://github.com/wschenk/material-ui-demo/tree/master/src
// https://stackoverflow.com/questions/38429024/having-trouble-using-appbar-drawer-material-ui-reactjs


import React, { useEffect, useRef, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useTheme } from '@mui/material/styles'

import { Link, useNavigate } from "react-router-dom"
import { makeStyles } from '@mui/styles'
import isEmpty from '../validation/is-empty'

// import { GeneralText } from "../styles/ftfStyles"
import classNames from 'classnames'

import { JellyAnimation } from '../helpers/utilities'

// Badge
import { Button, Divider, Drawer, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip, Typography, Zoom } from '@mui/material'

import Avatar from './Avatar'
const AvatarEditor = React.lazy(() => import('./AvatarEditor'))

// Support
import SendSupportMessage from './SendSupportMessage'
import Languages from './Languages'

// Modal Policies
import CodeOfConduct from '../policies/CodeOfConduct'
import CookiePolicy from '../policies/CookiePolicy'
import PrivacyPolicy from '../policies/PrivacyPolicy'
import TermsOfUseAgreement from '../policies/TermsOfUseAgreement'
import ShowDevices from '../policies/ShowDevices'

import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone'
import ChatTwoToneIcon from '@mui/icons-material/ChatTwoTone'
import ShowChartTwoToneIcon from '@mui/icons-material/ShowChartTwoTone'

import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone'
import LockTwoToneIcon from '@mui/icons-material/LockTwoTone'

import FavoriteTwoToneIcon from '@mui/icons-material/FavoriteTwoTone'
import GroupsTwoToneIcon from '@mui/icons-material/GroupsTwoTone'
import HelpTwoToneIcon from '@mui/icons-material/HelpTwoTone'
import PhotoTwoToneIcon from '@mui/icons-material/PhotoTwoTone'
import LanguageTwoToneIcon from '@mui/icons-material/LanguageTwoTone'
import PeopleOutlineTwoToneIcon from '@mui/icons-material/PeopleOutlineTwoTone'
import PersonTwoToneIcon from '@mui/icons-material/PersonTwoTone'
import SupportTwoToneIcon from '@mui/icons-material/SupportTwoTone'

// import PhotoCameraOutlined from '@mui/icons-material/PhotoCameraOutlined'
import PhotoCameraTwoToneIcon from '@mui/icons-material/PhotoCameraTwoTone'
import CancelPresentationTwoToneIcon from '@mui/icons-material/CancelPresentationTwoTone'
import SearchTwoToneIcon from '@mui/icons-material/SearchTwoTone'

import { StyledBadge } from "../styles/ftfStyles"

import { FormattedMessage } from 'react-intl'

import { getSupportMember } from '../actions/member.actions'

import { useSpring, animated } from "react-spring"

const useStyles = makeStyles(theme => ({
    buttonPolicies: {
        justifyContent: 'start',
        padding: '2px 2px',
    },

    camera: {
        position: 'absolute',
        left: -5,
        top: -5,
    },

    policyText: {
        color: theme.palette.primary.dark,
        fontSize: '.7rem',
        lineHeight: 'unset',
    },

    welcome: {
        color: theme.palette.primary.dark,
    },

    // Styles for the `Drawer` component
    drawer: {
        // display: "flex",
        width: theme.drawerWidth,
    },

    // Styles for the `Paper` component rendered by `Drawer`.
    drawerPaper: {
        width: 153, // Fixed width
        marginTop: theme.toolbarHeight,
        overflow: 'hidden',
        zIndex: 1,

        // Wide Screen
        height: `calc(100vh - ${theme.toolbarHeight})`,

        // Phone Landscape
        [theme.breakpoints.down('md')]: {
            height: `calc(100vh - ${theme.toolbarHeight})`,
        },

        // Phone Portrait
        [theme.breakpoints.down('sm')]: {
            height: `calc(100vh - ${theme.toolbarHeight})`,
        },
    },

    //-----------------------  
    // List
    //-----------------------
    listItem: {
        padding: '0 0 0 4px',
    },

    listItemIcon: {
        color: theme.palette.primary.dark,
        // flexshrink: 0,
        margin: 0,
        minWidth: 24,
    },

    //-----------------------  
    // Member Card (Top)
    //-----------------------
    memberAvatar: {
        alignItems: 'center',
        // margin: 0,
        // padding: 0,
        display: 'flex',
        flexDirection: 'column',
    },

    memberCard: {
        margin: 0,
        backgroundColor: theme.palette.primary.light,
        minWidth: 152,
        // boxShadow: '0 4px 8px -5px rgba(0, 0, 0, 1)',
    },

    memberName: {
        padding: 0,
        textAlign: "center",
        width: '100%',
        // fontWeight: "bold",
        fontSize: '.8rem',
        color: theme.palette.primary.dark,
    },

    // TouchRippleProps={{ classes: rippleClasses }}
    rippleVisible: {
        color: theme.palette.error.dark,
        opacity: 0.5,
        animation: `$enter 550ms ${theme.transitions.easing.easeInOut}`
    },
    "@keyframes enter": {
        "0%": {
            transform: "scale(0)",
            opacity: 0.1
        },
        "100%": {
            transform: "scale(1)",
            opacity: 0.5
        }
    }
}))


export default function Navbar(props) {
    const classes = useStyles(props)
    const navigate = useNavigate()
    const theme = useTheme()

    const isAuthenticated = useSelector(state => state.auth.isAuthenticated)
    const profile = useSelector(state => state.profile.profile)
    const supportMember = useSelector(state => state.member.member)
    const isDrawerOpen = useSelector(state => state.app.isDrawerOpen)
    // const isMobile = (width <= 500)

    // Only after useSelector(s) for now... - DSW
    const dispatch = useDispatch()

    const [selectedIndex, setSelectedIndex] = useState(0)

    const [isLocked, setIsLocked] = useState(false)
    const [showSendMessage, setShowSendMessage] = useState(false)
    const [showLanguages, setShowLanguages] = useState(false)
    const [showAvatarEditor, setShowAvatarEditor] = useState(false)
    const [showCodeOfConduct, setShowCodeOfConduct] = useState(false)
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false)
    const [showCookiePolicy, setShowCookiePolicy] = useState(false)
    const [showTermsOfUse, setShowTermsOfUse] = useState(false)

    const [showDevices, setShowDevices] = useState(false)

    const refValue = useRef(isLocked)   // Stale value in useEffect function fix


    const animationDrawer = useSpring({
        config: { duration: 250 },
        to: {
            opacity: isDrawerOpen ? 1 : 0,
            // If "vertical scrolling" is possible...
            // marginLeft: !isDrawerOpen ? 0 : (window.innerHeight > 685 ? theme.drawerWidth : theme.drawerWidth + 15) 
        }
    })


    // The 'index' field is the physical position to highlight in the menu
    const ftfRoutes = React.useMemo(() =>
        [
            { pathname: '/dashboard', index: 0 },
            { pathname: '/chats', index: 1 },
            { pathname: '/friends', index: 2 },

            { pathname: '/groups', index: 3 },
            { pathname: '/group', index: 31 },

            // { pathname: '/news', index: 4 },

            { pathname: '/groupevents', index: 5 },
            { pathname: '/search', index: 6 },
            { pathname: '/viewedme', index: 7 },

            { pathname: '/wall', index: 8 },
            { pathname: '/whoisonline', index: 9 },

            // { pathname: '/widgets', index: 10 },

            // Note: There are profile views for '/friends' 
            //       and profile views for non-friends
            //       So '99' un-highlights NavBar selection
            { pathname: '/memberprofile', index: 99 },

            { pathname: '/profile', index: 20 },

            { pathname: '/help', index: 21 },
        ], [])


    //-------------------------------------------
    // Listen to history - Menu Highlighting and Navi
    // Auto-Close NavBar if "not" locked and width is narrow
    //-------------------------------------------
    // Google Analytics
    // https://developers.google.com/analytics/devguides/collection/gtagjs/pages
    // 
    // Events
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events

    // useLocation 
    // https://reactrouter.com/docs/en/v6/api#uselocation
    useEffect(() => {
        // On mount...
        if (isEmpty(location))
            return

        // console.log('Navbar route changed: ' + location.pathname)
        window.gtag('set', {
            'page_title': 'FindTrueFriends - ' + location.pathname,
            // 'currency': 'USD'
        })

        window.gtag("config", "G-CC950TJG3M", {
            page_title: location.pathname,
            page_path: location.pathname,
        })


        var route = ftfRoutes.filter(d => d.pathname === location.pathname)
        if (route.length) {
            handleListItemClick(null, route[0].index)

            // Auto-Close NavBar if "not" locked and width is narrow
            // if (!refValue.current && window.innerWidth <= 500) {
            //     console.log(">>>---> Navbar.closeNavBar()")
            //     props.closeNavBar()
            // }

            //----------------------------------------------------
            // TODO: Auto-close Drawer on Mobile...  -DSW
            //----------------------------------------------------
            // if (isDrawerOpen && window.innerWidth <= 500) {
            //     console.log(">>>---> Navbar.closeNavBar()")
            //     props.closeNavBar()
            // }
        }

        // https://github.com/remix-run/react-router/issues/8568
        // if (isEmpty(unlisten.current))
        //     unlisten.current = navigate.listen(location => changeRoute(location))

        // return () => {
        //     unlisten()
        // }

    }, [ftfRoutes, navigate, isDrawerOpen, props])


    //-------------------------------------------
    // Stale useState value in useEffect with function 
    // https://stackoverflow.com/questions/55154186/react-hooks-usestateuseeffectevent-gives-stale-state
    //-------------------------------------------
    useEffect(() => {
        refValue.current = isLocked
    }, [isLocked])


    //-------------------------------------------
    // Drawer Lock
    //-------------------------------------------
    function handleLock(e) {
        e.preventDefault()
        setIsLocked(!isLocked)
        props.isNavBarLocked(!isLocked)
    }

    //-------------------------------------------
    // openAvatarEditor
    //-------------------------------------------
    // @param {Event} event
    function openAvatarEditor(event) {
        event.preventDefault()
        setShowAvatarEditor(!showAvatarEditor)
    }

    //-------------------------------------------
    // handleAvatarUpdate
    //-------------------------------------------
    function handleAvatarUpdate(e) {
        openAvatarEditor(e)
    }

    //-------------------------------------------
    // handleListItemClick
    //-------------------------------------------
    // @param {Event} event
    // @param {number} index
    function handleListItemClick(e, index) {
        setSelectedIndex(index)
        // console.log('selectedIndex: ' + selectedIndex)
    }

    //-------------------------------------------
    // Devices
    //-------------------------------------------
    function toggleShowDevices() {
        setShowDevices(!showDevices)
    }

    //-------------------------------------------
    // Policies
    //-------------------------------------------
    function toggleCodeOfConduct() {
        setShowCodeOfConduct(!showCodeOfConduct)
    }

    function toggleCookiePolicy() {
        setShowCookiePolicy(!showCookiePolicy)
    }

    function togglePrivacyPolicy() {
        setShowPrivacyPolicy(!showPrivacyPolicy)
    }

    function toggleTermsOfUse() {
        setShowTermsOfUse(!showTermsOfUse)
    }

    //-------------------------------------------
    // Contact Support
    //-------------------------------------------
    function handleContactSupport(e) {
        dispatch(getSupportMember())
        setShowSendMessage(true)
    }

    function handleCloseSendMessage(e, reason) {
        if (reason === 'backdropClick')
            return

        setShowSendMessage(false)
    }

    //-------------------------------------------
    // Language
    //-------------------------------------------
    function handleLanguagesClick(e, reason) {
        if (reason === 'backdropClick')
            return

        setShowLanguages(!showLanguages)
    }

    // const toggleDrawer = (anchor, open) => (event) => {
    //     if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
    //       return
    //     }

    //     setState({ ...state, [anchor]: open });
    //   };

    return (
        <div>
            {/* TODO: Make a MsgType Enum - DSW */}
            <SendSupportMessage isSupportMsg chat={supportMember} show={showSendMessage} fullscreen={false} onClose={handleCloseSendMessage} />

            <Languages show={showLanguages} fullscreen={false} onClose={handleLanguagesClick} />

            <CodeOfConduct show={showCodeOfConduct} fullscreen={false} onClose={toggleCodeOfConduct} />
            <CookiePolicy show={showCookiePolicy} fullscreen={false} onClose={toggleCookiePolicy} />
            <PrivacyPolicy show={showPrivacyPolicy} fullscreen={false} onClose={togglePrivacyPolicy} />
            <TermsOfUseAgreement show={showTermsOfUse} fullscreen={false} onClose={toggleTermsOfUse} />

            {/* Debugging Devices - REMOVE - DSW */}
            <ShowDevices show={showDevices} fullscreen={false} onClose={toggleShowDevices} />

            <animated.div id='mainContent' style={animationDrawer} >
                <Drawer
                    style={{
                        width: theme.drawerWidth,
                        flexShrink: 0,
                    }}

                    classes={{
                        paper: classNames(classes.drawerPaper),  // , !open && classes.drawerPaperClose
                    }}

                    anchor="left"
                    open={isDrawerOpen}

                    direction='right'
                    elevation={128}
                    hideBackdrop

                    // variant="temporary"
                    variant="persistent"

                    transitionDuration={{ enter: 250, exit: 100 }}

                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                >

                    {!isAuthenticated && <div className={classes.memberCard}>
                        <div style={{ margin: 4 }}>
                            <Typography className={classes.welcome} variant="h6" align="center">
                                <FormattedMessage id="system.msg.welcome" defaultMessage="Welcome..." />
                            </Typography>

                            <Typography className={classes.welcome} variant="subtitle2" align="center">
                                <FormattedMessage id="system.msg.pleaseLogin" defaultMessage="Please log in or sign up to access our services" />
                            </Typography>

                            {/* {!isAuthenticated && (
                        <Button id='login' className={classes.toolbarButtonStyle}>
                        <FormattedMessage id="system.login" defaultMessage="Login" />
                        </Button>
                    )} */}
                        </div>
                    </div>
                    }

                    {isAuthenticated && <div className={classes.memberCard}>

                        {showAvatarEditor && <div>
                            <React.Suspense fallback={<div />}>
                                <AvatarEditor AvatarUpdate={handleAvatarUpdate} />
                            </React.Suspense>
                        </div>}


                        {!showAvatarEditor && <div>
                            <div className={classes.memberAvatar} >
                                <Avatar big gender={profile.gender} avatarUrl={profile.avatarUrl} delay={250} alt={profile.firstname}>...</Avatar>
                            </div>

                            <div className={classes.memberName}>
                                {isAuthenticated && (
                                    <Button className={classes.memberName} size="small" color="primary" component={Link} to="/profile" onClick={event => handleListItemClick(event, 20)}>
                                        <Typography className={classes.memberName} variant="subtitle1" align="center">{profile.firstname}</Typography>
                                    </Button>
                                )}

                                {!isAuthenticated && (
                                    <Typography className={classes.memberName} variant="subtitle1" align="center">
                                        <FormattedMessage id="system.guest" defaultMessage="Guest" />
                                    </Typography>
                                )}
                            </div>
                        </div>}

                        {/* Avatar Editing */}
                        {isAuthenticated && !showAvatarEditor && <div className={classes.camera}>
                            <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom"
                                title={<FormattedMessage id="navbar.updatePhoto" defaultMessage="Update Photo" />}
                            >
                                <IconButton color="primary" aria-label="Update Photo" onClick={(e) => openAvatarEditor(e)}>
                                    <JellyAnimation>
                                        <PhotoCameraTwoToneIcon />
                                    </JellyAnimation>
                                </IconButton>
                            </Tooltip>
                        </div>}

                        {/* Avatar - Cancel Editing */}
                        {isAuthenticated && showAvatarEditor && <div className={classes.camera}>
                            <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom"
                                title={<FormattedMessage id="system.cancel" defaultMessage="Cancel" />}
                            >
                                <IconButton color="primary" aria-label="Cancel" onClick={(e) => openAvatarEditor(e)}>
                                    <CancelPresentationTwoToneIcon />
                                </IconButton>
                            </Tooltip>
                        </div>}
                    </div>}

                    {/* focusRippleColor="darkRed" touchRippleColor="red" > */}

                    {isAuthenticated && <List dense>
                        <ListItem button selected={selectedIndex === 2}
                            component={Link} to="/friends"
                            classes={{ root: classes.listItem }}
                            disabled={!isAuthenticated}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <FavoriteTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.friends" defaultMessage="Friends" />}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    // variant: 'subtitle2',
                                }}
                            />
                        </ListItem>

                        <ListItem button color="primary" selected={selectedIndex === 1}
                            component={Link} to="/chats"
                            classes={{ root: classes.listItem }}
                            disabled={!isAuthenticated}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <StyledBadge offset={2} color="secondary" className={classes.icon} badgeContent={profile.hasMessages} max={999} >
                                    <ChatTwoToneIcon fontSize="small" />
                                </StyledBadge>
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.chats" defaultMessage="Chats" />}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    // variant: 'subtitle2',
                                }}
                            />
                        </ListItem>

                        <ListItem button color="primary" selected={selectedIndex === 0}
                            component={Link} to="/dashboard"
                            classes={{ root: classes.listItem }}
                            disabled={!isAuthenticated}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <ShowChartTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.dashboard" defaultMessage="Dashboard" />}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    // variant: 'subtitle2',
                                }}
                            />
                        </ListItem>

                        <ListItem button selected={selectedIndex === 3}
                            component={Link} to="/groups"
                            classes={{ root: classes.listItem }}
                            disabled={!isAuthenticated}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <StyledBadge offset={2} color="secondary" className={classes.icon} badgeContent={profile.hasGroupPosts} max={999} >
                                    <GroupsTwoToneIcon fontSize="small" />
                                </StyledBadge>
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.groups" defaultMessage="Groups" />}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    // variant: 'subtitle2',
                                }}
                            />
                        </ListItem>

                        {/* <ListItem button selected={selectedIndex === 5}
                            component={Link} to="/groupevents" classes={{ root: classes.listItem }} disabled={!isAuthenticated}>
                            <ListItemIcon className={classes.listItemIcon}>
                                <Notifications fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.groupevents" defaultMessage="Events" />} />
                        </ListItem> */}

                        {/* <ListItem button selected={selectedIndex === 4}
                            component={Link} to="/news" classes={{ root: classes.listItem }} disabled={!isAuthenticated}>
                            <ListItemIcon className={classes.listItemIcon}>
                            <FavoriteTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.news" defaultMessage="News" />} />
                        </ListItem> */}

                        <ListItem button selected={selectedIndex === 6}
                            component={Link} to="/search"
                            classes={{ root: classes.listItem }}
                            disabled={!isAuthenticated}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <SearchTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.search" defaultMessage="Search" />}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    // variant: 'subtitle2',
                                }}
                            />
                        </ListItem>

                        <ListItem button selected={selectedIndex === 7}
                            component={Link} to="/viewedme"
                            classes={{ root: classes.listItem }}
                            disabled={!isAuthenticated}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <StyledBadge offset={2} color="secondary" className={classes.icon} badgeContent={profile.hasViews} max={999} >
                                    <PersonTwoToneIcon fontSize="small" />
                                </StyledBadge>
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.viewedme" defaultMessage="Views" />}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    // variant: 'subtitle2',
                                }}
                            />
                        </ListItem>

                        <ListItem button selected={selectedIndex === 8}
                            component={Link} to="/wall"
                            classes={{ root: classes.listItem }}
                            disabled={!isAuthenticated}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <PhotoTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.wall" defaultMessage="Photos" />}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    // variant: 'subtitle2',
                                }}
                            />
                        </ListItem>

                        <ListItem button selected={selectedIndex === 9}
                            component={Link} to="/whoisonline"
                            classes={{ root: classes.listItem }}
                            disabled={!isAuthenticated}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <PeopleOutlineTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.whoisonline" defaultMessage="Who's Online" />}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    // variant: 'subtitle2',
                                }}
                            />
                        </ListItem>

                        {/* ---------------------------
                            TODO: Widgets - DSW
                        --------------------------- */}
                        {/* <ListItem button selected={selectedIndex === 90} 
                        component={Link} to="/widgets" classes={{ root: classes.listItem }} disabled={!isAuthenticated}>
                            <ListItemIcon className={classes.listItemIcon}>
                            <Toys fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary="Widgets" />
                        </ListItem> */}

                        <Divider />

                        <ListItem button selected={selectedIndex === 20}
                            component={Link} to="/profile"
                            classes={{ root: classes.listItem }}
                            disabled={!isAuthenticated}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <AccountCircleTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.profile" defaultMessage="Profile" />}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    // variant: 'subtitle2',
                                }}
                            />
                        </ListItem>

                        <ListItem button selected={selectedIndex === 21}
                            component={Link} to="/help"
                            classes={{ root: classes.listItem }}
                            disabled={!isAuthenticated}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <HelpTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.help" defaultMessage="Help" />}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    // variant: 'subtitle2',
                                }}
                            />
                        </ListItem>

                        <ListItem button selected={selectedIndex === 22}
                            onClick={e => handleContactSupport(e)}
                            classes={{ root: classes.listItem }}
                            disabled={!isAuthenticated}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <SupportTwoToneIcon fontSize="small" />
                            </ListItemIcon>
                            <ListItemText primary={<FormattedMessage id="navbar.support" defaultMessage="Support" />}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    // variant: 'subtitle2',
                                }}
                            />
                        </ListItem>
                    </List>}

                    <List dense>
                        <Divider />

                        <ListItem button selected={selectedIndex === 30}
                            onClick={(e) => handleLanguagesClick(e)}
                            classes={{ root: classes.listItem }}
                        >
                            <ListItemIcon className={classes.listItemIcon}>
                                <LanguageTwoToneIcon fontSize="small" />
                            </ListItemIcon>

                            <ListItemText primary={<FormattedMessage id="navbar.language" defaultMessage="Language" />}
                                primaryTypographyProps={{
                                    color: 'primary',
                                    // variant: 'subtitle2',
                                }}
                            />
                        </ListItem>
                    </List>

                    <Grid container direction='column' >
                        <Button id="codeofconduct" color="primary" className={classes.buttonPolicies} onClick={toggleCodeOfConduct}>
                            <Typography variant="caption" className={classes.policyText}>
                                <FormattedMessage id="navbar.codeofconduct" defaultMessage="Code of Conduct" />
                            </Typography>
                        </Button>

                        <Button id="cookiepolicy" color="primary" className={classes.buttonPolicies} onClick={toggleCookiePolicy}>
                            <Typography variant="caption" className={classes.policyText}>
                                <FormattedMessage id="navbar.cookiepolicy" defaultMessage="Cookie Policy" />
                            </Typography>
                        </Button>

                        <Button id="privacypolicy" color="primary" className={classes.buttonPolicies} onClick={togglePrivacyPolicy}>
                            <Typography variant="caption" className={classes.policyText}>
                                <FormattedMessage id="navbar.privacypolicy" defaultMessage="Privacy Policy" />
                            </Typography>
                        </Button>

                        <Button id="termsofuse" color="primary" className={classes.buttonPolicies} onClick={toggleTermsOfUse}>
                            <Typography variant="caption" className={classes.policyText}>
                                <FormattedMessage id="navbar.termsofuse" defaultMessage="Terms of Use" />
                            </Typography>
                        </Button>

                        {/* <Button id="advertising" color="primary" className={classes.buttonPolicies} onClick={toggleAdvertising}>
                        <Typography variant="caption" className={classes.policyText}>
                            <FormattedMessage id="navbar.advertising" defaultMessage="Advertising" />
                        </Typography>
                    </Button> */}

                        <Grid container direction='row' >
                            <Tooltip arrow enterDelay={750} TransitionComponent={Zoom} placement="bottom" title={<FormattedMessage id="navbar.lockOpen" defaultMessage="Lock Open" />}>
                                <IconButton
                                    onClick={handleLock}
                                    aria-label="Add to Friends"
                                    color="primary"
                                    size="large"
                                >
                                    {!isLocked && <LockOpenTwoToneIcon className={classes.listItemIcon} />}
                                    {isLocked && <LockTwoToneIcon className={classes.listItemIcon} color="secondary" />}
                                </IconButton>
                            </Tooltip>
                        </Grid>

                        {/* Copyright */}
                        <Typography variant="caption" className={classes.policyText} style={{ margin: '0 4px' }}>
                            © Copyright 2019<br />
                            Bright Objects, Inc.
                        </Typography>
                    </Grid>
                </Drawer>
            </animated.div>
        </div>
    )
}



