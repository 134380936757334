// ad.Reducer.js

import moment from 'moment'

import { LOGOUT } from '../actions/auth.actions'

import { AD_BLOCK_REQUEST, AD_BLOCK_SUCCESS, AD_BLOCK_FAILED } from '../actions/ad.actions';
import { ADS_REQUEST, ADS_SUCCESS, ADS_FAILED } from '../actions/ad.actions';
import { AD_REQUEST, AD_SUCCESS, AD_FAILED } from '../actions/ad.actions';
// import { AD_CLICKS_REQUEST, AD_CLICKS_SUCCESS, AD_CLICKS_FAILED } from '../actions/ad.actions';


const initialState = {
    isLoading: false,

    adFooterUrl: null,
    adFooterHotlink: null,

    adChatUrl: null,
    adChatHotlink: null,
    
    adRectUrl: null,
    adRectHotlink: null,

    errors: {},
    receivedAt: moment().format('lll SSS'),
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOGOUT:
            return { ...initialState }


        case AD_REQUEST:
            return {
                ...state,
                isLoading: true,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case AD_SUCCESS:
            return {
                ...state,
                isLoading: false,

                adFooterUrl: action.ad.adFooterUrl || action.ad.adFooterUrl,
                adFooterHotlink: action.ad.adFooterHotlink,

                adChatUrl: action.ad.adChatUrl || action.ad.adChatUrl,
                adChatHotlink: action.ad.adChatHotlink,
                 
                adRectUrl: action.ad.adRectUrl || action.ad.adRectUrl,
                adRectHotlink: action.ad.adRectHotlink,

                receivedAt: moment().format('lll SSS'),
            }

        case AD_FAILED:
            return {
                // ...state,
                isLoading: false,

                adFooterUrl: null,
                adFooterHotlink: null,

                adChatUrl: null,
                adChatHotlink: null,
                
                adRectUrl: null,
                adRectHotlink: null,

                errors: action.errors,
                receivedAt: moment().format('lll SSS'),
            }


        // Get an array of Ads to play
        case ADS_REQUEST:
            return {
                ...state,
                isLoading: true,
                // adUrls: [],
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case ADS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                adUrl: action.adUrls || action.adUrls[0],
                adUrls: action.adUrls,
                receivedAt: moment().format('lll SSS'),
            }

        case ADS_FAILED:
            return {
                // ...state,
                isLoading: false,
                adUrl: null,
                adUrls: [],
                errors: action.errors,
                receivedAt: moment().format('lll SSS'),
            }


        // Block an ad...
        case AD_BLOCK_REQUEST:
            return {
                ...state,
                isLoading: true,
                vendorId: action.vendorId,
                errors: {},
                receivedAt: moment().format('lll SSS'),
            }

        case AD_BLOCK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                adUrl: null,
                results: action.results,
                receivedAt: moment().format('lll SSS'),
            }

        case AD_BLOCK_FAILED:
            return {
                // ...state,
                isLoading: false,
                adUrl: null,  // New ad??
                errors: action.errors,
                receivedAt: moment().format('lll SSS'),
            }


        // case AD_CLICKS_REQUEST:
        //     return {
        //         ...state,
        //         isLoading: true,
        //         photoLikes: [],
        //         errors: {},
        //         receivedAt: moment().format('lll SSS'),
        //     }

        // case AD_CLICKS_SUCCESS:
        //     return {
        //         ...state,
        //         isLoading: false,
        //         photoLikes: action.members,
        //         results: action.results,
        //         receivedAt: moment().format('lll SSS'),
        //     }

        // case AD_CLICKS_FAILED:
        //     return {
        //         // ...state,
        //         isLoading: false,
        //         photoLikes: [],
        //         errors: action.errors,
        //         receivedAt: moment().format('lll SSS'),
        //     }

        default:
            return state;
    }
}


