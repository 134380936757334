
// Query: Find visible female users online in your radius and age range (check blocked)

import React, { useEffect } from "react"

import { useSelector, useDispatch } from "react-redux"
import { FormattedMessage } from 'react-intl'
// import isEmpty from '../validation/is-empty'

// Mobile "real height"
// https://github.com/mvasin/react-div-100vh
import Div100vh from 'react-div-100vh'

import { LayoutWithHeader, PageHeading, PageSubheadingRight } from "../styles/ftfStyles"

import { Grid, Typography } from '@mui/material'

// import { getGender } from '../helpers/utilities'

import ReactVirtualizedTable from './ReactVirtualizedTable'
import { SortDirection } from 'react-virtualized'

import { whoIsOnline } from '../actions/whois.actions'


export default function WhoisOnlinePage(props) {

    // const intl = useIntl()

    const profile = useSelector(state => state.profile.profile)
    const membersOnline = useSelector(state => state.whois.members)

    // const [profileFilter, setProfileFilter] = useState('')

    const dispatch = useDispatch()

    //-------------------------------------------
    // Reload
    //-------------------------------------------
    useEffect(() => {
        dispatch(whoIsOnline())
    }, [dispatch, profile]);


    return (
        <Div100vh>
            {/* Header */}
            <Grid container justifyContent="space-between" direction="row" alignItems="flex-start">
                <Typography style={PageHeading} variant="h5">
                    {<FormattedMessage id="navbar.whoisonline" defaultMessage="Who's Online" />}
                </Typography>

                <Typography style={PageSubheadingRight}>                 
                    {/* ( {profileFilter}, {profile.targetAgeMin} - {profile.targetAgeMax} ) */}
                    <FormattedMessage id="system.age" defaultMessage="Age" />: {profile.targetAgeMin} - {profile.targetAgeMax} 
                </Typography>
            </Grid>

            <LayoutWithHeader>
                <ReactVirtualizedTable
                    title={<FormattedMessage id="navbar.whoisonline" defaultMessage="Who's Online" />}
                    defaultSortBy="firstname"
                    defaultSortDir={SortDirection.ASC}
                    members={membersOnline}
                />
            </LayoutWithHeader>
        </Div100vh>
    )
}


//   <div className="col-md-6 col-md-offset-3">
//       {/* <h3>Members Online:</h3> */}

//       {whois.isLoading && <em>Loading users...</em>}
//       {/* {whois.errors && <span className="text-danger">ERROR: {whois.errors}</span>} */}
//       {whois.members &&
//           <ul>
//               {whois.members.map((user, i) =>
//                   <li key={i}>
//                       {user.firstname}
//                       {
//                           // user.deleting ? <em> - Deleting...</em>
//                           // : user.deleteError ? <span className="text-danger"> - ERROR: {user.deleteError}</span>
//                           // : <span> - <a onClick={handleDeleteUser(user.id)}>Delete</a></span>
//                       }
//                   </li>
//               )}
//           </ul>
//       }
//   </div>


