
// import { CONNECTION_ERROR } from '../actions/error.actions';
import { USERS_GETALL_REQUEST, USERS_GETALL_SUCCESS, USERS_GETALL_FAILED } from '../actions/auth.actions';
// import { USERS_DELETE_REQUEST, USERS_DELETE_SUCCESS, USERS_DELETE_FAILED } from '../actions/auth.actions';
// import isEmpty from '../validation/is-empty';

export default function (state = {}, action) {

    switch (action.type) {

        case USERS_GETALL_REQUEST:
            return {
                loading: true
            };

        case USERS_GETALL_SUCCESS:
            return {
                items: action.usersOnline
            };

        case USERS_GETALL_FAILED:
            return {
                error: action.errors
            };



        // case USERS_DELETE_REQUEST:
        //     // add 'deleting:true' property to user being deleted
        //     return {
        //         ...state,
        //         items: state.items.map(user =>
        //             user.id === action.id
        //                 ? { ...user, deleting: true }
        //                 : user
        //         )
        //     };

        // case USERS_DELETE_SUCCESS:
        //     // remove deleted user from state
        //     return {
        //         items: state.items.filter(user => user.id !== action.id)
        //     };

        // case USERS_DELETE_FAILED:
        //     // remove 'deleting:true' property and add 'deleteError:[error]' property to user 
        //     return {
        //         ...state,
        //         items: state.items.map(user => {
        //             if (user.id === action.id) {
        //                 // make copy of user without 'deleting:true' property
        //                 const { deleting, ...userCopy } = user;

        //                 // return copy of user with 'deleteError:[error]' property
        //                 return { ...userCopy, deleteError: action.error };
        //             }

        //             return user;
        //         })
        //     };

        default:
            return state
    }
}