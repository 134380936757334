//-----------------------------------------------
// Other Cropping Tools - DSW
//-----------------------------------------------
// https://github.com/fengyuanchen/cropperjs
// http://foliotek.github.io/Croppie/#documentation
// https://ourcodeworld.com/articles/read/281/top-7-best-image-cropping-javascript-and-jquery-plugins


import React, { useCallback, useEffect, useRef, useState } from "react"

import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles'

import debounce from 'lodash.debounce'

import isEmpty from '../validation/is-empty'
// import FTF_Images from './FTF_Images';

import { useTheme } from '@mui/material/styles'

// import Avatar from '../_components/Avatar'
import { FormattedMessage } from 'react-intl'  // , FormattedDate, FormattedTime

import { SmallButton, DialogHeading, PageHeading } from "../styles/ftfStyles"

import { Box, Button, Dialog, DialogContent, DialogActions, Divider, Grid, Slide, Slider, Tooltip, TextField, Typography, Fade, Zoom } from '@mui/material'

import FolderOpenOutlinedIcon from '@mui/icons-material/FolderOpenOutlined';
import CancelIcon from '@mui/icons-material/Cancel'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
// import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
// import OpenWithIcon from '@mui/icons-material/OpenWith';
// import CloudUploadTwoTone from '@mui/icons-material/CloudUploadTwoTone'

import RotateRight from '@mui/icons-material/RotateRight'
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined'

import { runUploadPhoto } from '../actions/image.actions'

import BeatLoader from 'react-spinners/BeatLoader'

//-----------------------------------------------
// Image EXIF Orientation
// https://github.com/blueimp/JavaScript-Load-Image
import * as loadImage from 'blueimp-load-image'

import styled, { css } from 'styled-components'   // keyframes

import ReactAvatarEditor from 'react-avatar-editor'
import { useDropzone } from 'react-dropzone'


const cssBeatLoader = css`
    display: block
    margin: 0 auto
    border-color: blue
`

// top: 50%;
// left: 50%;
// transform: translate(-50%, -50%);
// background-image: url(${props => props.src});
// background-size: cover;
// background: rgba(0, 153, 255, 0.3);  
// background-position: center center;
// border: 4px solid white;

const StyledPhoto = styled.div`
    width: auto;
    height: auto;
        
    &:hover {
        box-shadow: 3px 4px 5px 0px rgba(0, 153, 255, 0.3);
    }

    box-shadow: 3px 4px 5px 0px rgba(0, 0, 0, 0.2);  
        
    transition: box-shadow 0.5s;
    will-change: transform;

    display: inline-block;
`

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})


const useStyles = makeStyles(theme => ({
    content: {
        width: '100%',
        // padding: 2,
        margin: 0,
        overflow: 'hidden',

        // Promote the list into his own layer on Chrome. 
        // This cost memory but helps keeping high FPS.
        transform: 'translateZ(0)'
    },


    dialogPaper: {
        display: 'flex',
        height: 535,
        width: 355,

        margin: 16,
        padding: 0,
        overflow: 'hidden',

        borderColor: theme.palette.primary.main,
        borderRadius: 5,
        border: '2px solid',
    },


    gifImage: {
        textAlign: 'center',
    },

    icon: {
        color: theme.palette.primary.dark,
        flexshrink: 0,
        margin: '6px 4px 0 0',
        float: 'left',
    },

    photoShadow: {
        borderRadius: 10,
    },

    toolbar: {
        ariaLabel: "Photo Toolbar",
        margin: '24px 12px 0 12px',
    },

    toolbarButton: {
        marginBottom: 20,
    },

}))


//-----------------------------------------------
// Attributes:
//      autoClose - true or false
//      id - {group._id}
//      onClose - function
//      showComment - true or false
//      title - text
//-----------------------------------------------
export default function UploadImage(props) {
    const classes = useStyles(props)
    const theme = useTheme()

    const fileUploader = useRef(null)
    const editorRef = useRef(null)

    // https://react-redux.js.org/api/hooks
    // https://www.pluralsight.com/guides/simplifying-redux-bindings-with-react-hooks
    // https://blog.isquaredsoftware.com/2019/07/blogged-answers-thoughts-on-hooks/
    // https://medium.com/better-programming/how-to-use-redux-with-react-hooks-5422a7ceae6e

    const isLoading = useSelector(state => state.image.isLoading)

    const dispatch = useDispatch()

    const [showGifWarning, setShowGifWarning] = useState(false)

    // Fixed for Pinterest Layout
    // const imageWidth = 244
    // const imageHeight = 366
    const imageHeight = 300
    const imageWidth = imageHeight * .66

    const [previewWidth, setPreviewWidth] = useState(imageWidth)
    const [previewHeight, setPreviewHeight] = useState(imageHeight)
    const [position, setPosition] = useState({ x: 0.5, y: 0.5 })
    const [scale, setScale] = useState(10)
    const [rotate, setRotate] = useState(0)
    const [aspect, setAspect] = useState('Portrait')

    // const [photo, setPhoto] = useState(null)
    const [imageDesc, setImageDesc] = useState("")
    const [imageSrc, setImageSrc] = useState(null)
    const [imageType, setImageType] = useState(null)


    //-------------------------------------------
    // onInputImageChanged
    //-------------------------------------------
    // @param {file}  Either e.target.files[0] or acceptedFiles[0]
    const onInputImageChanged = React.useCallback(
        file => {
            try {
                setImageType(file.type)

                // loadImage doesn't handle gifs!
                if (file.type === "image/gif") {

                    // Is Gif too large??
                    if (file.size > 3 * 1024 * 1024) {
                        // console.log("ImageChanged - Gif size is greater than 3mb: ")
                        setImageType(null)
                        setShowGifWarning(true)
                        return
                    }

                    var reader = new FileReader()

                    reader.addEventListener("load", function () {
                        // Later, when you want to insert an image...
                        setImageSrc(reader.result)
                    })

                    reader.readAsDataURL(file) // converts blob to base64 and calls onload
                }
                else {
                    if (file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/tiff") {
                        loadAndFixRotation(file).then((fixedimg) => {
                            setImageSrc(fixedimg)
                        })
                    }
                }
            }
            catch (ex) {
                console.log("UploadImage.ImageChanged Exception: " + ex)
                setImageType(null)
                return
            }
        }, []
    )


    const onDrop = useCallback(acceptedFiles => {
        onInputImageChanged(acceptedFiles[0])
    }, [onInputImageChanged])

    const { getRootProps, getInputProps } = useDropzone({ noClick: true, onDrop })


    // This is the solution for: React.useCallback(debounce(debouncedChange, 333), [])
    // https://kyleshevlin.com/debounce-and-throttle-callbacks-with-react-hooks
    const debouncedChange = React.useMemo(
        () =>
            debounce(val => {
                setImageDesc(val)
            }, 333),
        []
    )

    // Called by control...
    const handleImageDescChange = React.useCallback(
        e => {
            setImageDesc(e.target.value)
            debouncedChange(e.target.value)
        },
        [debouncedChange]
    )


    // Dropzone
    // const getColor = (props) => {
    //     if (props.isDragAccept) {
    //         return '#00e676';
    //     }
    //     if (props.isDragReject) {
    //         return '#ff1744';
    //     }
    //     if (props.isDragActive) {
    //         return '#2196f3';
    //     }
    //     return '#eeeeee';
    // }

    //-------------------------------------------
    // File Selection (input)
    //-------------------------------------------
    const onClickSelectImage = event => {
        // Invoke local file selection...
        event.preventDefault()
        fileUploader.current.click()
    }


    //-------------------------------------------
    // getClippedImage
    //-------------------------------------------
    async function getClippedImage(canvas) {
        // *Note: '.90' knocks 30% off '.95' knocks 60% off '1' 
        // Quality is barely discernable
        const blob = await new Promise(resolve => canvas.toBlob(resolve, imageType, .90))

        return await new Promise(function (resolve, reject) {
            let reader = new FileReader();
            reader.readAsDataURL(blob); // converts the blob to base64 and calls onload

            reader.onload = function () {
                resolve(reader.result) // data url
            }
        })
    }


    //-------------------------------------------
    // Quality
    //-------------------------------------------
    // http://jsfiddle.net/sp3c7jeq/
    // https://stackoverflow.com/questions/18922880/html5-canvas-resize-downscale-image-high-quality/19235791#19235791 
    // https://stackoverflow.com/questions/19262141/resize-image-with-javascript-canvas-smoothly
    // 
    //-------------------------------------------
    // https://developer.mozilla.org/en-US/docs/Web/API/CanvasRenderingContext2D/imageSmoothingQuality


    //-------------------------------------------
    // upload
    // Return an image or send it off to the server (or both)...
    // 
    // TODO: 
    // ⭐️ asciify-image - Convert images to ASCII art
    // ⭐️ gifwrap - A Jimp-compatible library for working with GIFs
    // http://blueimp.github.io/JavaScript-Canvas-to-Blob/
    // https://javascript.info/blob
    // https://stackoverflow.com/questions/18922880/html5-canvas-resize-downscale-image-high-quality
    // https://stackoverflow.com/questions/17861447/html5-canvas-drawimage-how-to-apply-antialiasing
    // http://nodeca.github.io/pica/demo/
    // https://myrtana.sk/articles/how-to-resize-images-with-pica
    //-------------------------------------------
    async function upload(e, props) {
        e.preventDefault()

        var post = {
            ownerId: props.id,   // GroupId, null
            idType: props.idType,

            comment: imageDesc,  // The caption of the image
            image: null,
            imageHeight: previewHeight,
            imageWidth: previewWidth,
            type: imageType,     // The type of the file, base64 or URL

            variant: props.variant,
        }

        if (imageType === "image/jpeg" || imageType === "image/png") {
            //-----------------------------------------
            // #1 - Slower (no await)
            // photo.image = editorRef.current.getImage().toDataURL(`image/jpeg`, .9);   // A binary file, base64 data, or a URL for an image. (up to 10MB)

            //-----------------------------------------
            // #2 - Faster and Smaller file
            post.image = await getClippedImage(editorRef.current.getImage())
            // photo.image = await getClippedImage(editorRef.current.getImageScaledToCanvas())

            //-----------------------------------------
            // #4
            // http://blueimp.github.io/JavaScript-Canvas-to-Blob/
        }
        else if (imageType === "image/gif") {
            post.image = imageSrc      // A binary file, base64 data, or a URL for an image. (up to 10MB)
        }

        // setPhoto(photo)

        // Handle UI when image is modified/changed
        if (props.onImageChange) {
            props.onImageChange(post)
        }
        else {
            dispatch(runUploadPhoto(post))     // Upload Group Post
        }

        //---------------------------------------
        // Re-initialize UploadImage to default values
        //---------------------------------------
        setAspect('Portrait')
        setImageDesc("")
        setImageSrc(null)
        setImageType(null)
        setRotate(0)
        setScale(10)
        setPosition({ x: 0.5, y: 0.5 })

        // Auto close
        if (props.autoClose)
            props.onClose()
    }


    //-------------------------------------------
    // onScale
    // Scaling is based on the "Sliders" value
    // Editor scale is: 0 - 1
    //-------------------------------------------
    // @param {Event} e
    // @param {Slider.value} newValue (0 - 100)
    function onScale(e, newValue) {
        setScale(newValue)

        // console.log("Slider scale: " + newValue)
        // console.log("Avatar scale: " + newValue * .1)
    }


    //-------------------------------------------
    // Aspect Ration Calc
    // 
    // Pinterest Layout rules
    // Note: Height doesn't matter 
    // Portrait   - width is same as the column width 
    // landscape  - width: portrait width 
    // Square     - width: portrait width 
    //-------------------------------------------
    // https://andrew.hedges.name/experiments/aspect_ratio/
    //-------------------------------------------
    // @param {Event} e
    function onAspect(e) {
        e.preventDefault()

        if (aspect === 'Square')
            setAspect('Portrait')
        else if (aspect === 'Portrait')
            setAspect('Landscape')
        else if (aspect === 'Landscape')
            setAspect('Square')
    }

    //-------------------------------------------
    // Photo width and height is based on rotate and aspect
    //-------------------------------------------
    useEffect(() => {
        // console.log("useEffect rotate: " + rotate)
        // console.log("useEffect aspect: " + aspect)

        if (aspect === 'Square') {
            setPreviewHeight(imageWidth)
            setPreviewWidth(imageWidth)
        }

        else if (aspect === 'Portrait') {
            // Normal
            if (rotate === 0 || rotate === 180) {
                setPreviewHeight(imageHeight)
                setPreviewWidth(imageWidth)
            }
            else {
                setPreviewHeight(imageWidth)
                setPreviewWidth(imageHeight)
            }
        }

        else if (aspect === 'Landscape') {
            // Normal
            if (rotate === 0 || rotate === 180) {
                setPreviewHeight(imageWidth * .66)
                setPreviewWidth(imageWidth)
            }
            else {
                setPreviewHeight(imageWidth)
                setPreviewWidth(imageWidth * .66)
            }
        }

    }, [aspect, rotate, imageWidth, imageHeight]);


    // function rotateLeft(e) {
    //     e.preventDefault()
    //     setRotate(rotate > -180 ? rotate - 90 : 0)
    // }

    function rotateRight(e) {
        e.preventDefault()
        setRotate(rotate < 270 ? rotate + 90 : 0)
    }

    function logCallback(e) {
        // eslint-disable-next-line
        // console.log('callback', e)
    }

    function handlePositionChange(position) {
        setPosition(position)
    }


    //-------------------------------------------
    // fixRotationOfFile
    //-------------------------------------------
    // Both loadImage and toDataURL don't do gifs...
    // @param {Blob} blob
    // 
    // https://github.com/blueimp/JavaScript-Load-Image
    // https://github.com/blueimp/JavaScript-Load-Image/commit/840c776b772b29da8cb048ba6fb3c35bf1db013c
    //
    // document.getElementById('file-input').onchange = async function () {
    //     let data = await loadImage(this.files[0], { maxWidth: 600 })
    //     document.body.appendChild(data.image)
    //   }
    //
    //
    // XMLHttpRequest2
    // https://www.html5rocks.com/en/tutorials/file/xhr2/

    function loadAndFixRotation(file) {

        return new Promise((resolve) => {
            // Doesn't do gifs (it just takes an image of it)
            loadImage(file, (can) => {

                // Note: Another way...
                // https://stackoverflow.com/questions/44567511/downloading-canvas-image-using-toblob
                // img.toBlob(function (blob) {
                //      var url = URL.createObjectURL(blob)
                //      ...do something
                //      URL.revokeObjectURL(url)
                // })

                // Doesn't do gifs...
                // img is a canvas (below)...
                resolve(can.toDataURL())
            },
                {   // *Note: Restricting image size to ~100kb...
                    maxWidth: 640,
                    maxHeight: 640,
                    orientation: true,
                    canvas: true,
                    // *Note: Causes large (>500kb) images to fail on iOS - DSW
                    // pixelRatio: window.devicePixelRatio, // should be set to window.devicePixelRatio 
                })
        })
    }


    // Gif Warning
    const onGifWarningClose = () => {
        setImageType(null)
        setShowGifWarning(false)
    }

    function onGifWarningDialog() {
        return (
            <Dialog open={showGifWarning} onClose={onGifWarningClose}>

                <Grid container style={{ display: 'flex', justifyContent: "center" }}>
                    <Typography style={PageHeading} variant="h5">
                        {<FormattedMessage id="image.largeGif" defaultMessage="Gif is too large" />}
                    </Typography>
                </Grid>

                <Divider />

                <DialogContent>
                    {<FormattedMessage id="image.largeGifSize" defaultMessage="Gif files must be less than 3mb" />}
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="primary" autoFocus
                        style={SmallButton} onClick={onGifWarningClose}
                        startIcon={<CancelIcon aria-label='Close' />}
                    >
                        <FormattedMessage id="system.close" defaultMessage="Close" />
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }


    return (
        <div id='UploadImage' className={classes.content}>

            <Dialog classes={{ paper: classes.dialogPaper }}
                // 
                fullScreen={props.fullscreen}
                open={props.show}
                onClose={props.onClose}
                TransitionComponent={Transition}
            >
                {/* Header */}
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 8 }}>
                    <div className={classes.icon}>
                        {props.icon}
                    </div>
                    {props.title && <Typography style={DialogHeading} variant="subtitle1">{props.title}</Typography>}
                </div>

                {/* <Divider style={{ margin: '0 8px' }} /> */}

                {onGifWarningDialog()}

                <DialogContent style={{ display: 'flex', alignItems: 'flex-start', flexDirection: 'column', padding: '0 8px' }}>


                    {/* <Typography style={PageHeading} variant="body2"> */}
                    {/* <img src={FTF_Images.flagBlue} className={classes.iconList} />
                        {<FormattedMessage id="image.instructFace" defaultMessage="Faces must be clear in your photos" />}
                         <br /> */}

                    {/* <img src={FTF_Images.flagBlue} className={classes.iconList} />
                        {<FormattedMessage id="image.instructSmile" defaultMessage="Smile and turn your shoulders slightly" />}
                        <br /> */}

                    {/* <img alt='' src={FTF_Images.flagYellow} className={classes.iconList} />
                        {<FormattedMessage id="image.instructBackground" defaultMessage="Consider your background" />}
                        <br />

                        <img alt='' src={FTF_Images.flagRed} className={classes.iconList} />
                        {<FormattedMessage id="image.instructNudity" defaultMessage="Uploading nudity is not allowed" />}
                    </Typography> */}

                    {/* Toolbar and Image */}
                    <div style={{ display: 'flex', width: '100%', textAlign: 'center' }}>

                        <div className={classes.toolbar}>
                            <Tooltip arrow enterDelay={500} TransitionComponent={Zoom} placement="left" title={<FormattedMessage id="image.selectPhoto" defaultMessage="Select Photo" />}>
                                <div>
                                    <Button className={classes.toolbarButton} color="primary" variant="contained" aria-label="Select Photo"
                                        onClick={(e) => onClickSelectImage(e, null)}
                                    >
                                        <FolderOpenOutlinedIcon />
                                    </Button>
                                </div>
                            </Tooltip>

                            <Tooltip arrow enterDelay={500} TransitionComponent={Zoom} placement="left" title={<FormattedMessage id="image.rotateRight" defaultMessage="Rotate Right" />}>
                                <div>
                                    <Button className={classes.toolbarButton} color="primary" variant="contained" aria-label="Rotate Right"
                                        disabled={imageType === null || imageType === "image/gif"}
                                        onClick={rotateRight}
                                    >
                                        <RotateRight disabled={!imageSrc} />
                                    </Button>
                                </div>
                            </Tooltip>

                            <Tooltip arrow enterDelay={500} TransitionComponent={Zoom} placement="left" title={<FormattedMessage id="image.aspectRatio" defaultMessage="Aspect Ratio" />}>
                                <div>
                                    <Button className={classes.toolbarButton} color="primary" variant="contained" aria-label="Aspect Ratio"
                                        disabled={imageType === null || imageType === "image/gif"}
                                        onClick={onAspect}
                                    >
                                        <AspectRatioOutlinedIcon disabled={!imageSrc} />
                                    </Button>
                                </div>
                            </Tooltip>


                            {/* Slider */}
                            <Tooltip arrow enterDelay={500} TransitionComponent={Fade} placement="left" title={<FormattedMessage id="image.scale" defaultMessage="Scale" />}>
                                <Slider value={scale}
                                    aria-label="Zoom"
                                    min={10} max={25}
                                    disabled={imageType === null || imageType === "image/gif"}
                                    onChange={onScale}
                                    orientation='vertical'
                                    size='small'
                                    sx={{ height: 80 }}
                                />
                            </Tooltip>
                        </div>


                        {/* Testing - DSW */}
                        {/* <StyledImage src={imageSrc} {...props} /> */}
                        {/* <div id='testExifImage' style={{ display: 'flex', justifyContent: "center" }}>
                                <img width="auto" height="auto" src={imageSrc} />
                            </div> */}

                        <div style={{ height: 300, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <Box>
                                <div {...getRootProps()}>
                                    <input {...getInputProps()} />
                                    {
                                        <StyledPhoto>

                                            {imageType === "image/gif" &&
                                                <Box className={classes.gifImage} height={previewHeight} width={previewWidth} style={{ display: 'table-cell', verticalAlign: 'middle' }}>
                                                    <img alt='' style={{ maxWidth: '100%' }} src={imageSrc} />
                                                </Box>
                                            }

                                            {imageType !== "image/gif" &&

                                                <ReactAvatarEditor id='ReactAvatarEditor' ref={editorRef}
                                                    // https://github.com/mosch/react-avatar-editor/issues/200

                                                    width={previewWidth}
                                                    height={previewHeight}

                                                    maxwidth={260}
                                                    maxheight={300}

                                                    position={position}

                                                    border={0}
                                                    borderRadius={0}

                                                    disableHiDPIScaling={false}

                                                    scale={(scale * .1)}
                                                    style={{ maxWidth: 260, maxHeight: 300, background: '#eee', display: 'flex', justifyContent: "center", width: 'auto', height: 'auto' }}

                                                    onPositionChange={handlePositionChange}
                                                    rotate={parseFloat(rotate)}

                                                    onLoadFailure={logCallback.bind(this, 'onLoadFailed')}
                                                    onLoadSuccess={logCallback.bind(this, 'onLoadSuccess')}
                                                    onImageReady={logCallback.bind(this, 'onImageReady')}
                                                    image={imageSrc}
                                                    className="editor-canvas"
                                                />}
                                        </StyledPhoto>
                                    }
                                </div>
                            </Box>
                        </div>
                    </div>

                    <input type="file" ref={fileUploader} accept="image/*" style={{ display: 'none' }} onClick={(event) => event.target.value = ''} onChange={(e) => onInputImageChanged(e.target.files[0])} />


                    {/* Caption / props.showComment */}
                    {props.showComment &&
                        <TextField id="desc" autoFocus multiline
                            style={{ width: '100%', minHeight: 24, margin: '12px 0px' }}
                            label={<FormattedMessage id="system.comment" defaultMessage="Comment" />}
                            name="desc"
                            value={imageDesc}
                            rows="5"
                            variant="outlined"
                            onChange={e => handleImageDescChange(e)}
                            // inputProps={{ maxLength: 200 }}
                            InputLabelProps={{ shrink: true }}
                            InputProps={{
                                style: { lineHeight: 1.5 },
                            }}
                        />}

                    <div id='spinner' style={{ boxSizing: "content-box", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                        <BeatLoader css={cssBeatLoader} sizeUnit={"px"} size={32}
                            color={theme.palette.primary.dark}
                            loading={isLoading}
                        />
                    </div>


                </DialogContent>

                <DialogActions>
                    <Button size="small"
                        variant="contained"
                        color="primary"
                        disabled={isLoading ||
                            (props.imageRequired && isEmpty(imageSrc)) ||
                            (!props.imageRequired && (isEmpty(imageSrc) && isEmpty(imageDesc)))
                        }
                        startIcon={<CloudUploadIcon />}
                        onClick={e => { upload(e, { ...props }) }}
                    >
                        <FormattedMessage id="system.save" defaultMessage="Save" />
                    </Button>

                    <Button size="small"
                        variant="contained"
                        color="primary"
                        autoFocus
                        onClick={props.onClose}
                        startIcon={<CancelIcon aria-label='Close' />}
                    >
                        <FormattedMessage id="system.close" defaultMessage="Close" />
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

    // helpers/image.js
    // async function convertBlobToBinaryString(blob) {
    //     return new Promise((resolve, reject) => {
    //         const reader = new FileReader();

    //         reader.onload = () => {
    //             resolve(reader.result);
    //         }

    //         reader.onabort = () => {
    //             reject(new Error('Reading blob aborted'));
    //         }

    //         reader.onerror = () => {
    //             reject(new Error('Error reading blob'));
    //         }

    //         reader.readAsBinaryString(blob);
    //     });
    // }


    // async function handleImage(file, body) {
    //     return resizeImage(file, body)
    //         .then(blob => convertBlobToBinaryString(blob))
    //         .then(imageString => {
    //             const imageBase64 = btoa(imageString);
    //             console.log('imageBase64: ' + imageBase64.length)
    //             return imageBase64
    //         })
    // }



