// SendMessage2

// Example
// https://phann123.medium.com/slatejs-reactjs-implement-full-feature-editor-8ed5c2784911
// https://gist.github.com/phanngoc/473229c74d0119704d9c603b1251782a

// Emojis
// https://github.com/missive/emoji-mart

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch } from "react-redux"
import makeStyles from '@mui/styles/makeStyles'

import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'

import Avatar from './Avatar'

import { SmallButton, PageHeading } from "../styles/ftfStyles"

import { Box, Button, Dialog, DialogContent, DialogActions, Divider, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material'

import CancelIcon from '@mui/icons-material/Cancel'
import FormatBoldIcon from '@mui/icons-material/FormatBold';
import FormatItalicIcon from '@mui/icons-material/FormatItalic';
import FormatUnderlinedIcon from '@mui/icons-material/FormatUnderlined';
import PhotoOutlined from '@mui/icons-material/PhotoOutlined'
import ChatIcon from '@mui/icons-material/Chat'
import Title from '@mui/icons-material/Title'
import FormatListNumbered from '@mui/icons-material/FormatListNumbered'
import FormatListBulleted from '@mui/icons-material/FormatListBulleted'
import EmojiEmotionsOutlinedIcon from '@mui/icons-material/EmojiEmotionsOutlined';

import { FormattedMessage } from 'react-intl'

// Needed for onTouchTap?
// http://stackoverflow.com/a/34015469/988941
// var injectTapEventPlugin = import "react-tap-event-plugin"
// injectTapEventPlugin()

// Services  // Message
import { chatSendMessage } from '../actions/chat.actions'

//-----------------------------------------------
// Image EXIF Orientation
// https://github.com/blueimp/JavaScript-Load-Image
import * as loadImage from 'blueimp-load-image'

//-----------------------------------------------
// Import the Slate editor
// https://github.com/ianstormtaylor/slate/blob/master/site/examples/richtext.js

// https://github.com/ianstormtaylor/slate
// https://slate-slack.herokuapp.com/
// https://github.com/ianstormtaylor/slate/issues/2434
//-----------------------------------------------
// Slate Plugin code: https://codesandbox.io/s/j70v45wz85

// Import the Slate components and React plugin.
import { createEditor, Editor, Node, Text, Transforms } from 'slate'
import { Slate, Editable, withReact, useSlate, useSelected, useFocused } from 'slate-react'
import { withHistory } from 'slate-history'
import escapeHtml from 'escape-html'
import isHotkey from 'is-hotkey'
import isUrl from 'is-url'
import imageExtensions from 'image-extensions'

import { css } from 'styled-components'   // keyframes

// import { timingSafeEqual } from 'crypto'

// react-image-upload - Medium 
// https://codeburst.io/react-image-upload-with-kittens-cc96430eaece
// https://github.com/funador/react-image-upload/

// sss
// https://stackoverflow.com/questions/4459379/preview-an-image-before-it-is-uploaded

//-----------------------------------------------
// TODO: Cropping - DSW
// https://github.com/fengyuanchen/cropperjs
// http://foliotek.github.io/Croppie/#documentation
// https://ourcodeworld.com/articles/read/281/top-7-best-image-cropping-javascript-and-jquery-plugins


// const Transition = React.forwardRef(function Transition(props, ref) {
//     return <Slide direction="up" ref={ref} {...props} />
// })


const useStyles = makeStyles(theme => ({
    categoryControl: {
        margin: 8,
        width: '100%',
        backgroundColor: theme.palette.background.paper,

        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },

    editMessage: {
        zIndex: 9000,

        backgroundColor: 'white',

        maxWidth: 350,
        minWidth: 350,

        border: '1px solid',
        borderColor: theme.palette.primary.main,
        borderRadius: 10,

        boxShadow: '16px 16px 16px rgba(34, 25, 25, 0.4)',

        //------------------------

        position: 'absolute',
        top: 24,
        right: 24,

        // Phone Landscape
        [theme.breakpoints.down('md')]: {
            top: 16,
            right: 16,
        },

        // Phone Portrait
        [theme.breakpoints.down('sm')]: {
            left: 16,
            right: 16,
        },
    },


    slate: {
        padding: '0 8px',
        overflowY: 'auto',

        maxHeight: 425,
        minHeight: 125,
    },

    headingName: {
        alignSelf: 'center',
        color: theme.palette.primary.dark,
        marginLeft: 8,
        marginTop: 8,
        textAlign: 'left',
    },

    toolbar: {
        backgroundColor: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: 2,

        // backgroundColor: 'rgba(0, 0, 0, 0.025)',
        // display: 'flex',
        // justifyContent: 'space-between',
        // padding: 2,

        // border: '.5px solid',
        // borderColor: 'rgba(0, 0, 0, 0.07)',
        // borderRadius: 5,
    },

    toolbarButton: {
        boxShadow: 'unset',
        margin: 0,
        fontSize: '.5rem',
    },
}))

const HOTKEYS = {
    'mod+b': 'bold',
    'mod+i': 'italic',
    'mod+u': 'underline',
}

//-----------------------------------------------
// Custom editor plugin // withRichText Plugin
//-----------------------------------------------
const TEXT_FORMATS = ['bold', 'italic', 'underline']
const LIST_FORMATS = ['numbered-list', 'bulleted-list']
const LIST_ITEM_FORMATS = ['list-item']

const BLOCK_FORMATS = [
    ...LIST_FORMATS,
    'heading-one',
    'heading-two',
    'block-quote'
]


//-----------------------------------------------
// Helpers
//-----------------------------------------------
export const isListNode = format => LIST_FORMATS.includes(format)
export const isListItemNode = format => LIST_ITEM_FORMATS.includes(format)

// Old Slate Editor
export const isListFormat = (editor, format) => {
    if (isListNode(format)) {
        const [match] = Editor.nodes(editor, {
            match: { [format]: true },
            mode: 'all'
        })

        return !!match
    }
}

// Old Slate Editor
export const isListItemFormat = (editor, format) => {
    if (isListItemNode(format)) {
        const [match] = Editor.nodes(editor, {
            match: { [format]: true },
            mode: 'all'
        })

        return !!match
    }
}

// Old Slate Editor
export const isFormatActive = (editor, format) => {
    if (TEXT_FORMATS.includes(format)) {
        const [match] = Editor.nodes(editor, {
            match: { [format]: true },
            mode: 'all'
        })

        return !!match
    }

    if (BLOCK_FORMATS.includes(format)) {
        const [match] = Editor.nodes(editor, {
            match: { type: format },
            mode: 'all'
        })

        return !!match
    }

    return false
}


//-----------------------------------------------
// withImages Plugin
//-----------------------------------------------
const withImages = editor => {
    const { insertData, isVoid } = editor

    editor.isVoid = element => {
        return element.type === 'image' ? true : isVoid(element)
    }

    editor.insertData = data => {
        const text = data.getData('text/plain')
        const { files } = data

        if (files && files.length > 0) {
            for (const file of files) {
                const reader = new FileReader()
                const [mime] = file.type.split('/')

                if (mime === 'image') {
                    reader.addEventListener('load', () => {
                        const url = reader.result
                        insertImage(editor, url)
                    })

                    reader.readAsDataURL(file)
                }
            }
        } else if (isImageUrl(text)) {
            insertImage(editor, text)
        } else {
            insertData(data)
        }
    }


    // editor.exec = command => {
    //     switch (command.type) {
    //         case 'insert_image': {
    //             const { url } = command
    //             const text = { text: '' }
    //             const image = { type: 'image', url, children: [text] }
    //             Editor.insertNodes(editor, image)
    //             break
    //         }

    //         default: {
    //             // exec(command)
    //             break
    //         }
    //     }
    // }


    // https://github.com/ianstormtaylor/slate/issues/3456
    // editor.deleteFragment = () => {
    //     const { selection } = editor;

    //     if (selection && Range.isExpanded(selection)) {
    //         const images = Array.from(
    //             Editor.nodes(editor, {
    //                 match: n => n.type === TYPES.IMAGE
    //             })
    //         );

    //         if (!!images.length) {
    //             // We're only deleting the last image as that's what is always left behind.
    //             // Slate is handling the rest easily.
    //             const [, cellPath] = images[images.length - 1]
    //             Transforms.delete(editor, {
    //                 at: cellPath,
    //                 voids: true
    //             });
    //         }
    //     }
    //     deleteFragment()
    // }

    return editor
}


const insertImage = (editor, url) => {
    const text = { text: '' }
    const image = { type: 'image', url, children: [text] }
    Transforms.insertNodes(editor, image)
}

const isImageUrl = url => {
    if (!url) return false
    if (!isUrl(url)) return false
    const ext = new URL(url).pathname.split('.').pop()
    return imageExtensions.includes(ext)
}


//-----------------------------------------------
// Element
//-----------------------------------------------
// Define schema, element and leaf
// https://phann123.medium.com/slatejs-reactjs-implement-full-feature-editor-8ed5c2784911
const Element = ({ attributes, children, element }) => {
    const selected = useSelected()
    const focused = useFocused()

    switch (element.type) {

        case 'heading-one':
            return <h1 {...attributes}>{children}</h1>

        case 'heading-two':
            return <h2 {...attributes}>{children}</h2>


        case 'list-item':
            return <li {...attributes}>{children}</li>

        case 'bulleted-list':
            return <ul {...attributes}>{children}</ul>

        case 'numbered-list':
            return <ol {...attributes}>{children}</ol>


        case 'image':
            return (
                <div {...attributes}>
                    <div contentEditable={false}>
                        <img src={element.url} alt="" style={{ maxHeight: '20em', boxShadow: selected && focused ? '0 0 0 3px #d05ce3' : 'none' }}
                            className={css`
                                    display: block;
                                    max-width: 100%;
                                    max-height: 20em;
                                    box-shadow: ${selected && focused ? '0 0 0 3px #d05ce3' : 'none'};
                                    `}
                        />
                    </div>
                    {children}
                </div>
            )

        default:
            return <p {...attributes}>{children}</p>
    }
}


//-----------------------------------------------
// Leaf
//-----------------------------------------------

// Define a React component to render leaves with style(s)
// const Leaf = props => {
//     var styleObj = {}

//     if (props.leaf.hasOwnProperty('bold'))
//         styleObj = Object.assign({ fontWeight: props.leaf.bold ? 'bold' : 'normal' })

//     if (props.leaf.hasOwnProperty('italic'))
//         styleObj = Object.assign({ fontStyle: props.leaf.italic ? 'italic' : 'normal' })

//     if (props.leaf.hasOwnProperty('underline'))
//         styleObj = Object.assign({ textDecoration: props.leaf.underline ? 'underline' : 'normal' })

//     return (
//         <span {...props.attributes} style={{ ...styleObj }}>
//             {props.children}
//         </span>
//     )
// }

const Leaf = ({ attributes, children, leaf }) => {
    if (leaf.bold) {
        children = <strong>{children}</strong>
    }

    if (leaf.code) {
        children = <code>{children}</code>
    }

    if (leaf.italic) {
        children = <em>{children}</em>
    }

    if (leaf.underline) {
        children = <u>{children}</u>
    }

    return <span {...attributes}>{children}</span>
}


//-----------------------------------------------
// serialize
//-----------------------------------------------
const serialize = node => {
    if (Text.isText(node)) {
        let string = escapeHtml(node.text)

        if (node.bold) {
            string = `<strong>${string}</strong>`
        }

        if (node.italic) {
            string = `<italic>${string}</italic>`
        }

        if (node.underline) {
            string = `<underline>${string}</underline>`
        }

        return string
    }

    const children = node.children.map(n => serialize(n)).join('')

    switch (node.type) {
        case "bulleted-list":
            return `<ul style="list-style: disc; ">
                        ${SerializeChildren(node.children)}
                    </ul>`

        case "numbered-list":
            return `<ol>
                        ${SerializeChildren(node.children)}
                    </ol>`

        case "heading-one":
            return `<h1>${SerializeChildren(node.children)}</h1>`

        case "heading-two":
            return `<h2>${SerializeChildren(node.children)}</h2>`

        case "heading-three":
            return `<h3>${SerializeChildren(node.children)}</h3>`

        case "heading-four":
            return `<h4>${node.children}</h4>`

        case 'paragraph':
            return `<p>${SerializeChildren(node.children)}</p>`

        case 'image':
            try {
                var parts = node.url.split(';base64,');
                if (parts[0]) {
                    var tokens = parts[0].split("/")

                    // Note: img attributes can be sanitized if not allowed
                    if (tokens[1] === "png")
                        return `<img alt=''; style="max-width: 100%;" src=${node.url} />`
                    else
                        return `<img alt=''; style="max-width: 100%;" height="auto"; src=${node.url} />`
                }
                else {
                    return `<img alt=''; style="max-width: 100%;" height="auto"; src=${node.url} />`
                }

            } catch (ex) {
                console.log("SendMessage serialize image Exception: " + ex)
            }
            break

        // No Links!! 
        // case 'link':
        //     return `<a href="${escapeHtml(node.url)}">${children}</a>`

        default:
            return children
    }
}


// @prezly/slate-lists
// https://www.npmjs.com/package/@prezly/slate-lists

// Formatting children...
function SerializeChildren(children) {
    var html = ""

    children.forEach(function (node) {
        var cstyles = ""
        let string

        if (isListItemNode(node.type)) {
            let c = node.children[0]

            string = escapeHtml(c.text)
            string = `<li>${string}</li>`

            if (c.bold) {
                cstyles += 'font-weight: bold; '
            }

            if (c.italic) {
                cstyles += 'font-style: italic; '
            }

            if (c.underline) {
                cstyles += 'text-decoration: underline; '
            }
        }
        else {
            string = escapeHtml(node.text)

            if (node.bold) {
                cstyles += 'font-weight: bold; '
            }

            if (node.italic) {
                cstyles += 'font-style: italic; '
            }

            if (node.underline) {
                cstyles += 'text-decoration: underline; '
            }
        }

        if (cstyles.length)
            html += `<span style="${cstyles}">${string}</span>`
        else
            html += `<span>${string}</span>`
    })

    return html
}


//-----------------------------------------------
// SendMessage
//-----------------------------------------------
export default function SendMessage2(props) {
    const isSupportMsg = props.isSupportMsg

    // const theme = useTheme()
    const classes = useStyles(props)

    const fileUploader = useRef(null);

    // const currentChatId = useSelector(state => state.chat.currentChatId)

    const dispatch = useDispatch()

    const [showEmojiPicker, setShowEmojiPicker] = useState(false)
    const [isPristine, setIsPristine] = useState(true)
    const [showGifWarning, setShowGifWarning] = useState(false)
    const [selectedMessageType, setSelectedMessageType] = useState(10)

    const [chat, setChat] = useState({})

    // Slate
    const renderElement = useCallback(props => <Element {...props} />, [])
    const renderLeaf = useCallback(props => <Leaf {...props} />, [])

    // const editor = useState(withReact(withImages(withHistory(createEditor()))), [])
    const editor = useMemo(() => withReact(withImages(withHistory(createEditor()))), [])

    // const [value, setValue] = useState('')
    const [value, setValue] = useState([
        {
            type: 'paragraph',
            children: [{ text: '' }],
        },
    ])


    // Testing Example
    // const [value, setValue] = useState([
    //     {
    //         type: 'paragraph',
    //         children: [
    //             { text: 'This is editable ' },
    //             { text: 'rich', bold: true },
    //             { text: ' text, ' },
    //             { text: 'much', italic: true },
    //             { text: ' better than a ' },
    //             { text: '<textarea>', code: true },
    //             { text: '!' },
    //         ],
    //     },
    //     {
    //         type: 'image',
    //         url: 'https://source.unsplash.com/kFrdX5IeQzI',
    //         children: [{ text: '' }],
    //     },
    //     {
    //         type: 'paragraph',
    //         children: [
    //             {
    //                 text:
    //                     'This example shows images in action. It features two ways to add images. You can either add an image via the toolbar icon above, or if you want in on a little secret, copy an image URL to your keyboard and paste it anywhere in the editor!',
    //             },
    //         ],
    //     },
    // ])


    //-------------------------------------------
    // Chat
    //-------------------------------------------
    useEffect(() => {
        setChat(props.chat)
    }, [props.chat])


    //-------------------------------------------
    // Set Focus
    //-------------------------------------------
    useEffect(() => {
        // ReactEditor.focus(editor)  // Don't need this for a focus anymore??
        Transforms.select(editor, [0])
    }, [editor])


    //-------------------------------------------
    // handleMessageSend
    //-------------------------------------------
    function handleMessageSend() {
        let memberId

        if (props.chat.memberId != null)       // from a chat object in a table
            memberId = props.chat.memberId
        else if (props.chat.id != null)        // chat as a member object
            memberId = props.chat.id
        else if (props.chat._id != null)       // chat as a member object
            memberId = props.chat._id

        // Note: "currentChatId" should be null on new chat 
        if (!props.isSending && memberId) {
            // alert(`Selected file - ${this.fileUploader.current.files[0].name}`)

            // FUTURE: Upload to image(s) to server by replacing local URLs with server URLs - DSW
            // this.chatSendMessageImages()

            // Create a new serializer instance with our `rules` from above
            const myHtml = serialize(editor)

            setIsPristine(true)     // *Note: Do this only on send


            //------------------------------------------
            // Testing Validate the message size...
            //------------------------------------------
            // if (myHtml) {
            //     console.log('SendMessage myHtml length: ', myHtml.length)
            // }

            // Send Message
            dispatch(chatSendMessage(
                selectedMessageType,
                memberId,
                props.chat.hasOwnProperty('chatId') ? props.chat.chatId : null,   // Existing vs. new chat
                myHtml,
                null,
            ))

            // Close dialog
            props.onClose()

            //-----------------------------------
            // Reset the editor
            //-----------------------------------
            // setSelection(null)

            // https://github.com/ianstormtaylor/slate/issues/3477
            editor.selection = { anchor: { path: [0, 0], offset: 0 }, focus: { path: [0, 0], offset: 0 } }
            setValue([
                {
                    type: 'paragraph',
                    children: [{ text: '' }],
                },
            ])
        }
    }

    // https://github.com/ianstormtaylor/slate/issues/3412
    const MyEditor = {
        ...Editor,

        insertImage(editor, url) {
            const element = { type: 'image', url, children: [{ text: '' }] }
            Transforms.insertNodes(editor, element)
        },
    }


    //-------------------------------------------
    // onInputImageChange
    // Note: Serialized images grow about 4x
    //-------------------------------------------
    // @param {Event} e
    function onInputImageChange(e) {
        try {
            // loadImage doesn't handle gifs!
            if (e.target.files[0].type === "image/gif") {

                // Validate file size - 3mb max
                if (e.target.files[0].size > 3 * 1024 * 1024) {
                    // console.log("SendMessage - Your image size greater than 3mb: ")
                    setIsPristine(true)
                    setShowGifWarning(true)
                    return
                }

                var reader = new FileReader()

                reader.addEventListener("load", function () {
                    // Later, when you want to insert an image...
                    MyEditor.insertImage(editor, reader.result)
                })

                reader.readAsDataURL(e.target.files[0]) // converts blob to base64 and calls onload
            }
            else if (e.target.files[0].type === "image/jpeg" || e.target.files[0].type === "image/png" || e.target.files[0].type === "image/tiff") {
                loadAndFixRotation(e.target.files[0]).then((fixedimg) => {

                    MyEditor.insertImage(editor, fixedimg)
                })
            }
        }
        catch (ex) {
            console.log("SendMessage.onInputImageChange Exception: " + ex)
            setIsPristine(true)
            return
        }

        setIsPristine(false)
    }


    //-------------------------------------------
    // loadAndFixRotation (and resize)
    //-------------------------------------------
    // Both loadImage and toDataURL don't do gifs...
    // @param {Blob} blob
    // 
    // https://github.com/blueimp/JavaScript-Load-Image/commit/840c776b772b29da8cb048ba6fb3c35bf1db013c
    function loadAndFixRotation(file) {
        return new Promise((resolve) => {
            // Doesn't do gifs (it just takes an image of it)
            loadImage(file, (img) => {
                resolve(img.toDataURL())    // Note: Doesn't handle gifs...

                // Note: Another way...
                // https://stackoverflow.com/questions/44567511/downloading-canvas-image-using-toblob
                // img.toBlob(function (blob) {
                //      var url = URL.createObjectURL(blob)
                //      ...do something
                //      URL.revokeObjectURL(url)
                // })
            },
                {
                    maxWidth: 512,
                    maxHeight: 512,
                    orientation: true,
                    canvas: true,
                    pixelRatio: window.devicePixelRatio, // should be set to window.devicePixelRatio 
                })
        })
    }


    //-------------------------------------------
    // onClickImageButton
    // File Selection (input)
    //-------------------------------------------
    function onClickImageButton() {
        fileUploader.current.click()
    }

    const onInputClick = (event) => {
        event.target.value = ''
    }

    const onKeyDown = editor => event => {
        setIsPristine(false)

        //-----------------------------------
        // Ctrl + B
        // Ctrl + I
        // Ctrl + U
        //-----------------------------------
        for (const hotkey in HOTKEYS) {
            if (isHotkey(hotkey, event)) {
                event.preventDefault()
                const mark = HOTKEYS[hotkey]
                toggleMark(editor, mark)
            }
        }

        //-----------------------------------
        // Break out on "Enter"
        //-----------------------------------
        if (event.key === "Enter") {
            event.preventDefault()

            //-----------------------------------
            // Break out of Image on Enter...
            //-----------------------------------
            const [matchImage] = Editor.nodes(editor, {
                match: n => n.type === 'image',
            })

            if (matchImage) {
                const newLine = {
                    type: "paragraph",
                    children: [
                        {
                            text: "",
                            marks: [],
                        },
                    ],
                }

                Transforms.insertNodes(editor, newLine);
                return
            }

            //-----------------------------------
            // Break out of list on an "Enter"
            //-----------------------------------
            const [matchBulleted] = Editor.nodes(editor, {
                match: n => n.type === 'bulleted-list',
            })

            const [matchList] = Editor.nodes(editor, {
                match: n => n.type === 'numbered-list',
            })

            const [matchListItem] = Editor.nodes(editor, {
                match: n => n.type === 'list-item',
            })


            const isEmptyTextNode = (!!matchList || !!matchBulleted) && !!matchListItem && !!matchImage && Node.string(matchListItem[0]) === ''
            if (isEmptyTextNode) {
                // for (const f of LIST_FORMATS) {
                //     Transforms.unwrapNodes(editor, { mode: 'all', split: true })
                // }

                Transforms.setNodes(editor, {
                    type: 'paragraph'
                })

                Transforms.unwrapNodes(editor, { mode: 'all', split: true })

            } else {
                Editor.insertBreak(editor)
            }
        }
    }


    const BlockButton = ({ format, icon }) => {
        const editor = useSlate()

        return (
            <Button className={classes.toolbarButton}
                size="small"
                variant="contained"
                disableElevation

                color={isBlockActive(editor, format) ? "secondary" : "primary"}

                onMouseDown={event => {
                    event.preventDefault()
                    toggleBlock(editor, format)
                }}
            >
                {format === "heading-two" && <Title fontSize="small" />}
                {format === "numbered-list" && <FormatListNumbered fontSize="small" />}
                {format === "bulleted-list" && <FormatListBulleted fontSize="small" />}
            </Button>
        )
    }


    const EmojiButton = ({ format, icon }) => {
        const editor = useSlate()

        return (
            <Button className={classes.toolbarButton}
                size="small"
                variant="contained"
                disableElevation

                color={isMarkActive(editor, format) ? "secondary" : "primary"}

                onMouseDown={event => {
                    event.preventDefault()
                    // toggleMark(editor, format)

                    toggleEmojiPicker(editor)
                }}
            >
                < EmojiEmotionsOutlinedIcon fontSize="small" />
            </Button>

        )
    }

    // https://stackoverflow.com/questions/58878858/how-do-i-style-a-material-ui-icon-which-was-passed-as-prop
    const MarkButton = ({ format, icon }) => {
        const editor = useSlate()

        return (
            <Button className={classes.toolbarButton}
                size="small"
                variant="contained"
                disableElevation

                color={isMarkActive(editor, format) ? "secondary" : "primary"}

                onMouseDown={event => {
                    event.preventDefault()
                    toggleMark(editor, format)

                    if (format === 'image') {
                        onClickImageButton()
                    }
                }}
            >
                {format === "bold" && <FormatBoldIcon fontSize="small" />}
                {format === "italic" && <FormatItalicIcon fontSize="small" />}
                {format === "underline" && <FormatUnderlinedIcon fontSize="small" />}
                {format === "image" && <PhotoOutlined fontSize="small" />}
            </Button>

        )
    }


    // Block handling
    const toggleBlock = (editor, format) => {
        const isActive = isBlockActive(editor, format)
        const isList = LIST_FORMATS.includes(format)

        Transforms.unwrapNodes(editor, {
            match: n => LIST_FORMATS.includes(n.type),
            split: true,
        })

        Transforms.setNodes(editor, {
            type: isActive ? 'paragraph' : isList ? 'list-item' : format,
        })

        if (!isActive && isList) {
            const block = { type: format, children: [] }
            Transforms.wrapNodes(editor, block)
        }
    }


    // Mark handling
    const toggleMark = (editor, format) => {
        const isActive = isMarkActive(editor, format)

        if (isActive) {
            Editor.removeMark(editor, format)
        } else {
            Editor.addMark(editor, format, true)
        }
    }


    //-------------------------------------------
    // Slate issue #3481
    // Editor.nodes returns undefined
    // https://github.com/ianstormtaylor/slate/issues/3481
    //-------------------------------------------

    // const isBlockActive = (editor, format) => {
    //     const [match] = Editor.nodes(editor, {
    //         match: n => n.type === format,
    //     })

    //     return !!match
    // }

    // https://github.com/ianstormtaylor/slate/issues/4081
    // https://github.com/ianstormtaylor/slate/issues/4081#issuecomment-785027836

    const isBlockActive = (editor, format) => {
        try {
            const cursorPosition = editor.selection?.focus;
            const elementPath = cursorPosition?.path;
            let match = false

            if (elementPath && elementPath.length > 0 && editor.children) {
                let element = editor.children[elementPath[0]]
                for (let i = 1; i < elementPath.length; i += 1) {
                    if (element?.type === format) {
                        match = true
                        break
                    }
                    element = element.children[elementPath[i]]
                }
            }

            return match

        } catch (err) {
            console.log('SendMessage2.isBlockActive Exception: ', err)
        }

        return false
    }




    const isMarkActive = (editor, format) => {
        const marks = Editor.marks(editor)
        return marks ? marks[format] === true : false
    }


    // Gif Warning
    function handleGifWarningClose() {
        setIsPristine(true)
        setShowGifWarning(false)
    }


    const handleGifWarningDialog = () => {
        return (
            <Dialog open={showGifWarning} onClose={handleGifWarningClose}>

                <Grid container style={{ display: 'flex', justifyContent: "center" }}>
                    <Typography style={PageHeading} variant="h5">
                        {<FormattedMessage id="image.largeGif" defaultMessage="Gif is too large" />}
                    </Typography>
                </Grid>

                <Divider />

                <DialogContent>
                    {<FormattedMessage id="image.largeGifSize" defaultMessage="Gif files must be less than 3mb" />}
                </DialogContent>

                <DialogActions>
                    <Button variant="contained" color="primary" autoFocus
                        className={classes.buttons} onClick={handleGifWarningClose}
                        startIcon={<CancelIcon />}
                    >
                        <FormattedMessage id="system.cancel" defaultMessage="Cancel" />
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    //-------------------------------------------
    // Emojis
    // https://github.com/missive/emoji-mart/issues/509
    //-------------------------------------------
    function toggleEmojiPicker() {
        setShowEmojiPicker(!showEmojiPicker)
    }

    let onEmojiSelected = React.useCallback(emoji => {
        setShowEmojiPicker(false)
        editor.insertText(emoji.native)
        setIsPristine(false)
    }, [editor, setShowEmojiPicker])


    // Resize
    // https://developer.mozilla.org/pt-BR/docs/Web/Events/resize

    // ScrollIntoView
    // https://developer.mozilla.org/en-US/docs/Web/API/Element/scrollIntoView

    return (
        <div style={{ zIndex: 9900, opacity: 1 }}>
            <input type="file" ref={fileUploader} multiple accept="image/*" style={{ zIndex: 9900, display: 'none' }} onClick={(event) => onInputClick(event)} onChange={e => onInputImageChange(e)} />

            {/* Note: Logout clears 'chat' prop from parent */}
            {chat && <div className={classes.editMessage}
                open={props.show}
                onClose={props.onClose}
            >
                {/* Header */}
                <Grid container direction="row" alignItems="flex-start">
                    <Avatar small gender={chat.gender} avatarUrl={chat.avatarUrl} delay={250} alt={chat.firstname}>...</Avatar>

                    <div flexdirection='column'>
                        <Typography className={classes.headingName} variant="subtitle1">
                            {chat.firstname}
                        </Typography>

                        {isSupportMsg &&
                            <FormControl className={classes.categoryControl}>
                                <InputLabel shrink id="category-label">
                                    {<FormattedMessage id="system.msg.supportCategory" defaultMessage="Support Category" />}
                                </InputLabel>

                                {isSupportMsg && <Select labelId="support-category" id="support-category"
                                    value={selectedMessageType}
                                    onChange={e => setSelectedMessageType(e.target.value)}
                                >
                                    <MenuItem value={10}>
                                        {<FormattedMessage id="system.msg.featureRequest" defaultMessage="Feature Request" />}
                                    </MenuItem>
                                    <MenuItem value={20}>
                                        {<FormattedMessage id="system.msg.issueReport" defaultMessage="Issue Report" />}
                                    </MenuItem>
                                    <MenuItem value={30}>
                                        {<FormattedMessage id="system.msg.feedback" defaultMessage="Other" />}
                                    </MenuItem>
                                </Select>}
                            </FormControl>
                        }
                    </div>
                </Grid>

                <div>
                    <Slate
                        editor={editor}
                        value={value}
                        // selection={selection}  // depricated???
                        onChange={(value) => { setValue(value) }}
                    >

                        {/* Toolbar */}
                        <Box className={classes.toolbar}>

                            <MarkButton format="bold" />
                            <MarkButton format="italic" />
                            <MarkButton format="underline" />
                            <BlockButton format="heading-two" />
                            <BlockButton format="numbered-list" />
                            <BlockButton format="bulleted-list" />
                            <MarkButton format="image" />
                            <EmojiButton format="emoji" />


                            {/* <MarkButton format="code" icon="code" /> */}
                            {/* <BlockButton format="heading-two" icon="looks_two" /> */}
                            {/* <BlockButton format="block-quote" icon="format_quote" /> */}

                        </Box>

                        {/* https://codesandbox.io/s/slate-reproductions-gmxkf */}

                        <Editable className={classes.slate}
                            spellCheck
                            autoFocus
                            renderElement={renderElement}
                            renderLeaf={renderLeaf}
                            onKeyDown={onKeyDown(editor)}
                        />
                    </Slate>

                    <Divider />
                </div>

                {showEmojiPicker && <Picker native sheetSize={20} emojiSize={20} showPreview={false} showSkinTones={false} onSelect={onEmojiSelected} style={{ zIndex: 9900 }} />}

                <div style={{ display: 'flex', justifyContent: "flex-end", margin: 4 }}>
                    <Button variant="contained" color="primary"
                        autoFocus
                        style={SmallButton}
                        disabled={props.isSending || isPristine}
                        onClick={handleMessageSend}
                        startIcon={<ChatIcon aria-label='Send' />}
                    >
                        <FormattedMessage id="system.send" defaultMessage="Send" />
                    </Button>

                    <Button variant="contained" color="primary"
                        style={SmallButton} onClick={props.onClose}
                        startIcon={<CancelIcon aria-label='Cancel' />}
                    >
                        <FormattedMessage id="system.cancel" defaultMessage="Cancel" />
                    </Button>
                </div>
            </div>}


            {handleGifWarningDialog()}
        </div>
    )
}


