// GDPR Notice and Acceptance

import React, { useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import { makeStyles } from '@mui/styles';

import { useNavigate } from 'react-router-dom'

import { Button, Dialog, DialogTitle } from '@mui/material'
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined'
import CookiePolicy from '../policies/CookiePolicy'
import { profileChangesUpdate, profileChangesSave } from '../actions/profile.actions';
import { FormattedMessage } from 'react-intl'

import { DialogStyles, SmallButton } from "../styles/ftfStyles"
import { logoutUser } from '../actions/auth.actions'

const useStyles = makeStyles(DialogStyles)

export default function Gdpr(props) {
    const classes = useStyles(props)
    let navigate = useNavigate()

    const profile = useSelector(state => state.profile.profile)
    const [showCookiePolicy, setShowCookiePolicy] = useState(false)
    const dispatch = useDispatch()

    function onAcceptGDPR() {
        dispatch(profileChangesUpdate({ gdprAccepted: true }))
        dispatch(profileChangesSave(profile._id, 0))
    }

    function onLogout() {
        try {
            // closeNavBar(false)
            dispatch(logoutUser(navigate))
        } catch (err) {
            console.log('FindTrueFriends Logout error: ', err)
        }
    }

    function toggleCookiePolicy() {
        setShowCookiePolicy(!showCookiePolicy)
    }


    // Examples
    // https://www.privacypolicies.com/blog/gdpr-consent-examples/
    // https://www.privacypolicies.com/blog/gdpr/

    // Cookie Policy
    // https://termly.io/resources/templates/cookie-policy-template/
    // We use cookies to improve user experience, and analyze website traffic. For these reasons, we may share your site usage data with our analytics partners. By clicking “Accept Cookies,” you consent to store on your device all the technologies described in our Cookie Policy. You can change your cookie settings at any time by clicking “Cookie Preferences.”

    return (
        <div>
            <CookiePolicy show={showCookiePolicy} fullscreen={false} onClose={toggleCookiePolicy} />

            <Dialog open onClose={props.onClose} >
                <DialogTitle color="primary" className={classes.dialogTitle}>{<FormattedMessage id="system.GDPRHeader" defaultMessage="Our website uses cookies" />}</DialogTitle>

                {/* className={classes.dialogPaper}  */}
                <div id="xxx" style={{ padding: '0 12px' }}>
                    <FormattedMessage id="system.GDPR" defaultMessage="We use cookies" />
                </div>

                <div style={{ alignContent: 'baseline', justifyContent: 'flex-end', display: 'flex', padding: '6px 8px' }}>
                    <Button onClick={toggleCookiePolicy} style={SmallButton} variant="contained" color="primary">
                        <FormattedMessage id="navbar.cookiepolicy" defaultMessage="Cookie Policy" />
                    </Button>

                    <Button onClick={onAcceptGDPR}
                        style={SmallButton}
                        variant="contained" color="primary"
                        startIcon={<ThumbUpOutlinedIcon style={{ height: '.7em' }} aria-label='Accept' />}
                    >
                        <FormattedMessage id="system.acceptCookies" defaultMessage="I understand and accept the use of cookies" />
                    </Button>

                    <Button onClick={onLogout}
                        style={SmallButton}
                        variant="contained" color="primary"
                    >
                        <FormattedMessage id="system.logout" defaultMessage="Logout" />
                    </Button>

                    {/* <Button onClick={props.onClose} size='large' variant="contained" color="primary" style={SmallButton} >
                    <BlockIcon />
                        <FormattedMessage id="system.declineCookies" defaultMessage="I do not accept the use of cookies" />
                    </Button> */}
                </div>
            </Dialog>
        </div>
    )
}
