/* eslint-disable no-unused-vars */

// https://facebookincubator.github.io/fbt/
// https://facebookincubator.github.io/fbt/docs/enums/

// Future - Zodiac
// export const Zodiac = [
//     { value: 0, label: '', id: 'zodiac.xxx' },
//     { value: 2, label: '', id: 'zodiac.xxx' },
//     { value: 4, label: '', id: 'zodiac.xxx' },
//     { value: 8, label: '', id: 'zodiac.xxx' },
//     { value: 16, label: '', id: 'zodiac.xxx' },
//     { value: 32, label: '', id: 'zodiac.xxx' },
//     { value: 64, label: '', id: 'zodiac.xxx' },
// ];

// Future - Activities
// export const Activities = [
//     { value: 0, label: '', id: 'bodyType.xxx' },
//     { value: 2, label: '', id: 'bodyType.xxx' },
//     { value: 4, label: '', id: 'bodyType.xxx' },
//     { value: 8, label: '', id: 'bodyType.xxx' },
//     { value: 16, label: '', id: 'bodyType.xxx' },
//     { value: 32, label: '', id: 'bodyType.xxx' },
//     { value: 64, label: '', id: 'bodyType.xxx' },
// ];

export const SupportType = [
    // { value: 0, label: 'Any', id: 'bodyType.any' },
    { value: 30, label: 'Feedback', id: 'system.msg.feedback' },
    { value: 10, label: 'Feature Request', id: 'system.msg.featureRequest' },
    { value: 20, label: 'Issue Report', id: 'system.msg.issueReport' },
];

export const RightsType = [
    // { value: 0, label: 'Any', id: 'bodyType.any' },
    { value: 2, label: 'Slender', id: 'bodyType.slender' },
    { value: 4, label: 'Athletic/Toned', id: 'bodyType.athletic' },
    { value: 8, label: 'Average', id: 'bodyType.average' },
];


export const BodyType = [
    // { value: 0, label: 'Any', id: 'bodyType.any' },
    { value: 2, label: 'Slender', id: 'bodyType.slender' },
    { value: 4, label: 'Athletic/Toned', id: 'bodyType.athletic' },
    { value: 8, label: 'Muscular', id: 'bodyType.muscular' },
    { value: 16, label: 'Average', id: 'bodyType.average' },
    { value: 32, label: 'A few extra pounds', id: 'bodyType.extraPounds' },
    { value: 64, label: 'Curvy', id: 'bodyType.curvy' },
    { value: 128, label: 'Stout', id: 'bodyType.stout' },
    { value: 256, label: 'Heavyset', id: 'bodyType.heavyset' },
    { value: 512, label: 'Big', id: 'bodyType.big' },
];


export const Children = [
    // { value: 0, label: 'Any', id: 'children.any' },
    { value: 4, label: 'I do not have Children', id: 'children.none' },
    { value: 8, label: 'I have Children', id: 'children.yes' },
    { value: 16, label: 'I want Children', id: 'children.want' },
    { value: 64, label: 'My kids are under 18', id: 'children.under18' },
    { value: 128, label: 'My kids are over 18', id: 'children.over18' },
    { value: 512, label: 'They live at home', id: 'children.atHome' },
    { value: 1024, label: 'They do not live at home', id: 'children.notHome' },
];


export const Drinking = [
    // { value: 0, label: 'Any', id: 'drinking.any' },
    { value: 1, label: 'Never', id: 'drinking.none' },
    { value: 2, label: 'Socially', id: 'drinking.social' },
    { value: 4, label: 'Couple times a week', id: 'drinking.couple' },
    { value: 8, label: 'Daily', id: 'drinking.daily' },
];


// Ethnicity
export const Ethnicity = [
    // { value: 0, label: 'Any', id: 'ethnicity.any' },
    { value: 210, label: 'Asian', id: 'ethnicity.asian' },
    { value: 211, label: 'Caucasian', id: 'ethnicity.caucasian' },
    { value: 212, label: 'Black', id: 'ethnicity.black' },
    { value: 213, label: 'European', id: 'ethnicity.european' },
    { value: 214, label: 'Hispanic', id: 'ethnicity.hispanic' },
    { value: 215, label: 'Indian', id: 'ethnicity.indian' },
    { value: 216, label: 'Middle Eastern', id: 'ethnicity.middleEastern' },
    { value: 217, label: 'Mixed (unique)', id: 'ethnicity.unique' },
    { value: 218, label: 'Native American', id: 'ethnicity.nativeAmerican' },
    { value: 219, label: 'North American', id: 'ethnicity.northAmerican' },
    { value: 220, label: 'Oceania', id: 'ethnicity.oceania' },
    { value: 221, label: 'South American', id: 'ethnicity.southAmerican' },
];


export const Education = [
    // { value: 0, label: 'Any', id: 'education.any' },
    { value: 310, label: 'Trade School', id: 'education.trade' },
    { value: 320, label: 'High School', id: 'education.high' },
    { value: 321, label: 'Associates Degree', id: 'education.assocs' },
    { value: 322, label: 'Bachelors Degree', id: 'education.bachs' },
    { value: 323, label: 'Masters Degree', id: 'education.masts' },
    { value: 324, label: 'PhD', id: 'education.phD' },

];

// 4000 Series (appearance)

export const EyeColor = [
    // { value: 0, label: 'Any', id: 'eyeColor.any' },
    { value: 2, label: 'Amber', id: 'eyeColor.amber' },
    { value: 4, label: 'Blue', id: 'eyeColor.blue' },
    { value: 8, label: 'Brown (light)', id: 'eyeColor.lightBrown' },
    { value: 16, label: 'Brown (dark)', id: 'eyeColor.darkBrown' },
    { value: 32, label: 'Green', id: 'eyeColor.green' },
    { value: 64, label: 'Gray', id: 'eyeColor.gray' },
    { value: 128, label: 'Hazel', id: 'eyeColor.hazel' },
];

export const Exercise = [
    // { value: 0, label: 'Any', id: 'exercise.any' },
    { value: 2, label: "Not too much", id: 'exercise.zero' },
    { value: 4, label: '1-2 times per week', id: 'exercise.one' },
    { value: 8, label: '3-4 times per week', id: 'exercise.three' },
    { value: 16, label: '5+ times per week', id: 'exercise.alot' },
];


export const Faith = [
    // { value: 0, label: 'Any', id: 'faith.any' },
    { value: 10, label: 'Agnostic', id: 'faith.agnostic' },
    { value: 12, label: 'Angelican', id: 'faith.angelican' },
    { value: 13, label: 'Atheist', id: 'faith.atheist' },
    { value: 14, label: 'Baptist', id: 'faith.baptist' },
    { value: 15, label: 'Buddist', id: 'faith.buddist' },
    { value: 16, label: 'Catholic', id: 'faith.catholic' },
    { value: 17, label: 'Christian', id: 'faith.christian' },
    { value: 18, label: 'Hindu', id: 'faith.hindu' },
    { value: 19, label: 'Islam', id: 'faith.islam' },
    { value: 20, label: 'Jewish', id: 'faith.jewish' },
    { value: 21, label: 'Lutheran', id: 'faith.lutheran' },
    { value: 22, label: 'Methodist', id: 'faith.methodist' },
    { value: 23, label: 'Mormon', id: 'faith.mormon' },
    { value: 24, label: 'New Age', id: 'faith.newAge' },
    { value: 25, label: 'Sikh', id: 'faith.sikh' },
    { value: 26, label: 'Spiritual', id: 'faith.spiritual' },
    { value: 27, label: 'Presbyterian', id: 'faith.presbyterian' },
    { value: 28, label: 'Other', id: 'faith.other' },
];


// SearchType Map
export const SearchType = [
    // { value: 0, label: 'All', id: 'searchType.all' },
    // { value: 2, label: 'Events', id: 'searchType.events' },
    { value: 8, label: 'People', id: 'searchType.people' },
    { value: 4, label: 'Groups', id: 'searchType.groups' },

    // Old
    //     { value: 16, label: 'Couples (F, M)', id: 'searchType.coupleFM' },
    //     { value: 32, label: 'Couples (F, F)', id: 'searchType.coupleFF' },
    //     { value: 64, label: 'Couples (M, M)', id: 'searchType.coupleMM' },
    //     { value: 128, label: 'Females', id: 'searchType.female' },
    //     { value: 512, label: 'Males', id: 'searchType.male' },
]

// EventType Map
export const EventType = [
    // { value: 0, label: 'Any', id: 'eventType.any' },
    { value: 2, label: 'Today', id: 'eventType.today' },
    { value: 4, label: 'Future', id: 'eventType.future' },
    { value: 8, label: 'Past', id: 'eventType.past' },
];


// GroupType Map
export const GroupType = [
    // { value: 0, label: 'Any', id: 'system.any' },
    { value: 2, label: 'Competitive', id: 'groupType.competitive' },
    { value: 4, label: 'Personal', id: 'groupType.personal' },
    { value: 8, label: 'Professional', id: 'groupType.professional' },
    { value: 16, label: 'Recreational', id: 'groupType.recreational' },
];


// GenderType Map
// a woman interested in men
// a man interested in women
// a woman interested in women
// a man interested in men
export const Genders = [
    // { value: 0, label: 'Any', id: 'genders.any' },
    { value: 2, label: 'Woman interested in men', id: 'genders.fm' },
    { value: 4, label: 'Man interested in women', id: 'genders.mf' },
    { value: 8, label: 'Woman interested in women', id: 'genders.ff' },
    { value: 16, label: 'Man interested in men', id: 'genders.mm' },
];


export const HairColorColor = ["#d81b60", "#701000", "#2196f3", "#00bcd4", "#43a047", "#BBBBBB", "#FF7010", "#FFFFFF"];
export const HairColor = [
    // { value: 0, label: 'Any', id: 'hairColor.any' },
    { value: 2, label: 'Bald', id: 'hairColor.bald' },
    { value: 4, label: 'Black', id: 'hairColor.black' },
    { value: 8, label: 'Blond', id: 'hairColor.blond' },
    { value: 16, label: 'Brown', id: 'hairColor.brown' },
    { value: 32, label: 'Colored', id: 'hairColor.colored' },
    { value: 64, label: 'Grey', id: 'hairColor.grey' },
    { value: 128, label: 'Red', id: 'hairColor.red' },
    { value: 256, label: 'Shaved', id: 'hairColor.shaved' },
    { value: 384, label: 'Silver', id: 'hairColor.silver' },
    { value: 512, label: 'White', id: 'hairColor.white' },
];

export const Height = [
    // { value: 0, label: 'Any', id: 'height.any' },
    { value: 59, label: "under 5' (152 cm)", id: 'height.0' },

    { value: 60, label: "5' 0 (152 cm)", id: 'height.152' },
    { value: 61, label: "5' 1 (155 cm)", id: 'height.155' },
    { value: 62, label: "5' 2 (157 cm)", id: 'height.157' },
    { value: 63, label: "5' 3 (160 cm)", id: 'height.160' },
    { value: 64, label: "5' 4 (163 cm)", id: 'height.163' },
    { value: 65, label: "5' 5 (165 cm)", id: 'height.165' },
    { value: 66, label: "5' 6 (168 cm)", id: 'height.168' },
    { value: 67, label: "5' 7 (170 cm)", id: 'height.170' },
    { value: 68, label: "5' 8 (173 cm)", id: 'height.173' },
    { value: 69, label: "5' 9 (175 cm)", id: 'height.175' },
    { value: 70, label: "5' 10 (178 cm)", id: 'height.178' },
    { value: 71, label: "5' 11 (180 cm)", id: 'height.180' },

    { value: 72, label: "6' 0 (183 cm)", id: 'height.183' },
    { value: 73, label: "6' 1 (185 cm)", id: 'height.185' },
    { value: 74, label: "6' 2 (188 cm)", id: 'height.188' },
    { value: 75, label: "6' 3 (191 cm)", id: 'height.191' },
    { value: 76, label: "6' 4 (193 cm)", id: 'height.193' },
    { value: 77, label: "6' 5 (196 cm)", id: 'height.196' },
    { value: 78, label: "6' 6 (198 cm)", id: 'height.198' },
    { value: 79, label: "6' 7 (201 cm)", id: 'height.201' },
    { value: 80, label: "6' 8 (203 cm)", id: 'height.203' },
    { value: 81, label: "6' 9 (206 cm)", id: 'height.206' },
    { value: 82, label: "6' 10 (208 cm)", id: 'height.208' },
    { value: 83, label: "6' 11 (211 cm)", id: 'height.211' },
    { value: 84, label: "7' 0 (213 cm)", id: 'height.213' },
    { value: 85, label: "over 7' (213 cm)", id: 'height.999' },
];


export const IncomeLevel = [
    // { value: 0, label: 'Any', id: 'income.any' },
    { value: 1, label: '< $25k', id: 'income.0' },
    { value: 25, label: '> $25k', id: 'income.25' },
    { value: 50, label: '> $50k', id: 'income.50' },
    { value: 75, label: '> $75k', id: 'income.75' },
    { value: 100, label: '> $100k', id: 'income.100' },
    { value: 125, label: '> $125k', id: 'income.125' },
    { value: 150, label: '> $150k', id: 'income.150' },
    { value: 200, label: '> $200k', id: 'income.200' },
    { value: 300, label: '> $300k', id: 'income.300' },
];


export const MaritalStatus = [
    // { value: 0, label: 'Any', id: 'maritalStatus.any' },
    { value: 1, label: 'Divorced', id: 'maritalStatus.divorced' },
    { value: 2, label: 'Married', id: 'maritalStatus.married' },
    { value: 4, label: 'Separated', id: 'maritalStatus.separated' },
    { value: 8, label: 'Single', id: 'maritalStatus.single' },
    { value: 16, label: 'Widowed', id: 'maritalStatus.widowed' },
];


export const Pets = [
    // { value: 0, label: 'Any', id: 'pets.any' },
    { value: 2, label: 'No Pets', id: 'pets.none' },
    { value: 4, label: 'Bird(s)', id: 'pets.bird' },
    { value: 8, label: 'Cat(s)', id: 'pets.cat' },
    { value: 16, label: 'Dog(s)', id: 'pets.dog' },
    { value: 32, label: 'Ferret(s)', id: 'pets.ferret' },
    { value: 64, label: 'Fish', id: 'pets.fish' },
    { value: 128, label: 'Farm Animals', id: 'pets.farm' },
    { value: 256, label: 'Hampster/Guinea Pig', id: 'pets.hampster' },
    { value: 1024, label: 'Horse(s)', id: 'pets.horse' },
    { value: 2048, label: 'Poultry', id: 'pets.poultry' },
    { value: 4096, label: 'Rabbit(s)', id: 'pets.rabbit' },
    { value: 8192, label: 'Reptile(s)', id: 'pets.reptile' },
];


export const Politics = [
    // { value: 0, label: 'Any', id: 'politics.any' },
    { value: 2, label: 'Apolitical', id: 'politics.apolitical' },
    { value: 4, label: 'Conservative', id: 'politics.conserv' },
    { value: 8, label: 'Independent', id: 'politics.indie' },
    { value: 16, label: 'Liberal', id: 'politics.liberal' },
    { value: 32, label: 'Moderate', id: 'politics.moderate' },
    { value: 64, label: 'Progressive', id: 'politics.progessive' },
    { value: 128, label: 'Other', id: 'politics.other' },
];


export const Relationship = [
    // { value: 0, label: 'Any', id: 'relationship.any' },
    { value: 2, label: 'Art/Crafts', id: 'relationship.art' },
    { value: 4, label: 'Casual Relationship', id: 'relationship.casual' },
    { value: 8, label: 'Companionship', id: 'relationship.companionship' },
    { value: 16, label: 'Daytime', id: 'relationship.daytime' },
    { value: 32, label: 'Dining', id: 'relationship.dining' },
    { value: 64, label: 'Evenings', id: 'relationship.evenings' },
    { value: 128, label: 'Hanging Out', id: 'relationship.hangout' },
    { value: 256, label: 'Long Term relationship', id: 'relationship.longterm' },
    { value: 512, label: 'Enjoying Music', id: 'relationship.music' },
    { value: 1024, label: 'Staying Active', id: 'relationship.active' },
    { value: 2048, label: 'Travel', id: 'relationship.travel' },
    { value: 4096, label: 'Working Out', id: 'relationship.workout' },
];


export const Roles = [
    { value: 0, label: 'Member', id: 'roles.member' },
    { value: 2, label: 'Event Host', id: 'roles.eventHost' },
    { value: 8, label: 'Cop', id: 'roles.cop' },
    { value: 64, label: 'Group Admin', id: 'roles.groupAdmin' },
    { value: 128, label: 'FTF Admin', id: 'roles.ftfAdmin' },
];


export const SecondLanguage = [
    { value: 0, label: 'None', id: 'secLang.none' },
    { value: 10, label: 'Arabic', id: 'secLang.arabic' },
    { value: 11, label: 'Bengali', id: 'secLang.bengali' },
    { value: 12, label: 'Chinese', id: 'secLang.chinese' },
    { value: 14, label: 'English', id: 'secLang.english' },
    { value: 15, label: 'French', id: 'secLang.french' },
    { value: 16, label: 'German', id: 'secLang.german' },
    { value: 17, label: 'Hindi', id: 'secLang.hindi' },
    { value: 18, label: 'Italian', id: 'secLang.italian' },
    { value: 19, label: 'Japanese', id: 'secLang.japanese' },
    { value: 20, label: 'Javanese', id: 'secLang.javanese' },
    { value: 21, label: 'Korean', id: 'secLang.korean' },
    { value: 22, label: 'Mandarin', id: 'secLang.mandarin' },
    { value: 23, label: 'Malay', id: 'secLang.malay' },
    { value: 24, label: 'Polish', id: 'secLang.polish' },
    { value: 25, label: 'Portuguese', id: 'secLang.portuguese' },
    { value: 26, label: 'Punjabi', id: 'secLang.punjabi' },
    { value: 30, label: 'Russian', id: 'secLang.russian' },
    { value: 31, label: 'Spanish', id: 'secLang.spanish' },
    { value: 32, label: 'Telugu', id: 'secLang.telugu' },
    { value: 33, label: 'Urdu', id: 'secLang.urdu' },
    { value: 34, label: 'Turkish', id: 'secLang.turkish' },
    { value: 35, label: 'Thai', id: 'secLang.thai' },
    { value: 36, label: 'Vietnamese', id: 'secLang.vietnamese' },
];


export const Smoking = [
    // { value: 0, label: 'Any', id: 'smoking.any' },
    { value: 2, label: 'Non-Smoker', id: 'smoking.none' },
    { value: 4, label: 'Cigarettes', id: 'smoking.cigarettes' },
    { value: 8, label: 'Cigars', id: 'smoking.cigars' },
    { value: 16, label: '420 Friendly', id: 'smoking.420' },
    { value: 32, label: 'Date a Smoker', id: 'smoking.dateSmoker' },
];


export const Vehicles = [
    // { value: 0, label: 'Any', id: 'vehicles.any' },
    { value: 2, label: 'Airplane', id: 'vehicles.airplane' },
    { value: 4, label: 'Automobile', id: 'vehicles.auto' },
    { value: 8, label: 'Bicycle', id: 'vehicles.bicycle' },
    { value: 16, label: 'Boat', id: 'vehicles.boat' },
    { value: 32, label: 'Jetski/Waverunner', id: 'vehicles.jetski' },
    { value: 64, label: 'Kayak', id: 'vehicles.Kayak' },
    { value: 128, label: 'Motorcycle', id: 'vehicles.motorcycle' },
    { value: 512, label: 'Quad', id: 'vehicles.quad' },
    { value: 1024, label: 'RV', id: 'vehicles.rv' },
    { value: 2048, label: 'Truck', id: 'vehicles.truck' },
    { value: 4096, label: 'Snowmobile', id: 'vehicles.snowmobile' },
];


// Password Strength Text
export const StrengthText = ["Enter a Password", "8 character minimum", "Weak Password", "Strong Password"];


// https://github.com/opening-hours/opening_hours.js/blob/master/js/countryToLanguageMapping.js
// export const Countries = [
//     // { code: 'XX', label: 'Any' },
//     { code: 'AF', label: 'Afghanistan' },
//     { code: 'AX', label: 'Aland Islands' },
//     { code: 'AL', label: 'Albania' },
//     { code: 'DZ', label: 'Algeria' },
//     { code: 'AS', label: 'American Samoa' },
//     { code: 'AO', label: 'Angola' },
//     { code: 'AI', label: 'Anguilla' },
//     { code: 'AQ', label: 'Antarctica' },
//     { code: 'AG', label: 'Antigua and Barbuda' },
//     { code: 'AR', label: 'Argentina' },
//     { code: 'AM', label: 'Armenia' },
//     { code: 'AW', label: 'Aruba' },
//     { code: 'AU', label: 'Australia' },
//     { code: 'AT', label: 'Austria' },
//     { code: 'AZ', label: 'Azerbaijan' },
//     { code: 'BS', label: 'Bahamas' },
//     { code: 'BH', label: 'Bahrein' },
//     { code: 'BD', label: 'Bangladesh' },
//     { code: 'BB', label: 'Barbados' },
//     { code: 'BY', label: 'Belarus' },
//     { code: 'BE', label: 'Belgium' },
//     { code: 'BZ', label: 'Belize' },
//     { code: 'BJ', label: 'Benin' },
//     { code: 'BM', label: 'Bermuda' },
//     { code: 'BT', label: 'Bhutan' },
//     { code: 'BO', label: 'Bolivia' },
//     { code: 'BA', label: 'Bosnia and Herzegovina' },
//     { code: 'BW', label: 'Botswana' },
//     { code: 'BV', label: 'Bouvet Island' },
//     { code: 'BR', label: 'Brazil' },
//     { code: 'IO', label: 'British Indian Ocean Territory' },
//     { code: 'VG', label: 'British Virgin Islands' },
//     { code: 'BN', label: 'Brunei Darussalam' },
//     { code: 'BG', label: 'Bulgaria' },
//     { code: 'BF', label: 'Burkina Faso' },
//     { code: 'BI', label: 'Burundi' },
//     { code: 'CV', label: 'Cabo Verde' },
//     { code: 'KH', label: 'Cambodia' },
//     { code: 'CM', label: 'Cameroon' },
//     { code: 'CA', label: 'Canada' },
//     { code: 'BQ', label: 'Caribbean Netherlands' },
//     { code: 'KY', label: 'Cayman Islands' },
//     { code: 'CF', label: 'Centrafrican Republic' },
//     { code: 'TD', label: 'Chad' },
//     { code: 'CL', label: 'Chile' },
//     { code: 'CN', label: 'China' },
//     { code: 'CX', label: 'Christmas Island' },
//     { code: 'CC', label: 'Cocos(Keeling) Islands' },
//     { code: 'CO', label: 'Colombia' },
//     { code: 'KM', label: 'Comores' },
//     { code: 'CK', label: 'Cook Islands' },
//     { code: 'CR', label: 'Costa Rica' },
//     { code: 'CI', label: "Côte d'Ivoire" },
//     { code: 'HR', label: 'Croatia' },
//     { code: 'CU', label: 'Cuba' },
//     { code: 'CW', label: 'Curaçao' },
//     { code: 'CY', label: 'Cyprus' },
//     { code: 'CZ', label: 'Czech Republic' },
//     { code: 'CD', label: 'Democratic Republic of the Congo)' },
//     { code: 'DK', label: 'Denmark' },
//     { code: 'DJ', label: 'Djibouti' },
//     { code: 'DM', label: 'Dominica' },
//     { code: 'DO', label: 'Dominican Republic' },
//     { code: 'EC', label: 'Ecuador' },
//     { code: 'EG', label: 'Egypt' },
//     { code: 'SV', label: 'El Salvador' },
//     { code: 'GQ', label: 'Equatorial Guinea' },
//     { code: 'ER', label: 'Eritrea' },
//     { code: 'EE', label: 'Estonia' },
//     { code: 'ET', label: 'Ethiopia' },
//     { code: 'FK', label: 'Falkland Islands' },
//     { code: 'FO', label: 'Faroe Islands' },
//     { code: 'FJ', label: 'Fiji' },
//     { code: 'FI', label: 'Finland' },
//     { code: 'FR', label: 'France' },
//     { code: 'GF', label: 'French Guiana' },
//     { code: 'PF', label: 'French Polynesia' },
//     { code: 'TF', label: 'French Southern/Antarctic Lands' },
//     { code: 'GA', label: 'Gabon' },
//     { code: 'GE', label: 'Georgia' },
//     { code: 'DE', label: 'Germany' },
//     { code: 'GH', label: 'Ghana' },
//     { code: 'GI', label: 'Gibraltar' },
//     { code: 'GR', label: 'Greece' },
//     { code: 'GL', label: 'Greenland' },
//     { code: 'GD', label: 'Grenada' },
//     { code: 'GP', label: 'Guadeloupe' },
//     { code: 'GU', label: 'Guam' },
//     { code: 'GT', label: 'Guatemala' },
//     { code: 'GG', label: 'Guernsey' },
//     { code: 'GW', label: 'Guinea Bissau' },
//     { code: 'GN', label: 'Guinea' },
//     { code: 'GY', label: 'Guyana' },
//     { code: 'HT', label: 'Haiti' },
//     { code: 'HM', label: 'Heard Island/McDonald Islands' },
//     { code: 'HN', label: 'Honduras' },
//     { code: 'HK', label: 'Hong Kong(SAR of China)' },
//     { code: 'HU', label: 'Hungary' },
//     { code: 'IS', label: 'Iceland' },
//     { code: 'IN', label: 'India' },
//     { code: 'ID', label: 'Indonesia' },
//     { code: 'IR', label: 'Iran' },
//     { code: 'IQ', label: 'Iraq' },
//     { code: 'IE', label: 'Ireland' },
//     { code: 'IM', label: 'Isle of Man' },
//     { code: 'IL', label: 'Israel' },
//     { code: 'IT', label: 'Italy' },
//     { code: 'JM', label: 'Jamaica' },
//     { code: 'JP', label: 'Japan' },
//     { code: 'JE', label: 'Jersey' },
//     { code: 'JO', label: 'Jordan' },
//     { code: 'KZ', label: 'Kazakhstan' },
//     { code: 'KE', label: 'Kenya' },
//     { code: 'KI', label: 'Kiribati' },
//     { code: 'KW', label: 'Kuweit' },
//     { code: 'KG', label: 'Kyrgyzstan' },
//     { code: 'LA', label: 'Laos' },
//     { code: 'LV', label: 'Latvia' },
//     { code: 'LB', label: 'Lebanon' },
//     { code: 'LS', label: 'Lesotho' },
//     { code: 'LR', label: 'Liberia' },
//     { code: 'LY', label: 'Libya' },
//     { code: 'LI', label: 'Liechtenstein' },
//     { code: 'LT', label: 'Lithuania' },
//     { code: 'LU', label: 'Luxembourg' },
//     { code: 'MO', label: 'Macao(SAR of China)' },
//     { code: 'MK', label: 'Macedonia' },
//     { code: 'MG', label: 'Madagascar' },
//     { code: 'MW', label: 'Malawi' },
//     { code: 'MY', label: 'Malaysia' },
//     { code: 'MV', label: 'Maldives' },
//     { code: 'ML', label: 'Mali' },
//     { code: 'MT', label: 'Malta' },
//     { code: 'MH', label: 'Marshall Islands' },
//     { code: 'MQ', label: 'Martinique' },
//     { code: 'MR', label: 'Mauritania' },
//     { code: 'MU', label: 'Mauritius' },
//     { code: 'YT', label: 'Mayotte' },
//     { code: 'MX', label: 'Mexico' },
//     { code: 'FM', label: 'Micronesia(Federated States of)' },
//     { code: 'MD', label: 'Moldova' },
//     { code: 'MC', label: 'Monaco' },
//     { code: 'MN', label: 'Mongolia' },
//     { code: 'ME', label: 'Montenegro' },
//     { code: 'MS', label: 'Montserrat' },
//     { code: 'MA', label: 'Morocco' },
//     { code: 'MZ', label: 'Mozambique' },
//     { code: 'MM', label: 'Myanmar' },
//     { code: 'NA', label: 'Namibia' },
//     { code: 'NR', label: 'Nauru' },
//     { code: 'NP', label: 'Nepal' },
//     { code: 'NC', label: 'New Caledonia' },
//     { code: 'NZ', label: 'New Zealand' },
//     { code: 'NI', label: 'Nicaragua' },
//     { code: 'NE', label: 'Niger' },
//     { code: 'NG', label: 'Nigeria' },
//     { code: 'NU', label: 'Niue' },
//     { code: 'NF', label: 'Norfolk Island' },
//     { code: 'KP', label: 'North Korea' },
//     { code: 'MP', label: 'Northern Mariana Islands' },
//     { code: 'NO', label: 'Norway' },
//     { code: 'OM', label: 'Oman' },
//     { code: 'PK', label: 'Pakistan' },
//     { code: 'PW', label: 'Palau' },
//     { code: 'PS', label: 'Palestinian Territory' },
//     { code: 'PA', label: 'Panama' },
//     { code: 'PG', label: 'Papua New Guinea' },
//     { code: 'PY', label: 'Paraguay' },
//     { code: 'PE', label: 'Peru' },
//     { code: 'PH', label: 'Philippines' },
//     { code: 'PN', label: 'Pitcairn' },
//     { code: 'PL', label: 'Poland' },
//     { code: 'PT', label: 'Portugal' },
//     { code: 'PR', label: 'Puerto Rico' },
//     { code: 'QA', label: 'Qatar' },
//     { code: 'CG', label: 'Republic of the Congo(Brazzaville)' },
//     { code: 'RE', label: 'Reunion' },
//     { code: 'RO', label: 'Romania' },
//     { code: 'RU', label: 'Russia' },
//     { code: 'RW', label: 'Rwanda' },
//     { code: 'BL', label: 'Saint - Barthélemy' },
//     { code: 'SH', label: 'Saint Helena' },
//     { code: 'KN', label: 'Saint Kitts and Nevis' },
//     { code: 'LC', label: 'Saint Lucia' },
//     { code: 'SX', label: 'Saint Martin(Dutch part)' },
//     { code: 'MF', label: 'Saint Martin(French part)' },
//     { code: 'PM', label: 'Saint Pierre and Miquelon' },
//     { code: 'VC', label: 'Saint Vincent/Grenadines' },
//     { code: 'WS', label: 'Samoa' },
//     { code: 'SM', label: 'San Marino' },
//     { code: 'SA', label: 'Saudi Arabia' },
//     { code: 'RS', label: 'Serbia' },
//     { code: 'SC', label: 'Seychelles' },
//     { code: 'SL', label: 'Sierra Leone' },
//     { code: 'SG', label: 'Singapore' },
//     { code: 'SK', label: 'Slovakia' },
//     { code: 'SI', label: 'Slovenia' },
//     { code: 'SB', label: 'Solomon Islands' },
//     { code: 'SO', label: 'Somalia' },
//     { code: 'ZA', label: 'South Africa' },
//     { code: 'GS', label: 'South Georgia/South Sandwich Islands' },
//     { code: 'KR', label: 'South Korea' },
//     { code: 'SS', label: 'South Sudan' },
//     { code: 'ES', label: 'Spain' },
//     { code: 'LK', label: 'Sri Lanka' },
//     { code: 'SD', label: 'Sudan' },
//     { code: 'SR', label: 'Suriname' },
//     { code: 'SJ', label: 'Svalbard and Jan Mayen' },
//     { code: 'SZ', label: 'Swaziland' },
//     { code: 'SE', label: 'Sweden' },
//     { code: 'CH', label: 'Switzerland' },
//     { code: 'SY', label: 'Syria' },
//     { code: 'ST', label: 'São Tomé and Príncipe' },
//     { code: 'SN', label: 'Sénégal' },
//     { code: 'TW', label: 'Taiwan' },
//     { code: 'TJ', label: 'Tajikistan' },
//     { code: 'TZ', label: 'Tanzania' },
//     { code: 'TH', label: 'Thailand' },
//     { code: 'GM', label: 'The Gambia' },
//     { code: 'NL', label: 'The Netherlands' },
//     { code: 'TL', label: 'Timor - Leste' },
//     { code: 'TG', label: 'Togo' },
//     { code: 'TK', label: 'Tokelau' },
//     { code: 'TO', label: 'Tonga' },
//     { code: 'TT', label: 'Trinidad and Tobago' },
//     { code: 'TN', label: 'Tunisia' },
//     { code: 'TR', label: 'Turkey' },
//     { code: 'TM', label: 'Turkmenistan' },
//     { code: 'TC', label: 'Turks and Caicos Islands' },
//     { code: 'TV', label: 'Tuvalu' },
//     { code: 'UG', label: 'Uganda' },
//     { code: 'UA', label: 'Ukraine' },
//     { code: 'AE', label: 'United Arab Emirates' },
//     { code: 'GB', label: 'United Kingdom' },
//     { code: 'UM', label: 'United States Minor Outlying Islands' },
//     { code: 'VI', label: 'United States Virgin Islands' },
//     { code: 'US', label: 'United States of America' },
//     { code: 'UY', label: 'Uruguay' },
//     { code: 'UZ', label: 'Uzbekistan' },
//     { code: 'VU', label: 'Vanuatu' },
//     { code: 'VE', label: 'Venezuela' },
//     { code: 'VN', label: 'Vietnam' },
//     { code: 'WF', label: 'Wallis and Futuna' },
//     { code: 'EH', label: 'Western Sahara' },
//     { code: 'YE', label: 'Yemen' },
//     { code: 'ZM', label: 'Zambia' },
//     { code: 'ZW', label: 'Zimbabwe' },
// ];


// export const Personality = [
//     // { value: 0, label: 'Any', id: 'personality.any' },
//     { value: 2, label: 'Adventurer', id: 'personality.athletic' },
//     { value: 4, label: 'Artsy', id: 'personality.athletic' },
//     { value: 8, label: 'Athletic', id: 'personality.athletic' },
//     { value: 16, label: 'Beach Lover', id: 'personality.athletic' },
//     { value: 32, label: '', id: 'personality.athletic' },
//     { value: 64, label: 'Breweries', id: 'personality.athletic' },
//     { value: 128, label: 'Camper', id: 'personality.athletic' },
//     { value: 256, label: 'Chef', id: 'personality.athletic' },
//     { value: 1024, label: 'Concerts', id: 'personality.athletic' },
//     { value: 2048, label: 'Cooking', id: 'personality.athletic' },
//     { value: 4096, label: '', id: 'personality.athletic' },
//     { value: 8192, label: '', id: 'personality.athletic' },
//     { value: 2048, label: '', id: 'personality.athletic' },
//     { value: 4096, label: '', id: 'personality.athletic' },
//     { value: 8192, label: '', id: 'personality.athletic' },
//     { value: 2048, label: 'Foodie', id: 'personality.athletic' },
//     { value: 4096, label: 'Football', id: 'personality.athletic' },
//     { value: 8192, label: '', id: 'personality.athletic' },
//     { value: 2048, label: '', id: 'personality.athletic' },
//     { value: 4096, label: '', id: 'personality.athletic' },
//     { value: 8192, label: '', id: 'personality.athletic' },
//     { value: 2048, label: '', id: 'personality.athletic' },
//     { value: 4096, label: '', id: 'personality.athletic' },
//     { value: 8192, label: '', id: 'personality.athletic' },
// ];


// { value: 'ISTJ', label: 'The Duty Fulfiller', desc: '(Serious and quiet. Extremely thorough, responsible, and dependable)', id: 'bodyType.athletic' },
// { value: 'ISTP', label: 'The Mechanic', desc: '(Excellent skills with mechanical things. Risk-takers who they live for the moment)', id: 'bodyType.athletic' },
// { value: 'ISFJ', label: 'The Nurturer', desc: '(Quiet, kind, conscientious, dependable, stable and practical)', id: 'bodyType.athletic' },
// { value: 'ISFP', label: 'The Artist', desc: '(Appreciation for beauty. Quiet, sensitive, kind, open-minded, original, and creative)', id: 'bodyType.athletic' },
// { value: 'INFJ', label: 'The Protector', desc: '(Quietly forceful, original, sensitive, intuitive. Doing the right thing)', id: 'bodyType.athletic' },
// { value: 'INFP', label: 'The Idealist', desc: '(Quiet, reflective, idealistic, serving humanity with a well-developed value system)', id: 'bodyType.athletic' },
// { value: 'INTJ', label: 'The Scientist', desc: '(Independent, original, analytical, and determined long-range thinkers)', id: 'bodyType.athletic' },
// { value: 'INTP', label: 'The Thinker', desc: '(Individualistic, logical, original, creative thinkers, value knowledge, and logic)', id: 'bodyType.athletic' },
// { value: 'ESTP', label: 'The Doer', desc: '(Friendly, adaptable, action-oriented, here-and-now, risk-takers and fast-paced)', id: 'bodyType.athletic' },
// { value: 'ESTJ', label: 'The Guardian', desc: '(Practical, traditional, organized, loyal and hard-working. Likely to be athletic)', id: 'bodyType.athletic' },
// { value: 'ESFP', label: 'The Performer', desc: '(People-oriented and fun-loving. Living for the moment, they love new experiences)', id: 'bodyType.athletic' },
// { value: 'ESFJ', label: 'The Caregiver', desc: '(Warm-hearted, popular, and conscientious. Puts the needs of others first)', id: 'bodyType.athletic' },
// { value: 'ENFP', label: 'The Inspirer', desc: '(Enthusiastic, idealistic, and creative. Great people skills)', id: 'bodyType.athletic' },
// { value: 'ENFJ', label: 'The Giver', desc: '(Popular and sensitive, with outstanding people skills. Usually dislike being alone)', id: 'bodyType.athletic' },
// { value: 'ENTP', label: 'The Visionary', desc: '(Creative, resourceful, and intellectually quick. Good at a broad range of things)', id: 'bodyType.athletic' },
// { value: 'ENTJ', label: 'The Executive', desc: '(Assertive and outspoken. They are driven to lead)' }


/* eslint-enable no-unused-vars */