// Video Chat

import React, { useEffect, useRef, useState } from "react"
import clsx from 'clsx'

// useCallback useMemo
// import isEmpty from 'lodash/isEmpty'
// import isEmpty from '../validation/is-empty'
// import { SmallButton } from "../styles/ftfStyles"

import ReactHowler from 'react-howler'
import { JellyAnimation } from '../helpers/utilities'

import { useDispatch, useSelector } from "react-redux"

import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery'

import { Button, ButtonGroup, Grid, IconButton, Tooltip, Typography, Zoom } from '@mui/material'
import Draggable from 'react-draggable'

import BlockIcon from '@mui/icons-material/Block'
import CancelIcon from '@mui/icons-material/Cancel'
import ExitToAppIcon from '@mui/icons-material/ExitToApp'

import VoiceChatIcon from '@mui/icons-material/VoiceChat'

import MicIcon from '@mui/icons-material/Mic'
import MicOffIcon from '@mui/icons-material/MicOff'
import VideocamIcon from '@mui/icons-material/Videocam'
import VideocamOffIcon from '@mui/icons-material/VideocamOff'

import { FormattedMessage } from 'react-intl'

// Needed for onTouchTap?
// http://stackoverflow.com/a/34015469/988941
// var injectTapEventPlugin = import "react-tap-event-plugin"
// injectTapEventPlugin()

// Services  // Message
import { socketCreateMeeting, socketMeetingLeave, socketMeetingJoin, socketMeetingRejected } from '../actions/socket.actions'
import { blockMember } from '../actions/member.actions'

import Avatar from './Avatar'

import BeatLoader from 'react-spinners/BeatLoader'
import isEmpty from "../validation/is-empty"
// import ThemeApp from "../styles/ThemeApp";

// import { css } from 'styled-components'
// const cssBeatLoader = css`
//     display: block
//     margin: 0 auto
//     border-color: blue
// `


// const ValidationTextField = withStyles(theme => ({
//     root: {
//         '& .MuiOutlinedInput-root': {
//             '& fieldset': {
//                 borderColor: 'red',
//             },
//             '&:hover fieldset': {
//                 borderColor: theme.palette.primary.dark,
//             },
//             '&.Mui-focused fieldset': {
//                 borderColor: 'green',
//             },
//         },

//         '& input:valid + fieldset': {
//             borderColor: theme.palette.primary.main,
//             borderWidth: 2,
//         },
//         '& input:invalid + fieldset': {
//             borderColor: theme.palette.warning.main,
//             borderWidth: 2,
//         },
//         '& input:valid:focus + fieldset': {
//             borderWidth: 2,
//             borderLeftWidth: 6,
//             borderColor: theme.palette.primary.main,
//             padding: '4px !important', // override inline-style
//         },
//     },
// }))(TextField);


const useStyles = makeStyles(theme => ({

    accessId: {
        alignSelf: 'center',
        // color: theme.palette.primary.light,
        margin: 8,
    },

    // toolbar: {
    //     display: 'flex',
    //     flexDirection: 'row',
    // },

    actionButton: {
        cursor: "pointer",
        color: theme.palette.primary.light,
    },

    actionGroup: {
        // cursor: 'auto',
        // zIndex: 2001,

        width: 'auto',
        margin: '4px 8px',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',

        position: 'absolute',
        bottom: 6,
        right: 0,
    },

    categoryControl: {
        margin: 8,
        width: '100%',
        backgroundColor: theme.palette.background.paper,

        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },

    headingName: {
        alignSelf: 'center',
        color: theme.palette.primary.light,
        // color: theme.palette.primary.main,
        marginLeft: 8,
        marginTop: 0,
        // textAlign: 'left',
    },

    headingCityState: {
        alignSelf: 'center',
        // color: theme.palette.primary.light,
        color: 'rgba(255,255,255,.50)',
        marginLeft: 8,
    },

    toolbarButton: {
        minWidth: 115,
        maxWidth: 115,
        margin: 2,
        padding: '8px 12px',
    },

    videoAccessId: {
        width: '70%',
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)'
    },

    videoContainer: {
        alignSelf: 'flex-end',
        marginLeft: 0,
        marginTop: 44,
        width: '100%',
    },

    videoChat: {
        display: 'flex',
        flexDirection: 'initial',
        margin: 8,
        // zIndex: 2000,

        // Desktop/tablet/mobile
        maxWidth: window.innerWidth,
        minWidth: 370,
        minHeight: `calc(370px / 1.7777777778)`,

        width: `calc(100vw * .5)`,
        height: `calc(calc(100vw * .5) / 1.7777777778)`,  // Maintain Aspect Ratio 1.7777777778

        // Phone Landscape (960)
        [theme.breakpoints.down('md')]: {
            flexDirection: 'column',
            margin: '0',
        },

        // Phone Portrait (600)
        [theme.breakpoints.down('sm')]: {
            width: '100vw',
            height: `calc(100vw / 1.7777777778)`,
            maxHeight: 'unset',
        },
    },

    videoDialog: {
        position: 'relative',
        background: 'black',

        border: 'solid',
        borderRadius: '20px 20px 20px 20px',
        borderWidth: 3,
        borderColor: theme.palette.primary.main,

        // Phone Portrait
        // [theme.breakpoints.down('xs')]: {
        //     // width: 'unset',
        //     height: 'auto',
        // },
    },

    videoMessage: {
        // display: 'flex',
        // flexDirection: 'row',

        alignSelf: 'center',
        color: theme.palette.primary.main,
        textAlign: 'center',

        position: "absolute",
        top: "48%",
        left: "50%",
        transform: "translate(-50%, -50%)"
    },

    videoLocal: {
        border: 'solid',
        borderWidth: 1,
        borderRadius: '0px 5px 0px 18px',
        borderColor: theme.palette.primary.main,

        bottom: -1,
        left: -1,
        position: 'absolute',

        height: 55,
        width: 'auto',
    },

    videoRemote: {
        // backgroundColor: 'green',
        borderRadius: '20px 20px 20px 20px',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        margin: 0,
        position: 'absolute',
        // backgroundColor: 'rgba(255, 255, 255, 0.15)',
    },

    videoOverlay: {
        position: "absolute",
        top: 0,
        left: 0,
        margin: 4,

        display: 'flex',
        // justifyContent: 'center',

        // width: '100%',
        // width: '-moz-available',          /* WebKit-based browsers will ignore this. */
        // width: '-webkit-fill-available',  /* Mozilla-based browsers will ignore this. */
        // width: 'fill-available',


        height: '100%',

        // Phone Portrait
        // [theme.breakpoints.down('xs')]: {
        //     height: `calc(100% *.76)`,
        // },
    },
}))


//-----------------------------------------------
// VideoChat
//-----------------------------------------------
export default function VideoChat(props) {
    // const isCreatingRoom = props.isCreatingRoom // Is requesting the VC...
    // const isRoomCreator = useSelector(state => state.socket.isRoomCreator)

    const isPhonePortrait = useMediaQuery(theme => theme.breakpoints.down('sm'));
    const classes = useStyles(props)
    const theme = useTheme()

    // const appHeight = useSelector(state => state.app.height)
    // const appWidth = useSelector(state => state.app.width)

    const localStream = useSelector(state => state.socket.localStream)
    const remoteStream = useSelector(state => state.socket.remoteStream)
    const videoChatMsgId = useSelector(state => state.socket.videoChatMsgId)
    const accessId = useSelector(state => state.socket.accessId)

    const showVideoChat = useSelector(state => state.app.showVideoChat)
    const isVideoChatOwner = useSelector(state => state.app.isRoomCreator)

    const vcUser = useSelector(state => state.app.user)

    const userId = useSelector(state => state.profile.profile._id)

    const dispatch = useDispatch()

    // const [isDraggingDisabled, setDraggingDisabled] = useState(false)

    const [controlledPosition, setControlledPosition] = useState({ x: 0, y: 44 })

    const [isMuted, setIsMuted] = useState(false)
    const [isPaused, setIsPaused] = useState(false)

    const refLocalStream = useRef(null)
    const refRemoteStream = useRef(null)

    const width = useSelector(state => state.app.width)

    // const isMobile = (width <= 500)
    // function isMobile() {
    //     const isAndroid = /Android/i.test(navigator.userAgent);
    //     const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
    //     return isAndroid || isiOS;
    // }

    useEffect(() => {
        setControlledPosition({ x: width <= 500 ? 0 : theme.drawerWidth, y: 44 })
    }, [theme.drawerWidth, width])


    //-------------------------------------------
    // https://codesandbox.io/s/rm3v532lwm
    //-------------------------------------------
    useEffect(() => {
        // On mount...
        // if (width === 0) {
        //     setControlledPosition({ x: 0, y: 44 })
        // }

        const handleResize = () => {
            setControlledPosition({ x: width <= 500 ? 0 : theme.drawerWidth, y: 44 })
            // console.log("Window width: " + window.innerWidth + "/ height: " + window.innerHeight)
        }

        window.addEventListener('resize', handleResize)

        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [theme.drawerWidth, width])


    //-------------------------------------------
    // Reposition Chat on a resize
    //-------------------------------------------
    // useEffect(() => {
    //     setControlledPosition({ x: 0, y: 44 })
    //     // console.log('adjustXPos')
    //     // console.log("Window width: " + width + "/ height: " + height)
    // }, [])
    // // }, [appHeight, appWidth])


    //-------------------------------------------
    // localStream  
    //-------------------------------------------
    useEffect(() => {
        if (refLocalStream && refLocalStream.current) {
            refLocalStream.current.srcObject = localStream
        }
    }, [refLocalStream, localStream])


    //-------------------------------------------
    // remoteStream
    //-------------------------------------------
    useEffect(() => {
        if (refRemoteStream && refRemoteStream.current) {
            refRemoteStream.current.srcObject = remoteStream
        }
    }, [refRemoteStream, remoteStream])


    //-------------------------------------------
    // handleCreateRoom
    //-------------------------------------------
    useEffect(() => {
        function handleCreateRoom(isRoomCreator) {
            let guestId

            // Reconcile different objects
            if (vcUser.guestId != null)       // from a chat object in a table
                guestId = vcUser.memberId
            else if (vcUser.memberId != null) // from a chat object in a chat
                guestId = vcUser.memberId
            else if (vcUser._id != null)       // chat as a member object
                guestId = vcUser._id

            if (!isEmpty(guestId))
                dispatch(socketCreateMeeting(userId, guestId, isRoomCreator))
            else
                console.log('VideoChat.CreateRoom: guestId is null')
        }


        if (vcUser && !localStream && showVideoChat && isVideoChatOwner)
            handleCreateRoom(true)

    }, [dispatch, vcUser, localStream, userId, showVideoChat, isVideoChatOwner])


    //-------------------------------------------
    // Accept Meeting
    //-------------------------------------------
    function handleAcceptMeeting(e) {
        e.stopPropagation()
        dispatch(socketMeetingJoin(accessId, userId))

        // setIsVCAccepted(true)     // *Note: Do this only on send
    }

    //-------------------------------------------
    // Pause Video
    //-------------------------------------------
    function handlePause(e) {
        // e.stopPropagation()
        localStream.getVideoTracks()[0].enabled = isPaused
        localStream.getAudioTracks()[0].enabled = isPaused
        setIsPaused(!isPaused)
    }

    //-------------------------------------------
    // Mute Mic
    //-------------------------------------------
    function handleMute(e) {
        // e.stopPropagation()
        localStream.getAudioTracks()[0].enabled = isMuted
        setIsMuted(!isMuted)
    }

    //-------------------------------------------
    // Leave Chat
    //-------------------------------------------
    function handleLeaveChat(e) {
        // e.stopPropagation()

        if (refLocalStream.current.srcObject)
            dispatch(socketMeetingLeave(userId, accessId))
        else
            dispatch(socketMeetingRejected(userId, accessId))

        refLocalStream.current.srcObject = null
        refRemoteStream.current.srcObject = null

        props.onClose()
    }

    //-------------------------------------------
    // Reject Chat
    //-------------------------------------------
    function handleRejectChat(e) {
        e.stopPropagation()

        dispatch(socketMeetingRejected(userId, accessId))

        props.onClose()
    }

    //-------------------------------------------
    // Block Member Chat
    //-------------------------------------------
    function handleBlocking(e) {
        e.stopPropagation()

        dispatch(socketMeetingRejected(userId, accessId))
        dispatch(blockMember(vcUser.id))

        props.onClose()
    }

    //-------------------------------------------
    // handleEnableDrag
    //-------------------------------------------
    function onControlledDrag(e, position) {
        // console.log('>>>---> onControlledDrag')
        const { x, y } = position;
        setControlledPosition({ x, y })
    }

    //-------------------------------------------
    // onControlledDragStop
    //-------------------------------------------
    function onControlledDragStop(e, position) {
        if (position.x >= 0 && position.y >= 0)
            onControlledDrag(e, position)
        else
            setControlledPosition({ x: 0, y: 44 })
    }


    return (
        <div>
            {showVideoChat &&
                <div className={classes.videoChat} >
                    {/* Mobile vs. Desktop Issue */}
                    {/* https://github.com/react-grid-layout/react-draggable/issues/550 */}
                    <Draggable
                        id="Draggable"

                        enableUserSelectHack={false}

                        allowAnyClick={false}
                        disabled={false}
                        axis="both"
                        bounds="body"  // "parent"

                        position={controlledPosition}
                        // start={{ left: 0, top: toolbarHeight }}

                        cancel='.toolbarButton'

                        onStart={() => true}
                        onStop={onControlledDragStop}
                    >
                        {/* Sit just below AppBar ie: marginTop */}
                        <div id="Container" className={classes.videoContainer}>
                            {/* Note: Logout clears 'chat' prop from parent */}
                            {vcUser && <div id='videoChat' className={clsx(classes.videoChat, classes.videoDialog)} >
                                {/* <div id='spinner' style={{ boxSizing: "content-box", position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" }}>
                            <BeatLoader css={cssBeatLoader} sizeUnit={"px"} size={32} color={theme.palette.primary.dark} loading />
                        </div> */}

                                {/* https://github.com/facebook/react/issues/11163 */}
                                <video id="remote-video" ref={refRemoteStream} playsInline autoPlay className={classes.videoRemote} />

                                <div className={classes.videoOverlay} >
                                    <Avatar borderColor={theme.palette.primary.main} small gender={vcUser.gender} avatarUrl={vcUser.avatarUrl} delay={250} alt={vcUser.firstname}>...</Avatar>

                                    <div style={{ display: 'inline-block' }} >
                                        <Typography className={classes.headingName} variant="h5">
                                            {vcUser.firstname}
                                        </Typography>

                                        {vcUser.city && <Typography className={classes.headingCityState} variant="body2">
                                            {vcUser.city}, {vcUser.state}
                                        </Typography>}
                                    </div>
                                </div>

                                <div className={classes.videoMessage}>
                                    {videoChatMsgId && <Typography color={theme.palette.primary.light} variant="subtitle1">
                                        {<FormattedMessage id={videoChatMsgId.toString()} defaultMessage="VideoChat not available" />}
                                    </Typography>}

                                    {!videoChatMsgId && <div id='spinner' >
                                        <BeatLoader sizeUnit={"px"}
                                            color={theme.palette.primary.light}
                                            loading={!remoteStream}
                                        />
                                    </div>}

                                    <ButtonGroup className={classes.toolbar}
                                        // orientation="vertical"
                                        color="primary"
                                        aria-label="Group Toolbar"
                                    // variant="contained"
                                    >
                                        {!isVideoChatOwner && !remoteStream && <Button name='vcAccept' variant="contained" color="primary"
                                            // disabled={isChatting}
                                            onClick={(e) => handleAcceptMeeting(e)}
                                            onTouchEnd={(e) => handleAcceptMeeting(e)}

                                            // style={SmallButton}
                                            className={classes.toolbarButton}
                                            startIcon={<VoiceChatIcon aria-label='Accept' />}
                                        >
                                            {/* ../sounds/video-chat.wav */}
                                            {/* <ReactHowler src={'https://findtruefriends.com/sounds/video-chat.wav'} playing preload loop /> */}

                                            <ReactHowler src={'../sounds/video-chat.wav'} playing preload loop />
                                            <FormattedMessage id="system.accept" defaultMessage="Accept" />
                                        </Button>}

                                        {!localStream && !isVideoChatOwner && <Button name='vcReject' variant="contained" color="primary"
                                            onClick={(e) => handleRejectChat(e)}
                                            onTouchEnd={(e) => handleRejectChat(e)}

                                            // style={SmallButton}
                                            className={classes.toolbarButton}
                                            startIcon={<CancelIcon aria-label='Reject' />}
                                        >
                                            <FormattedMessage id="system.reject" defaultMessage="Reject" />
                                        </Button>}

                                        {!localStream && !isVideoChatOwner && <Button name='vcReject' variant="contained" color="primary"
                                            onClick={(e) => handleBlocking(e)}
                                            onTouchEnd={(e) => handleBlocking(e)}

                                            // style={SmallButton}
                                            className={classes.toolbarButton}
                                            startIcon={<BlockIcon aria-label='Block' />}
                                        >
                                            <FormattedMessage id="tooltip.block" defaultMessage="Block" />
                                        </Button>}
                                    </ButtonGroup>
                                </div>

                                <video id="local-video" ref={refLocalStream} playsInline autoPlay muted="muted" className={classes.videoLocal} />

                                {/* Future: Acccess Id -  */}
                                {/* <ValidationTextField name="Access Id" id="validation-outlined-input"
                        required
                        fullWidth
                        className={classes.videoAccessId}
                        label={<FormattedMessage id="videoChat.accessId" defaultMessage="Access Id" />}
                        variant="outlined"
                        defaultValue="abcde"
                        onChange={(e) => setMeetingId(e.target.value)}
                        />  */}

                                {/* <Grid container direction="column" alignItems="flex-end" style={{ height: 100, padding: 8 }}> */}

                                <Grid container direction={isPhonePortrait ? "column" : "row"} className={classes.actionGroup} >
                                    {localStream && <Tooltip enterDelay={750} arrow TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="videoChat.pause" defaultMessage="Pause" />}>
                                        <JellyAnimation>
                                            <IconButton
                                                className={classes.actionButton}
                                                onClick={(e) => handlePause(e)}
                                                onTouchEnd={(e) => handlePause(e)}
                                                aria-label="Pause"
                                                color="primary"
                                                size="large"
                                            >
                                                {!isPaused && <VideocamIcon fontSize="small" />}
                                                {isPaused && <VideocamOffIcon color={theme.palette.error.light} fontSize="small" />}
                                            </IconButton>
                                        </JellyAnimation>
                                    </Tooltip>}

                                    {localStream && <Tooltip enterDelay={750} arrow TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="videoChat.mute" defaultMessage="Mute" />}>
                                        <JellyAnimation>
                                            <IconButton
                                                className={classes.actionButton}
                                                onClick={(e) => handleMute(e)}
                                                onTouchEnd={(e) => handleMute(e)}
                                                aria-label="Mute"
                                                // color="primary"
                                                size="large"
                                            >
                                                {!isMuted && <MicIcon fontSize="small" />}
                                                {isMuted && <MicOffIcon color={'pink'} fontSize="small" />}
                                            </IconButton>
                                        </JellyAnimation>
                                    </Tooltip>}

                                    <Tooltip enterDelay={750} arrow TransitionComponent={Zoom} placement="top" title={<FormattedMessage id="system.leave" defaultMessage="Leave" />}>
                                        <JellyAnimation>
                                            <IconButton
                                                className={classes.actionButton}
                                                onClick={(e) => handleLeaveChat(e)}
                                                onTouchEnd={(e) => handleLeaveChat(e)}
                                                aria-label="Leave"
                                                color="primary"
                                                size="large"
                                            >
                                                <ExitToAppIcon fontSize="small" />
                                            </IconButton>
                                        </JellyAnimation>
                                    </Tooltip>
                                </Grid>
                            </div>}
                        </div>
                    </Draggable >
                </div>
            }

        </div>
    )
}


